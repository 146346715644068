<template>
  <div :class="isStatus ? '_box':''">
    <FloatPlayer
      :class="isStatus ? 'cpt-common-float-playerBox':''"
      @close="$emit('close')"
      type="3"
      :data="data"
    />
  </div>
</template>

<script>
import FloatPlayer from "@/components/common/float-player";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data: {
    isStatus: false,
  },
  components: { FloatPlayer },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep {
  .cpt-common-float-player {
    top: 0;
  }
}


</style>
<style lang="scss">

</style>