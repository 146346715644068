<template>
  <div style="width: 100%;height: 100%">
   <LivePlayer class="livenvr-player" ref="liveqing-player" :videoUrl="videoUrl" />
 </div>
</template>

<script>
  import LivePlayer from "@liveqing/liveplayer";
  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    components: { LivePlayer },
    data() {
      return {
        videoUrl: "",
      };
    },
    watch: {
      data: {
        handler(value) {
          if (value && value.vUrl) {
            this.videoUrl = value.vUrl
            this.$nextTick(() => {
              this.$refs["liveqing-player"].play();
            });
          }
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>

<style lang="scss">
  .livenvr-player {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .vjs-volume-panel,
  .vjs-playback-rate,
  .live-player-stretch-btn,
  .vjs-time-divider,
  .vjs-duration,
  .vjs-live-control {
    display: none !important;
  }
</style>