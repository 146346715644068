<template>
  <div class="videoTomapBox">
    <div class="item cf tc cp">
      <SymbolIcon icon="qiehuanshijiao" />
    </div>
    <div class="videoTomapBox_right">
      <el-tooltip content="无人机视频切换" placement="bottom">
        <div class="ml10 itemList cp mt2" @click="changeSj(0)">
          <SymbolIcon v-if="itmeIndex == 0 && flag" icon="suoyoushijiao" />
          <SymbolIcon v-else icon="diyishijiao" />
        </div>
      </el-tooltip>
      <el-tooltip content="仓内视频切换" placement="bottom">
        <div class="ml10 itemList cp mt2" @click="changeSj(1)">
          <SymbolIcon v-if="itmeIndex == 1 && flag" icon="suoyoushijiao" />
          <SymbolIcon v-else icon="diyishijiao" />
        </div>
      </el-tooltip>
      <el-tooltip content="仓外视频切换" placement="bottom">
        <div class="ml10 itemList cp mt2" @click="changeSj(2)">
          <SymbolIcon v-if="itmeIndex == 2 && flag" icon="suoyoushijiao" />
          <SymbolIcon v-else icon="diyishijiao" />
        </div>
      </el-tooltip>
      <div class="itemList ml20 cp mr10 mt2" @click="$emit('showVideo')">
        <el-tooltip content="视频初始化" placement="bottom">
          <img
            class="dib w32 h23 mt4"
            src="~@/assets/images/observe/fckernel/nest/changeVideo.png"
            alt
          />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";
// 引用bus
import Bus from "@/assets/ligature.js";
export default {
  components: { SymbolIcon },
  data() {
    return {
      flag: false,
      itmeIndex: null,
    };
  },
  methods: {
    changeSj(num) {
      if (num == this.itmeIndex) {
        // 如果当前点击和上次点击相同， 代表关闭全屏，并需要初始化状态
        this.flag = false;
        this.itmeIndex = null;
      } else if (this.flag) {
        //否则如果flag值为true代表正在执行其他视频的全屏操作
        this.itmeIndex = num;
      } else {
        this.flag = !this.flag;
        this.itmeIndex = num;
      }

      // 搜索关键字：飞控 机巢 左下角视频切换为全局时状态
      //  num为0 切换无人机视频 num为1 仓内视频切换  num为2 仓外视频
      Bus.$emit("videos_flag", this.flag, num);
      if (this.flag) {
        this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
          flag: true,
          num,
          type: "yc",
        });
      } else {
        this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
          flag: false,
          num,
          type: "yc",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.videoTomapBox {
  position: absolute;
  left: 20px;
  bottom: 10px;
  width: 150px;
  display: flex;
  justify-content: flex-start;

  .item {
    margin-top: 10px;
    padding: 5px;
    background: rgba(9, 32, 87, 0.7);
    border-radius: 50%;
    border: 2px solid #fff;
  }

  .item:hover {
    transition: 0.3s;
    transform: rotate(180deg);
  }

  .videoTomapBox_right {
    display: none;
    background: rgba(9, 32, 87, 0.7);
    border: 1px solid #43deff;

    margin-top: 10px;
    padding: 3px;
    justify-content: flex-start;
    background: rgba(9, 32, 87, 0.7);

    .itemList {
      background: #000000;
      border-radius: 4px;
      border: 1px solid #eee;
    }
  }
}

.videoTomapBox:hover {
  transition: 1s;

  .videoTomapBox_right {
    display: flex;
  }
}
</style>
