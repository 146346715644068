<template>
  <div class="mountControllerBox">
    <div class="wih00 h40 mountControllerTitle fix">
      <div class="fl ml20 lh40 mr30 cfc fw700">网枪</div>
      <div class="fr lh40 cfc mr20 cp" @click="$emit('close')">关闭</div>
    </div>
    <div class="jcsb h250">
      <div class="w85 dib">
        <div class="w157 cp mt70 h55 leftMountGun lh55 ml100 tc">
          <div style="color: #6da953">
            {{ safety_switch_state ? "未锁定" : "已锁定" }}
          </div>
        </div>
      </div>
      <div class="dib jcsb mt70 mr80 cf">
        <div class="mr40 cp tc" @click="changeFlag">
          <div class="bor50 w32 h32 ml15 lh32 tc" style="background-color: #15d4d2">
            <img class="dib cp w12 h12" src="~@/assets/images/observe/012.png" />
          </div>
          <div>{{ safety_switch_state ? "开启保险" : "关闭保险" }}</div>
        </div>
        <div class="cp" @click="gimbal_mode2_ctrl(1)">
          <div class="bor50 w32 h32 lh32 tc" style="background-color: #15d4d2">
            <img class="dib cp w12 h12" src="~@/assets/images/observe/013.png" />
          </div>
          <div>发射</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import data from "./data/index";
  import methods from "./message/index";
  export default {
    inject: ["mountControllerThis"],
    data() {
      return {
        ...data,
        safety_switch_state: false,
        controInfor: {
          type: 200,
          systemCode: "mmc",
          state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
          username: "",
          height: 0,
          idlingTime: 0,
          data: {
            // this.accident_This.mountList.mountType>1024?
            cmdControlType: 7000,
            cmdFunction: null,
            cmdState: null,
            cmdValue: null,
            cmdBuffer: "",
            cmdName: "MMC_Gimbal_FN3",
            //
          },
          deviceHardId: this.mountControllerThis.deviceHardId,
        },
      };
    },
    mounted() {
      this.controInfor.username = this.user_info && this.user_info.username;
    },
    computed: {
      ...mapGetters(["user_info"]),
    },
    methods: {
      ...methods,
      changeFlag() {
        this.safety_switch_state = !this.safety_switch_state;
      },
      handleClick(num, data) {
        this.safety_switch_state = data;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .mountControllerBox {
    background: rgba(0, 23, 79, 0.7);
    border-radius: 10px;
  }

  .form {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 40px;
    color: #dce9ff;
    letter-spacing: 1px;
    box-sizing: border-box;

    .form_item {
      display: flex;
      margin-bottom: 15px;

      .form_item_title {
        display: block;
        width: 85px;
      }

      .mono_box {
        width: 28px;
        height: 13px;
        border-radius: 3px;
        margin-top: 2px;
        cursor: pointer;
      }

      .active {
        background: url("~@/assets/images/accident/mono_box.png") no-repeat !important;
        background-size: 100%;
      }

      .mono-short,
      .mono-long,
      .slider,
      .text {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
      }

      .mono-left {
        margin-left: 2px;
      }

      .mono-right {
        margin-right: 2px;
      }

      .mono-short {
        width: 111px;
        height: 17px;
        background: url("~@/assets/images/mount_short.png") no-repeat;
        background-size: 100%;
        margin-bottom: 5px;
      }

      .mono-long {
        width: 168px;
        height: 17px;
        background: url("~@/assets/images/mount_long.png") no-repeat;
        background-size: 100%;
        margin-bottom: 5px;
      }

      .imgs {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        &>img {
          cursor: pointer;
          width: 32px;
          height: 32px;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .slider_box {
        width: 140px;
        margin: -7px 5px 0;
      }

      .symbol {
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid #08c2d1;
        width: 24px;
        height: 24px;
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        font-size: 20px;
        padding-left: 1px;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .img_bottom {
      display: flex;
      width: 320px;
      justify-content: space-between;

      &>img {
        cursor: pointer;
        width: 130px;
        height: 130px;
      }
    }

    ::v-deep {

      .el-select,
      .el-input {
        width: 170px;
        height: 26px;
      }

      .el-input__inner {
        width: 170px;
        height: 26px;
        background: #000000;
        border: 1px solid #08c2d1;
        color: #dce9ff;
      }

      .el-select .el-input .el-select__caret {
        line-height: 26px;
      }

      // 修改input清除按钮样式
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-icon-circle-close:before {
            line-height: 16px;
            position: absolute;
            top: 5px;
            right: 3px;
          }
        }
      }

      .el-button {
        margin-left: 10px;
        padding: 0;
        background: #2aefed;
        border-radius: 2px;
        width: 43px;
        height: 24px;
        outline: none;
        color: #000000;
        border: none;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .caozuoImg {
    background-image: url("~@/assets/images/accident/caozuo.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .leftMountGun {
    background-image: url("~@/assets/images/observe/bg_5.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .mountControllerTitle {
    background: rgba(16, 65, 215, 0.20);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.60);
    border-radius: 10px 10px 0 0;
  }
</style>