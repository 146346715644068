import mqtt from "mqtt";
const { VUE_APP_FCMQTT_URL } = process.env;
import dayjs from "dayjs";
import { message } from "@/components/message";
export default {
  uint8array2json(uint8array) {
    if (!uint8array || !uint8array.length) {
      return uint8array;
    }
    let { decoder } = this.mqtt;
    if (!decoder) {
      decoder = new TextDecoder("utf-8");
    }
    let content = decoder.decode(uint8array);
    return content ? JSON.parse(content) : content;
  },
  json2uint8array(data) {
    console.log(data, "shuju");
    if (!data) {
      return data;
    }
    let { encoder } = this.mqtt;
    if (!encoder) {
      encoder = new TextEncoder();
    }
    let uint8array = encoder.encode(JSON.stringify(data));
    return uint8array;
  },
  circulate() {
    clearTimeout(this.uav.offlineTime);
    this.uav.offline = false;
    this.uav.offlineTime = setTimeout(() => {
      this.uav.offline = true;
      this.mqtt_inserWebLogs();
    }, 3000);
  },
  // 记录数据d断开日志
  async inserWebLogs() {
    let day = dayjs();
    let { user_info } = this;
    let { name, deviceHardId } = this.nest.data;
    const time = day.format("YYYY-MM-DD HH:mm:ss");
    let errorData =
      "登录用户：" +
      user_info.name +
      "，所属机构：" +
      name +
      ",无人机id：" +
      deviceHardId +
      ",断开时间" +
      time;
    let errorType = "机巢应用";
    let res = await API.FCKERNEL.inserWebLogs({
      errorType,
      errorData,
    });
  },
  async init() {
    if (this.mqtt.client) {
      this.mqtt.client.end();
      this.mqtt.client = null;
    }
    let { user_info, nest } = this;
    let url = VUE_APP_FCMQTT_URL;
    // 链接mqtt
    const crypto =
      window.crypto ||
      window.webkitCrypto ||
      window.mozCrypto ||
      window.oCrypto ||
      window.msCrypto;
    let uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
    // user_info.id
    this.mqtt.client = mqtt.connect(url, {
      protocol: "mqtt",
      clientId: user_info.id,
    });
    this.mqtt.client_yg = mqtt.connect(url, {
      protocol: "mqtt",
      clientId: uuid,
    });
    this.mqtt.client_yg.on("connect", () => {
      console.log("摇杆 is connected");
    });
    // 断开发起重连
    this.mqtt.client_yg.on("reconnect", (reconnect) => {
      console.log("断开发起重连", reconnect);
    });

    this.mqtt.client.on("connect", () => {
      console.log("mqtt is connected");
    });
    this.mqtt.client.on("close", function (params) {
      console.log(params, "断开链接");
    });
    this.mqtt.client.on("error", (error) => {
      console.log(error, "链接异常");
    });
    // 断开发起重连
    this.mqtt.client.on("reconnect", (reconnect) => {
      console.log("断开发起重连", reconnect);
    });
    this.mqtt.client.on("message", (topic, payload, packet) => {
      let data = this.mqtt_uint8array2json(payload);
      let data2 = JSON.parse(payload.toString());
      window.$log("observe/fckernel/nest/methods/mqtt.js message", data);
      window.$log("topic", topic);
      // console.log(data,'258------------------------------');
      // 飞控 机巢 无人机视频监听
      // if(data.type == 268){
      //     console.log(data,'油门');
      // }
      if (data.type == 258) {
        // console.log(data.data,'258');
        // 修改状态 接受到到258修改状态刷新视频
        this.UAVvideo = true;
        // 重置收到270的状态
        this.count = 0;
        this.ShutdownCount = 0;
        // 由于视频有时不会一进来就会刷新，所以设定间隔
        this.videoCount++;
        // 判断是否打开一键任务
        // 监听oneClicktask的值，变为true触发方法
        if (this.oneClicktask == true) {
          this.taskrenwu();
        }
        // 收到50次258再次刷新一下
        if (this.videoCount == 50) {
          this.UAVvideo = false;
        }
      } else if (data.type == 270) {
        // 每收到一次270 计数一次
        this.count++;
        // 连续超过五次判定为断开（不用定时器的理由是，定时器为异步执行，这里还关联了刷新右上角的状态，考虑到有可能会重新收到信号。所以改为次数判定）
        if (this.count > 5) {
          if (this.count < 10) {
            // 将视频刷新，不会变为最后一刻的影响
            this.UAVvideo = false;
            // 清空右上角的数据
            this.uav.data = {};
          }
        }
      } else if (data.type == 2066) {
        this.ShutdownCount++;
        // 关机计时，飞控机巢在查看充电时的无人机时会造成数据污染，因为充电的的无人机处于开机状态
        if (this.ShutdownCount > 50) {
          // 清空右上角的数据
          this.uav.data = {};
        }
      }
      // console.log(this.count, "data.type");
      // 停机坪数据  机巢id
      let ycTopic = `APRON/RECEIVE/${nest.data.deviceHardId}`;
      window.$log("ycTopic", ycTopic);
      // console.log(topic,'ycTopicycTopicycTopicycTopic');
      // console.log(nest.data.deviceHardId,'nest.data.deviceHardIdnest.data.deviceHardId');
      if (topic == ycTopic) {
        if (data.type == 2065) {
          this.nest.jcData = data.data || {};
        }
        if (data.type == 2066) {
          if (data.data.humidity) {
            this.nest.qxzData = data.data;
          }
        }
        if (data2.type == 2063) {
          this.nest.msg_list.unshift({
            type: 2063,
            text: data2.data.text,
            time: this.mqtt_getstartDate(),
          });
        }
      }

      let infoTopic = `PROCESS/RECEIVE/${nest.data.deviceHardId}`;
      if (infoTopic == topic) {
        // console.log(data,'222222222222222');
        if (data.state == "-1") {
          this.$message.error(data.body);
        } else {
          this.$message.success(data.body);
        }
        this.nest.msg_list.unshift({
          type: data.type,
          text: data.body,
          time: this.mqtt_getstartDate(),
        });
      }
      let wrjTopic = `${nest.data.deviceList[0].type}/RECEIVE/${nest.data.deviceList[0].deviceHardId}`;
      window.$log("wrjTopic", wrjTopic);
      if (topic == wrjTopic) {
        if (data.datatype === 260) {
          // message({
          //     message: data.data.msg,
          //     duration: 2000,
          //     type: "warning"
          // })
          this.nest.msg_list.unshift({
            type: "警告",
            text: data.data.msg,
            time: this.mqtt_getstartDate(),
          });
        }
        if (data.type === 258) {
          this.mqtt_circulate();
          this.uav.data = data?.data;
          this.uav.status = 1;
          // 收集无人机上线上报的数据
          let location = data.data.locationCoordinate3D;
          let attitude = data.data.attitude;
          if (location && attitude) {
            let position = Cesium.Cartesian3.fromDegrees(
              location.longitude * 1,
              location.latitude * 1,
              location.altitude * 1
            );
            // pitch 俯仰角 围绕x旋转
            // yaw 偏航角 围绕x旋转
            // roll 翻滚角 围绕z旋转
            let yaw = Cesium.Math.toRadians(attitude.yaw + 90),
              pitch = Cesium.Math.toRadians(attitude.pitch),
              roll = Cesium.Math.toRadians(attitude.roll),
              hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
              orientation = Cesium.Transforms.headingPitchRollQuaternion(
                position,
                hpr
              );
            if (this.Model) {
              const curPos = this.Model.position.getValue(
                Cesium.JulianDate.now()
              );

              if (curPos) {
                const curCart = new Cesium.Cartesian3(
                  curPos.x,
                  curPos.y,
                  curPos.z
                );
                const afterCart = new Cesium.Cartesian3(
                  position.x,
                  position.y,
                  position.z
                );
                if (curCart.equals(afterCart)) {
                  return false;
                } else {
                  // 航向
                  this.uav.orientation = orientation;
                  // 航线数据
                  this.uav.positions.push(position);
                }
              }
            }
            // 无人机定位
            this.uav.position = position;
          }
          // }
        }

        // 无人机电池数据
        if (data.type === 257) {
          this.$set(this.uav, "battery", data.data);
        }
        // 挂载数据
        if (data.type === 259) {
          let index = this.uav.mounts.findIndex(
            (item) => item.gimbalName === data.data.gimbalName
          );
          if (index === -1) {
            this.uav.mounts.push(data.data);
          }
          this.$set(
            this.uav.mounts_payload,
            data.data.gimbalName,
            data.data.payload
          );
        }
        if (data.type === 268) {
          this.uav.channelData = data.data;
        }
        if (data.type === 270) {
          this.uav.wrjType = data.data;
          this.nest.msg_list.unshift({
            type: data.data.code,
            text: data.data.text,
            time: this.mqtt_getstartDate(),
          });
        }
        // 无人机故障信息
        if (data.type === 269) {
          this.$message.info("data");
        }
      }
    });
    // 订阅机巢数据
    this.mqtt_subscribe(`APRON/RECEIVE/${nest.data.deviceHardId}`);
    // this.mqtt_subscribe(`APRON/RECEIVE/${nest.data.deviceList[0].deviceHardId}`)
    this.mqtt_subscribe(`PROCESS/RECEIVE/${nest.data.deviceHardId}`);
    this.mqtt_subscribe(
      `${nest.data.deviceList[0].type}/RECEIVE/${nest.data.deviceList[0].deviceHardId}`
    );
  },
  getstartDate() {
    let date = new Date();
    let y = date.getFullYear();
    let m =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let mi =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    let startDateTime = y + "-" + m + "-" + d + " " + h + ":" + mi + ":" + s;
    return startDateTime;
  },
  //关闭连接
  close() {
    if (this.mqtt.client) {
      this.mqtt.client.end();
      this.mqtt.client = null;
    }
  },
  /**
   * 订阅
   * @param {*} topic
   * @param {*} callback
   */
  subscribe(topic, callback) {
    if (!topic) {
      return topic;
    }
    let { client } = this.mqtt;
    if (client) {
      client.subscribe(topic, callback);
    }
  },

  /**
   * 取消订阅
   * @param {*} topic
   * @returns
   */
  unsubscribe(topic) {
    if (!topic) {
      return topic;
    }
    let { client } = this.mqtt;
    if (client) {
      client.unsubscribe(topic);
    }
  },
  fn(data, type) {
    // 飞控 机巢 操作区域
    let { nest } = this;
    if (type == "yc") {
      let topic = `PROCESS/OBTAIN/${nest.data.deviceHardId}`;
      this.mqtt_publish(topic, data, (e) => {
        console.log("yc", data);
      });
    } else if (type == "wrj") {
      let topic = `${nest.data.deviceList[0].type}/OBTAIN/${nest.data.deviceList[0].deviceHardId}`;
      this.mqtt_publish(topic, data, (e) => {
        // console.log('数据发送成功');
      });
    } else if (type == "yg") {
      let topic = `${nest.data.deviceList[0].type}/OBTAIN/${nest.data.deviceList[0].deviceHardId}`;
      this.mqtt_publish_yg(topic, data, (e) => {
        console.log("摇杆数据发送成功");
      });
    }
  },
  move_directive(data) {
    if (data.mountId && data.data) {
      this.mqtt_fn(
        {
          type: 514,
          data: {
            mountId: data.mountId,
            payload: data.data,
          },
        },
        "wrj"
      );
    }
  },
  publish(topic, message, callback, options = { qos: 0 }) {
    // console.log(topic,message,"topicmessage");
    let { client } = this.mqtt;
    if (client) {
      // console.log(topic, "topic");
      client.publish(
        topic,
        new TextEncoder().encode(JSON.stringify(message)),
        options,
        callback
      );
    }
  },
  publish_yg(topic, message, callback, options = { qos: 0 }) {
    let { client_yg } = this.mqtt;
    if (client_yg) {
      // console.log(topic, "topic");
      client_yg.publish(
        topic,
        new TextEncoder().encode(JSON.stringify(message)),
        options,
        callback
      );
    }
  },
};
