<template>
  <div class="nset_control_box">
    <div v-if="!infoShow" v-interact>
      <div class="nset_control_box_header lh34">
        <div class="title ml10">
          <!-- <img src="~@/assets/images/mount/mount_head.png" /> -->
          <div class="font">{{ device.deviceName || "" }}</div>
        </div>
        <div class="exit mr10 cp" @click="$emit('exit')">
          <img class="img" src="@/assets/img/i40.png" alt="">
        </div>
      </div>
      <div class="nset_control_box_area p10">
        <div class="wrj">
          <div class="w97 h110 item" v-if="healthData.BAT">
            <img src="@/assets/images/observe/fckernel/health/info.png" alt @click="infoDetail('智能电池', 'BAT')"
              class="cp" />
            <div class="iconfont icon-chuli mt6 ml25 cp" style="color: #ffe417;"
              @click="infoDetail('智能电池', 'BAT', true)" />
            <div class="content">
              <img src="@/assets/images/observe/fckernel/health/cell.png" />
              <span class="dib">智能电池</span>
            </div>
            <div class="notice" v-if="healthData.BAT && healthData.BAT.warningLevel != 'NORMAL'">
              <div v-if="healthData.BAT.title.length <= 6">{{ healthData.BAT.title }}</div>
              <marquee v-else direction="left">{{ healthData.RTK.title }}</marquee>
            </div>
            <div class="type" v-else>正常</div>
          </div>
          <div class="w97 h110 item" v-if="healthData.RTK">
            <img src="@/assets/images/observe/fckernel/health/info.png" alt @click="infoDetail('RTK', 'RTK')" />
            <div class="iconfont icon-chuli mt6 ml25 cp" style="color: #ffe417;"
              @click="infoDetail('RTK', 'RTK', true)" />
            <div class="content">
              <img src="@/assets/images/observe/fckernel/health/rtk.png" />
              <span class="dib">RTK</span>
            </div>
            <div class="notice" v-if="healthData.RTK && healthData.RTK.warningLevel != 'NORMAL'">
              <div v-if="healthData.RTK.title.length <= 6">{{ healthData.RTK.title }}</div>
              <marquee v-else direction="left">{{ healthData.RTK.title }}</marquee>
            </div>
            <div class="type" v-else>正常</div>
          </div>
          <div class="w97 h110 item" v-if="healthData.GPS">
            <img src="@/assets/images/observe/fckernel/health/info.png" alt @click="infoDetail('GPS', 'GPS')" />
            <div class="iconfont icon-chuli mt6 ml25 cp" style="color: #ffe417;"
              @click="infoDetail('GPS', 'GPS', true)" />
            <div class="content">
              <img src="@/assets/images/observe/fckernel/health/gps.png" />
              <span class="dib">GPS</span>
            </div>
            <div class="notice" v-if="healthData.GPS && healthData.GPS.warningLevel != 'NORMAL'">
              <div v-if="healthData.GPS.title.length <= 6">{{ healthData.GPS.title }}</div>
              <marquee v-else direction="left">{{ healthData.GPS.title }}</marquee>
            </div>
            <div class="type" v-else>正常</div>
          </div>
          <div class="w97 h110 item" v-if="healthData.OBS">
            <img src="@/assets/images/observe/fckernel/health/info.png" alt @click="infoDetail('避障', 'OBS')" />
            <div class="iconfont icon-chuli mt6 ml25 cp" style="color: #ffe417;" @click="infoDetail('避障', 'OBS', true)" />

            <div class="content">
              <img src="@/assets/images/observe/fckernel/health/obstacles.png" />
              <span class="dib">避障</span>
            </div>
            <div class="notice" v-if="healthData.OBS && healthData.OBS.warningLevel != 'NORMAL'">
              <div v-if="healthData.OBS.title.length <= 6">{{ healthData.OBS.title }}</div>
              <marquee v-else direction="left">{{ healthData.OBS.title }}</marquee>
            </div>
            <div class="type" v-else>正常</div>
          </div>
          <!-- <div class="w97 h110 item mr17 mb17">
            <img
              src="@/assets/images/observe/fckernel/health/info.png"
              alt=""
              @click="infoDetail('图传')"
            />
            <div class="content">
              <img src="@/assets/images/observe/fckernel/health/video.png" />
              <span class="dib">图传</span>
            </div>
            <div class="type">正常</div>
          </div>-->
          <!-- <div class="w97 h110 item mr17 mb17">
            <img
              src="@/assets/images/observe/fckernel/health/info.png"
              alt=""
              @click="infoDetail('警灯')"
            />
            <div class="content">
              <img src="@/assets/images/observe/fckernel/health/light.png" />
              <span class="dib">警灯</span>
            </div>
            <div class="type">正常</div>
          </div>-->
          <div class="w97 h110 item" v-if="healthData.VPN">
            <img src="@/assets/images/observe/fckernel/health/info.png" alt @click="infoDetail('公网', 'VPN')" />
            <div class="iconfont icon-chuli mt6 ml25 cp" style="color: #ffe417;" @click="infoDetail('公网', 'VPN', true)" />
            <div class="content">
              <img src="@/assets/images/observe/fckernel/health/public.png" />
              <span class="dib">公网</span>
            </div>
            <div class="notice" v-if="healthData.VPN && healthData.VPN.warningLevel != 'NORMAL'">
              <div v-if="healthData.VPN.title.length <= 6">{{ healthData.VPN.title }}</div>
              <marquee v-else direction="left">{{ healthData.VPN.title }}</marquee>
            </div>
            <div class="type" v-else>正常</div>
          </div>
          <div class="w97 h110 item" v-if="healthData.SPE">
            <img src="@/assets/images/observe/fckernel/health/info.png" alt @click="infoDetail('专网', 'SPE')" />
            <div class="iconfont icon-chuli mt6 ml25 cp" style="color: #ffe417;" @click="infoDetail('专网', 'SPE', true)" />
            <div class="content">
              <img src="@/assets/images/observe/fckernel/health/pr.png" />
              <span class="dib">专网</span>
            </div>
            <div class="notice" v-if="healthData.SPE && healthData.SPE.warningLevel != 'NORMAL'">
              <div v-if="healthData.SPE.title.length <= 6">{{ healthData.SPE.title }}</div>
              <marquee v-else direction="left">{{ healthData.SPE.title }}</marquee>
            </div>
            <div class="type" v-else>正常</div>
          </div>
          <div class="w97 h110 item" v-if="healthData.CHUTE">
            <img src="@/assets/images/observe/fckernel/health/info.png" alt @click="infoDetail('降落伞', 'CHUTE')" />
            <div class="iconfont icon-chuli mt6 ml25 cp" style="color: #ffe417;"
              @click="infoDetail('降落伞', 'CHUTE', true)" />
            <div class="content">
              <img src="@/assets/images/observe/fckernel/health/san.png" />
              <span class="dib">降落伞</span>
            </div>
            <div class="notice" v-if="healthData.CHUTE && healthData.CHUTE.warningLevel != 'NORMAL'
              ">
              <div v-if="healthData.CHUTE.title.length <= 6">{{ healthData.CHUTE.title }}</div>
              <marquee v-else direction="left">{{ healthData.CHUTE.title }}</marquee>
            </div>
            <div class="type" v-else>正常</div>
          </div>
          <!-- <div class="w97 h110 item mr17">
            <img
              src="@/assets/images/observe/fckernel/health/info.png"
              alt=""
              @click="infoDetail('高清摄像机')"
            />
            <div class="content">
              <img src="@/assets/images/observe/fckernel/health/photo.png" />
              <span class="dib">高清摄像机</span>
            </div>
            <div
              class="notice"
              v-if="healthData.SPEAK && healthData.SPEAK.warningLevel != 'NORMAL'"
            >
              {{ healthData.SPEAK.title }}
            </div>
            <div class="type" v-else>正常</div>
          </div>-->
          <div class="w97 h110 item" v-if="healthData.SPEAK">
            <img src="@/assets/images/observe/fckernel/health/info.png" alt @click="infoDetail('喊话器', 'SPEAK')" />
            <div class="iconfont icon-chuli mt6 ml25 cp" style="color: #ffe417;"
              @click="infoDetail('喊话器', 'SPEAK', true)" />
            <div class="content">
              <img src="@/assets/images/observe/fckernel/health/speak.png" />
              <span class="dib">喊话器</span>
            </div>
            <div class="notice" v-if="healthData.SPEAK && healthData.SPEAK.warningLevel != 'NORMAL'">
              <div v-if="healthData.SPEAK.title.length <= 6">{{ healthData.SPEAK.title }}</div>
              <marquee v-else direction="left">{{ healthData.SPEAK.title }}</marquee>
            </div>
            <div class="type" v-else>正常</div>
          </div>

          <div class="empty" v-if="Object.keys(healthData).length == 0">
            <img src="~@/assets/img/i133.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="nset_control_box_header lh34">
        <div class="title ml10">
          <img src="~@/assets/images/mount/mount_head.png" />
          <div class="font">{{ infoName }}</div>
        </div>
        <div class="exit mr10 cp" @click="reset">返回</div>
      </div>
      <div class="nest_info">
        <!-- <div class="btn" @click="getInfoList">异常记录</div> -->
        <div class="bat" v-if="infoName == '智能电池' && !listShow">
          <div class="batlist mt16" v-for="item in batList" :key="item.id">
            <div class="head">
              <div class="line"></div>
              <div class="name">{{ item.name }}</div>
            </div>
            <div class="center mt16">
              <div class="item">
                <div class="title">电压：</div>
                <div class="indo">{{ item.voltage.toFixed(1) }}V</div>
              </div>
              <div class="item ml83">
                <div class="title">电流：</div>
                <div class="indo">{{ item.current }}A</div>
              </div>
              <div class="item ml83">
                <div class="title">温度：</div>
                <div class="indo">{{ item.temperature }}</div>
              </div>
              <div class="item mt16">
                <div class="title">剩余电量：</div>
                <div class="indo">{{ item.chargeRemaining }}%</div>
              </div>
              <div class="item ml65 mt16">
                <div class="title">充放电次数：</div>
                <div class="indo">{{ item.cycleIndex }}</div>
              </div>
              <!-- <div class="item">
              <div class="title">耗电百分比：</div>
              <div class="indo">{{}}</div>
              </div>-->
            </div>
          </div>
        </div>
        <div class="rtk" v-else-if="infoName == 'RTK' && !listShow">
          <div class="item">
            <div class="title">FIX状态：</div>
            <div class="ingps">{{ healthData.RTK && healthData.RTK.title || '暂无' }}</div>
          </div>
          <div class="item">
            <div class="title">搜星数：</div>
            <div class="inrtk">{{ uavData.gps && uavData.gps.satelliteCount }}</div>
          </div>
          <div class="item">
            <div class="title">定位精度：</div>
            <div class="inrtk">{{ uavData.gps && uavData.gps.levelDivisor }}</div>
          </div>
        </div>
        <div class="gps" v-else-if="infoName == 'GPS' && !listShow">
          <div class="item">
            <div class="title">FIX状态：</div>
            <div class="ingps">{{ healthData.GPS && healthData.GPS.title || '暂无' }}</div>
          </div>
          <div class="item">
            <div class="title">搜星数：</div>
            <div class="ingps">{{ uavData.gps && uavData.gps.satelliteCount || '暂无' }}</div>
          </div>
          <div class="item">
            <div class="title">定位精度：</div>
            <div class="ingps">{{ uavData.gps && uavData.gps.levelDivisor || '暂无' }}</div>
          </div>
        </div>
        <div class="public" v-else-if="infoName == '专网' && !listShow">
          <div class="pu">
            <div class="item">
              <div class="title">专网信号质量：</div>
              <div class="inpublic">{{ healthData.SPE && healthData.SPE.title || '暂无' }}</div>
            </div>
            <div class="item">
              <div class="title">消息类型：</div>
              <div class="inpublic"
                :style="{ 'color': healthData.SPE && warningLevel[healthData.SPE.warningLevel].color }">
                {{ healthData.SPE && warningLevel[healthData.SPE.warningLevel].title || '暂无' }}</div>
            </div>
            <div class="item">
              <div class="title">描述：</div>
              <div class="inpublic">{{ healthData.SPE && healthData.SPE.description || '暂无' }}</div>
            </div>
          </div>
        </div>
        <div class="public" v-else-if="infoName == '公网' && !listShow">
          <!-- warningLevel -->
          <div class="pr">
            <div class="item">
              <div class="title">公网信号质量：</div>
              <div class="inpublic">{{ healthData.VPN && healthData.VPN.title || '暂无' }}</div>
            </div>
            <div class="item">
              <div class="title">消息类型：</div>
              <div class="inpublic"
                :style="{ 'color': healthData.VPN && warningLevel[healthData.VPN.warningLevel].color }">
                {{ healthData.VPN && warningLevel[healthData.VPN.warningLevel].title || '暂无' }}</div>
            </div>
            <div class="item">
              <div class="title">描述：</div>
              <div class="inpublic">{{ healthData.VPN && healthData.VPN.description || '暂 无' }}</div>
            </div>
          </div>
        </div>
        <div class="public" v-else-if="infoName == '避障' && !listShow">
          <div class="pu">
            <div class="item">
              <div class="title">避障状态：</div>
              <div class="inpublic">{{ healthData.OBS && healthData.OBS.title || '暂 无' }}</div>
            </div>
            <div class="item">
              <div class="title">消息类型：</div>
              <div class="inpublic"
                :style="{ 'color': healthData.OBS && warningLevel[healthData.OBS.warningLevel].color }">
                {{ healthData.OBS && warningLevel[healthData.OBS.warningLevel].title || '暂无' }}</div>
            </div>
            <div class="item">
              <div class="title">是否触发：</div>
              <div class="inpublic">{{ obstacles && obstacles.enable ? '触发避障' : '未触发' }}</div>
            </div>
          </div>
          <div class="pr">
            <div class="item">
              <div class="title">描述：</div>
              <div class="inpr">{{ healthData.OBS && healthData.OBS.description || '暂 无' }}</div>
            </div>
          </div>
        </div>
        <div class="public" v-else-if="infoName == '降落伞' && !listShow">
          <div class="pu">
            <div class="item">
              <div class="title">状态：</div>
              <div class="inpublic">{{ healthData.CHUTE && healthData.CHUTE.title || '暂 无' }}</div>
            </div>
            <div class="item">
              <div class="title">消息类型：</div>
              <div class="inpublic"
                :style="{ 'color': healthData.CHUTE && warningLevel[healthData.CHUTE.warningLevel].color }">
                {{ healthData.CHUTE && warningLevel[healthData.CHUTE.warningLevel].title || '暂无' }}</div>
            </div>
          </div>
          <div class="pr">
            <div class="item">
              <div class="title">描述：</div>
              <div class="inpr">{{ healthData.CHUTE && healthData.CHUTE.description || '暂 无' }}</div>
            </div>
          </div>
        </div>
        <div class="public" v-else-if="infoName == '喊话器' && !listShow">
          <div class="pu">
            <div class="item">
              <div class="title">状态：</div>
              <div class="inpublic">{{ healthData.SPEAK && healthData.SPEAK.title || '暂 无' }}</div>
            </div>
            <div class="item">
              <div class="title">消息类型：</div>
              <div class="inpublic"
                :style="{ 'color': healthData.SPEAK && warningLevel[healthData.SPEAK.warningLevel].color }">
                {{ healthData.SPEAK && warningLevel[healthData.SPEAK.warningLevel].title || '暂无' }}</div>
            </div>
          </div>
          <div class="pr">
            <div class="item">
              <div class="title">描述：</div>
              <div class="inpr">{{ healthData.SPEAK && healthData.SPEAK.description || '暂 无' }}</div>
            </div>
          </div>
        </div>
        <Logs v-else :type="type" :device="device"></Logs>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import Logs from "./components/logs";
export default {
  props: {
    healthData: {
      type: Object,
      default: () => ({}),
    },
    batteryData: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { Logs },
  computed: {
    taskId () {
      return this.$store.state.fckernel.taskId;
    },
    obstacle () {
      return this.$store.state.fckernel.obstacle;
    },
  },
  watch: {
    uavData: function (value) {
      // console.log(value, "value");
    },
    healthData: function (newval, old) {
      // console.log(newval, "new");
      // console.log(old, "old");
      for (let i in newval) {
        if (newval[i].warningLevel != "NORMAL") {
          if (newval[i].code != old[i].code) {
            if (this.taskId) {
              this.message.taskId = this.taskId;
            }
            this.message = {
              exceptionTitle: newval[i].title,
              exceptionTypeSign: i,
            };
            console.log(this.message, "message");
            this.addMessage();
          }
        }
      }
    },
    batteryData: function (value) {
      let battery = { ...value, name: "智能电池" };
      if (this.batList.length > 0) {
        let arr = [];
        this.batList.forEach((val) => {
          arr.push(val.id);
        });
        if (arr.indexOf(value.id) > -1) {
          this.batList[arr.indexOf(value.id)] = battery;
        } else {
          this.batList.push(battery);
        }
      } else {
        this.batList.push(battery);
      }
      this.batList.forEach((val, i) => {
        if (i == 0) {
          val.name = "一号电池";
        } else if (i == 1) {
          val.name = "二号电池";
        }
      });
    },
    deep: true,
    immediate: true,
  },
  data () {
    return {
      warningLevel: {
        NORMAL: {
          title: "正常",
          color: "green",
        },
        NOTICE: {
          title: "通知",
          color: "green",
        },
        CAUTION: {
          title: "提醒",
          color: "#eee",
        },
        WARNING: {
          title: "警告",
          color: "#ffbd36",
        },
        SERIOUS_WARNING: {
          title: "严重警告",
          color: "red",
        },
      },
      infoName: "",
      infoShow: false,
      listShow: false,
      type: "",
      batList: [],
      pickTime: null,
      qianVal: null,
      message: {},
    };
  },
  beforeDestroy () { },
  methods: {
    infoDetail (name, val, flag) {
      if (flag === true) {
        this.listShow = true;
      } else {
        this.listShow = false;
      }
      this.infoShow = true;
      this.infoName = name;
      this.type = val;
      // flag 为true 代表展示异常信息
    },
    reset () {
      this.infoShow = false;
      this.infoName = "";
    },
    // 打开异常信息列表
    // getInfoList() {
    //   this.listShow = true;
    //   // this.onSearch();
    // },
    async addMessage () {
      let res = await API.FCKERNEL.exceptionAdd(this.message);
      console.log(res, "res");
    },
  },
  mounted () {
    console.log(this.healthData, "healthData");
  },
};
</script>

<style lang="scss" scoped>
.nset_control_box {
  min-height: 111px;
  // background: rgba(12, 34, 73, 0.7);
  // border-radius: 10px;
  // border: 1px solid rgba(26, 92, 246, 0.5);
  // backdrop-filter: blur(1px);
  // position: absolute;
  // right: 27px;
  // top: -155px !important;
  /* top: calc(50% - 14px); */
  // bottom: auto !important;
  min-width: 470px;
  width: 586px;
  position: fixed;
  right: 100px;
  top: 8%;
  background: #19334D;

  .nset_control_box_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid #979797;
    // background: linear-gradient(180deg,
    //     #9198ff 0%,
    //     rgba(45, 81, 153, 0.45) 40%,
    //     #05091a 100%);
    // box-shadow: inset 0px 0px 10px 2px #3f9dff;
    // border-radius: 10px 10px 0px 0px;
    // border: 1px solid #427dff;

    .title {
      display: flex;
      align-items: center;

      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        // line-height: 26px;
        // text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        // background: linear-gradient(135deg,
        //     #e3aa77 0%,
        //     #f5cda9 38%,
        //     #f9ecd3 58%,
        //     #fcdbb1 79%,
        //     #edb07a 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
    }

    .exit {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      // background-color: #00dfff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .img {
        height: 24px;
        height: 24px;
      }
    }
  }

  .nset_control_box_area {
    .wrj {
      height: 258px;
      // border: 1px solid yellow;
      // height: auto;
      overflow: auto;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;

      .item {
        margin-right: 17px;
        margin-bottom: 17px;
        position: relative;
        background: url("~@/assets/images/observe/fckernel/health/bg.png") no-repeat;
        background-size: 100% 100%;
        box-shadow: 0 0 5px 0 #0c1c47;
        border-radius: 4.5px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover {
          background: url("~@/assets/images/observe/fckernel/health/checkbg.png") no-repeat;
          background-size: 100% 100%;
        }

        >img {
          position: absolute;
          right: 3px;
          top: 3px;
        }

        .content {
          width: 100%;
          height: 82px;
          display: flex;
          flex-direction: column;
          justify-content: end;
          align-items: center;
          margin-bottom: 10px;

          img {}

          .dib {
            margin-top: 6px;
            font-size: 12px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #ffffff;
          }
        }

        .type {
          width: calc(100% - 2px);
          text-align: center;
          height: 27px;
          line-height: 27px;
          background: rgba(32, 29, 12, 1);
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #36e81a;
          border-radius: 0 0 4.5px 4.5px;
        }

        .notice {
          width: calc(100% - 2px);
          text-align: center;
          height: 27px;
          line-height: 27px;
          background: #ffbd36;
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #fff;
          border-radius: 0 0 4.5px 4.5px;
        }
      }

      .item:nth-of-type(4n) {
        margin-right: 0;
      }

      .empty {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: #00dfff;

        img {
          width: 100%;
        }
      }
    }
  }

  .nest_info {
    padding: 16px;

    .btn {
      width: 80px;
      height: 28px;
      background: url("~@/assets/images/observe/twobg.png") no-repeat;
      background-size: 100% 100%;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      text-align: center;
      line-height: 28px;
    }

    .bat {
      .batlist {
        display: flex;
        flex-direction: column;

        .head {
          display: flex;

          .line {
            width: 4px;
            height: 16px;
            background: #ffd800;
            border-radius: 2px;
          }

          .name {
            margin-left: 4px;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
          }
        }

        .center {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          .item {
            display: flex;
            flex-wrap: nowrap;

            .title {
              white-space: nowrap;
              font-size: 14px;
              font-weight: 500;
              color: #fff;
            }

            .indo {
              font-size: 16px;
              font-weight: 500;
              color: #00dfff;
            }
          }
        }
      }
    }

    .rtk,
    .gps {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;

      .item {
        display: flex;
        flex-wrap: nowrap;

        .title {
          white-space: nowrap;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
        }

        .ingps,
        .inrtk {
          font-size: 16px;
          font-weight: 500;
          color: #00dfff;
        }
      }
    }

    .public {
      display: flex;
      flex-direction: column;

      .pu,
      .pr {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;

        .item {
          display: flex;
          flex-wrap: nowrap;

          .title {
            white-space: nowrap;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
          }

          .inpublic,
          .inpr {
            font-size: 16px;
            font-weight: 500;
            color: #00dfff;
          }
        }
      }
    }
  }

  .list {
    position: fixed;
    top: calc(50% + 90px);
    left: calc(50% - 870px);
    width: 676px;
    height: 434px;
    background: rgba(12, 34, 73, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(26, 92, 246, 0.5);
    backdrop-filter: blur(1px);

    .nset_control_box_header {
      display: flex;
      justify-content: space-between;
      height: 32px;
      background: linear-gradient(180deg,
          #9198ff 0%,
          rgba(45, 81, 153, 0.45) 40%,
          #05091a 100%);
      box-shadow: inset 0px 0px 10px 2px #3f9dff;
      border-radius: 10px 10px 0px 0px;
      border: 1px solid #427dff;
    }
  }
}
</style>
