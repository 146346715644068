<template>
  <div class="nest_header_box jcsb">
    <div class="left">
      <!-- @click="$router.push('/observe/home')" -->
      <div class="title" @click="goHome">
        <img alt="" src="~@/assets/img/observe-shi-logo.png" />
        <!-- <span>联视 ● 机巢应用</span> -->
        <span>飞行监控</span>
      </div>
      <div class="menu" v-if="false">
        <ul class="ul">
          <template v-for="item in menuData">
            <li class="tap" :key="item.id" v-if="item.id == 1" @click="$emit('changeF')">
              <span>{{ item.title }}</span>
            </li>
            <li class="tap jin" :key="item.id" v-else-if="item.id == 2">
              <span>{{ item.title }}</span>
            </li>
            <li class="tap" :key="item.id" v-else @click="changeItem">
              <span>{{ item.title }}</span>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div class="right">
      <tianqi class="tianqi"></tianqi>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";
import tianqi from "@/pages/observe/home/components/header/tianqi";
import dayjs from "dayjs";
// 引用bus
import { mapGetters } from "vuex";
import Bus from "@/assets/ligature.js";
export default {
  props: {
    uavBattery: {
      type: Object,
    },
    nestData: {
      type: Object,
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
    isSelectFlag: {
      type: Boolean,
      default: () => true,
    },
    device: {
      type: Object,
      default: () => ({}),
    },
  },

  data () {
    return {
      menuData: [
        { id: 1, title: "无人机应用", url: "/fckernel?page=1" },
        { id: 2, title: "机巢应用", url: "/fckernel?page=2" },
        { id: 3, title: "视频墙", url: "/fckernelVideos" },
      ],
      priority: "专网",
      modelData: null,
      openshow: false,
      modelType: null,
      ModeStatusList: [
        { id: 2, data: "1.4G", label: "专网" },
        { id: 3, data: "4G/5G", label: "公网" },
      ],
      zwArr: [
        {
          // icon: "xinhaoshaixuan",
          icon: "a-14-4beifen21",
          info: "基站专网信号数据断开",
        },
        {
          icon: "a-14-6",
          info: "覆盖非常好",
        },
        {
          icon: "a-14-5",
          info: "覆盖好,室外能够发起各种业务,可获得高等数据业务,室内能够发起各种业务，可获得中等数据业务",
        },
        {
          icon: "a-14-4",
          info: "覆盖较好,室外能够发起各种业务,可获得中等数据业务,室内能够发起各种业务，可获得低数据业务",
        },
        {
          icon: "a-14-3",
          info: "覆盖一般，室外能够发起各种业务，可获得中等数据业务,室内呼叫成功率低",
        },
        {
          icon: "a-14-2",
          info: "覆盖率差，户外语音业务能够起呼，但成功率较低",
        },
        {
          icon: "a-14-1",
          info: "覆盖较差，业务基本无法起呼",
        },
      ],
      gwArr: [
        {
          icon: "xinhaoshaixuan",
          info: "基站信号数据断开",
        },
        {
          icon: "a-5g-6",
          info: "覆盖非常好",
        },
        {
          icon: "a-5g-5",
          info: "覆盖好,室外能够发起各种业务,可获得高等数据业务,室内能够发起各种业务，可获得中等数据业务",
        },
        {
          icon: "a-5g-4",
          info: "覆盖较好,室外能够发起各种业务,可获得中等数据业务,室内能够发起各种业务，可获得低数据业务",
        },
        {
          icon: "a-5g-3",
          info: "覆盖一般，室外能够发起各种业务，可获得中等数据业务,室内呼叫成功率低",
        },
        {
          icon: "a-5g-2",
          info: "覆盖率差，户外语音业务能够起呼，但成功率较低",
        },
        {
          icon: "a-5g-1",
          info: "覆盖较差，业务基本无法起呼",
        },
      ],
      setIntervalTime: "",
      isSelect: false,
      rcChannelStateList: {
        0: "摇杆中位",
        "-98": "摇杆外场权限",
        "-99": "摇杆未连接",
        1: "俯仰偏前",
        "-1": "俯仰偏后",
        "-2": "横滚偏左",
        2: "横滚偏右",
        3: "油门偏高",
        "-3": "油门偏低",
        4: "航向偏右",
        "-4": "航向偏左",
      },
      mode: "离线状态",
      weatherIcon: null,
      now: null,
      curr_val: 0,
      remote: {
        0: "yaokongqi-duankailianjie",
        1: "yaokongqiliang",
      },
      week_map: {
        0: "天",
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
      },
      ws: null,

      fire: {
        0: "yanganan",
        1: "yanganhong",
        2: "yanganliang",
      },
      water: {
        0: "shuijinan",
        1: "shuijinhong",
        2: "shuijinliang",
      },
      power: {
        1: "dianliangbaifenzhiershi",
        2: "dianliangbaifenzhisishi",
        3: "dianliangbaifenzhiliushi",
        4: "dianliangbaifenzhibashi",
        5: "chongdianmoshi5",
      },
      weather: {},
      weatherTitle: "暂未选择机巢",
      weather_data: {
        weather: "晴",
        icon: require(`@/assets/images/weather/icon/day/01.png`),
        temperature: 0,
        humidity: 0,
        windPower: "",
        windSpeed: "",
        minTemp: "",
        maxTemp: "",
        windDirection: "东北",
      },
      modeList: [
        { id: 1, data: "MANUAL", label: "手动模式", disabled: true },
        { id: 2, data: "ALTITUDE", label: "定高模式", disabled: false },
        { id: 3, data: "POSITION", label: "定点模式", disabled: false },
        { id: 4, data: "AUTO_MISSION", label: "航线模式", disabled: false },
        { id: 5, data: "ACRO", label: "特技模式", disabled: true },
        { id: 6, data: "OFF_BOARD", label: "版外模式", disabled: true },
        { id: 7, data: "STABILIZED", label: "自稳模式", disabled: true },
        { id: 8, data: "HOLD", label: "保持模式", disabled: false },
        { id: 9, data: "LAND", label: "降落模式", disabled: true },
        { id: 10, data: "TAKE_OFF", label: "起飞模式", disabled: true },
        { id: 11, data: "AUTO_RTL", label: "返航模式", disabled: false },
        {
          id: 12,
          data: "AUTO_FOLLOW_TARGET",
          label: "自动跟随模式",
          disabled: true,
        },
      ],
      signal: {
        1: "GPS1",
        2: "GPS2",
        3: "GPS3",
        4: "GPS4",
        5: "GPS5",
      },
    };
  },
  watch: {
    "device.deviceHardId": {
      handler (value) {
        if (this.setIntervalTime) {
          clearInterval(this.setIntervalTime);
          this.setIntervalTime = "";
        }
        // this.getNestWeatherFn(value);
      },
      deep: true,
    },
    value: {
      handler (value) {
        this.curr_val = value;
      },
      deep: true,
      immediate: true,
    },
    isSelectFlag: function (newValue) {
      this.isSelect = newValue;
    },
  },
  computed: {
    ...mapGetters(["user_info"]),
    rcChannelState () {
      let { uavData } = this;
      return uavData && uavData.rcChannelState;
    },
    zwSqe14 () {
      let { uavData } = this;
      if (uavData && uavData.signal && uavData.signal.sqe14) {
        if (uavData.signal.sqe14 === -1) {
          return 0;
        }
        return uavData.signal.sqe14;
      }
      return 6;
    },
    gwSqe45 () {
      let { uavData } = this;
      if (uavData && uavData.signal && uavData.signal.sqe45) {
        if (uavData.signal.sqe45 === -1) {
          return 0;
        }
        return uavData.signal.sqe45;
      }
      return 6;
    },
    gzSignal () {
      let { uavData } = this;
      return 0;
    },
    FlightMode () {
      let { uavData, modeList } = this;
      let flightMode = modeList.find((e) => e.data == uavData?.flightMode);
      // 用vuex将当前的模式存起来 飞控 机巢
      this.$store.commit("shoudong/compose_mode", flightMode?.label);
      return flightMode?.label || "离线状态";
    },
    fire_state () {
      let { nestData } = this;
      return nestData?.fireWarn ? nestData.fireWarn : 0;
    },

    RKT () {
      let { uavData } = this;
      return (uavData && uavData.isRtkEnable == true) || 0;
    },
    FIX () {
      let { uavData } = this;
      return uavData?.gps?.fixType;
    },
    water_state () {
      let { nestData } = this;
      return nestData?.waterWarn ? nestData.waterWarn : 0;
    },
    is_charger () {
      let { nestData } = this;
      return nestData && nestData.chargerPower == 1;
    },
    power_state () {
      let { uavBattery } = this;
      if (uavBattery && uavBattery.chargeRemaining) {
        return parseInt(uavBattery && uavBattery.chargeRemaining / 20 + 1);
      } else {
        return 1;
      }
    },
    remote_state () {
      let { uavData } = this;
      return uavData ? (uavData.isRcConnecting ? 1 : 0) : 0;
    },
    voltage () {
      let { uavBattery } = this;
      return uavBattery ? uavBattery.voltage || 0 : 0;
    },
    power_num () {
      let { uavBattery } = this;
      return uavBattery ? uavBattery.chargeRemaining : 0;
    },
    date () {
      let { now } = this;
      return now ? `${now.format("YYYY/MM/DD")}` : "0000/00/00";
    },
    week () {
      let { now, week_map } = this;
      return now ? `周${week_map[now.day()]}` : "周N/A";
    },
    time () {
      let { now } = this;
      return now ? `${now.format("HH:mm")}` : "00:00";
    },
    satelliteCount () {
      let { uavData } = this;
      return uavData?.gps?.satelliteCount || 0;
    },
    signal_state () {
      let { uavData } = this;
      if (uavData && uavData.satelliteCount) {
        let count = parseInt(uavData.satelliteCount / 6);
        if (count >= 5) {
          count = 5;
        } else {
          count += 1;
        }
        return uavData.satelliteCount ? count : 1;
      }
      return 5;
    },
  },
  components: { SymbolIcon, tianqi },
  mounted () { },
  methods: {
    // 跳转到首页
    goHome () {
      this.$router.push('/home')
      // let { href } = this.$router.resolve({ path: "/home" });
      // window.open(href, "_self");
      // setTimeout(() => {
      //   this.$router.go(0);
      // }, 100);
    },
    async getWeather () {
      let organId = { organId: this.user_info["departmentId"] };
      let res = await API.WEATHER.getNewWeather(organId);
      if (res.length > 0) {
        this.weather_data = res[0];
      }
      // console.log(this.weatherList,'this.weatherListthis.weatherList');
      const date = new Date();
      let time = date.getHours();
      this.weatherIcon = require(`@/assets/images/weather/icon/iconfont/W${time >= 18 ? this.weather_data.nightIcon : this.weather_data.dayIcon
        }.png`);
    },
    handle_uav_mode (lable) {
      this.priority = lable;
      this.$store.commit("fckernel/SET_YC_VIDEO_STATUS", lable);
    },
    toVideos () {
      window.open(`#/videos`, "_blank");
    },
    handle_change_uav_mode (data, flag) {
      let mode = this.modeList.find((item) => item.data === data);
      this.modelData = data;
      if (
        mode.label == "定高模式" ||
        mode.label == "定点模式" ||
        mode.label == "保持模式"
      ) {
        if (
          (this.rcChannelState !== 0 && flag !== 1) ||
          (this.rcChannelState !== "-99" && flag !== 1)
        ) {
          this.modelType = mode.label;
          this.openshow = true;
        } else {
          this.model_qr(data);
        }
      } else {
        this.model_qr(data);
      }
    },
    model_qr (data) {
      let mode = this.modeList.find((item) => item.data === data);
      if (mode) {
        this.$emit("fn", { type: 513, data: mode.data }, "wrj");
        if (mode.data == "AUTO_RTL") {
          this.$message.success("进入返航模式");
        }
      }
      this.openshow = false;
    },
    changeItem () {
      this.$router.push("/fckernelVideos");
      // this.isSelect = !this.isSelect;
      // this.$forceUpdate();
      // try {
      //   this.$emit("nest_chenge", this.isSelect);
      // } catch (error) {
      //   console.log(error);
      // }
    },
    async getNestWeatherFn (deviceId) {
      if (deviceId) {
        let res = await API.FCKERNEL.getNestWeather({ deviceId });
        this.weatherTitle = res;
        this.setIntervalTime = setInterval(async () => {
          let res = await API.FCKERNEL.getNestWeather({ deviceId });
          this.weatherTitle = res;
        }, 10000);
      }
    },
  },
  created () {
    this.now = dayjs();
    // 将右上角的模式修改为定点模式
    // 匹配关键字：修正模式
    Bus.$on("quick_search", (data) => {
      this.handle_change_uav_mode(data, 1);
    });
    // 需求返航模式
    // 关键字：机巢返航模式
    Bus.$on("superModel", (data) => {
      // console.log(data,'返航模式')
      this.handle_change_uav_mode(data, 1);
    });
  },
  inject: ["g_weather"],
};
</script>

<style lang="scss" scoped>
.nest_header_box {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 64px;
  // background: center url("~@/assets/img/head_bg.png");
  background-color: #0E3042;
  background-size: 100%;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-left: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      margin-top: -10px;

      img {
        width: 63px;
        height: 58px;
        margin-right: 10px;
      }

      span {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
    }

    .menu {
      margin-left: 150px;

      ul {
        list-style: none;
        display: flex;
        height: 100%;
        vertical-align: top;

        .tap {
          width: 185px;
          height: 64px;
          position: relative;
          text-align: center;
          // margin: -8px 0 0 0;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: row;
  }

  .tap {
    background-image: url("~@/assets/img/head-menu-bg-nomal.png");
    background-size: 100% 100%;
    cursor: pointer;
  }

  .tap:hover {
    background-image: url("~@/assets/img/head-menu-bg-select.png");
    background-size: 100% 100%;
  }

  .jin {
    background-image: url("~@/assets/img/head-menu-bg-select.png");
    background-size: 100% 100%;
  }

  .gohome {
    left: 50%;
    transform: translateX(-50%);
  }

  .nest_header_box_right {
    margin-top: 8px;
    height: 50px;
    min-width: 435px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .changeBtn {
      position: absolute;
      top: 5px;
      right: calc(50% - 370px);
      width: 150px;
      height: 50px;
      background: center url("~@/assets/images/observe/fckernel/nest/right.png");
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #70f6f9;
    }

    .changeBtn:hover {
      background: center url("~@/assets/images/observe/fckernel/nest/rightD.png");
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isSelect {
      background: center url("~@/assets/images/observe/fckernel/nest/btnActiveRight.png");
      background-size: contain;
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }
  }

  .nest_header_box_left {
    margin-top: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    height: 42px;
    padding-left: 117px;

    .changeBtn {
      position: absolute;
      top: 5px;
      left: calc(50% - 370px);
      width: 150px;
      height: 50px;
      background: center url("~@/assets/images/observe/fckernel/nest/leftD.png");
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-size: 14px;
      color: #70f6f9;
      letter-spacing: 0;
    }

    .changeBtn:hover {
      background: center url("~@/assets/images/observe/fckernel/nest/left.png");
      // background-size: contain;
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isFlight {
      font-family: YouSheBiaoTiHei;
      font-size: 28px;
      color: #6aff64;
      letter-spacing: 0;
      font-weight: 400;
    }

    .noFlight {
      font-family: YouSheBiaoTiHei;
      font-size: 28px;
      color: #ffc15b;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  .nav-list {
    width: 590px;
    height: 77px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;

    .nav-item {
      width: 223px;
      height: 77px;
      box-sizing: border-box;
      background: center url("~@/assets/images/observe/fckernel/nav-item.png") no-repeat;
      background-size: cover;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;

      &:hover,
      &.selected {
        background: center url("~@/assets/images/observe/fckernel/nav-item-selected.png") no-repeat;

        .text-box {
          color: #3df1ff;
        }
      }

      .text-box {
        line-height: 77px;
        font-family: Microsoft YaHei Bold;
        font-size: 20px;
        color: #aab8d1;
        font-weight: 700;
        transition: 0.3s;
      }

      &._2 {
        transform: scaleX(-1);

        .text-box {
          transform: scaleX(-1);
        }
      }
    }
  }

  .date-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 20px;
    top: 0;
    height: 60px;
    box-sizing: border-box;

    .yyyy {
      font-family: Microsoft YaHei;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      margin-bottom: 3px;
    }

    .week-box {
      display: flex;
      justify-content: space-between;

      .week {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }

      .hhmm {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.mode_nameBox {
  width: 116px;
  height: 28px;

  background: url("~@/assets/images/observe/fckernel/nest/select.png") no-repeat;
  background-size: 100% 100%;
  margin-right: 5px;
  padding-left: 7px;
  display: flex;
  align-items: center;

  .mode_name {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    color: #3df1ff;
    margin: 0 6px 0 0;
  }

  .el-dropdown {
    color: #3df1ff;
    font-weight: 400;
    font-size: 14px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    .el-icon-caret-bottom {
      color: #fff !important;
      line-height: 24px;
    }
  }
}

.el-dropdown-menu {
  // left: 1797px !important;
  width: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(9, 32, 87, 0.5) !important;

  .el-dropdown-menu__item {
    width: 115px;
    color: #3df1ff !important;
    box-sizing: border-box;
    text-align: center;
  }
}

.noStyle {
  opacity: 0.3;
}

.el-dropdown-selfdefine {
  margin-right: 0;
}

.line {
  width: 1px;
  height: 32px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 47%,
      rgba(255, 255, 255, 0) 100%);
}

.el-dropdown {
  display: flex;
  align-items: center;
}

/deep/ .el-dialog__header {
  margin-left: 0;
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
  z-index: 2023 !important;
}

/deep/ .el-dialog {
  z-index: 2022 !important;
  background: transparent !important;
}

// 弹框样式
.endrenwu {
  width: 100%;
  height: 177px;
  border: 1px solid #70daf9 !important;
  background: rgba(9, 32, 87, 0.7) !important;
}

.queding {
  color: #92d9ff;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 30px 0 0 0;
}

.tishiyu {
  color: #92d9ff;
  font-size: 18px;
  margin: 30px 0 0 0;
  text-align: center;
  width: 100%;
}

.btn_kuang {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  width: 79px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  margin: 20px 0 0 0;
  cursor: pointer;
}

.btn_lan {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #606266;
}

.btn_lv {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #fff;
}
</style>

"盐城公安将 时刻绷紧思想之弦 坚决扛起防疫之责 疫情在前 重任在肩 你我携手前行 "
"近日，盐城市公安局盐南高新区分局聚龙湖警务站接到群众报警，称家住华夏路某小区的一名老太太晚上七点出门之后就一直没有回家。据了解，因情况紧急，警方动用“鹰眼”无人机，快速搜寻到了走失老人。
12月24日晚8时许，接警后，聚龙湖警务站民警迅速赶达现场。经了解，该名走失老人已近90岁高龄，还有轻微阿尔茨海默症。“当时天色已晚、寒风刺骨、街面人流车流量大，老人独自在外非常不安全。”于是，警务站迅速调度路面巡逻警力和“街面智巡”警用无人车在走失地点周边巡查。
12月24日晚8时许，接警后，聚龙湖警务站民警迅速赶达现场。经了解，该名走失老人已近90岁高龄，还有轻微阿尔茨海默症。“当时天色已晚、寒风刺骨、街面人流车流量大，老人独自在外非常不安全。”于是，警务站迅速调度路面巡逻警力和“街面智巡”警用无人车在走失地点周边巡查。
锁定老人所处具体方位后，民警立即赶到位于人民南路某小区西门的老人身边，此时老人已筋疲力尽、浑身发抖。民警随即将老人搀扶上了警车，并将老人送回家中休息。家人看见老人平安回家，感激不已。
盐南警方提醒，早晚天气寒冷，对患有精神疾病、记忆力减退以及行动迟缓容易迷路的老人，家人一定要多加看护，尽量避免老人独自外出。在老人身上放置写有联系方式的小卡片，以备不时之需。如发现家中老人走失，可及时拨打110电话求助。
"
