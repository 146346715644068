let uav_model = null;
let uav_polyline = null;

export default {
  init_params() {
    this.uav.data = null;
    this.uav.battery = null;
    this.uav.mounts = [];
    this.uav.status = 0;
    this.uav.position = null;
    this.uav.positions = [];
    this.uav.orientation = null;
    uav_model = null;
    uav_polyline = null;
  },
  // 飞控 机巢 一键任务
  one_click_task() {
    let { status, position, orientation, positions } = this.uav;
    if (!position) {
      // this.$el_message("无人机未就绪", () => {}, "warning");
      return false;
    }

    if (position) {
      let cesium_layer = this.g_cesium_layer();
      let model = viewer.entities.getById(`nest_uav_model`);

      if (!model) {
        this.uav.entity_id = `nest_uav_model`;
        model = this.Model = cesium_layer.add_model_X85(
          viewer.entities,
          this.uav
        );
        viewer.camera.flyTo({
          destination: model.position.getValue(Cesium.JulianDate.now()),
          maximumHeight: 100,
          complete: () => {
            // 锁定
            viewer.trackedEntity = model;
          },
        });
        model.viewFrom = new Cesium.Cartesian3(0, 0, 1000);
      } else {
        this.Model = model;
      }

      let polyline = viewer.entities.getById(`nest_uav_polyline`);

      if (!polyline) {
        cesium_layer.add_polyline(viewer.entities, {
          entity_id: `nest_uav_polyline`,
          positions: positions,
        });
      }
    }
  },
  // 飞控 机巢 定位
  location() {
    let { status, position, orientation } = this.uav;
    if (!position) {
      this.$el_message("无人机未就绪", () => {}, "warning");
      return false;
    }
    if (position) {
      let cesium_layer = this.g_cesium_layer();
      let model = viewer.entities.getById(`nest_uav_model`);

      if (!model) {
        this.uav.entity_id = `nest_uav_model`;
        model = this.Model = cesium_layer.add_model_X85(
          viewer.entities,
          this.uav
        );

        //cartesian3转wgs84, 通过经纬度高度1000米创建新cartesian3，并设置相机飞入此位置
        var ellipsoid = viewer.scene.globe.ellipsoid;
        var cartesian3 = model.position.getValue(Cesium.JulianDate.now());
        var cartogtraphic = ellipsoid.cartesianToCartographic(cartesian3);
        var lat = Cesium.Math.toDegrees(cartogtraphic.latitude);
        var lng = Cesium.Math.toDegrees(cartogtraphic.longitude);

        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(lng, lat, 1000),
          maximumHeight: 100,
          complete: () => {
            // 锁定
            viewer.trackedEntity = model;
          },
        });
        model.viewFrom = new Cesium.Cartesian3(0, 0, 1000);
      } else {
        this.Model = model;
      }
    }
  },
  //清除  飞控 机巢 模型和轨迹
  closeIconShow() {
    // 清除模型
    // 清除轨迹模型
    // 关键词：飞控机巢任务结束
    let model = viewer.entities.getById(`nest_uav_model`);
    viewer.entities.remove(model);
    // 清除航线
    let polyline = viewer.entities.getById(`nest_uav_polyline`);
    viewer.entities.remove(polyline);
    this.uav.position = null; //清除无人机定位
  },
  // 结束任务时清除任务列表数据
  clearIdFn() {
    // 清除飞行数据
    if (this.$refs.TaskListRef) {
      // let model = viewer.entities.getById(`nest_uav_model`);
      // viewer.entities.remove(model)
      // 清除模型
      let model = viewer.entities.getById(`nest_uav_model`);
      viewer.entities.remove(model);
      // 清除航线
      let polyline = viewer.entities.getById(`nest_uav_polyline`);
      viewer.entities.remove(polyline);
      // 清除飞行数据
      this.uav.positions = [];
      this.$refs.TaskListRef.close();
    }
  },
  // 飞控 机巢 轨迹定位
  airway() {
    let { status, positions } = this.uav;
    // if (!status || !positions.length) {
    // this.$el_message("无人机未就绪。。。。", () => { }, "warning")
    //     return false
    // }
    // 定位到无人机
    this.uav_location();
    let cesium_layer = this.g_cesium_layer();
    let polyline = viewer.entities.getById(`nest_uav_polyline`);
    if (!polyline) {
      cesium_layer.add_polyline(viewer.entities, {
        entity_id: `nest_uav_polyline`,
        positions: positions,
      });
    }
  },

  video_init(item) {
    this.nest.video = {
      in: {
        vUrl: item.internal,
        deviceName: "舱内",
        list: item.deviceList,
      },
      out: {
        vUrl: item.uav,
        deviceName: "无人机",
        list: item.deviceList,
      },
      uav: {
        vUrl: item.external,
        deviceName: "舱外",
        list: item.deviceList,
      },
    };
    this.uav.video = {
      in: null,
      out: null,
      uav: null,
    };
  },

  video_play(type) {
    this.uav.video[type] = this.nest.video[type];
  },

  video_close(type) {
    this.uav.video[type] = null;
  },
};
