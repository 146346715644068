<template>
  <div class="PagerP1Box" style="pointer-events: auto">
    <div class="PagerP1TitleBox jcsb" v-interact>
      <span class="cp">{{ title }}</span>
      <span class="cp" @click="close">{{ exit }}</span>
    </div>
    <div class="PagerP1AreaBox jcsb">
      <div class="leftArea">
        <AngleControl />
      </div>
      <div class="rightArea">
        <div class="w340 h409 mt24 rightAreaBox">
          <div class="jcsb h40 tc lh40">
            <div
              v-for="item in ItemList"
              :key="item.id"
              class="w160 f12"
              :class="type == item.name ? 'active' : 'rightAreaNav'"
              @click="type = item.name"
            >{{ item.title }}</div>
          </div>
          <div>
            <component :is="type"></component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MavLink20Processor from "@/utils/PagerP1/mavlink20_processor";
import AngleControl from "./components/angleControl"; //p1喊话器
import ItemA from "./components/itemA"; //文字转语音
import ItemB from "./components/itemB"; //语音广播
import ItemC from "./components/itemC"; //音频文件

//必须引入的核心，换成require也是一样的。注意：recorder-core会自动往window下挂载名称为Recorder对象，全局可调用window.Recorder，也许可自行调整相关源码清除全局污染
import Recorder from "recorder-core";
import { mapGetters } from "vuex";
const { VUE_APP_FCMQTT_URL } = process.env;
//需要使用到的音频格式编码引擎的js文件统统加载进来
import "recorder-core/src/engine/mp3";
import "recorder-core/src/engine/mp3-engine";
import "recorder-core/src/engine/pcm";
import mqtt from "mqtt";
export default {
  props: {
    title: {
      default: "喊话器",
      type: String
    },
    exit: {
      default: "关闭",
      type: String
    },
    PoliceKeyCode: {
      default: "MMC301R22205057",
      type: String
    }
  },
  data() {
    return {
      type: "ItemA",
      music: {
        list: []
      },
      // ttsState: null,
      key: "PoliceF737",
      ItemList: [
        {
          id: 1,
          name: "ItemA",
          title: "文字转语音"
        },
        {
          id: 2,
          name: "ItemB",
          title: "语音广播"
        },
        {
          id: 3,
          name: "ItemC",
          title: "音频文件"
        }
      ],
      mqtt: {
        client: null,
        decoder: null,
        encoder: new TextEncoder()
      },
      ttsState: null,
      recodeState: null,
      volume: null,
      angle: null,
      ttsCycle: null,
      recodeCycle: null
    };
  },
  provide() {
    return {
      PagerP1: this,
      PoliceKey: () => this.PoliceKeyCode,
    };
  },
  // watch: {
  //   'PoliceKeyCode': {
  //     handler(value) {
  //       if(value){
  //         this.key = value;
  //       }
  //       this.init();
  //     },
  //     deep: true,
  //     immediate:true
  //   },
  // },
  components: {
    AngleControl,
    ItemA,
    ItemB,
    ItemC
  },
  computed: {
    ...mapGetters(["user_info"])
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let url = VUE_APP_FCMQTT_URL;
      // let url = 'ws://200.10.1.245:8883/mqtt'
      // console.log("测试开始连接");
      this.mqtt.client = mqtt.connect(url, {
        protocol: "mqtt",
        clientId: this.user_info["mmc-identity"]
      });
      let key = this.PoliceKeyCode;
      // console.log(`SPEAK/RECEIVE/${key}-01`, "链接主题");
      this.mqtt.client.subscribe(`SPEAK/RECEIVE/${key}-01`, e => {
        // console.log("init初始化完成", "subscribe on_and_off_broker");
        // console.log(this.mqtt.client);
      });
      let music_list = [];
      this.mqtt.client.on("message", (topic, payload, packet) => {
        const data = JSON.parse(packet.payload.toString());
        // console.log(data,"datadata");
        this.music.list = data.musicList;
        this.ttsState = data.ttsState;
        this.recodeState = data.recodeState;
        this.volume = data.status_info.volume
        this.angle = data.angle
        this.ttsCycle = data.ttsCycle
        this.recodeCycle = data.recodeCycle
      });
    },
    close() {
      this.$emit("close");
      this.mqtt.client.end();
      this.mqtt.client = null;
    },
    mqtt_subscribe(topic, callback) {
      if (!topic) {
        return topic;
      }
      let { client } = this.mqtt;
      if (client) {
        client.subscribe(topic, callback);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.PagerP1Box {
  position: fixed;
  top: 70px;
  right: 60px;
  /* background: center url("~@/assets/images/observe/fckernel/mount/01_bg.png")
    no-repeat; */
  // background: rgba(0, 23, 79, 0.7);
  // box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
  //   inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  // border-radius: 10px;
  // background-size: 100% 100%;
  // width: 700px;
  // height: 400px;
  box-sizing: border-box;
  padding: 10px 20px;
  // position: relative;
  // z-index: 10;
  width: 546px;
  // border: 1px solid #43deff;
  // box-shadow: 0 2px 8px 0 rgba(1, 162, 255, 0.7), inset 0 0 8px 0 #019aff,
  //   inset 0 0 64px 0 rgba(26, 138, 227, 0.35);
  // border-radius: 10px;
  min-height: 501px;
  .PagerP1TitleBox {
    height: 44px;
    line-height: 44px;
    padding: 0 24px;
    color: #41d8fa;
    background: rgba(25, 66, 156, 0.5);
    background-image: radial-gradient(
      50% 100%,
      rgba(107, 217, 255, 0.15) 0%,
      rgba(35, 68, 184, 0) 100%
    );
    box-shadow: 0 2px 8px 0 rgba(2, 4, 10, 0.15);
    border-radius: 10px 10px 0 0;
  }
  .PagerP1AreaBox {
    height: 457px;
    background: rgba(24, 39, 76, 0.7);
    .leftArea {
      width: 150px;
    }
    .rightArea {
      width: calc(100% - 150px);
      // background-color:aquamarine
      .rightAreaBox {
        border: 1px solid #3768b3;
        border-radius: 4px 4px 0 0;
        .rightAreaNav {
          background: rgba(23, 70, 216, 0.2);
          box-shadow: 0 2px 4px 0 rgba(23, 33, 60, 0.5), inset 0 0 4px 0 #00a7ff,
            inset 0 0 16px 0 rgba(33, 137, 255, 0.4);
          cursor: pointer;
          color: #3ca8e0;
        }
        .active {
          background: #304bc2;
          box-shadow: 0 2px 4px 0 rgba(23, 33, 60, 0.5), inset 0 0 4px 0 #00a7ff,
            inset 0 0 16px 0 rgba(33, 137, 255, 0.4);
          cursor: pointer;
          color: #fff;
        }
      }
    }
  }
}
</style>
