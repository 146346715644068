<template>
  <div class="create-task-container">
    <div class="header">
      <div class="left">
        <img src="@/assets/img/i38.png" alt="" class="img" @click="toMain">
        <span class="name">{{ cateIdFlag == 0 ? "创建常态任务" : "创建临时紧急任务" }}</span>
      </div>
    </div>
    <div class="body">
      <el-form class="body-el-form" ref="form" :model="form" label-position="top" :rules="rules">
        <el-form-item label="任务名称" prop="taskTitle">
          <el-input v-model="form.taskTitle" placeholder="请输入任务名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="执行方式">
          <el-select v-model="form.sourceType" placeholder="选择任务" @change="executeWayChange"
            popper-class="popper-select-panel">
            <el-option label="无人机" :value=1></el-option>
            <el-option label="机巢" :value=2></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择航线">
          <el-select popper-class="popper-select-panel" v-model="form.flightLineId" placeholder="请选择航线名称" clearable
            @focus="drd_airway" @change="handle_change">
            <el-option v-for="item in drd.airway" :key="item.id" :label="item.title" :value="item.id">
              <span style="float: left">{{ item.title }}</span>
              <span style="float: left; color: #00b900; font-size: 13px">{{
                item.issafe == 1 ? "（安全）" : ""
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务类型">
          <el-select popper-class="popper-select-panel" v-model="form.taskTypeId" placeholder="请选择任务类型">
            <el-option v-for="(type) in drd.type" :key="type.id" :label="type.title" :value="type.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务场景">
          <el-select popper-class="popper-select-panel" v-model="form.cenarioName" placeholder="请选择任务场景">
            <el-option v-for="(item, index) in cenarioList" :key="index" :label="item.name" :value="item.name" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="责任单位">
          <el-cascader placeholder="请选择所属机构" v-model="form.organizationId" :options="drd.department"
            :show-all-levels="false" :props="{
              children: 'children',
              label: 'orgName',
              value: 'id',
              checkStrictly: true,
              emitPath: false,
            }" clearable>
          </el-cascader>
        </el-form-item> -->
        <el-form-item label="责任单位" prop="organizationId">
          <el-cascader placeholder="请选择所属机构" v-model="form.organizationId" :options="drd.department"
            popper-class="unit-cascader-drop-panel-create-task" :show-all-levels="false" :props="{
              children: 'children',
              label: 'orgName',
              value: 'id',
              checkStrictly: true,
              emitPath: false,
            }" clearable>
          </el-cascader>
        </el-form-item>
        <!-- 无人机开始 -->
        <!-- 如果是机巢，则隐藏飞手 -->
        <el-form-item label="飞手" v-show="selectAirLineFlag == 1">
          <el-select popper-class="popper-select-panel" filterable v-model="form.userIds" placeholder="请选择飞手"
            :popper-append-to-body="false" multiple clearable>
            <el-option v-for="team in drd.team" :key="team.id" :label="team.title" :value="team.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="电池编号">
          <el-select popper-class="popper-select-panel" filterable v-model="form.batteryNumbers" clearable
            placeholder="请选择电池编号" :popper-append-to-body="false" multiple :multiple-limit="2">
            <el-option v-for="item in drd.batteryList" :key="item.id" :label="item.title" :value="item.title">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 无人机结束 -->
        <!-- 机巢开始 -->
        <!-- <el-form-item label="机巢" v-show="selectAirLineFlag == 2">
          <el-select v-model="form.region" placeholder="请选择任务类型">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- 机巢结束 -->
        <el-form-item label="预计开始时间">
          <el-date-picker :picker-options="pickerOptions" v-model="form.startedAt" type="datetime"
            popper-class="create-task-date-panel" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择预计开始时间"
            :append-to-body="true">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="内容描述">
          <el-input type="textarea" v-model="form.remark" :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="foot">
      <el-button type="primary" class="btn cancel-btn" @click="cancel">取消</el-button>
      <el-button type="primary" class="btn save-btn" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>

import API from "@/api";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import { Utils } from "@/lib/cesium";
let airline_entitys = [];
let air_line_preview = [];
export default {
  props: {
    cateIdFlag: {
      type: Number,
      default: 0,
    }
  },
  data () {
    return {
      form: {
        taskTitle: '', //任务名称
        sourceType: 1, //执行方式
        flightLineId: '', //航线名称
        taskTypeId: '', //任务类型
        cenarioName: '', //任务场景
        organizationId: false, //责任单位
        userIds: '', //飞手
        batteryNumbers: '',//电池编号
        startedAt: '',//预计开始时间
        remark: '',//描述内容
        cateId: 2,
      },
      rules: {
        taskTitle: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      taskFlag: false, // 无人机任务 or 机巢任务
      drd: {
        airway: [],
        type: [],
        department: [],
        team: [],
        batteryList: [],
      },
      selectAirLineFlag: 1, //选择航线标志，1 无人机， 2 机巢
      cenarioList: [],//任务场景
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000; // 设置选择今天以及今天之后的日
          // return time.getTime() > Date.now(); //设置选择今天以及今天以前的日期
          // return time.getTime() < Date.now();//设置选择今天之后的日期（不能选择当天时间）
          // return time.getTime() > Date.now() - 8.64e7 //设置选择今天之前的日期（不能选择当天）
          // 设置当天23：59：59可选
          // let currentTime = this.getNowMonthDay() + ` 23:59:59`
          // return time.getTime() > new Date(currentTime).getTime()
        },
        selectableRange: `${Date().split(" ")[4]} - 23:59:59`,
      },
    }
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  mounted () {
    this.getTypeList();
    // 任务类型
    this.drd_type();
    // 任务场景
    this.getAllTaskCenarioList();
    // 责任单位
    this.drd_department();
    // 飞手
    this.drd_team();
    // 电池编号
    this.drd_batteryList();

    this.user_info && (this.form.organizationId = this.user_info.departmentId);
  },
  methods: {
    // 请选择航线名称
    handle_change (e) {
      // 提取路径点
      let a;
      this.drd.airway.forEach((item, index) => {
        if (item.id == e) {
          a = index;
        }
      });
      a = JSON.parse(this.drd.airway[a].flightCourseJson);

      let name = this.drd.airway.find((item) => item.id == e);
      if (name) {
        this.form.taskTitle = `${name.title}-${dayjs().format(
          "YYYYMMDDHHmmss"
        )}`;
      } else {
        this.form.taskTitle = null;
      }
      // 调用预览
      this.handleAirLinePreview(a);
    },
    // 航线预览
    async handleAirLinePreview (item) {
      try {
        let viewer = window.viewer;
        if (airline_entitys?.length) {
          airline_entitys.forEach((item) => {
            viewer.entities.remove(item);
          });
        }
        let res = item;
        air_line_preview.forEach((item) => {
          viewer.entities.remove(item);
        });
        if (res != null) {
          let positions = [];
          let center = [];
          let polyline = res;
          let label_arr = [];
          let _this = this;
          polyline.forEach((item, index) => {
            positions.push(item.lon, item.lat, item.alt);
            label_arr.push(
              Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
            );
            let point_entity = viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                item.lon,
                item.lat,
                item.alt
              ),
              name: "show_airline_point",
              point: {
                pixelSize: 20,
                color: Cesium.Color.red,
                color: Cesium.Color.fromCssColorString("red"),
              },
              label: {
                text: String(item.alt) + "m",
                scale: 0.6,
                font: "bold 28px Microsoft YaHei",
                // fillColor: Cesium.Color.BLUE,
                fillColor: Cesium.Color.fromCssColorString("#FF7F09"),
                horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                showBackground: false,
                outlineWidth: 0,
                pixelOffset: new Cesium.Cartesian2(25, -10),
              },
            });
            let point_entity_num = viewer.entities.add({
              name: "airline_point",
              // id: uuid,
              position: Cesium.Cartesian3.fromDegrees(
                item.lon,
                item.lat,
                item.alt
              ),
              point: {
                pixelSize: 10,
                color: Cesium.Color.red,
                color: Cesium.Color.fromCssColorString("red"),
                // fillColor: Cesium.Color.red,
                // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND, // supermap版本会导致拖动显示错误
              },
              label: {
                text: new Cesium.CallbackProperty(() => {
                  return String(index + 1);
                }, false),
                // text:index + 1,
                font: "bold 14px Microsoft YaHei",
                // fillColor: Cesium.Color.BLUE,
                fillColor: Cesium.Color.fromCssColorString("white"),
                horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                showBackground: false,
                outlineWidth: 0,
                pixelOffset: new Cesium.Cartesian2(0, 0),
              },
            });

            airline_entitys.push(point_entity);
            airline_entitys.push(point_entity_num);
            if (label_arr.length > 1) {
              let before = label_arr[label_arr.length - 2];
              let after = label_arr[label_arr.length - 1];

              _this.create_label(before, after);
            }
          });
          positions = Cesium.Cartesian3.fromDegreesArrayHeights(positions);
          let redLine = viewer.entities.add({
            name: "Red line on terrain",
            polyline: {
              positions,
              width: 4,
              material: Cesium.Color.fromCssColorString("#00A9A9"),
            },
          });

          viewer.flyTo(redLine);
          airline_entitys.push(redLine);
        } else {
          this.$el_message("暂无航线", () => { }, "error");
        }
      } catch (error) {
        this.$el_message("暂无航线", () => { }, "error");
      }
    },
    // 显示距离
    create_label (before, after) {
      let viewer = window.viewer;
      let before_wgs84 = Utils.transformCartesian2WGS84(before);
      let after_wgs84 = Utils.transformCartesian2WGS84(after);

      let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      let position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10,
      });

      let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      let label_entity = viewer.entities.add({
        id: `label_${before}`,
        position: position,
        label: {
          text: text,
          scale: 0.5,
          font: "bold 30px Microsoft YaHei",
          fillColor: Cesium.Color.fromCssColorString("#fff"),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10),
        },
      });
      airline_entitys.push(label_entity);
    },
    // 航线名称列表获取
    async drd_airway () {
      let res = await API.AIRWAY.Drd({
        sourceType: this.taskFlag ? 2 : 1,
      });
      this.drd.airway = res || [];
    },
    // 任务类型
    async getTypeList () {
      let res = await API.TASK.DrdType();
      this.drd.type = res || [];
    },
    // 执行方式改变
    executeWayChange (v) {
      this.selectAirLineFlag = v;
    },
    // 选择航线
    async drd_airway () {
      let res = await API.AIRWAY.Drd({
        sourceType: this.selectAirLineFlag,
      });
      this.drd.airway = res || [];
    },
    // 任务类型
    async drd_type () {
      let res = await API.TASK.DrdType();
      this.drd.type = res || [];
    },
    // 任务场景数据
    async getAllTaskCenarioList () {
      let data = await API.BATTERY.getAllTaskCenarioList();
      this.cenarioList = data;
    },
    // 责任单位
    async drd_department () {
      let res = await API.DEPARTMENT.Drd();
      this.drd.department = [res] || [];
    },
    // 飞手
    async drd_team () {
      let res = await API.USER.DrdPilot();
      this.drd.team = res || [];
    },
    // 电池编号
    async drd_batteryList () {
      let data = await API.BATTERY.List();
      this.drd.batteryList = data || [];
    },
    // 保存
    save () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          // console.log(this.form);

          if (this.cateIdFlag == 0) {
            this.form.cateId = 2;
          } else {
            this.form.cateId = 3;
          }
          let res = await API.TASK.Add(this.form);
          if (res.status == 1) {
            this.$el_message("创建成功");
            this.clearForm();
            this.toMain();
          } else {
            this.$message.warning(res.message);
          }
        }
      });
    },
    // 返回主面板
    toMain () {
      this.clearPreviewLine();
      this.clearForm();
      this.$emit('mainShow');
    },
    // 清除form表单
    clearForm () {
      this.form.taskTitle = null; //任务名称
      this.form.sourceType = 1; //执行方式
      this.form.flightLineId = ''; //航线名称
      this.form.taskTypeId = ''; //任务类型
      this.form.cenarioName = ''; //任务场景
      this.form.organizationId = false; //责任单位
      this.form.userIds = ''; //飞手
      this.form.batteryNumbers = '';//电池编号
      this.form.startedAt = '';//预计开始时间
      this.form.remark = '';//描述内容
      this.form.cateId = 2;
    },
    // 取消
    cancel () {
      // this.clearForm();
      this.toMain();
    },
    // 清空图上的预览航线
    clearPreviewLine () {
      let viewer = window.viewer;
      if (airline_entitys?.length) {
        airline_entitys.forEach((item) => {
          viewer.entities.remove(item);
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.create-task-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  // border: 1px solid yellow;

  .header {
    width: 100%;
    height: 54px;
    background: #224D68;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0 10px;

    .left {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .img {
        width: 16px;
        height: 12px;
        cursor: pointer;
      }

      .name {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 5px;
      }
    }
  }

  .body {
    width: 100%;
    height: calc(100% - 108px);
    // height: calc(100% - 0);
    background: #0A293A;
    box-sizing: border-box;
    padding: 10px;
    overflow-y: auto;

    .body-el-form {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    /deep/ .el-form-item {
      margin-bottom: 10px;
      height: 10%;

      // background-color: #007AFF;
      .is-disabled {
        .el-input__inner {
          background-color: transparent;
        }
      }
    }

    /deep/ .el-form-item__label {
      color: #ffffff;
      padding: 0;
      height: auto;
      height: 30px;
      display: flex;
      align-items: center;
    }

    /deep/ .el-select {
      border: 1px solid #3B5461;
      border: 0;
      border-radius: 4px;
      height: 42px
    }

    /deep/ .el-textarea__inner {
      background-color: #0A293A;
      border: 1px solid #3B5461;
      color: #ffffff;
    }

    /deep/ .el-input__inner {
      color: #ffffff;
      border: 1px solid #3B5461;
    }

    ::-webkit-input-placeholder {
      /* WebKit browsers，webkit内核浏览器 */
      color: #768791;
      font-size: 12px;
    }

    /deep/ .el-date-editor {
      width: 100%;
    }
  }

  .foot {
    width: 100%;
    height: 54px;
    background: #0D3449;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;

    .btn {
      width: 114px;
      height: 40px;
      border-radius: 4px;
      color: #ffffff;
      border: 0;
    }

    .cancel-btn {
      background: #133D54;
    }

    .save-btn {
      background: #007AFF;
    }
  }

}
</style>
<style lang="scss">
// @import "@/styles/wuhuCommon.scss";

.popper-select-panel {
  min-width: 380px !important;

  .el-scrollbar {
    background-color: #0A293A !important;
    border: 1px solid #223E4D;

  }

  .el-select-dropdown__item:hover {
    background-color: #34466E !important;
  }
}

.unit-cascader-drop-panel-create-task {
  background-image: url('~@/assets/img/i101.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0 !important;
  margin: 0 !important;
  // top: 15px !important;
  border: 1px solid #223E4D !important;

  .el-scrollbar {
    background-color: transparent;
    border: 0;
    border-right: 1px solid #223E4D;
  }

  .el-scrollbar:last-child {
    border: 0 !important;
  }

  .el-cascader-node {
    background-color: transparent;
  }

  .el-cascader__dropdown {
    box-shadow: unset;
    margin: 0 !important;
    border: 0 !important;
  }

  .el-scrollbar__view {
    background-color: #0A293A;
  }

  .popper__arrow {
    display: none;
  }

  .el-cascader-node__label {
    color: #fff;
  }

  .el-icon-arrow-right {
    color: #fff;
  }
}

.create-task-date-panel {
  .el-picker-panel__body-wrapper {
    // background: #0d224f;
    background-color: #0A293A !important;
    border: 1px solid #3B5461;
    color: #fff;

    .el-input__inner {
      color: #fff;
      border: 1px solid #3B5461;
    }

    .el-date-table th {
      border-bottom: solid 1px #3B5461;
      color: #fff;
    }

    .el-date-table td.in-range div {
      background-color: #0A293A;
    }

    .el-input--small .el-input__inner {
      // background-color: #0d224f;
      background-color: #0A293A;
    }

    .el-picker-panel__icon-btn {
      color: #fff;
    }
  }

  .el-picker-panel__footer {
    // background-color: #0d224f !important;
    background-color: #0A293A !important;
    border-top: 1px solid #3B5461;
  }

  .el-button.el-button--mini.is-plain,
  .el-button.is-disabled.is-plain {
    background-color: transparent !important;
    border: none;
    color: #00aeff;
  }

  .el-date-range-picker__time-header {
    border: 1px solid #3B5461 !important;
  }

  .el-date-picker__time-header {
    border: 1px solid #3B5461 !important;
  }

  .el-date-range-picker__content.is-left {
    border: 1px solid #3B5461;
  }

  .el-scrollbar {
    background-color: #0A293A !important;
    border: 1px solid #3B5461;
  }

  .el-time-panel__footer {
    background-color: #0A293A !important;
    border-top: 1px solid #3B5461;
  }

  .el-time-panel {
    border: 1px solid #3B5461;
  }
}
</style>