<template>
  <div class="nest_controlBox">
    <PositionBall class="pa left-31" :uav-data="uavData" />
    <ymfz :channelData="data"></ymfz>
    <img class="img2" src="@/assets/img/ball2.png" />
    <img class="img3" src="@/assets/img/ball2.png" />
    <div class="w729 h78 nest_control">
      <Info :offline="offline" class="w575 ml35 h78" :uav-data="uavData"></Info>
      <div class="line mr1"></div>
      <battery :uav-battery="uavBattery"></battery>
    </div>

    <div></div>
  </div>
</template>
<script>
import PositionBall from "./components/ball/index";
import Info from "./components/info/index";
import battery from "./components/battery/index";
import ymfz from "./components/ymfz/index";

export default {
  data() {
    return {};
  },
  props: {
    uavData: {
      type: Object,
      default: () => ({}),
    },
    uavBattery: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    offline: {
      type: Boolean,
      default: () => { true
      },
    },
  },
  components: { PositionBall, Info, battery, ymfz },
  computed: {
    props_obj() {
      let { data } = this;
    },
  },
};
</script>
<style scoped lang="scss">
.nest_controlBox {
  width: 850px;
  height: 200px;
  position: absolute;
  display: flex;
  justify-content: start;
  justify-content: center;
  align-items: center;
  left: calc(53% - 425px);
  bottom: -40px;
  z-index: 3;
  pointer-events: none;
  .nest_control {
    display: flex;
    background: rgba(9, 32, 87, 0.7);
    .line {
      width: 0px;
      height: 75px;
      border: 1px solid;
      border-image: linear-gradient(
          180deg,
          rgba(112, 218, 249, 0),
          rgba(112, 218, 249, 1),
          rgba(112, 218, 249, 0)
        )
        2 2;
    }
  }
  .img2 {
    position: absolute;
    left: -36px;
    top: calc(35%);
    width: 8px;
    height: 9px;
    z-index: 9999;
    transform: rotate(-75deg);
  }
  .img3 {
    position: absolute;
    left: -40px;
    top: calc(34%);
    width: 8px;
    height: 9px;
    z-index: 9999;
    transform: rotate(115deg);
  }
}
</style>