<template>
  <div class="controlMenuBox">
    <div
      class="left-bar"
      :class="{ collapse, yidong: wiezhi == true, didian: task_wei == true }"
    >
      <div class="left-bar-item item" @click="closeAI()">
        <img src="~@/assets/images/nest/task.svg" class="left-bar-item__icon" />
        <span class="left-bar-item__text">任务</span>
      </div>
      <div
        class="left-bar-item item"
        @click="
          openAIList = !openAIList;
          $emit('changeTask', false);
        "
      >
        <img
          class="left-bar-item__icon"
          src="~@/assets/images/observe/ai.png"
        />
        <div class="left-bar-item__text">智能识别</div>
      </div>
      <div class="ai-list" :class="{ active: openAIList }" @click.stop>
        <div class="left-bar-item item" @click="switchAI(0)">
          <img
            class="left-bar-item__icon"
            src="~@/assets/images/observe/faceAI2.png"
          />
          <div class="left-bar-item__text">人脸识别</div>
        </div>
        <Face
          v-if="openFace"
          class="ai-dialog"
          :uavId="device.deviceList[0].deviceHardId"
          @close="openFace = false"
        />
        <div class="left-bar-item item" @click="switchAI(1)">
          <img
            class="left-bar-item__icon"
            src="~@/assets/images/observe/carAI2.png"
          />
          <div class="left-bar-item__text">车辆识别</div>
        </div>
        <Car
          v-if="openPlate"
          class="ai-dialog"
          :uavId="device.deviceList[0].deviceHardId"
          @close="openPlate = false"
        />
        <div
          class="left-bar-item item"
          @click="switchAI(2)"
          v-if="taskId !== null"
        >
          <img
            class="left-bar-item__icon"
            src="~@/assets/images/observe/traffic.png"
          />
          <div class="left-bar-item__text">交通指引</div>
        </div>
        <!-- <div class="left-bar-item item" @click="switchAI(3)">
          <img class="left-bar-item__icon" src="~@/assets/images/observe/ksjm.png" />
          <div class="left-bar-item__text">快速建模</div>
        </div>-->
        <Traffic
          v-if="openTrafiic"
          class="ai-dialog"
          :uavId="device.deviceList[0].deviceHardId"
          @close="openTrafiic = false"
        />
      </div>
    </div>
    <div class="cmb-bar">
      <!--  v-show="taskType == 'itemA'" -->
      <div class="cmb-item" @click="handClick(0)">
        <SymbolIcon icon="yijianrenwu" class="cmb-item__icon" />
        <span class="dib f8">一键任务</span>
      </div>
      <!--  v-show="taskbtn" -->
      <div class="cmb-item" @click="handClick(1)">
        <SymbolIcon icon="yijianfanhang2" class="cmb-item__icon" />
        <span class="dib f8">一键返航</span>
      </div>
      <!-- v-show="taskbtn" -->
      <div class="cmb-item" @click="handClick(2)">
        <SymbolIcon icon="anquanjiangla1" class="cmb-item__icon" />
        <span class="dib f8">安全降落</span>
      </div>

      <div
        v-if="!unlockType"
        class="w48 h48 cmb-item mb3 cf tc cp"
        :class="!unlockType && iStatusValue == 15 ? 'sdActive' : 'sdDefault'"
      >
        <div @click="changeType">
          <SymbolIcon icon="shoudong" />
          <span class="dib f8">手动</span>
        </div>
      </div>
      <div v-else class="w48 h48 cmb-item mb3 cf tc cp">
        <div @click="unlock">
          <SymbolIcon icon="zidong" />
          <span class="dib f8">自动</span>
        </div>
      </div>
    </div>

    <div class="pf" style="top: 128px">
      <div
        class="cmb-item cmb-item--mb2"
        :class="controlTypeFlag && controlListFlag ? 'selectItem' : ''"
        @click="controlListFlagFn('yc')"
      >
        <SymbolIcon icon="yingchao2" class="cmb-item__icon" />
        <span class="dib f8">机巢</span>
      </div>
      <div
        class="cmb-item cmb-item--mb2"
        :class="MountFlag ? 'selectItem' : ''"
        @click="mountFn"
      >
        <SymbolIcon icon="guazai1" class="cmb-item__icon" />
        <span class="dib f8">挂载</span>
      </div>
      <div
        class="cmb-item cmb-item--mb2"
        :class="!controlTypeFlag && controlListFlag ? 'selectItem' : ''"
        @click="controlListFlagFn('wrj')"
      >
        <SymbolIcon icon="wurenji2" class="cmb-item__icon" />
        <span class="dib f8">无人机</span>
      </div>
      <div class="cmb-item" @click="stk()">
        <!-- <div @click="stk()"> -->
        <img src="@/assets/images/font/stk.png" alt />
        <span class="dib f8">视图库</span>
        <!-- </div> -->
      </div>
      <div class="stk" v-if="stkshow">
        <div class="stk_head">
          <div :class="isfk == 1 ? 'ac' : ''" @click="isflyk(1)">视图</div>
          <div :class="isfk == 2 ? 'bc' : ''" @click="isflyk(2)">机载</div>
        </div>
        <div class="content">
          <!-- 先注释，下次更新要用 -->
          <div
            class="head"
            :style="
              isfk == 2
                ? 'justify-content: space-between;'
                : 'justify-content: flex-end;'
            "
          >
            <div class="bt jcsb" v-if="isfk == 2">
              <div class="btns" @click="submit">转存</div>
              <div class="btns blue ml12" @click="handleCheckAllChange">
                全选
              </div>
            </div>
            <div class="select" v-if="isfk == 2">
              <div
                class="btns"
                :class="filetype == 0 ? 'active' : ''"
                @click="changephotoType(0)"
              >
                图片
              </div>
              <div
                class="btns"
                :class="filetype == 1 ? 'active' : ''"
                @click="changephotoType(1)"
              >
                视频
              </div>
              <span class="iconfont icon-gengxin" @click="reset"></span>
            </div>
            <div class="select" v-if="isfk == 1">
              <div
                class="btns"
                :class="filetypev2 == 0 ? 'active' : ''"
                @click="changephotoType(1)"
              >
                图片
              </div>
              <div
                class="btns"
                :class="filetypev2 == 1 ? 'active' : ''"
                @click="changephotoType(2)"
              >
                视频
              </div>
              <span class="iconfont icon-gengxin" @click="reset"></span>
            </div>
          </div>
          <div class="lists">
            <!-- <div style="text-align: left">asfnjkfassfausfasn</div> -->
            <div class="boxs" v-if="isfk == 1">
              <div class="task" v-for="(item, index) in photoList" :key="index">
                <div class="name">{{ item.taskName }}</div>
                <div class="images">
                  <div
                    class="box"
                    v-for="(item2, index2) in photoList[index].list"
                    :key="index2"
                    :class="isfk == 1 && filetypev2 == 2 ? 'boxv2' : ''"
                  >
                    <!-- <el-image
                        v-if="isfk == 1 && filetypev2 == 1"
                        :src="imgApi + '/uploads'+ item2.fileKey"
                        fit="cover"
                        :preview-src-list="[imgApi + '/uploads' +item2.fileKey]"
                    ></el-image>-->
                    <img
                      @click="lookBig(item2)"
                      :src="imgApi + '/uploads' + item2.fileKey"
                    />
                    <video
                      controls
                      v-if="isfk == 1 && filetypev2 == 2"
                      :src="imgApi + '/uploads' + item2.fileKey"
                    ></video>
                    <div
                      class="down"
                      v-if="isfk == 1 && filetypev2 == 1"
                      @click="download(item2, index2)"
                    >
                      下载
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="boxs jz" v-else>
              <el-checkbox-group v-model="checkList">
                <div
                  class="box"
                  v-for="(item, index) in photoList"
                  :key="index"
                  :class="isfk == 2 && filetype == 1 ? 'boxv2' : ''"
                >
                  <el-checkbox :label="item">
                    <el-image
                      v-if="isfk == 2 && filetype == 0"
                      :src="item.jsonFilePath"
                      fit="cover"
                      :preview-src-list="[item.jsonFilePath]"
                    ></el-image>

                    <video
                      controls
                      v-if="isfk == 2 && filetype == 1"
                      :src="item.jsonFilePath"
                    ></video>
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
      <ControlList
        @clearId="$emit('clearId')"
        @closeIconShow="iconShow = false"
        :jcData="jcData"
        :controlTypeFlag="controlTypeFlag"
        :class="controlTypeFlag ? 'ycStyle' : 'wrjStyle'"
        @airway-display="$emit('airway-display')"
        @uav-location="$emit('uav-location')"
        @yxrz="$emit('yxrz')"
        @fn="fn"
        @exit="controlListFlag = false"
        v-if="controlListFlag"
      ></ControlList>

      <Mount
        :offline="offline"
        @fn="fn"
        :device="device"
        :uav-mounts="uavMounts"
        :uav-mounts-payload="uavMountsPayload"
        :mount="mount"
        v-if="MountFlag"
      ></Mount>
    </div>
    <!-- 飞控 机巢 安全降落 -->
    <el-dialog
      :visible.sync="safetyNotice"
      width="30%"
      :modal-append-to-body="false"
    >
      <div class="endrenwu" v-if="dialog_type == 'task'">
        <div class="tishiyu">安全降落</div>
        <div class="queding">
          无人机即将原地降落，请确认无人机下方是否安全！
        </div>
        <div class="btn_kuang">
          <div class="btn btn_lan" @click="safetyNotice = false">取消</div>
          <div style="width: 20px"></div>
          <div class="btn btn_lv" @click="_remind">确定</div>
        </div>
      </div>
      <div class="endrenwu" v-if="dialog_type == 'model'">
        <div class="tishiyu">温馨提示</div>
        <div class="queding">
          请确定是否进行
          <span style="color: red">{{ modelType }}</span
          >切换,目前无人机处于
          <span style="color: red">{{
            rcChannelStateList[rcChannelState]
          }}</span
          >状态
        </div>
        <div class="btn_kuang">
          <div class="btn btn_lan" @click="safetyNotice = false">取消</div>
          <div style="width: 20px"></div>
          <div class="btn btn_lv" @click="model_qr">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from "@/api";
import mqtt from "mqtt";
import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";
import ControlList from "@/components/observe/fckernel/nest/control/controlList";
import Mount from "@/components/observe/fckernel/nest/control/components/mount";
const { VUE_APP_FCMQTT_URL, VUE_APP_FCMQ_URL } = process.env;
import mixinJoystick from "./mixins/joystick";
// 引用bus
import Bus from "@/assets/ligature.js";
import Traffic from "./AI/traffic"; //交通指引
import Face from "./AI/face"; //人脸识别
import Car from "./AI/car"; //车牌识别
import methods from "./methods";
export default {
  mixins: [mixinJoystick],
  components: { SymbolIcon, ControlList, Mount, Traffic, Face, Car },
  props: {
    iconShow: {
      type: Boolean,
      default: false,
    },
    uavMounts: {
      type: Array,
      default: () => [],
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
    wrjType: {
      type: Object,
      default: () => ({}),
    },
    uavMountsPayload: {
      type: Object,
      default: () => ({}),
    },
    mount: {
      type: Object,
      default: () => ({}),
    },
    taskType: {
      type: String,
      default: () => "itemA",
    },
    jcData: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    offline: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imgApi: process.env.VUE_APP_IMG_URL,
      stkshow: false,
      dialog_type: "task", //值为task时代表为任务结束提示框 ，为model，代表模式切换
      rcChannelState: null, //rcChannelState为0时 处于中位 大于处于高位，小于0处于低位
      modelType: null, //提示语 dialog_type为model时生效
      rcChannelStateList: {
        0: "摇杆中位",
        "-98": "摇杆外场权限",
        "-99": "摇杆未连接",
        1: "俯仰偏前",
        "-1": "俯仰偏后",
        "-2": "横滚偏左",
        2: "横滚偏右",
        3: "油门偏高",
        "-3": "油门偏低",
        4: "航向偏右",
        "-4": "航向偏左",
      },
      isfk: 1,
      pageNo: 1,
      photoList: [],
      checkList: [],
      filetype: 0,
      filetypev2: 1,
      controlListFlag: false,
      controlTypeFlag: false,
      MountFlag: false,
      unlockType: true,
      ws: "",
      client: null,
      safetyNotice: false, // 安全降落提示框
      taskbtn: null, //iconShow 传过来的值，
      wiezhi: false,
      task_wei: false,
      openAIList: false, //打开AI列表
      openTrafiic: false, //打开交通指引
      openFace: false, //打开人脸识别
      openPlate: false, //车牌识别
    };
  },
  computed: {
    taskId() {
      return this.$store.state.fckernel.taskId;
    },
    collapse() {
      return this.$store.state.fckernel.nestCollapse;
    },
  },
  async mounted() {
    let res = await API.FCKERNEL.getTaskAndUserRelation({
      sourceType: 1,
      deviceHardId: this.device.deviceList[0].deviceHardId,
    });
    if (res.code == 200 && res.list) {
      this.nest_this.iconShow = true;
    } else if (res.code == 201) {
      this.$message.warning(res.msg);
    }
    // let waypointList = JSON.parse(sessionStorage.getItem("waypointList"));
    // if (waypointList) {
    //     this.nest_this.iconShow = true
    //     console.log( this.nest_this.iconShow,111,' this.nest_this.iconShow');
    // }
    // 摇杆 mqtt初始化
    this.initMqtt();
    //

    if (sessionStorage.getItem("iconShow") != null) {
      this.taskbtn = sessionStorage.getItem("iconShow");
    }
  },
  watch: {
    uavData(val) {
      // 如果数据值由 0 或者 -99 或者 -98 变为其他状态则提示
      if (
        this.rcChannelState == 0 ||
        this.rcChannelState == "-99" ||
        this.rcChannelState == "-98"
      ) {
        if (
          val.rcChannelState != 0 &&
          val.rcChannelState != "-99" &&
          val.rcChannelState != "-98"
        ) {
          this.$message.warning(
            `当前无人机处于${
              this.rcChannelStateList[val.rcChannelState]
            } 状态,请谨慎飞行`
          );
        }
      }
      //rcChannelState为0时 处于中位 大于处于高位，小于0处于低位
      this.rcChannelState = val.rcChannelState;
    },
    device(val) {
      //选择不同的机巢，重新获取挂载
      this.MountFlag = false;
      if (val) {
        //自动模式
        this.unlockType = true;
        this.closeJoystick();
        this.ws && this.ws.close();
        this.ws = null;
        if (this.client) {
          this.client.end();
          this.client = null;
        }
        // 摇杆 mqtt初始化/
        this.initMqtt();
      }
    },
    // "$store.state.shoudong.Mstate"(vla, old) {
    //   if (vla == "定点模式") {
    //     this.initJoystick(this.device.deviceList[0]);
    //     if (this.uavModel == true) {
    //       this.unlockType = true;
    //     } else {
    //       this.unlockType = false;
    //     }
    //   }
    // },
    iconShow: function (vla, old) {
      // console.log(vla,'sadasdasdasd123----------');
      // 防止页面刷新造成的数据消失
      sessionStorage.setItem("iconShow", vla);
      this.taskbtn = vla;
    },
    deep: true,
  },
  inject: ["nest_this"],
  beforeDestroy() {
    this.unlockType = true;
    this.ws && this.ws.close();
    this.ws = null;
    if (this.client) {
      this.client.end();
      this.client = null;
    }
  },
  created() {
    // 搜索关键字：飞控机巢左侧任务框位置判定
    Bus.$on("ren_task", (status) => {
      this.wiezhi = status;
    });
    // 搜索关键字：飞控机巢左侧任务框位置判定(侧边栏关闭状态)
    Bus.$on("close_ce", (status) => {
      this.task_wei = status;
    });
  },
  methods: {
    ...methods,
    /**
     * 切换AI功能
     */
    switchAI(type) {
      this.$emit("changeTask", false);
      this.openFace = false;
      this.openPlate = false;
      this.openTrafiic = false;

      switch (type) {
        case 0:
          this.openFace = !this.openFace;
          break;

        case 1:
          this.openPlate = !this.openPlate;
          break;

        case 2:
          this.openTrafiic = !this.openTrafiic;
          break;
        case 3:
          let a = document.createElement("a");
          a.href = "MMCEagleEye://";
          a.click();
          break;
      }
    },
    initMqtt() {
      let url = VUE_APP_FCMQTT_URL;
      const crypto =
        window.crypto ||
        window.webkitCrypto ||
        window.mozCrypto ||
        window.oCrypto ||
        window.msCrypto;
      let uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
      this.client = mqtt.connect(url, {
        protocol: "mqtt",
        clientId: uuid,
      });
      this.client.on("connect", () => {
        console.log("摇杆mqtt链接成功");
      });
      this.client.on("error", function (err) {
        console.log("摇杆mqtt-err=>", err);
        this.client.end();
      });
      this.client.on("reconnect", function (err) {
        console.log("正在重连", err);
      });
      // this.client.on("message", (topic, payload, packet) => {
      //   let data = JSON.parse(payload.toString());
      //   if (data.type == 270) {
      //   }
      // });
    },
    subscribe(topic, callback) {
      if (!topic) {
        return topic;
      }
      let { client } = this;
      if (client) {
        client.subscribe(topic, callback);
      }
    },
    fn(data, type) {
      this.$emit("fn", data, type);
    },
    async changeType() {
      if (this.rcChannelState == null) return false;
      this.dialog_type = "model";
      this.modelType = "自动模式";
      if (this.rcChannelState == 0 || this.rcChannelState == "-98") {
        this.model_qr();
      } else {
        this.safetyNotice = true;
      }
    },
    unlock() {
      if (this.rcChannelState == null) return false;
      this.dialog_type = "model";
      this.modelType = "手动模式";
      if (this.rcChannelState == 0 || this.rcChannelState == "-98") {
        this.model_qr();
      } else {
        this.safetyNotice = true;
      }
    },
    // 切换模式时弹出框 确认事件
    model_qr() {
      if (this.modelType == "手动模式") {
        let a = document.createElement("a");
        a.href = "JoystickTools://";
        a.click();
        setTimeout(() => {
          this.initJoystick(this.device.deviceList[0]);
        }, 5000);
        // 将右上角的模式修改为定点模式
        // 匹配关键字：修正模式
        Bus.$emit("quick_search", "POSITION");
      } else if (this.modelType == "自动模式") {
        // 切换为自动模式
        this.unlockType = true;
        this.ws.send(JSON.stringify({ type: 2002, data: {} }));
        this.ws && this.ws.close();
        this.ws = null;
        // 将右上角的模式修改为航线模式
        // 匹配关键字：修正模式
        Bus.$emit("quick_search", "AUTO_MISSION");
      }
    },
    mqtt_fn(data, type) {
      let device = this.device;
      if (device) {
        let topic = `${device.deviceList[0].type}/OBTAIN/${device.deviceList[0].deviceHardId}`;
        this.publish(topic, data);
      }
    },
    publish(topic, message, callback, options = { qos: 0 }) {
      let { client } = this;
      if (client) {
        client.publish(
          topic,
          new TextEncoder().encode(JSON.stringify(message)),
          options,
          (error) => {
            console.log(
              topic,
              message,
              new TextEncoder().encode(JSON.stringify(message))
            );
            if (!error) {
              console.log("发送成功");
            } else {
              //console.log("error", error);
            }
          }
        );
      }
    },
    controlListFlagFn(item) {
      // 运行状态检测
      // 关闭
      Bus.$emit("yunxing", false);
      this.controlListFlag = false;
      if (item == "yc") {
        this.controlTypeFlag = true;
      } else {
        this.controlTypeFlag = false;
      }
      this.MountFlag = false;
      this.controlListFlag = true;
    },
    mountFn() {
      this.MountFlag = !this.MountFlag;
      if (this.MountFlag) {
        this.controlListFlag = false;
      }
    },
    async start() {
      let waypointList = JSON.parse(sessionStorage.getItem("waypointList"));
      if (waypointList) {
        // 判断是否有任务
        let nestData = await API.FCKERNEL.getNestTaskByTime({
          nestId: this.nest_this.nest.data.deviceHardId,
        });
        if (nestData.code == 201) {
          return this.$message.warning(nestData.message);
        }

        // 局部预警
        let checkBeforeTakeOff = await API.DEVICE.checkBeforeTakeOff({
          taskId: waypointList.flyTypeId,
        });
        if (!checkBeforeTakeOff.code) {
          this.$emit("Lsdom", checkBeforeTakeOff);
          return;
        }
        this.$confirm("请确认是否进行一键任务操作", "安全确认", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          customClass: "uav_controlPane",
          showClose: false,
        })
          .then(async () => {
            // 生成架次號
            let getFlightSortic = await API.DEVICE.getFlightSortic({
              taskId: waypointList.flyTypeId,
              deviceHardId: waypointList.uavDeviceId,
            });
            // 告诉飞控开始任务,并且把架次号和 任务id传过去
            this.startFlight({
              taskId: waypointList.flyTypeId,
              flightSortiesID: getFlightSortic,
            });
            // 机巢一键任务指令
            this.$emit(
              "fn",
              {
                cmdControlType: 100004,
                uavDeviceId: waypointList.uavDeviceId, //无人机id
                wayLineObj: {
                  taskId: waypointList.flyTypeId, //任务id
                  flightSortiesID: getFlightSortic,
                  ...waypointList.wayLineObj,
                }, //航线数据
              },
              "yc"
            );
            // 更改任务状态 status 任务执行状态 默认-1待派发 0表示待执行；3执行中;1执行完成
            this.task_update(3, waypointList.flyTypeId);
            // 显示航线
            this.$emit("airway-display");
            this.iconShow = true;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else {
        this.$message.warning("请选择任务");
      }
    },
    startFlight(data) {
      // 开始任务
      this.$emit("fn", { data, type: 769 }, "wrj");
    },
    async handClick(item) {
      if (item == 0) {
        await this.$confirm("请确认是否进行一键任务操作", "安全确认", {
          center: true,
        });
        this.start();
      } else if (item == 1) {
        //一键返航
        await this.$confirm("请确认是否进行一键返航操作？", "安全确认", {
          center: true,
        });
        Bus.$emit("superModel", "AUTO_RTL");
      } else if (item == 2) {
        this.dialog_type = "task";
        this.safetyNotice = true;
        // this.$message.success('操作成功')
      }
    },
    _remind() {
      this.$emit("fn", { type: 516 }, "wrj");
      this.safetyNotice = false;
    },
    task_update(status, flyTypeId) {
      API.DEVICE.task_update({
        id: flyTypeId,
        status,
        deviceHardId: waypointList.uavDeviceId,
      });
    },
    closeAI() {
      this.openAIList = false;
      this.openFace = false;
      this.openPlate = false;
      this.openTrafiic = false;
      this.$emit("changeTask");
    },
  },
};
</script>
<style lang="scss" scoped>
.left-bar {
  position: fixed;

  left: 0px;
  top: 82px;
  transition: 0.3s;

  &.collapse {
    left: 10px;
  }

  .left-bar-item {
    cursor: pointer;
    position: relative;
    width: 48px;
    height: 48px;
    background: rgba(9, 32, 87, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2px;

    .left-bar-item__icon {
      width: 20px;
      height: 20px;
      margin-bottom: 2px;
    }

    .left-bar-item__text {
      font-size: 16px;
      transform: scale(0.6);
      white-space: nowrap;
      color: #fff;
    }
  }

  .ai-list {
    width: 0;
    position: absolute;
    left: 55px;
    top: 50px;
    display: none;

    &.active {
      display: flex;
    }

    .left-bar-item {
      // margin-right: 4px;
    }
  }
}

.controlMenuBox {
  position: fixed !important;
  right: 20px;
  top: 130px;
  width: 44px;

  .item {
    padding: 5px;
    background: rgba(9, 32, 87, 0.7);
    border: 1px solid transparent;
    transform: scale(0.8);
    margin: 0 0 -10px 0;
  }

  .ycStyle {
    position: absolute;
    top: 0;
  }

  .wrjStyle {
    position: absolute;
    bottom: -55px;
  }

  .selectItem {
    border: 1px solid #70daf9;
  }
}

// 弹框样式
.endrenwu {
  width: 100%;
  height: 177px;
  background: rgba(9, 32, 87, 0.7) !important;
  border: 1px solid #70daf9 !important;
}

.queding {
  color: #92d9ff;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 30px 0 0 0;
}

.tishiyu {
  color: #92d9ff;
  font-size: 18px;
  margin: 30px 0 0 0;
  text-align: center;
  width: 100%;
}

.btn_kuang {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  width: 79px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  margin: 20px 0 0 0;
  cursor: pointer;
}

.btn_lan {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #606266;
}

.btn_lv {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #fff;
}

/deep/ .el-dialog__header {
  margin-left: 0;
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
  z-index: 2023 !important;
}

/deep/ .el-dialog {
  z-index: 2022 !important;
  background: transparent !important;
}

.cmb-item {
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  background: rgba(9, 32, 87, 0.7);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;

  &.cmb-item--mb2 {
    margin-bottom: 2px;
  }
}

.cmb-item__icon {
  width: 20px;
}

.cmb-task {
  position: fixed;
  left: 10px;
  top: 82px;
  transition: 0.3s;
  z-index: 20;

  &.collapse {
    left: 10px;
  }
}

.cmb-bar {
  position: fixed;
  left: 50%;
  bottom: 116px;
  display: flex;
  transform: translate(-55%, 0);

  .cmb-item {
    margin-right: 34px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.f8 {
  transform: scale(0.66);
}

.yidong {
  left: 479px !important;
}

.didian {
  left: 10px;
}

.stk {
  position: absolute;
  right: 60px;
  top: 150px;
  width: 470px;
  height: 378px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);

  .stk_head {
    display: flex;

    div {
      padding: 0 8px;
      // width: 243px;
      width: 100%;
      text-align: center;
      line-height: 32px;
      font-size: 16px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 21px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    }

    .ac {
      background: linear-gradient(
        180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.45) 40%,
        #05091a 100%
      );
      box-shadow: inset 0px 0px 10px 2px #3f9dff;
      border-radius: 10px 0px 0px 0px;
      border: 1px solid #427dff;
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .bc {
      background: linear-gradient(
        180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.45) 40%,
        #05091a 100%
      );
      box-shadow: inset 0px 0px 10px 2px #3f9dff;
      border-radius: 0px 10px 0px 0px;
      border: 1px solid #427dff;
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .content {
    margin-top: 10px;
    padding: 0 16px;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btns {
        width: 64px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        background: url("~@/assets/images/font/zc.png") no-repeat;
        background-size: 100% 100%;
      }

      .blue {
        background: url("~@/assets/images/font/qx.png") no-repeat;
        background-size: 100% 100%;
      }

      .select {
        display: flex;
        align-items: center;

        .iconfont {
          color: #fff;
        }

        .el-select {
          width: 80px;
          height: 32px;

          /deep/.el-input--suffix {
            width: 100%;
            height: 100%;

            .el-input__inner {
              width: 100%;
              height: 100%;
            }
          }

          /deep/.el-input__suffix {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .lists {
      // margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .boxs {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      height: 280px;
      overflow: auto;
      margin-top: 14px;
      .task {
        display: flex;
        flex-direction: column;
        .name {
          color: #fff;
        }
        .images {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          // height: 280px;
          overflow: hidden;
          overflow-y: auto;
          margin-top: 14px;
          .box {
            position: relative;
            width: 135px;
            height: 88px;
            background: #ffffff;
            border-radius: 2px;
            margin-left: 8px;
            margin-bottom: 14px;
            .el-image {
              width: 138px;
              height: 88px;
              background: #ffffff;
              border-radius: 2px;
            }
            img {
              width: 135px;
              height: 88px;
            }
            .down {
              position: absolute;
              bottom: 0;
              right: -3px;
              width: 30px;
              height: 20px;
              text-align: center;
              line-height: 20px;
              background: rgba($color: #000000, $alpha: 0.6);
              color: #fff;
              font-size: 12px;
              display: none;
            }
            &:hover {
              .down {
                display: block;
              }
            }
          }
          .boxv2 {
            width: 430px;
            height: 120px;
            margin-left: 0;
            video {
              width: 430px;
              height: 120px;
            }
          }
        }
      }
    }
  }
}

.el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 280px;
  .box {
    width: 135px;
    height: 88px;
    background: #ffffff;
    border-radius: 2px;
    margin-left: 8px;
    margin-bottom: 14px;
  }
  .boxv2 {
    width: 430px;
    height: 120px;
    margin-left: 0;
    video {
      width: 430px;
      height: 120px;
    }
  }
}

// .el-checkbox{
//   width: 135px;
//   height: 88px;
//   margin-right: 0;
//   // margin-left: 6px;
//   // margin-top: 6px;
// }
/deep/.el-checkbox__input {
  position: absolute;
  top: 0;
  left: 8px;
}

/deep/.el-checkbox__label {
  padding: 0;
}

.ai-dialog {
  position: absolute;
  top: 0px;
  left: 190px;
}

.sdActive {
  background: rgba(0, 128, 10, 0.6) !important;
}

.sdDefault {
  background: rgba(212, 11, 21, 0.747) !important;
}
</style>
