import API from '@/api'
import { set } from 'lockr'
import Bus from '@/assets/ligature.js'
import { DOM } from '@/utils/dom.js'
import dayjs from 'dayjs'
import _ from 'lodash'
import CesiumSpace from '@/utils/cesiumSpace.js'
import { transformWGS842Cartesian, transformCartesian2WGS84, createHTMLCanvasElement } from '@/lib/cesium/utils.js'

let data_source = null
let uav_data = null
let prePosition = new Cesium.Cartesian3(0, 0, 0)
let currentEntity = null
let receiveCount = 0
let receiveDataList = []
let leapIndex = 0
let g_last = new Date().getTime()
let g_sum = 0
let currentOnLinePolyLine = null
let currentOnLineModel = null
let headerFlag = 0
let currentPolylineEntity = null
let q20ModelUrl = 'cesium/model/Q20/Q20_withAnimation_20230926_01.gltf' //四旋翼，针对Q20,
let td550ModeBodyUrl = 'cesium/model/550/body_meshModel_noMotion_20230830.gltf' //550 Body
let td550ModeUplUrl = 'cesium/model/550/lower_rotar_meshModel_noMotion_20230830.gltf' //550 UP
let td550ModeDownUrl = 'cesium/model/550/upper_rotar_meshModel_noMotion_20230830.gltf' //550 DOWN
let modelEnList = [];
let uavDataWS = null;


class Parser {
  static uav_data_parser(metadata) {
    let data = JSON.parse(metadata)
    return data
  }
}

function tree2list(tree, list = []) {
  tree.forEach((item) => {
    if (item.devices && item.devices.length) {
      item.devices.forEach((d) => list.push(d))
    }
    if (item.child && item.child.length) {
      let arr = tree2list(item.child)
      arr.forEach((d) => list.push(d))
    }
  })
  return list
}

function tree2list_org(tree, list = []) {
  tree.forEach((item) => {
    list.push(item)
    if (item.child && item.child.length) {
      // 为了不失去引用，所以写成遍历然后 push
      let arr = tree2list_org(item.child)
      arr.forEach((_item) => list.push(_item))
    }
  })
  return list
}

export default {
  //请求无人机控制权限
  async jursdiction() {
    let res = await API.FCKERNEL.checkUavControl({
      deviceHardId: this.uav.NoticeData.deviceHardId
    })
    if (res.code == 200) {
      this.$message.success(res.msg)
      this.uav.NoticeFlag = false
    }
  },
  // 初始化地图数据源
  async init_data_source() {
    data_source = new Cesium.CustomDataSource('FCKERNEL_LAYER')
    await viewer.dataSources.add(data_source)
  },
  // 销毁地图数据容器
  destroy_data_source() {
    if (data_source) {
      data_source.entities.removeAll()
      viewer.dataSources.remove(data_source, true)
      data_source = null
    }
  },
  /** 无人机列表搜索
   *@Description: 无人机列表搜索
   *@Date: 2023-10-16 14:21:57
   *@Params1:
   *@Return1:
   */
  /** 无人机列表搜索
 *@Description: 无人机列表搜索
 *@Date: 2023-10-16 14:21:57
 *@Params1:
 *@Return1:
 */
  async list(search) {
    let loadingInstance = DOM.createLoading('正在加载数据', 'uavListId')
    // let list = await API.FCKERNEL.ListUAV({stationType: 1});
    let list = await API.DEVICE.List({ ...search, cate: 1 })
    console.log(list, '无人机列表')
    // return;
    // let list = require("./uavListData").default.data;
    if (list && list.length && list[0].child) {
      list[0].child.sort(function (a, b) {
        return a.sort - b.sort
      })
    }
    // return;
    function each(tree, flag) {
      return tree.map((item) => {
        return {
          ...item,
          collapse: flag,
          devices:
            item.devices && item.devices.length
              ? item.devices.map((device) => {
                return {
                  ...device,
                  _location: false,
                  _video: false,
                  _airway: false,
                  _control: false,
                  org_id: item.id
                  // reportStatus: 1
                }
              })
              : [],
          child: item.child && item.child.length ? each(item.child, true) : []
        }
      })
    }
    if (list && list.length) {
      list = each(list)
    }
    this.uav.list = list || []
    this.uav.list.forEach(v => {
      console.log('vvvvvv', v);
    })
    loadingInstance.close()
    this.uav_getDeviceListFromList();
  },
  // mqtt 订阅
  subscribe(topic, callbck) {
    this.mqtt_subscribe(topic, callbck)
  },
  // 初始化地面站 ws 数据链接
  init_ws() {
    let { user_info } = this
    let _this = this
    let username = user_info.username
    let token = user_info['mmc-identity'] + user_info['FLYINGSESSIONID']
    let appId = user_info.appid
    let ws_url = process.env.VUE_APP_WS_URL
    let ws = (this.uav.ws = new WebSocket(ws_url))
    uavDataWS = ws;
    let flag = 0
    ws.onopen = (e) => {
      console.log(`飞行监控页面地面站 webSocket: ${ws_url} 连接成功`, e)
      ws.send(
        JSON.stringify({
          type: 100,
          systemCode: 'mmc',
          state: 1,
          username,
          token,
          appId
        })
      )
      this.$store.commit('device/SET_WS_WHXF_STATE', this.uav.ws.readyState);
    }
    ws.onmessage = (e) => {
      // this.$store.commit('device/SET_WS_WHXF_STATE', this.uav.ws.readyState);
      // console.log(`芜湖消防飞行监控无人机飞行websocket${ws_url}接收数据`, e)
      /************************************* 平滑处理开始 ***********************************************/
      /************************************* 平滑处理结束 ***********************************************/
      // 鹰视监听禁飞区
      if (!this.uav.control.device || this.uav.control.device?.stationType == 1) {
        // console.log('地图上没有飞机');
        // console.log('飞控链路数据，地面站数据继续传输但是不处理');
        // return
      }
      let metadata = null
      try {
        metadata = JSON.parse(e.data)
        // console.log(metadata, '飞行监控无人机数据');
      } catch (e) {
        console.error(e.data)
      }
      if (metadata && metadata.msgnum === 4120) {
        _this.$el_message('admin账号控制中，请稍后控制！', () => { }, 'error')
      }
      if (metadata && metadata.type === 300) {
        // _this.$el_message('执行成功！')
      }
      if (metadata && metadata.type === 300 && metadata.cmdFunction == 9000) {
        _this.$el_message('航线上传成功！')
        // if (this.takeOff == true) {
        this.fly_take_off()
        // }
      }
      if (metadata && metadata.type === 300 && metadata.cmdFunction == 9001) {
        this.level = metadata.data.RSRPLEVEL
      }
      // 监听无人机上锁
      if (metadata && metadata.type === 810) {
        let {
          uav: { control }
        } = this

        let deviceHardId = control.deviceHardId
        if (deviceHardId == metadata.deviceHardId) {
          // this.uav.taskStatus = true
        }
      }
      if (metadata && metadata.msgnum) {
        // 设备上线
        if (metadata.msgnum === 4132) {
          let deviceHardId = metadata && metadata.deviceHardId
          this.uav_update_device_report_status(1, deviceHardId, 'list')
          this.uav_update_device_report_status(1, deviceHardId, 'mountList')
        }
        // 设备离线
        if (metadata.msgnum === 4121) {
          let deviceHardId = metadata && metadata.deviceHardId
          this.uav_update_device_report_status(0, deviceHardId, 'list')
          this.uav_update_device_report_status(0, deviceHardId, 'mountList')
        }
      } else {
        // 无人机上报数据
        if (metadata && metadata.deviceData) {
          uav_data = JSON.parse(metadata.deviceData)
          let onlineObj = _.find(this.subTaskListCopy, function (o) { return o.deviceHardId == uav_data.deviceHardId; });
          // console.log('uav_data', uav_data);
          // console.log('onlineObj', onlineObj);
          if (onlineObj) {
            // console.log('更新onlineObj', onlineObj);
            onlineObj.reportStatus = 1;
            // console.log('这是当前子任务列表里面的飞行任务');
            let en = window.viewer.entities.getById('whxf_model_img_' + onlineObj.deviceHardId);
            let enLabel = window.viewer.entities.getById('whxf_model_label_' + onlineObj.deviceHardId);
            // console.log('en', en);
            if (en) {
              if (this.whxf.subTaskSelectedIndex != -1) {
                if (this.subTaskListCopy[this.whxf.subTaskSelectedIndex].deviceHardId == uav_data.deviceHardId) {
                  // 更新遥测信息，除了电量
                  this.uav.control.data = uav_data.data.uavInfo;
                }
              }
              let lon = parseFloat(uav_data.data.uavInfo.longitude);
              let lat = parseFloat(uav_data.data.uavInfo.latitude);
              let height = parseFloat(uav_data.data.uavInfo.height);
              let altitude = parseFloat(uav_data.data.uavInfo.altitude);
              if (lon && lat && altitude) {
                // let position = Cesium.Cartesian3.fromDegrees(lon, lat, height);
                let position = Cesium.Cartesian3.fromDegrees(lon, lat, altitude);
                let modelEnCurrent = _.find(modelEnList, function (o) { return o.deviceHardId == uav_data.deviceHardId; });
                modelEnCurrent.uavDataList.push(uav_data.data.uavInfo);
                if (modelEnCurrent.uavDataList.length > 3) {
                  modelEnCurrent.uavDataList.shift();
                }
                modelEnCurrent.g_sum = 0;
                if (!onlineObj.linePosition) {
                  onlineObj.linePosition = [];
                } else {
                  if (lon != 0 && lat != 0) {
                    onlineObj.linePosition.push(position);
                  }
                }
              }
            } else {
              console.log('无人机上报对应数据', uav_data);
              this.uav_loadUavInit2Map(onlineObj, uav_data);
            }
          }
        }
        if (metadata && metadata.type === 800) {
          // this.uav.imgUrl = metadata.url
          // this.uav.imgVisible = true
        }
      }
    }
    ws.onerror = (error) => {
      console.log(`飞行监控页面地面站 webSocket: ${ws_url} 连接错误`, error)
      this.$store.commit('device/SET_WS_WHXF_STATE', this.uav.ws.readyState);
    }
    ws.onclose = (data) => {
      console.log(`飞行监控页面地面站 webSocket: ${ws_url} 连接关闭`, data)
      this.$store.commit('device/SET_WS_WHXF_STATE', this.uav.ws.readyState);
    }
  },
  // 模型位置插值平滑处理 Position smoothing
  smoothingPosition(time, result) {
    console.log('modelEnList', modelEnList);
    // console.log('模型位置插值平滑处理', time)

    //     if (time && receiveDataList.length > 3) {
    //       // debugger
    //       try {
    // 获取最后一个点和倒数第二个点
    // console.log('receiveDataList', receiveDataList)
    //         let firstLast = receiveDataList.at(-1)
    //         let secondLast = receiveDataList.at(-2)
    //         let threeLast = receiveDataList.at(-3);
    //         let diff = Math.abs(firstLast.dateTime - threeLast.dateTime);
    //         if (diff > 900) { 
    // console.log(secondLast, firstLast, 'firstLast')
    //         let secondPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(secondLast.longitude), parseFloat(secondLast.latitude), secondLast.altitude)
    // console.log(secondPointC3, 'secondPointC3')
    //         let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude), parseFloat(firstLast.latitude), firstLast.altitude)
    //         let millsecond = parseInt(firstLast.dateTime) - parseInt(secondLast.dateTime)
    //         if (millsecond != 0) {
    //           let a = Cesium.JulianDate.toDate(time)
    //           let b = a.getTime()
    //           let c = b - g_last;
    //           g_sum += c
    //           let gSumP = g_sum / millsecond
    //           let lerpPointC3 = Cesium.Cartesian3.lerp(secondPointC3, firstPointC3, gSumP, new Cesium.Cartesian3())
    //           g_last = b
    //           return lerpPointC3
    //         }
    // }


    // return new Cesium.Cartesian3.fromDegrees(108, 30, 10000);
    //       } catch (error) {
    //         // console.log(error, 'error')
    //         // return new Cesium.Cartesian3.fromDegrees(118, 30, 10000)
    //       } finally {
    //         // console.log('fffffffffff')
    //         // return new Cesium.Cartesian3.fromDegrees(118, 30, 10000);
    //       }
    //     } else {
    //       // return new Cesium.Cartesian3.fromDegrees(18, 30, 10000);
    //     }
    return new Cesium.Cartesian3.fromDegrees(118, 30, 10000);
  },
  // 模型姿态(俯仰角)插值平滑处理
  smoothingYaw(time, result) {
    if (receiveDataList.length > 3) {
      // console.log('receiveDataList', receiveDataList)
      let firstLast = receiveDataList.at(-1)
      let secondLast = receiveDataList.at(-2)
      // let secondPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(secondLast.longitude), parseFloat(secondLast.latitude), secondLast.altitude)
      let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude), parseFloat(firstLast.latitude), firstLast.altitude)
      let yaw1 = firstLast.yaw
      let yaw2 = secondLast.yaw
      let millsecond = parseInt(firstLast.dateTime) - parseInt(secondLast.dateTime)
      if (millsecond != 0) {
        let a = Cesium.JulianDate.toDate(time)
        let b = a.getTime()
        let c = b - g_last
        g_sum += c
        let gSumP = g_sum / millsecond
        let yawN = yaw2 + Math.abs(yaw1 - yaw2) * gSumP
        let h = Cesium.Math.toRadians(yawN + 90)
        // let h = Cesium.Math.toRadians(yawN + 0)
        // console.log(h, 'h')
        let orientation = Cesium.Transforms.headingPitchRollQuaternion(firstPointC3, new Cesium.HeadingPitchRoll(h, 0, 0))
        g_last = b
        return orientation
      }
    }
  },
  // TD220 螺旋桨转动
  td220_liftingAirscrew(time, result) {
    if (receiveDataList.length > 0) {
      let firstLast = receiveDataList.at(-1)
      let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude), parseFloat(firstLast.latitude), firstLast.altitude)
      headerFlag += 2
      if (headerFlag >= 360) headerFlag = 0
      let h = Cesium.Math.toRadians(headerFlag)
      let orientation = Cesium.Transforms.headingPitchRollQuaternion(firstPointC3, new Cesium.HeadingPitchRoll(h, 0, 0))
      return orientation
    }
  },

  async control_ws(cate) {
    // debugger
    // let {
    //     flight_line_id,
    //     id
    // } = this.$store.state.task.controlPanelData;
    let deviceHardId = this.uav.control.device.deviceHardId
    // let taskId = this.$store.state.fckernel.taskId
    // if (!deviceHardId) {
    //     return false
    // }
    let username = this.user_info.username
    // if (cate > 8 && taskId) {} else {
    //     return this.$message.info('请选择航线')
    // }
    if (cate == 11) {
      // 安全降落
      this.$confirm('请确认是否执行安全降落操作?', '安全确认', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        customClass: 'uav_controlPane',
        showClose: false
      })
        .then(() => {
          this.uav_send_ws({
            type: 200,
            systemCode: 'mmc',
            state: 1,
            username,
            data: {
              cmdFunction: 2116
            },
            deviceHardId
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    } else if (cate == 12) {
      // 一键返航
      this.$confirm('请确认是否进行一键返航操作?', '返航确认', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        customClass: 'uav_controlPane',
        showClose: false
      })
        .then(() => {
          this.uav_send_ws({
            type: 200,
            systemCode: 'mmc',
            state: 1,
            username,
            data: {
              cmdFunction: 2112
            },
            deviceHardId
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    } else if (cate == 13) {
      // 航线模式
      this.uav_send_ws({
        type: 200,
        systemCode: 'mmc',
        state: 1,
        username,
        data: {
          cmdFunction: 2115
        },
        deviceHardId
      })
    } else if (cate == 17) {
      // 暂停飞行
      this.uav_send_ws({
        type: 200,
        systemCode: 'mmc',
        state: 1,
        username,
        data: {
          cmdFunction: 2117
        },
        deviceHardId
      })
    }
  },
  /** todo ws 地面站最终指令发送
   *@Description: ws 地面站最终指令发送
   *@Author: name
   *@Date: 2023-12-26 11:04:15
   *@Params1:
   *@Return1:
   */
  send_ws(data) {
    // debugger
    // let a = JSON.stringify(data);
    // console.log(data, 'send_data');
    console.log('ws 地面站最终指令发送', data)
    console.log('this.uav.ws', this.uav.ws);

    // debugger
    this.uav.ws && this.uav.ws.send(JSON.stringify(data))
  },
  /** 点击视频、控制面板、接管 按钮
   *@Description: 点击视频、控制面板、接管 按钮
   *@Date: 2023-10-16 14:44:47
   *@Params1: cate 3， 4， 5 分别是视频、控制面板、接管 按钮
   *@Return1:
   */
  async fn(cate, device, orgName) {
    console.log('------------------------------无人机列表点击按钮')
    console.log(cate, 'cate')
    console.log(device, 'device')
    // 删除之前备降点
    this.uav_deleteStandByPoint()
    // 查询备降点并添加到地图上
    let res = await API.DEVICE.getUavStandByPosition(device.deviceHardId)
    device.forceLandingPoint = res.forceLandingPoint
    res.forceLandingPoint && this.uav_addStandByPoint2Map(device, res.forceLandingPoint)
    // console.log(orgName, 'orgName')
    if (device.stationType == 1) {
      console.log('数据类型：飞控链路mqtt')
      this.wsShow = false
    } else {
      console.log('数据类型：地面站数据')
      this.wsShow = true
    }
    // debugger
    this.uavId = device
    // 判断无人机是否在线
    if (device.reportStatus !== 1) {
      this.$message('无人机不在线')
      return
    }
    // 初始化地图数据源
    if (!data_source) {
      await this.uav_init_data_source()
    }
    // 无人机列表面板显示或者关闭
    cate != 6 && this.collapseFlagfn()
    // 打开创建任务面板
    // this.controlMenuFlag = true;
    // 设置无人机型号
    let uavCate = device.cateName == 'px4' ? 'PX4' : 'APM'
    // let topic = `${uavCate}/RECEIVE/${device.deviceHardId}`;
    // 设置飞控链路订阅topic
    let topic = `uaq20/RECEIVE/${device.deviceHardId}`
    // debugger
    if (cate === 1) {
      // 定位
      device._location = !device._location
      if (device._location) {
        this.uav_show_location(device)
      } else {
        this.uav_hide_location(device)
      }
    } else if (cate === 2) {
      // 航线
      device._airway = !device._airway
      if (device._airway) {
        this.uav_show_airway(device)
      } else {
        this.uav_hide_airway(device)
      }
    } else if (cate === 3) {
      // 视频
      device._video = !device._video
      if (device._video) {
        device._video = true
        this.uav_show_video(device, orgName)
        this.VideoTomapFlag = true
        if (device.stationType == 1) {
          this.wsShow = false
          this.uav_subscribe(topic, () => {
            console.log(`订阅无人机数据：topic:${topic}`)
          })
        } else {
          if (this.mqtt.client) {
            this.mqtt.client.end()
            this.mqtt.client = null
          }
          this.wsShow = true
          this.uav_send_ws({
            type: 1126,
            deviceHardId: device.deviceHardId
          })
        }
        if (this.$store.state.fckernel.taskId) {
          let dataRun = {
            taskId: this.$store.state.fckernel.taskId,
            typeId: 4,
            deviceId: device.id
          }
          let run = API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
        }
      } else {
        this.VideoTomapFlag = false
        device._video = false
        this.uav_hide_video(device)
      }
    } else if (cate === 4) {
      // 控制面板
      // 清空创建任务的面板
      Bus.$emit('clearCreateTaskPanelData', true)
      // 隐藏地图上旧数据
      this.uav_hide_location_all()
      // 隐藏地图上航线数据
      this.uav_hide_airways_all()
      // 打开控制面板
      device._control = !device._control
      // 关闭创建任务面板
      this.controlMenuFlag = device._control

      // debugger
      if (device._control) {
        // 挂载，健康管理，图库工具条初始化
        this.br3ClickedFlag = -1
        // 打开视频窗口
        device._video = true
        this.uav_show_video(device, orgName)
        // 老版本的控制器
        this.uav_show_control(device)
        // 底部信息面板
        this.ControlFlag = true
        Bus.$emit('uav_flag_new_test', true)
        // 位置平滑处理变量数据
        receiveDataList = []
        leapIndex = 0
        g_sum = 0
        // debugger
        // 打开 任务面板
        if (device.stationType == 1) {
          // 飞控链路数据
          this.mqtt_init()
          console.log('该无人机连接mqtt')
          // this.wsShow = false
          this.wsShow = false
          this.uav_subscribe(topic, () => {
            console.log(`订阅无人机数据：topic:${topic}`)
          })
        } else {
          console.log('该无人机连接地面站')
          if (this.mqtt.client) {
            this.mqtt.client.end()
            this.mqtt.client = null
          }
          this.wsShow = true
          this.uav_send_ws({ type: 1126, deviceHardId: device.deviceHardId })
        }
        device._location = false
        this.linevisible = false
        // 创建 entity
        // debugger
        this.uav_show_location(device)
        // this.collapseFlag = true
      } else {
        this.healthData = {}
        this.controlMenuFlag = false
        this.VideoTomapFlag = false
        device._video = false
        this.ControlFlag = false
        this.uav_hide_control(device)
        this.uav_hide_video(device)
      }
    } else if (cate === 5) {
      // 接管无人机
      // debugger
      // 点击接管无人机
      // 查看是否有接管权限
      let res = await API.FCKERNEL.getTakeOverInfo({
        deviceHardId: device.deviceHardId
      })
      // { code : 200, msg : "允许操作无人机！" }
      if (res.code == 201) {
        this.$message.warning(res.msg)
        return false
      } else if (res.code == 200) {
        let res = await API.FCKERNEL.checkUseOperate({
          deviceHardId: device.deviceHardId
        })
        // 检查用户操作
        // debugger
        if (res.code == 201) {
          let text = `是否请求接管 ${orgName} 下的 ${device.deviceName}`
          this.uav.NoticeData.deviceHardId = device.deviceHardId
          this.uav.NoticeData.content = text
          this.uav.NoticeFlag = true
        } else if (res.code == 200) {
          // debugger
          return this.$message.warning(res.msg)
        }
      }
    } else if (cate === 6) {
      // 关闭无人机列表，打开创建任务面板
      console.log('关闭无人机列表，打开创建任务面板')
      if (!device._control) {
        this.$message({
          type: 'warning',
          message: '暂无选择任务，请先点击控制面板选择任务'
        })
        return
      }
      this.controlMenuFlag = true
    }
  },
  trans_location_data(device, uav_data) {
    if (!uav_data) {
      return uav_data
    }
    let uavInfo = uav_data.uavInfo
    let position = null
    let positions = []
    let orientation = null
    try {
      position = Cesium.Cartesian3.fromDegrees(uavInfo.longitude * 1, uavInfo.latitude * 1, uavInfo.altitude * 1)
      let yaw = Cesium.Math.toRadians(uavInfo.yaw + 90),
        pitch = Cesium.Math.toRadians(uavInfo.pitch),
        roll = Cesium.Math.toRadians(uavInfo.roll),
        hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll)
      orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr)

      if (uavInfo.longitude != 0 && uavInfo.latitude != 0) {
        positions.push(position)
      }
    } catch (e) {
      this.$message({
        type: 'warning',
        message: '定位失败'
      })
    }

    return {
      position,
      positions,
      orientation,
      device
    }
  },
  // 转换数据？
  trans_location_datas(device, uav_data) {
    if (!uav_data) {
      return uav_data
    }
    let uavInfo = uav_data.data.uavInfo
    // uavInfo.altitude += 10
    let position = Cesium.Cartesian3.fromDegrees(uavInfo.longitude * 1, uavInfo.latitude * 1, uavInfo.altitude * 1)
    let yaw = Cesium.Math.toRadians(uavInfo.yaw + 90),
      pitch = Cesium.Math.toRadians(uavInfo.pitch),
      roll = Cesium.Math.toRadians(uavInfo.roll),
      hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
      orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr)

    let positions = []
    if (uavInfo.longitude != 0 && uavInfo.latitude != 0) {
      positions.push(position)
    }
    return {
      position,
      positions,
      orientation,
      device
    }
  },
  // 飞控链路位置数据
  trans_mqtt_location_data(device, uav_data) {
    if (!uav_data || !uav_data.locationCoordinate3D) {
      return uav_data
    }
    // 获取航线
    let location = uav_data.locationCoordinate3D
    if (!location) {
      return
    }
    let position = Cesium.Cartesian3.fromDegrees(location.longitude * 1, location.latitude * 1, location.altitude * 1)
    let positions = []
    let attitude = uav_data.attitude
    let yaw = Cesium.Math.toRadians(attitude.yaw + 0),
      pitch = Cesium.Math.toRadians(attitude.pitch),
      roll = Cesium.Math.toRadians(attitude.roll),
      hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
      orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr)
    // console.log(this.uav.model, '飞控链路位置数据...模型');
    if (this.uav.model) {
      let model = this.uav.model
      // console.log(model, 'model');
      // console.log(model?.position, 'model');
      if (!model?.position) {
        return
      }
      const curPos = model.position.getValue(Cesium.JulianDate.now())
      if (curPos) {
        const curCart = new Cesium.Cartesian3(curPos.x, curPos.y, curPos.z)
        const afterCart = new Cesium.Cartesian3(position.x, position.y, position.z)
        if (curCart.equals(afterCart)) {
        } else {
          // 航线数据
          positions.push(position)
        }
      } else {
      }
    }
    return {
      position,
      positions,
      orientation,
      device
    }
  },
  Cartesian3_to_WGS84(point) {
    var cartesian33 = new Cesium.Cartesian3(point.x, point.y, point.z)
    var cartographic = Cesium.Cartographic.fromCartesian(cartesian33)
    var lat = Cesium.Math.toDegrees(cartographic.latitude)
    var lng = Cesium.Math.toDegrees(cartographic.longitude)
    var alt = cartographic.height
    return { lng: lng, lat: lat, alt: alt }
  },
  /**
   * 鹰视  航线 高度 加10
   * 创建无人机entity和航线entity
   * **/
  async show_location(device) {
    console.log(this.wsShow, 'this.wsShow')
    console.log(this.linevisible, 'this.linevisible')
    console.log(device, 'deviceeeeeeeeeee')
    if (!this.wsShow) {
      if (!this.linevisible) {
        this.linevisible = true
        viewer.trackedEntity = null
        let uav_data = await API.FCKERNEL.GetLastUavData({
          deviceHardId: device.deviceHardId
        })
        if (!uav_data || uav_data.latitude == '' || uav_data.longitude == '') {
          this.$el_message('暂未找到位置信息', () => { }, 'error')
          return uav_data
        }
        if (!data_source) {
          await this.uav_init_data_source()
        }
        let entities = data_source.entities
        let cesium_layer = this.g_cesium_layer()
        try {
          //由于3D地图悬浮于2D地图表面，需要增加无人机模型的海拔高度，不然会被3D地图遮挡
          // uav_data.uavInfo.altitude += 10
          uav_data.uavInfo.altitude
        } catch (e) { }
        // console.log('海拔高度增加10米', uav_data.uavInfo.altitude);
        let data = this.uav_trans_location_data(device, uav_data)
        let deviceHardId = device.deviceHardId
        let model = entities.getById(`fckernel_model_${deviceHardId}`)
        this.uav.online[deviceHardId] = data
        if (!model) {
          this.uav.online[deviceHardId].entity_id = `fckernel_model_${deviceHardId}`
          model = this.uav.model = cesium_layer.add_model(entities, this.uav.online[deviceHardId])
          //cartesian3转wgs84, 通过经纬度高度1000米创建新cartesian3，并设置相机飞入此位置
          var ellipsoid = viewer.scene.globe.ellipsoid
          var cartesian3 = model.position.getValue(Cesium.JulianDate.now())
          var cartogtraphic = ellipsoid.cartesianToCartographic(cartesian3)
          var lat = Cesium.Math.toDegrees(cartogtraphic.latitude)
          var lng = Cesium.Math.toDegrees(cartogtraphic.longitude)
          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(lng, lat, 3000), //model.position.getValue(Cesium.JulianDate.now()),
            // maximumHeight: 100,
            complete: () => {
              viewer.trackedEntity = model
              // model.viewFrom = new Cesium.Cartesian3(1000, 110000, 200155.0) // 偏移量--设置视角高度
              // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxx');
            }
          })
          // model.viewFrom = new Cesium.Cartesian3(0, 10000, 1000)

          this.uav.model = model
        }
        let polyline = entities.getById(`fckernel_polyline_${deviceHardId}`)
        if (!polyline) {
          let model = cesium_layer.add_polyline(entities, {
            ...data,
            entity_id: `fckernel_polyline_${deviceHardId}`,
            options: {
              show: true
            }
          })
          console.log(model, 'xxxxxxxxxxxxxxxxxxxxxxxx')
          // model.polyline.positions = new Cesium.CallbackProperty();
        }
      } else {
        this.linevisible = false
        this.uav_remove_model(device)
      }
    } else {
      if (!this.linevisible) {
        this.linevisible = true
        let uav_data = Parser.uav_data_parser(device.latestData)
        //
        this.uav_show_airways(device, uav_data)
        let item = this.uav.online[device.deviceHardId]
        let entity = viewer.entities.getById(item.entity_id)
        // 如果是TD220，那么还有两个桨叶
        let low = viewer.entities.getById(item.entity_id + '_lower_rotar')
        let up = viewer.entities.getById(item.entity_id + '_upper_rotar')
        entity.show = true
        if (low) low.show = true
        if (up) up.show = true
      } else {
        this.linevisible = false
        // this.uav_hide_location_all(device, uav_data)
        this.uav_hide_airways(device)
      }
    }
  },
  // 重新创建图层和cesium viewer 删除无人机数据
  hide_location_all() {
    let cesium_layer = this.g_cesium_layer()
    let viewer = cesium_layer.viewer()
    viewer.trackedEntity = null
    console.log('hide_location_all...this.uav.online', this.uav.online)
    // debugger
    Object.keys(this.uav.online).forEach((key) => {
      // debugger
      let item = this.uav.online[key]
      let entity = viewer.entities.getById(item.entity_id)
      console.log(item, 'item')
      console.log(entity, 'entity')
      // 如果是TD220，那么还有两个桨叶
      let low = viewer.entities.getById(item.entity_id + '_lower_rotar')
      let up = viewer.entities.getById(item.entity_id + '_upper_rotar')
      // item.positions = [];
      entity && viewer.entities.remove(entity)
      low && viewer.entities.remove(low)
      up && viewer.entities.remove(up)
      // entity.show = false
      // if (low) low.show = false
      // if (up) up.show = false
    })
  },
  // 显示航线
  show_airways(device, uav_data) {
    console.log('show_airways...显示航线 device', device)
    console.log('show_airways...显示航线 uav_data', uav_data)
    console.log('show_airways...显示航线')
    // debugger
    let entities = window.viewer.entities
    if (!device._location) {
      device._location = true
      this.uav_show_locations(device, uav_data)
    }
    // 显示航线
    let entity = entities.getById(`observe_polylineUav_${device.deviceHardId}`)
    entity && (entity.polyline.show = true)
    entity && (currentPolylineEntity = entity)
  },
  hide_airways(device) {
    let entity = viewer.entities.getById(`observe_polylineUav_${device.deviceHardId}`)
    /* let entity = entities.getById(
                this.uav.online[device.deviceHardId].entity_id
              ); */
    entity && (entity.polyline.show = false)
  },
  // 删除地图上的航线数据
  hide_airways_all() {
    console.log(this.uav.online, 'this.uav.online 删除地图上的航线数据0')
    Object.keys(this.uav.online).forEach((key) => {
      let entity = viewer.entities.getById(`observe_polylineUav_${key}`)
      console.log(entity, 'entity 删除地图上的航线数据1')
      entity && window.viewer.entities.remove(entity)
      // if (entity) {
      //   entity.polyline.show = false
      // }
    })
    // debugger
  },
  // 显示位置 创建entity 模型和航线！！！
  show_locations(device, uav_data) {
    console.log(device, uav_data, 'show_locations...显示位置，创建位置entity')
    if (!uav_data) {
      // this.$el_message("暂未找到位置信息", () => { }, "error")
      return uav_data
    }
    let { deviceHardId } = device
    // 重新创建cesium
    console.log('重新创建cesium')
    let cesium_layer = this.g_cesium_layer()
    let viewer = window.viewer
    // 转换数据？
    // console.log('转换数据，作用是啥？')
    let data = this.uav_trans_location_datas(device, uav_data)
    console.log('转换数据，作用是啥 包含航线数据？？', data)
    let model = window.viewer.entities.getById(`observe_model_${deviceHardId}`)
    // console.log(model, '查找模型是否已经创建')
    if (!model) {
      console.log('如果模型没有创建,那么创建entity')
      this.uav.online[deviceHardId] = data
      this.uav.online[deviceHardId].entity_id = `observe_model_${data.device.deviceHardId}`
      console.log(this.uav.online, 'V! this.uav.online')
      if (!data.device['cateName']) {
        data.device['cateName'] = 'amp'
      }
      // 根据不同的无人机类型加载不同的模型
      // debugger
      // ！！！ 如果是 amp 加载的TD220 模型，包括机体，和两个叶浆，返回的是模型数组，机体，上叶浆和下叶浆
      // console.log(data.device['cateName'], 'VVB')
      if (data.device['cateName'] == 'amp') {
        let a = cesium_layer.add_model(window.viewer.entities, this.uav.online[deviceHardId], data.device)
        model = a[0]
        window.myBodyEntity = model
        // 模型位置平滑更新！！！
        model.position = new Cesium.CallbackProperty(this.uav_smoothingPosition, false)
        model.orientation = new Cesium.CallbackProperty(this.uav_smoothingYaw, false)
        a[1].position = new Cesium.CallbackProperty(this.uav_smoothingPosition, false)
        a[2].position = new Cesium.CallbackProperty(this.uav_smoothingPosition, false)
        a[1].orientation = new Cesium.CallbackProperty(this.uav_td220_liftingAirscrew, false)
        a[2].orientation = new Cesium.CallbackProperty(this.uav_td220_liftingAirscrew, false)
        // 还要处理模型姿态！！！
      } else {
        model = cesium_layer.add_model(window.viewer.entities, this.uav.online[deviceHardId], data.device)
        // 模型位置平滑更新！！！
        model.position = new Cesium.CallbackProperty(this.uav_smoothingPosition, false)
      }
      console.log(model, '根据不同的无人机类型加载不同的模型')
      // window.myModel = model;
      // currentOnLineModel =
      // prePosition = this.uav.online[deviceHardId].position.clone()
      // debugger
    }
    console.log('查找航线entity')
    let polyline = window.viewer.entities.getById(`observe_polylineUav_${deviceHardId}`)
    console.log(polyline, '查找航线entity')
    // debugger
    if (!polyline) {
      console.log('如果航线不存在，则创建航线')
      console.log('data', data)
      cesium_layer.add_polyline(window.viewer.entities, {
        ...data,
        entity_id: `observe_polylineUav_${data.device.deviceHardId}`,
        options: { show: false }
      })
    }
    // 初始化定位
    console.log(uav_data.data.uavInfo, '初始化定位')
    let uInfo = uav_data.data.uavInfo
    let fPos = new Cesium.Cartesian3.fromDegrees(parseFloat(uInfo.longitude), parseFloat(uInfo.latitude), parseFloat(uInfo.altitude))
    viewer.camera.flyTo({
      destination: fPos,
      // maximumHeight: 10,
      complete: () => {
        viewer.trackedEntity = model
        // model.viewFrom = new Cesium.Cartesian3(0, -700, 850)
        // debugger
      }
    })
    // debugger
  },
  remove_model(device) {
    // 删除飞控 无人机 模型航线
    if (data_source) {
      let entities = data_source.entities
      entities.removeById(`fckernel_model_${device.deviceHardId}`)
    }
    this.linevisible = false
    this.uav_hide_airway(device)
  },
  hide_location(device, uav_data) {
    viewer.trackedEntity = null
  },
  // 飞控链路mqtt，无人机位置信息更新
  update_location(device, uav_data) {
    // console.log(uav_data, '控链路mqtt数据');
    let online = this.uav.online[device.deviceHardId]
    // console.log(online, '控链路mqtt，无人机位置信息更新...在线模型modelEntity');
    if (online) {
      let data = this.uav_trans_mqtt_location_data({ device }, uav_data)
      // console.log(data, '飞控链路位置数据返回值');
      if (data?.position) {
        console.log(online.positions, 'online.positionsonline.positionsonline.positions')

        online.orientation = data.orientation
        online.position = data.position
        console.log(data.position, 'data.position')
        // console.log(data.position, '飞控链路mqtt，无人机位置信息更新，位置数据');
        // if (data.positions.length > 0) {
        //   online.positions.push(data.position)
        // }
        online.positions.push(data.position)
      }
    }
  },
  //TODO: 地面站数据，更新无人机位置信息
  update_locations(uav_data) {
    window.yaw = uav_data.data.uavInfo.yaw
    Bus.$emit('lonlat', {
      lon: uav_data.data.uavInfo.longitude,
      lat: uav_data.data.uavInfo.latitude
    })
    // 无人机模型 entity
    let online = this.uav.online[uav_data.deviceHardId]
    // window.online = online;
    // console.log(this.uav.online, 'this.uav.online这是？');
    // console.log(online, '无人机模型？')
    // this.uav.datalist = uav_data.data
    if (online) {
      let data = this.uav_trans_location_datas({}, uav_data)
      // 更新姿态和坐标
      let lng = parseFloat(uav_data.data.uavInfo.longitude) || 0
      let lat = parseFloat(uav_data.data.uavInfo.latitude) || 0
      let alt = parseFloat(uav_data.data.uavInfo.altitude) || 100
      let position = new Cesium.Cartesian3.fromDegrees(lng, lat, alt)
      window.myOnline = online
      // let orientation = Cesium.Transforms.headingPitchRollQuaternion(position, new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(parseFloat(uav_data.data.uavInfo.yaw)), Cesium.Math.toRadians(parseFloat(uav_data.data.uavInfo.pitch)), Cesium.Math.toRadians(parseFloat(uav_data.data.uavInfo.roll))))
      // online.position = position
      // online.orientation = orientation
      // window.viewer.scene.forceRender();
      // 在这里每次数据更新，计算前一个坐标位置和后一个坐标位置的方位角，利用方位角计算模型的偏航角，也就是左右的偏转方向，控制模型头部的朝向
      // let en = window.viewer.entities.getById(`${online.entity_id}`)
      // en = undefined
      // if (en) {
      //   let ang = this.uav_bearing(prePosition, data.position)
      //   if (ang) {
      //     let h = Cesium.Math.toRadians(ang)
      //     let orientation = Cesium.Transforms.headingPitchRollQuaternion(data.position, new Cesium.HeadingPitchRoll(h, 0, 0))
      //     en.orientation = orientation
      //     // 本次坐标即为下一次的前一个坐标
      //     prePosition = data.position.clone()
      //   }
      // }
      // online.positions.push(online.position)
      // console.log(online.positions, 'XX_online.positions')

      // 1 查询航线entity
      let a = currentPolylineEntity.polyline.positions._value

      if (a != undefined) {
        console.log('重新建立！！！')
        online.positions = []
        let data = this.uav_trans_location_datas(this.uav.control.device, uav_data)
        currentPolylineEntity.polyline.positions = new Cesium.CallbackProperty(() => {
          // console.log(data.positions, 'uav_data.positions')
          return online.positions
        }, false)
      }
      // 更新航线！！！
      online.positions.push(position)
    }
  },
  // 显示/创建航线
  async show_airway(device) {
    let polyline = entities.getById(`fckernel_polyline_${deviceHardId}`)
    if (!polyline) {
      cesium_layer.add_polyline(entities, {
        ...data,
        entity_id: `fckernel_polyline_${data.device.deviceHardId}`,
        options: {
          show: true
        }
      })
    }
  },
  hide_airway(device) {
    if (!data_source) {
      return false
    }

    let entities = data_source.entities
    let entity = entities.getById(`fckernel_polyline_${device.deviceHardId}`)
    entity && (entity.polyline.show = false)
    if (device.deviceHardId) {
      try {
        // 删除飞机模型
        window.viewer.entities.removeById(`fckernel_polyline_${device.deviceHardId}`)
      } catch (error) { }
    }
  },
  // 显示视频
  async show_video(device, orgName) {
    // 清空所有无人机控制的状态
    console.log(this.uav.list, 'this.uav.list')
    try {
      let device_list = tree2list(this.uav.list)
      device_list.forEach((item) => {
        if (item.deviceHardId !== device.deviceHardId && item._control) {
          item._video = false
          this.uav_hide_control(item)
        }
      })
      this.$set(this.uav.control, 'device', device)
    } catch (error) { }
    // 获取视频流
    let data = await API.FCKERNEL.GetDeviceStreamAddressByDeviceId({
      deviceHardId: device.deviceHardId
    })
    if (!data.list) {
      device._video = false
      return this.$message.info('暂无视频')
    } else {
      // 设置视频位置
      let b = document.querySelector('.uav_videoBox')
      b.style.top = '8%'
      let index = this.uav.videos.findIndex((item) => item === undefined)
      if (index !== -1) {
        this.uav.videos.splice(index, 1, { ...device, orgName, videos: data })
      } else {
        if (this.uav.videos.length < 4) {
          this.uav.videos = [{ ...device, orgName, videos: data }]
        } else {
          this.uav_hide_video(this.uav.videos[0])
          this.uav_show_video(device, uav_data)
        }
      }
    }
  },
  hide_video(device) {
    let { deviceHardId } = device
    let index = this.uav.videos.findIndex((item) => item && item.deviceHardId === deviceHardId)

    let devices = tree2list(this.uav.list)
    device = devices.find((item) => item.deviceHardId === deviceHardId)

    if (index !== -1) {
      device._video = false
      this.uav.videos.splice(index, 1)
    }
  },
  show_control(device) {
    // 先清空所有无人机控制的状态
    let device_list = tree2list(this.uav.list)
    device_list.forEach((item) => {
      if (item.deviceHardId !== device.deviceHardId && item._control) {
        item._control = false
        this.uav_hide_control(item)
      }
    })
    this.$set(this.uav.control, 'device', device)
  },
  hide_control(device) {
    this.uav.control.device = null
    this.uav.control.device = null
    this.uav.control.data = null
    this.uav.control.battery = null
    this.uav.control.mount = null
    this.uav.control.mounts = []
    this.uav.control.mounts = []
  },
  // 飞控链路，更新控制面板
  //TODO:地面站更新控制面板
  update_control(device, uav_data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      if (control_device.stationType == 1) {
        // console.log(uav_data, '飞控链路面板数据更新');
        // console.log(uav_data?.gps?.absoluteAlt, '飞行高度');
        // 飞控链路
        let uav_data_copy = _.cloneDeep(uav_data)
        uav_data_copy.longitude = uav_data?.locationCoordinate3D?.longitude ? uav_data.locationCoordinate3D.longitude : 0
        uav_data_copy.latitude = uav_data?.locationCoordinate3D?.latitude ? uav_data.locationCoordinate3D.latitude : 0
        // 飞行高度
        uav_data_copy.height = uav_data?.gps?.absoluteAlt || 0
        // 起点距离
        uav_data_copy.distanceToHome = uav_data?.distanceToHome ? uav_data.distanceToHome : 0
        // 海拔高度
        uav_data_copy.altitude = uav_data?.locationCoordinate3D?.altitude ? uav_data.locationCoordinate3D.altitude : 0
        // 飞行时间
        uav_data_copy.flightTime = uav_data?.flyTime ? uav_data.flyTime : 0
        uav_data_copy.flightTime = uav_data_copy.flightTime / 1000
        // 飞行里程
        uav_data_copy.flightDistance = uav_data?.flyDistance ? uav_data.flyDistance : 0
        // 飞行速度
        uav_data_copy.groundSpeed = uav_data?.groundSpeed ? uav_data.groundSpeed : 0
        // 电量，不在这更新
        // 爬升率，没有爬升率
        uav_data_copy.climbRate = 0
        // pitch
        uav_data_copy.pitch = uav_data?.attitude?.pitch ? uav_data.attitude.pitch : 0
        uav_data_copy.yaw = uav_data?.attitude?.yaw ? uav_data.attitude.yaw : 0
        // rcChannelState
        // console.log(uav_data_copy, '最终面板数据');
        this.$set(this.uav.control, 'data', uav_data_copy)
      } else {
        this.$set(this.uav.control, 'data', uav_data)
      }
    }
    // console.log('更新无人机信息');
  },
  //TODO: 更新电池信息
  update_control_battery(device, battery_data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      // 更新电池
      // console.log(battery_data, '更新电池信息')
      if (control_device.stationType == 1) {
        // 把飞控链路数据的电池字段统一到地面站  chargeRemaining ---> battaryRemain
        // console.log('2 飞控链路更新电池信息', battery_data);
        // console.log('3 飞控链路更新电池', battery_data.chargeRemaining);
        let battery_data_copy = _.cloneDeep(battery_data)
        battery_data_copy.battaryRemain = battery_data_copy.chargeRemaining
        this.$set(this.uav.control, 'battery', battery_data_copy)
      } else {
        this.$set(this.uav.control, 'battery', battery_data)
      }
    }
  },
  update_channelData(device, channelData) {
    // if (this.uav.control.device.deviceHardId === device.deviceHardId) {
    this.$set(this.uav.control, 'channelData', channelData)
    // }
  },
  // 无人机提示信息
  update_control_info(device, battery_data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      this.uav.control.info.push(battery_data)
    }
  },
  update_mount(device, data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      let mount = this.mount.list.find((item) => item.name === data.gimbalName)
      if (mount) {
        let find_item = this.uav.control.mounts.find((item) => item.name === data.gimbalName)
        if (!find_item) {
          this.uav.control.mounts.push({
            ...data,
            ...mount
          })
        }
      }
    }
  },
  // 更新挂载信息
  update_mounts(device, data) {
    if (!data) {
      return
    }
    this.uav.control.mounts = data
    // console.log(this.mount.list, 'this.mount.list');
    // console.log(this.uav.control.mounts, 'this.uav.control.mounts');
    this.mount.list.forEach((item, i) => {
      this.uav.control.mounts.forEach((val, j) => {
        if (val.mountName === item.name) {
          val.name = item.name
          val.icon = item.icon
          val.component = item.component
        }
      })
    })
  },
  change_mount(mount) {
    this.$set(this.uav.control, 'mount', mount)
  },
  mount_directive(data) {
    let mount = this.uav.control.mount

    if (mount) {
      this.uav_mqtt_fn({
        type: 514,
        data: {
          mountId: mount.mountId,
          payload: data
        }
      })
    }
  },
  // 视频指点命令
  move_directive(data) {
    // debugger
    // data.mountId && 不需要mountId
    if (data.data) {
      if (this.wsShow) {
        let sendData = {
          type: 200,
          // systemCode: 'mmc',
          systemCode: 'uatair',
          state: 1,
          username: this.user_info && this.user_info.username,
          height: 0,
          idlingTime: 0,
          data: {
            // cmdControlType: 7000,
            cmdFunction: parseInt(7000),
            cmdState: null,
            // cmdValue: null,
            // cmdBuffer: data.data.join(','),
            // cmdName: data.name,
            cmdName: 'UAT_MOUNT_Z40TIR',
            method: data.newData.method,
            cmdValue: {
              x: data.newData.x,
              y: data.newData.y
            }
          },
          deviceHardId: data.deviceHardId
        }
        // debugger
        console.log(sendData, '指点控制')
        this.uav_send_ws(sendData)
      } else {
        this.uav_mqtt_fn({
          type: 514,
          data: {
            mountId: data.mountId,
            payload: data.data
          }
        })
      }
    }
  },

  /**
   * 切换无人机模式
   * { type: 513, data: mode }
   * @param {*} data
   */
  change_mode(mode) {
    this.uav_mqtt_fn({ type: 513, data: mode.data })
  },

  update_org_status(cate, id) {
    let list = tree2list_org(this.uav.list)
    let org_id = id
    while (org_id) {
      let org = list.find((x) => x.id === org_id)
      if (org) {
        if (cate === 1) {
          org.offline -= 1
          org.online += 1
        }
        if (cate === 0) {
          org.offline += 1
          org.online -= 1
        }
        org_id = org.pid
      } else {
        org_id = null
      }
    }
  },

  /**
   * cate { -1: 离线, 1: 上线 }
   * @param {*} cate
   * @param {*} deviceHardId
   */
  update_device_report_status(cate, deviceHardId) {
    let devices = tree2list(this.uav.list)
    let did = this.uav.control.device && this.uav.control.device.deviceHardId
    devices.map((item) => {
      if (item.deviceHardId == deviceHardId) {
        if (item.reportStatus !== cate) {
          item.reportStatus = cate
          if (cate === 1) {
            this.uav_update_org_status(1, item.org_id)
          } else if (cate == 0) {
            this.uav_update_org_status(0, item.org_id)
            item._control = false
            // // 视频推流状态关闭
            // item._videoStatus = 0
            if (did && did == deviceHardId) {
              this.uav.control = {}
            }
          }
        }
      }
    })
  },

  /**
   * 无人机 mqtt 发送指令
   * @param {*} data
   */
  mqtt_fn(data, type, device = this.uav.control.device) {
    // debugger
    if (device) {
      console.log('无人机指令', data)
      let uavCate = device.cateName == 'px4' ? 'PX4' : 'APM'
      // let uavCate = device.cateName == 'PX4'
      uavCate = 'uaq20'
      let topic = `${uavCate}/OBTAIN/${device.deviceHardId}`
      try {
        window.$log('mqtt指令---------', topic, '----------', data)
      } catch (e) { }
      this.mqtt_publish(topic, data, (e) => {
        console.log('发送指令', data, 'topic', topic)
      })
    }
  },

  // 计算方位角 p0 p1 分别为 Cartesian3 格式 返回单位 度 °
  bearing(p0, p1) {
    let p00 = this.uav_c3toDegrees(p0)
    let p01 = this.uav_c3toDegrees(p1)
    let a = p01.lon - p00.lon
    let b = p01.lat - p00.lat
    let c = p01.height - p00.height
    // console.log(p00, 'p00');
    // console.log(p01, 'p01');
    // console.log(a, b, c, 'ccccccccccccccccc');
    if (Math.abs(a) < 1e-6 || Math.abs(b) < 1e-6) {
      return null
    }
    let startLat = p00['lat']
    let startLng = p00['lon']
    let destLat = p01['lat']
    let destLng = p01['lon']
    let y = Math.sin(destLng - startLng) * Math.cos(destLat)
    let x = Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng)
    let brng = Math.atan2(y, x)
    let brngDgr = Cesium.Math.toDegrees(brng)
    return (brngDgr + 360) % 360
  },
  // 笛卡尔坐标转经纬度
  c3toDegrees(c3) {
    //笛卡尔世界坐标转经纬度弧度坐标
    let cartographic = window.viewer.scene.globe.ellipsoid.cartesianToCartographic(c3)
    //经纬度弧度坐标转经纬度坐标
    let lon = Cesium.Math.toDegrees(cartographic.longitude)
    let lat = Cesium.Math.toDegrees(cartographic.latitude)
    let height = cartographic.height
    return { lon: lon, lat: lat, height: height }
  },
  //******************************* 无人机状态信息 websocker **************************************************** //
  /**
   *@Description: 接收各种状态信息 无人机离线，在线
   *@Author: name
   *@Date: 2023-11-09 14:26:51
   *@Params1:
   *@Return1:
   */
  init_ws_status() {
    let { user_info } = this
    let _this = this
    let username = user_info.username
    let token = user_info['mmc-identity'] + user_info['FLYINGSESSIONID']
    let appId = user_info.appid
    let ws_url = process.env.VUE_APP_WS_URL_ALARM
    let ws_status = (this.uav.ws_status = new WebSocket(ws_url))
    let params = {
      type: 100,
      systemCode: 'mmc',
      state: 1,
      username,
      token,
      appId
    }
    ws_status.onopen = (e) => {
      console.log(`${ws_url} 已连接`, e)
      ws_status.send(JSON.stringify(params))
    }
    ws_status.onmessage = (e) => {
      // console.log(`飞行监控无人机状态websocket${ws_url}接收数据`, e);
      try {
        let metadata = null
        // console.info(e, `${ws_url} 飞行监控状态信息原始数据`);
        metadata = JSON.parse(e.data)
        // 无人机离线在线等状态信息检查
        this.uav_checkOnLineStatus(metadata)
      } catch (error) {
        console.info(error, `${ws_url} 接收消息失败`)
      } finally {
      }
    }
    ws_status.onerror = (error) => {
      console.log(`${ws_url} 连接错误`, error)
    }
    ws_status.onclose = (data) => {
      console.log(`${ws_url} 连接关闭`, data)
    }
  },
  /**
   *@Description: 无人机状态信息检查
   *@Author: name
   *@Date: 2023-11-13 14:23:24
   *@Params1:
   *@Return1:
   */
  checkOnLineStatus(metadata) {
    if (!metadata) return
    // console.log(metadata, '无人机状态信息');
    // console.log(this.uav.list, '无人机列表信息');
    // console.log(this.onUavSearch, 'onUavSearch');
    // this.onUavSearch();
    if (metadata.msgnum === 4139) {
      // console.log(metadata.data.deviceHardId + ' 设备状态更新通知---4139');
      this.uav_checkListData(metadata.data)
    }
  },
  /**
   *@Description: 检查列表数据在线离线状态
   *@Author: name
   *@Date: 2023-11-13 15:45:05
   *@Params1:
   *@Return1:
   */
  checkListData(wsData) {
    let _this = this
    let data = this.uav.list
    for (let i = 0; i < data.length; i++) {
      childFn(data[i])
      deviceFn(data[i])
    }
    function childFn(data) {
      let nchild = data.child
      if (nchild && nchild.length != 0) {
        for (let i = 0; i < nchild.length; i++) {
          for (let j = 0; j < nchild[i].devices.length; j++) {
            // console.log(nchild[i].devices[j], 'childFn');
            if (wsData.deviceHardId == nchild[i].devices[j].deviceHardId) {
              nchild[i].devices[j].reportStatus = parseInt(wsData.onLine)
              _this.uav_afterOffLine(wsData)
            }
          }
          childFn(nchild[i])
        }
      }
    }
    function deviceFn(data) {
      let devices = data.devices
      for (let m = 0; m < devices.length; m++) {
        // console.log(devices[m], 'deviceFn');
        if (wsData.deviceHardId == devices[m].deviceHardId) {
          devices[m].reportStatus = parseInt(wsData.onLine)
          _this.uav_afterOffLine(wsData)
        }
      }
    }
  },
  /**
   *@Description: 当前页面无人机离线、在线
   *@Author: name
   *@Date: 2023-11-13 16:01:31
   *@Params1:
   *@Return1:
   */
  afterOffLine(wsData) {
    if (!this.uavId) {
      return;
    }
    console.log(this.uavId, 'this.uavId');
    console.log(wsData, 'wsData');
    if (this.uavId.deviceHardId == wsData.deviceHardId) {
      if (parseInt(wsData.onLine) == 0) {
        // this.$message.warning(`${this.uavId.deviceName} 已离线`)
      } else {
        // this.$message.success(`${this.uavId.deviceName} 已上线`)
      }
      Bus.$emit('uavOnLineFlagFn', parseInt(wsData.onLine))
    }
  },
  /** 添加备降点到地图上
   *@Description:
   *@Author: name
   *@Date: 2024-01-12 16:22:26
   *@Params1:
   *@Return1:
   */
  addStandByPoint2Map(device, v) {
    let defaultEn = new Cesium.Entity({
      id: `f_standBy_point_default_id`,
      position: new Cesium.Cartesian3.fromDegrees(v.backup.longitude, v.backup.latitude),
      point: {
        pixelSize: 12,
        color: Cesium.Color.YELLOW,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: 99000000
      },
      label: {
        text: `备用备降点`,
        show: true,
        showBackground: false,
        font: '10px monospace bolder',
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        pixelOffset: new Cesium.Cartesian2(0, -15),
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: 99000000,
        fillColor: Cesium.Color.fromCssColorString('#FFFF00') // 字体颜色
      }
    })
    let standByEn = new Cesium.Entity({
      id: `f_standBy_point_standby_id`,
      position: new Cesium.Cartesian3.fromDegrees(v.default.longitude, v.default.latitude),
      point: {
        pixelSize: 12,
        color: Cesium.Color.YELLOW,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: 99000000
      },
      label: {
        text: `默认备降点`,
        show: true,
        showBackground: false,
        font: '10px monospace bolder',
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        pixelOffset: new Cesium.Cartesian2(0, -15),
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: 99000000,
        fillColor: Cesium.Color.fromCssColorString('#FFFF00') // 字体颜色
        // translucencyByDistance: new Cesium.NearFarScalar(1000, 0, 100000, 1)
      }
    })
    window.viewer.entities.add(defaultEn)
    window.viewer.entities.add(standByEn)
  },
  /** 删除备降点
   *@Description:
   *@Author: name
   *@Date: 2024-01-12 16:38:29
   *@Params1:
   *@Return1:
   */
  deleteStandByPoint() {
    let en1 = window.viewer.entities.getById(`f_standBy_point_default_id`)
    let en2 = window.viewer.entities.getById(`f_standBy_point_standby_id`)
    en1 && window.viewer.entities.remove(en1)
    en2 && window.viewer.entities.remove(en2)
  },
  /**@Description :  *******************************************  从无人机设备 list 中查找对应设备信息
   **@Date: 2024-05-21 11:13:32
  */
  getDeviceListFromList() {
    this.uav.deviceList = [];
    let list = this.uav.list;
    let _this = this;
    for (let i = 0; i < list.length; i++) {
      myLoop(list[i]);
    }
    function myLoop(item) {
      if (item.devices.length > 0) {
        item.devices.forEach((device) => {
          _this.uav.deviceList.push(device);
        });
      }
      if (item.child.length > 0) {
        item.child.forEach((child) => {
          myLoop(child);
        });
      }
    }
  },
  /**@Description :  ******************************************* 将在线无人机的初始位置加载到地图上
   **@Date: 2024-05-21 17:20:38
  */
  loadUavInit2Map(device, uav_data) {
    // let device = onlineObj.device;
    console.log('device-添加无人机到地图上', device);
    // 不使用 latestData
    // let latestData = JSON.parse(device.latestData);
    // console.log('latestData', latestData);
    let longitude = uav_data.data.uavInfo.longitude;
    let latitude = uav_data.data.uavInfo.latitude;
    let altitude = uav_data.data.uavInfo.altitude;
    let labelName = this.whxf_getDeviceNameByDeviceHardId(device.deviceHardId);
    if (longitude && latitude && altitude) {
      let lon = parseFloat(longitude);
      let lat = parseFloat(latitude);
      let height = parseFloat(altitude);
      console.log('lon, lat, height', lon, lat, height);
      let position = new Cesium.Cartesian3.fromDegrees(lon, lat, height);
      if (!Cesium.defined(position)) return;
      device.position = position;
      device.linePosition = [position];
      if (lon == 0 || lat == 0) {
        device.linePosition = [];
      }
      // 添加模型 whxf_model_
      let linearGradient1 = new Cesium.Color(1.0, 0.0, 0.0, 1.0);
      let linearGradient2 = new Cesium.Color(0.0, 1.0, 0.0, 1.0);
      let linearGradient3 = null;
      // text, radius, startBgColor, endBgColor, strokeColor
      let imgCanvas = createHTMLCanvasElement(String(labelName), 10, 'rgba(4, 19, 40, 0.50)', 'rgba(52, 70, 97, 0.50)', 'rgba(114, 179, 255, 0.49)');
      // Cesium.Color.multiply(linearGradient1, linearGradient2, linearGradient3);
      // linearGradient.setByString("red, blue");
      let mEn = new Cesium.Entity({
        id: 'whxf_model_img_' + device.deviceHardId,
        // model: {
        //   uri: q20ModelUrl,
        //   scale: 30.0,
        //   minimumPixelSize: 10,
        //   maximumScale: 100,
        // },
        billboard: {
          show: true,
          image: "image/uav_img_normal.png",
          // image: imgCanvas,
          // pixelOffset: new Cesium.Cartesian2(0, 0),
          scale: 0.7,
          scaleByDistance: new Cesium.NearFarScalar(0, 0.7, 25000, 0.0),
          eyeOffset: Cesium.Cartesian3(0, 0, -0),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
          // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          // disableDepthTestDistance: 99000000
        },
      });
      let mEnLabel = new Cesium.Entity({
        id: 'whxf_model_label_' + device.deviceHardId,
        billboard: {
          show: true,
          image: imgCanvas,
          scale: 0.7,
          scaleByDistance: new Cesium.NearFarScalar(0, 0.7, 25000, 0.0),
          // eyeOffset: new Cesium.Cartesian3(0, 0, 1000),
          pixelOffset: new Cesium.Cartesian2(0, -32),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY
        },
      });
      // 添加航线
      let lineEn = new Cesium.Entity({
        id: 'whxf_line_' + device.deviceHardId,
        polyline: {
          positions: new Cesium.CallbackProperty(() => {
            return device.linePosition;
          }, false),
          width: 6,
          material: Cesium.Color.fromCssColorString('rgba(29, 191, 115, 1.0)'),
          // material: Cesium.Color.fromCssColorString('rgba(255, 0, 0, 1.0)'),
        },
      });
      let temp = {
        model: mEn,
        modelLabel: mEnLabel,
        deviceHardId: device.deviceHardId,
        uavDataList: [],
        g_last: (new Date()).getTime(),
        g_sum: 0,
      };
      modelEnList.push(temp);
      // 位置
      mEn.position = new Cesium.CallbackProperty((time, result) => {
        if (temp.uavDataList.length > 2) {
          let firstLast = temp.uavDataList.at(-1);
          let secondLast = temp.uavDataList.at(-2);
          let secondPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(secondLast.longitude), parseFloat(secondLast.latitude), secondLast.altitude);
          let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude), parseFloat(firstLast.latitude), firstLast.altitude);
          let millsecond = parseInt(firstLast.dateTime) - parseInt(secondLast.dateTime)
          if (millsecond != 0) {
            let a = Cesium.JulianDate.toDate(time);
            let b = a.getTime();
            let c = b - temp.g_last;
            temp.g_sum += c;
            let gSumP = temp.g_sum / millsecond;
            let lerpPointC3 = Cesium.Cartesian3.lerp(secondPointC3, firstPointC3, gSumP, new Cesium.Cartesian3());
            temp.g_last = b;
            // let df = CesiumSpace.toDegrees(lerpPointC3);
            // getValue 获取不到坐标，把坐标先存到name属性中，在mEnLabel中使用！！！
            mEn.name = lerpPointC3;
            return lerpPointC3;
          }
        }
      }, false);
      mEnLabel.position = new Cesium.CallbackProperty((time, result) => { 
        if (mEn && mEn.name) {
          return mEn.name;
        }
      }, false);




      window.viewer.entities.add(mEn);
      window.viewer.entities.add(mEnLabel);
      window.viewer.entities.add(lineEn);
    }
  },
  // 备份
  loadUavInit2Map_cp1(device) {
    // let device = onlineObj.device;
    console.log('device-添加无人机到地图上', device);
    let latestData = JSON.parse(device.latestData);
    console.log('latestData', latestData);
    latestData = { data: { uavInfo: {} } };
    latestData.data.uavInfo.longitude = 109.718649;
    latestData.data.uavInfo.latitude = 31.0292229;
    latestData.data.uavInfo.height = 10.0;
    // latestData = true;
    let labelName = this.whxf_getDeviceNameByDeviceHardId(device.deviceHardId);
    if (latestData) {
      let lon = latestData.data.uavInfo.longitude;
      let lat = latestData.data.uavInfo.latitude;
      let height = latestData.data.uavInfo.height;
      // lon = 109.718649;
      // lat = 31.0292229;
      // height = 10.0;
      console.log('lon, lat, height', lon, lat, height);
      let position = new Cesium.Cartesian3.fromDegrees(lon, lat, height);
      let positionPixel = new Cesium.Cartesian3.fromDegrees(0, 0, 100);
      device.position = position;
      device.linePosition = [position];
      if (lon == 0 || lat == 0) {
        device.linePosition = [];
      }
      // 现在只有 Q20 和 TD550
      if (device.goodsName == 'TA-Q20' || device.goodsName == 'TD550') {
        // 添加模型 whxf_model_
        let mEn = null;
        let mEn550Up = null;
        let mEn550Down = null;
        if (device.goodsName == 'TA-Q20') {
          mEn = new Cesium.Entity({
            id: 'whxf_model_q20_' + device.deviceHardId,
            // model: {
            //   uri: q20ModelUrl,
            //   scale: 30.0,
            //   minimumPixelSize: 10,
            //   maximumScale: 100,
            // },
            billboard: {
              show: true,
              image: "image/uav_img.png",
              // pixelOffset: new Cesium.Cartesian2(0, 0),
              scale: 0.7,
              scaleByDistance: new Cesium.NearFarScalar(0, 0.7, 25000, 0.0),
              eyeOffset: Cesium.Cartesian3(0, 0, -0),
              horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              // disableDepthTestDistance: Number.POSITIVE_INFINITY,
              // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
              // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
              // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
              // disableDepthTestDistance: 99000000
            },
            label: {
              // text: String(device.deviceHardId),
              text: String(labelName),
              font: '20px bold sans-serif',
              // fillColor:Cesium.Color.fromCssColorString('#D3BA0C'),
              fillColor: Cesium.Color.fromCssColorString('#01FFFF'),
              translucencyByDistance: new Cesium.NearFarScalar(0, 1.0, 4000, 0.0),
              pixelOffset: new Cesium.Cartesian2(0, -50),
              // horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
              // verticalOrigin: Cesium.VerticalOrigin.TOP,
              // disableDepthTestDistance: Number.POSITIVE_INFINITY,
              // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
            }
          });
        }
        if (device.goodsName == 'TD550') {
          mEn = new Cesium.Entity({
            id: 'whxf_model_td550_' + device.deviceHardId,
            model: {
              // uri: q20ModelUrl,
              uri: td550ModeBodyUrl,
              scale: 60.0,
            }
          });
          mEn550Up = new Cesium.Entity({
            id: 'whxf_model_td550_up_' + device.deviceHardId,
            model: {
              // uri: q20ModelUrl,
              uri: td550ModeUplUrl,
              scale: 60.0,
            }
          });
          mEn550Down = new Cesium.Entity({
            id: 'whxf_model_td550_down_' + device.deviceHardId,
            model: {
              // uri: q20ModelUrl,
              uri: td550ModeDownUrl,
              scale: 60.0,
            }
          });
        }
        // 添加航线
        let lineEn = new Cesium.Entity({
          id: 'whxf_line_' + device.deviceHardId,
          polyline: {
            positions: new Cesium.CallbackProperty(() => {
              return device.linePosition;
            }, false),
            width: 6,
            material: Cesium.Color.fromCssColorString('rgba(29, 191, 115, 1.0)'),
            // material: Cesium.Color.fromCssColorString('rgba(255, 0, 0, 1.0)'),
          },
        });
        let temp = {
          model: mEn,
          deviceHardId: device.deviceHardId,
          uavDataList: [],
          g_last: (new Date()).getTime(),
          g_sum: 0,
          mEn550Up: mEn550Up,
          mEn550Down: mEn550Down,
        };
        modelEnList.push(temp);
        // 位置
        mEn.position = new Cesium.CallbackProperty((time, result) => {
          if (temp.uavDataList.length > 2) {
            let firstLast = temp.uavDataList.at(-1);
            let secondLast = temp.uavDataList.at(-2);
            let secondPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(secondLast.longitude), parseFloat(secondLast.latitude), secondLast.altitude);
            let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude), parseFloat(firstLast.latitude), firstLast.altitude);
            let millsecond = parseInt(firstLast.dateTime) - parseInt(secondLast.dateTime)
            if (millsecond != 0) {
              let a = Cesium.JulianDate.toDate(time);
              let b = a.getTime();
              let c = b - temp.g_last;
              temp.g_sum += c;
              let gSumP = temp.g_sum / millsecond;
              let lerpPointC3 = Cesium.Cartesian3.lerp(secondPointC3, firstPointC3, gSumP, new Cesium.Cartesian3());
              temp.g_last = b;
              let df = CesiumSpace.toDegrees(lerpPointC3);
              return lerpPointC3;
            }
          }
        }, false);
        let headerFlag = 0;
        if (mEn550Up) {
          mEn550Up.position = new Cesium.CallbackProperty((time, result) => {
            if (temp.uavDataList.length > 2) {
              let firstLast = temp.uavDataList.at(-1);
              let secondLast = temp.uavDataList.at(-2);
              let secondPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(secondLast.longitude), parseFloat(secondLast.latitude), secondLast.altitude);
              let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude), parseFloat(firstLast.latitude), firstLast.altitude);
              let millsecond = parseInt(firstLast.dateTime) - parseInt(secondLast.dateTime)
              if (millsecond != 0) {
                let a = Cesium.JulianDate.toDate(time);
                let b = a.getTime();
                let c = b - temp.g_last;
                temp.g_sum += c;
                let gSumP = temp.g_sum / millsecond;
                let lerpPointC3 = Cesium.Cartesian3.lerp(secondPointC3, firstPointC3, gSumP, new Cesium.Cartesian3());
                temp.g_last = b;
                let df = CesiumSpace.toDegrees(lerpPointC3);
                return lerpPointC3;
              }
            }
          }, false);
          mEn550Up.orientation = new Cesium.CallbackProperty((time, result) => {
            if (temp.uavDataList.length > 1) {
              let firstLast = temp.uavDataList.at(-1)
              let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude), parseFloat(firstLast.latitude), firstLast.altitude)
              headerFlag += 2
              if (headerFlag >= 360) headerFlag = 0
              let h = Cesium.Math.toRadians(headerFlag)
              let orientation = Cesium.Transforms.headingPitchRollQuaternion(firstPointC3, new Cesium.HeadingPitchRoll(h, 0, 0))
              return orientation
            }
          }, false);
        }
        if (mEn550Down) {
          mEn550Down.position = new Cesium.CallbackProperty((time, result) => {
            if (temp.uavDataList.length > 2) {
              let firstLast = temp.uavDataList.at(-1);
              let secondLast = temp.uavDataList.at(-2);
              let secondPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(secondLast.longitude), parseFloat(secondLast.latitude), secondLast.altitude);
              let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude), parseFloat(firstLast.latitude), firstLast.altitude);
              let millsecond = parseInt(firstLast.dateTime) - parseInt(secondLast.dateTime)
              if (millsecond != 0) {
                let a = Cesium.JulianDate.toDate(time);
                let b = a.getTime();
                let c = b - temp.g_last;
                temp.g_sum += c;
                let gSumP = temp.g_sum / millsecond;
                let lerpPointC3 = Cesium.Cartesian3.lerp(secondPointC3, firstPointC3, gSumP, new Cesium.Cartesian3());
                temp.g_last = b;
                let df = CesiumSpace.toDegrees(lerpPointC3);
                return lerpPointC3;
              }
            }
          }, false);
          mEn550Down.orientation = new Cesium.CallbackProperty((time, result) => {
            if (temp.uavDataList.length > 1) {
              let firstLast = temp.uavDataList.at(-1)
              let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude), parseFloat(firstLast.latitude), firstLast.altitude)
              headerFlag += 2
              if (headerFlag >= 360) headerFlag = 0
              let h = Cesium.Math.toRadians(headerFlag)
              let orientation = Cesium.Transforms.headingPitchRollQuaternion(firstPointC3, new Cesium.HeadingPitchRoll(h, 0, 0))
              return orientation
            }
          }, false);
        }
        // 姿态
        mEn.orientation = new Cesium.CallbackProperty((time, result) => {
          if (temp.uavDataList.length > 2) {
            let firstLast = temp.uavDataList.at(-1);
            let secondLast = temp.uavDataList.at(-2);
            let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude), parseFloat(firstLast.latitude), firstLast.altitude);
            let yaw1 = firstLast.yaw
            let yaw2 = secondLast.yaw
            let millsecond = parseInt(firstLast.dateTime) - parseInt(secondLast.dateTime)
            if (millsecond != 0) {
              let a = Cesium.JulianDate.toDate(time);
              let b = a.getTime();
              let c = b - temp.g_last;
              temp.g_sum += c;
              let gSumP = temp.g_sum / millsecond;
              let yawN = yaw2 + Math.abs(yaw1 - yaw2) * gSumP
              let h = Cesium.Math.toRadians(yawN + 90)
              let orientation = Cesium.Transforms.headingPitchRollQuaternion(firstPointC3, new Cesium.HeadingPitchRoll(h, 0, 0))
              temp.g_last = b;
              return orientation;
            }
          }
        }, false);
        window.viewer.entities.add(mEn);
        mEn550Up && window.viewer.entities.add(mEn550Up);
        mEn550Down && window.viewer.entities.add(mEn550Down);
        window.viewer.entities.add(lineEn);
      }
    }
  },
  /**@Description :  ******************************************* 初始化页面变量
   **@Date: 2024-05-27 09:47:00
  */
  initVariable() {
    modelEnList = [];
  },
  /**@Description :  ******************************************* 关闭WS
   **@Date: 2024-05-27 14:00:56
  */
  closeWS() {
    uavDataWS && uavDataWS.close();
  }
}
