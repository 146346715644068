<template>
  <div class="mountBox uav_mountBox" ref="uav_mountBox">
    <!-- <div v-if="mounts.length > 0" class="list"> -->
    <div v-if="false" class="list">
      <div class="mount-item pr mt6" v-for="(item, index) in mount" :key="index">
        <div class="icon-box" @click="fn(item)">
          <img class="cp dib w30 h30 mount-img" :src="item.icon" />
        </div>
      </div>
    </div>
    <template v-if="selected_mount">
      <!-- <div class="header">xxxxxxxxxxx</div> -->
      <!-- fixed -->
      <!-- 云台吊舱 src\components\observe\mount\MMC_Gimbal_Z40\index.vue -->
      <component :moveType="moveType" :selected_mount="selected_mount" :device="device" :is="selected_mount.component"
        :mounts="mount" @directive="handle_directive" :taskId="taskId" @take_photo="take_photo" @record="record"
        @playAudio="playAudio" @showCenter="showCenter" @close="mountClose"
        :PoliceKeyCode="uav_This.uav.control.device.deviceHardId" :payload="selected_mount && uavMountsPayload
          ? uavMountsPayload[selected_mount.name]
          : []
          " />
    </template>
  </div>
</template>
<script>

import API from "@/api";
import { mapGetters } from "vuex";
export default {
  data () {
    return {
      selected_mount: {},
      moveType: "wrj"
    };
  },
  props: {
    wsShow: {
      type: Boolean,
      default: () => {
        false;
      }
    },
    iconShow: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: Boolean,
      default: ""
    },
    uavMounts: {
      type: Array,
      default: () => []
    },
    uavMountsPayload: {
      type: Array,
      default: () => []
    },
    mount: {
      type: Array,
      default: () => []
    },
    device: {
      type: Object,
      default: () => ({})
    },
    initMount: {
      type: Object,
      default: () => { },
    }
  },
  inject: ["uav_This"],
  mounted () {
    // console.log(this.mount, 'mountmountmountmountmount');
    this.selected_mount = this.mount[0];
  },
  methods: {
    fn (item) {
      // #region
      // let data = {
      //   "gimbalName": "PagerP1",
      //   "mountId": 27,
      //   "mountType": 1051,
      //   "payload": [
      //     165,
      //     79,
      //     49,
      //     85,
      //     170,
      //     220,
      //     172,
      //     64,
      //     2,
      //     0,
      //     19,
      //     221,
      //     24,
      //     63,
      //     71,
      //     178,
      //     24,
      //     56,
      //     0,
      //     1,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     215,
      //     255,
      //     1,
      //     0,
      //     255,
      //     36,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     13,
      //     152,
      //     22,
      //     178,
      //     0,
      //     10,
      //     26,
      //     134
      //   ],
      //   "widgetHeight": 505,
      //   "widgetWidth": 500,
      //   "title": "MMC_Gimbal_P3",
      //   "icon": require("@/assets/images/observe/015.png"),
      //   "name": "MMC_Gimbal_P3",
      //   "type": [
      //     1,
      //     2,
      //     3,
      //     4,
      //     5,
      //     6,
      //     8
      //   ],
      //   component:() => import("@/components/observe/mount/MMC_Gimbal_Z20")
      // }
      // #endregion

      console.log(item, '点击挂载列表');
      this.selected_mount = item;
      this.$store.commit("fckernel/SET_SELECT_MOUNT", item);
    },
    /**
     *@Description: 云台发送指令 弃用
     *@Date: 2023-10-17 09:41:50
     *@Params1: 
     *@Return1: 
    */
    handle_directive1 (buffer) {
      let { selected_mount } = this;
      if (selected_mount) {
        if (!this.wsShow) {
          this.$emit(
            "fn",
            {
              type: 514,
              data: {
                mountId: selected_mount.mountId,
                payload: buffer
              }
            },
            "wrj"
          );
        } else {
          let buff = buffer.join(",");
          let data = {
            type: 200,
            systemCode: "mmc",
            state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
            username: this.user_info.username,
            height: 0,
            idlingTime: 0,
            data: {
              cmdControlType: 7000, //520
              cmdFunction: null,
              cmdState: null,
              cmdValue: null,
              cmdBuffer: buff,
              cmdName: this.selected_mount.name
            },
            deviceHardId: this.device.deviceHardId
          };
          this.$emit("fun", data);
        }
      }
    },
    /**
     *@Description: 新云台发送指令
     *@Date: 2023-10-17 09:45:03
     *@Params1: 
     *@Return1: 
    */
    handle_directive (buffer) {
      let { selected_mount } = this;
      if (selected_mount) {
        if (!this.wsShow) {
          this.$emit(
            "fn",
            {
              type: 514,
              data: {
                mountId: selected_mount.mountId,
                payload: buffer
              }
            },
            "wrj"
          );
        } else {
          // let buff = buffer.join(",");
          console.log(arguments, 'arguments');
          // debugger
          let a = arguments.length > 1 ? arguments[1] : null;
          let data = {
            type: 200,
            // systemCode: "mmc",
            systemCode: "uatair",
            state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
            username: this.user_info.username,
            height: 0,
            idlingTime: 0,
            data: {
              // cmdControlType: 7000, //520
              cmdFunction: 7000,
              cmdState: null,
              // cmdValue: null,
              cmdBuffer: null,
              // cmdName: this.selected_mount.name,
              cmdName: 'UAT_MOUNT_Z40TIR', // 暂时先固定这一个
              method: a ? a.method : '',
              cmdValue: {
                cameraMode: a ? a.value : 0, //云台模式
                zoom: a ? a.zoomRate : 0, //变焦倍数
                pitch: a ? a.pitch : 0, //俯仰角
                yaw: a ? a.yaw : 0, //偏航角
              },
            },
            deviceHardId: this.device.deviceHardId
          };
          // 控制速度
          if (data.data.method == 'gimbal_cmd_speed') {
            data.data.cmdValue = {
              seq: a ? a.seq : 0, //控制速度
              pitchSpeed: a ? a.pitchSpeed : 0, //控制速度
              yawSpeed: a ? a.yawSpeed : 0, //控制速度
            };
          }
          // 云台模式
          if (data.data.method == 'camera_mode') {
            data.data.cmdValue = {
              cameraMode: a ? a.value : 0, //云台模式
            };
          }
          // 变焦速度
          if (data.data.method == 'camera_zoom_rate') {
            data.data.cmdValue = {
              zoomRate: a ? a.zoomRate : 0, //变焦速度
            };
          }
          // 俯仰角
          if (data.data.method == 'gimbal_cmd_degree') {
            data.data.cmdValue = {
              pitch: a ? a.pitch : 0, //俯仰角
              yaw: a ? a.yaw : 0, //偏航角
            };
          }
          // 拍照
          if (data.data.method == 'capture_control') {
            data.data.cmdValue = null;
          }
          // 录像
          if (data.data.method == 'video_control') {
            data.data.cmdValue = {
              videoState: a ? a.videoState : 0,
            };
          }
          console.log(data, 'data发送指令---1');
          // console.log(data, 'yawwwwwwwwwwwwww-0');
          this.$emit("fun", data);
        }
      }
    },
    showCenter (val) {
      this.$emit("showCenter", val)
    },
    async playAudio (val) {
      if (this.taskId) {
        let typeId = val
        let dataRun = {
          taskId: this.taskId,
          typeId: typeId,
          deviceId: this.device.id
        }
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
      }
    },

    async take_photo (data) {
      // if(!this.taskId || this.taskId == "") return this.$message.error("暂无任务！")
      if (data.data) {
        // data.data.data.taskID = this.taskId
        console.log(data, "photo拍照");
        this.$emit("fn", data)
        this.$message.success("拍照成功！")
      }
      if (this.taskId) {
        let dataRun = {
          taskId: this.taskId,
          typeId: 2,
          deviceId: this.device.id
        }
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
      }
    },
    async record (data) {
      // if(!this.taskId  || this.taskId == "") return this.$message.error("暂无任务！")
      // if(data.data){
      //   data.data.data.taskID = this.taskId
      // }
      if (data.data.data.recordControl || data.data.data.status) {
        this.$message.success("开始录像！")
        if (this.taskId) {
          let dataRun = {
            taskId: this.taskId,
            typeId: 25,
            deviceId: this.device.id
          }
          let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
        }
      } else {
        this.$message.success("停止录像！")
      }
      console.log(data, "record录像");
      this.$emit("fn", data)
    },
    /**
     *@Description: 关闭面板
     *@Date: 2023-10-17 11:22:49
     *@Params1: 
     *@Return1: 
    */
    mountClose () {
      this.selected_mount = null;
      this.$emit('gzPanelClose');
    },
  },
  beforeDestroy () {
    this.$store.commit("device/SET_MOVE_DATA", {});
  },
  computed: {
    ...mapGetters(["user_info"]),
    mounts () {
      let { uavMounts, mount } = this;
      console.log(uavMounts, "uavMounts");
      console.log(mount, "mount");
      // if (uavMounts && uavMounts.length) {
      //     let mounts = [];
      //     uavMounts.forEach((item) => {
      //         let m = mount.list.find((i) => i.name === item.gimbalName);
      //         if (m) {
      //             m.mountId = item.mountId;
      //             mounts.push(m);
      //         }
      //     });
      //     console.log(mounts);
      return mount;
      // }
      setTimeout(() => {
        this.tips = "暂无挂载";
      }, 5000);
      return [];
    }
  },
  created () {
    this.tips = "正在识别使用中的挂载....";
  }
};
</script>
<style scoped lang="scss">
.mountBox {
  // background: rgba(9, 32, 87, 0.7);
  background-color: transparent;
  // border: 1px solid #70daf9;
  // position: absolute;
  // top: -152px;
  // top: -90px;
  // top: -175px;
  // top: -185px;
  // right: 22px;

  position: fixed;
  top: 8%;
  right: 100px;
  // width: auto;
  // height: auto;

  .list {
    display: flex;
    justify-content: space-around;

    .mount-item {
      width: 30px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #70daf9;
    }
  }
}
</style>
