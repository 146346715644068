<template>
  <div class="side-bar-container">
    <div class="item item2" :class="[selectedFlag == 1 ? 'item-selected' : '']" @click="itemClick(1)">
      <img src="@/assets/img/i23-1.png">
      <span>任务订单</span>
    </div>
    <div class="item item1" :class="[selectedFlag == 0 ? 'item-selected' : '']" @click="itemClick(0)">
      <img src="@/assets/img/i25.png">
      <span>无人机</span>
    </div>
    <div class="item item4" :class="[selectedFlag == 3 ? 'item-selected' : '']" @click="itemClick(3)">
      <img src="@/assets/img/bh.png">
      <span>作战标绘</span>
    </div>
    <div class="item item4" :class="[selectedFlag == 4 ? 'item-selected' : '']" @click="itemClick(4)">
      <img src="@/assets/img/bj-1.png">
      <span>消息报警</span>
    </div>
    <div class="item item3" :class="[selectedFlag == 2 ? 'item-selected' : '']" @click="itemClick(2)">
      <img src="@/assets/img/i24.png">
      <span>视频墙</span>
    </div>
  </div>
</template>

<script>
// 引用bus
import Bus from "@/assets/ligature.js";
export default {
  props: {
    selectedFlag: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      // selectedFlag: 0, // 0 无人机， 1 机巢应用， 2 视频墙
    }
  },
  methods: {
    itemClick(v) {
      // this.selectedFlag = v;
      if (v == 0) {
        Bus.$emit('lianshi-type', v);
        // window.open("#/fckernel", "_blank");

        window.open("#/fckernel_uu", "_self");
        this.$router.go(0);
      } else if (v == 1) { // 订单任务
        Bus.$emit('lianshi-type', v);
        // window.open("#/fckernel?page=2", "_blank");
        // window.open("#/fckernel_uu?page=2", "_self");
        window.open("#/taskOrder_uu", "_self");
        this.$router.go(0);
      } else if(v == 2) { // 视频墙
        Bus.$emit('lianshi-type', v);
        // window.open("#/fckernelVideos", "_blank");
        window.open("#/fckernelVideos_uu", "_self");
      } else if (v == 3) { //作战标绘
        window.open("http://ualins.uatair.com:8090/iserver/iClient/for3D/webgl/zh/examples/webgl/editor.html#plot_dynamicPlot", "_blank");
      } else if (v == 4) { //消息报警
        window.open("#/uav_whxf_alert", "_blank");
        // Bus.$emit('uav_whxf_notice_alert');
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.side-bar-container {
  width: 80px;
  height: calc(100% - 60px);
  background: #0E3042;

  .item {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    span {
      margin-top: 10px;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }
  }

  .item2 {
    img {
      width: 30px;
      height: 30px;
    }
  }

  .item3 {
    img {
      width: 25px;
      height: 16px;
    }
  }
  .item4{
    img {
      width: 30px;
      height: 30px;
    }
  }

  .item-selected {
    background: #2B72EA;
  }
}
</style>