import API from '@/api'
import dayjs from 'dayjs'
import _ from 'lodash'
import { DOM } from '@/utils/dom.js'
import Bus from '@/assets/ligature';
let polylinePrimitive = null
let polygonPrimitive = null
let conflictEntity = []

export default {
  async list() {
    // console.log('查询航线列表', this.airway);
    if (!this.airway) {
      return
    }
    // 存储到store
    this.$store.commit('airway/saveParams', this.airway)
    let { pagination } = this.airway

    // let loadingInstanceText = '';
    this.loading = true
    let { data, labelName, pageNo, pageSize, totalCount } = await API.AIRWAY.List({
      ...pagination
    })
    let list = data || []
    if (list && list.length) {
      list = list.map((item) => ({
        ...item
        // addTime: dayjs(item.addTime).format("YYYYMMDD"),
      }))
    }
    this.airway.list = [...list]
    this.labelName = labelName
    this.airway.pagination.pageNo = pageNo
    this.airway.pagination.pageSize = pageSize
    this.airway.pagination.totalCount = totalCount
    this.loading = false
  },

  /**
   *@description: 航线检查，并调用添加航线
   *@date: 2023-10-07 14:22:07
   *@params1: options 航线内容 "flightName" "flightCourseJson"，type 手动（manual）或者自动（autoPlan）
   *@return1: 返回后端返回航线检查结果 {data, message, status}
   */
  async check(options, type) {
    // debugger
    // this.airway = this.$store.state.airway.airlinelistparams;
    if (JSON.parse(options.flightCourseJson).points.length == 0) {
      this.$message({
        type: 'warning',
        message: '请先规划航线',
        duration: 3000
      })
      return
    }
    // return;
    // console.log('航线类型', type);
    // console.log(this.saveTitle, '按钮标题');
    // debugger
    if (this.saveTitle == '保存') {
      // 第一次检查航线
      // let res = await API.AIRWAY.check(options);
      let res = {}
      res.status = 1
      // console.log('第一次res航线检查结果', res)
      if (res.status == 1) {
        // 检查规划航线正常，直接添加
        console.log(options, '添加/编辑接口数据')
        if (options?.id) {
            // 更新
            console.log('更新航线');
          this.airway_updateFlightLine(options, type)
        } else {
            // 添加
            console.log('添加航线');
          this.airway_add(options, type)
        }
        // 清除冲突航线和网格
        this.airway_clearConfilctLineGrid()
        // 清除航线和关闭规划面板
        // 手动规划成功
        // 1 关闭规划面板
        this.$emit('close')
        // 3 清除规划数据
        this.destory()
      } else {
        //航线有问题，再次确认是否要添加
        if (res.status == 104) {
          //需要把返回的航线和网格码绘制在地图上
          conflictEntity = []
          for (let i = 0; i < res.data.length; i++) {
            this.airway_addConflictAirLine(res.data[i].flightCourseJson, res.data[i].taskTimes)
            this.airway_addConflictGrids(res.data[i].gridVoList)
          }
        }
        this.$message({
          type: 'warning',
          message: res.message,
          duration: 3500
        })
        // 把"保存"按钮 修改为"仍然保存"
        // Bus.$emit('airline-check-save', '仍然保存');
        this.saveTitle = '仍然保存'
        return
        {
          this.$confirm(`${res.message}，是否继续保存？`, '航线确认', {
            cancelButtonText: '取消',
            confirmButtonText: '确定',
            customClass: 'uav_controlPane',
            showClose: false,
            modal: false,
            closeOnClickModal: false
          })
            .then(async () => {
              this.airway_add(options, type)
              // 清除冲突航线和网格
              this.airway_clearConfilctLineGrid()
            })
            .catch(() => {
              // console.log('456');
              this.$message({
                type: 'info',
                message: '已取消规划'
              })
              // 清除冲突航线和网格
              // this.airway_clearConfilctLineGrid();
            })
        }
      }
    } else {
      // 二次保存
      console.log('二次保存')
      this.airway_add(options, type)
      this.saveTitle = '保存'
      // 清除冲突航线和网格
      this.airway_clearConfilctLineGrid()
      // 清除航线和关闭规划面板
      // 手动规划成功
      // 1 关闭规划面板
      this.$emit('close')
      // 2 显示航线列表
      this.$emit('showListPanel')
      // 3 清除规划数据
      this.destory()
    }
    // return res;
  },

  async add(options, type) {
    // if (!options.destination && type !== 'autoPlan') {
    //   this.$message.warning("请选择目标位置");
    //   return;
    // }
    let num = parseInt(JSON.parse(options.flightCourseJson).line.baseSpeed)
    let _this = this
    let situationDetail = this.situation_detail
    let optionsObj = Object.assign({}, options)
    let newData = JSON.parse(optionsObj.flightCourseJson)
    newData.line.baseSpeed = num
    optionsObj.flightCourseJson = JSON.stringify(newData)
    let { status, message, data } = await API.AIRWAY.Add(optionsObj)
    if (status == 1 || status == 101 || status == 102) {
      this.$el_message('创建成功', () => {
        // this.airway.add.visible = false;
        // console.log('创建成功，请求数据！！！');
        // this.form.scroll = '';
        // this.airway_search();
        // this.airway_list();
        let targetElement = $('#airLineListBtnID')
        console.log(targetElement, 'targetElementtargetElement')
        targetElement.click(() => {
          // console.log('jquery模拟点击请求航空航线列表数据');
        })
        targetElement.click()
        if (this.is_from_situation_edit) {
          this.change_flightTask_view()
        }
      })
    }
    // else if (status == 102) {
    //   this.$confirm("是否申请跨电子围栏空域飞行?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(async () => {
    //       let { status } = await API.AIRWAY.ApplyFlightLineAuth({
    //         id: data.flight_line_span_id,
    //         status: 1,
    //       });
    //       if (status == 0) {
    //         this.$message({
    //           type: "success",
    //           message: "申请成功!",
    //         });
    //       }
    //       this.airway.add.visible = false;
    //       this.airway_list();
    //       if (this.is_from_situation_edit) {
    //         this.airway.add.visible = false;
    //         this.change_flightTask_view();
    //       }
    //     })
    //     .catch(async () => {
    //       let { status } = await API.AIRWAY.Delete({
    //         id: data.flight_line_span_id,
    //       });
    //       this.airway.add.visible = false;
    //       this.airway_list();
    //       if (status == 1) {
    //         this.$message('取消申请,航线未规划');
    //       }
    //       if (this.is_from_situation_edit) {
    //         this.airway.add.visible = false;
    //         this.change_flightTask_view();
    //       }
    //     });
    // } else if (status == 101) {
    //   this.$message.error(message);
    // }
  },

  page_change(page) {
    this.airway.pagination.pageNo = page
    this.airway_list()
  },

  size_change(size) {
    this.airway.pagination.pageSize = size
    this.airway_list()
  },

  search(value) {
    this.airway.pagination.pageNo = 1
    this.airway.pagination.pageSize = 10
    this.airway.pagination.flightName = value.flightName
    // this.airway.pagination.labelName = Flight
    this.airway.pagination.sourceType = value.sourceType
    this.airway.pagination.isSafe = value.isSafe
    this.airway.pagination.type = value.type
    this.airway.pagination.labelName = value.labelName
    this.airway.pagination.userName = value.userName
    this.airway.pagination.scroll = value.scroll
    console.log(this.airway, 'this.airway.pagination')
    this.airway_list()
  },
  /**
   *@Description: 绘制冲突航线
   *@date: 2023-10-07 17:36:36
   *@params1: 航线数据
   *@return1:
   */
  addConflictAirLine(flightCourseJson, taskTimes) {
    // console.log(flightCourseJson, 'flightCourseJsonflightCourseJsonflightCourseJson');
    let viewer = window.viewer
    if (flightCourseJson) {
      let positions = []
      let center = []
      let polyline = JSON.parse(flightCourseJson)
      console.log('polyline', polyline)
      let label_arr = []
      // 点实体
      polyline.points.forEach((item, index) => {
        let pos = new Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
        positions.push(item.lon, item.lat, item.alt)
        let point_entity = null
        point_entity = viewer.entities.add({
          position: pos,
          name: 'show_conflict_airline_point',
          point: {
            pixelSize: 8,
            color: Cesium.Color.YELLOW
            // outlineWidth: 2,
            // outlineColor: Cesium.Color.WHITE,
          }
        })
        conflictEntity.push(point_entity)
      })
      // 线实体
      let lineEnt = viewer.entities.add({
        name: 'show_conflict_airline_point',
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
          width: 4, //线的宽度（像素），默认为1
          material: Cesium.Color.YELLOW //线的颜色，默认为白色
        }
      })
      conflictEntity.push(lineEnt)
      // 时间标签
      // console.log(positions[0], 'positions[0]positions[0]positions[0]');
      console.log(taskTimes, 'taskTimestaskTimestaskTimes')
      let len = polyline.points.length
      let midIndex = parseInt(len / 2)
      let labelEnt = viewer.entities.add({
        position: Cesium.Cartesian3.fromDegrees(polyline.points[midIndex].lon, polyline.points[midIndex].lat, polyline.points[midIndex].alt),
        name: 'show_conflict_airline_point',
        label: {
          text: '任务时间：\r\n ' + taskTimes,
          fillColor: Cesium.Color.RED,
          font: '16px monospace',
          scaleByDistance: new Cesium.NearFarScalar(1.5e2, 2.0, 1.5e5, 0.0)
          // horizontalOrigin: Cesium.HorizontalOrigin.MIDDLE,
          // verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          // pixelOffset: new Cesium.Cartesian2(5, 5),
          // disableDepthTestDistance: Number.POSITIVE_INFINITY,
        }
      })
      conflictEntity.push(labelEnt)
    }
  },
  /**
   *@Description: 添加网格
   *@Date: 2023-10-07 19:06:25
   *@Params1: 网格数据
   *@Return1:
   */
  addConflictGrids(gridsData) {
    window.viewer.scene.postProcessStages.fxaa.enabled = true
    let polylineGeomInstanceArr = []
    let polygonGeomInstanceArr = []
    let len = gridsData.length
    for (let i = 0; i < len; i++) {
      gridsData[i].h = 0
      // 矩形
      let polygon = new Cesium.PolygonGeometry({
        polygonHierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray([gridsData[i].l, gridsData[i].b, gridsData[i].r, gridsData[i].b, gridsData[i].r, gridsData[i].t, gridsData[i].l, gridsData[i].t])),
        height: 0 //设置默认为0，禁飞区为8 让禁飞区在网格码上面
      })
      let polygonGeometry = Cesium.PolygonGeometry.createGeometry(polygon)
      let polygonGeomInstance = new Cesium.GeometryInstance({
        geometry: polygonGeometry,
        attributes: {
          color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString('#FFFF00').withAlpha(0.4))
        }
      })
      // 多段线，边框
      let polylineGeometry = new Cesium.PolylineGeometry({
        positions: Cesium.Cartesian3.fromDegreesArray([gridsData[i].l, gridsData[i].b, gridsData[i].r, gridsData[i].b, gridsData[i].r, gridsData[i].t, gridsData[i].l, gridsData[i].t, gridsData[i].l, gridsData[i].b]),
        width: 0.6
      })
      let polylineGeomInstance = new Cesium.GeometryInstance({
        geometry: polylineGeometry,
        attributes: {
          color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString('#FFFF00').withAlpha(1.0))
        }
      })
      polylineGeomInstanceArr.push(polylineGeomInstance)
      polygonGeomInstanceArr.push(polygonGeomInstance)
    }
    if (polylinePrimitive) {
      window.viewer.scene.primitives.remove(polylinePrimitive)
    }
    if (polygonPrimitive) {
      window.viewer.scene.primitives.remove(polygonPrimitive)
    }
    polylinePrimitive = new Cesium.Primitive({
      geometryInstances: polylineGeomInstanceArr,
      vertexFormat: Cesium.PolylineColorAppearance.VERTEX_FORMAT,
      appearance: new Cesium.PolylineColorAppearance({
        aboveGround: true,
        translucent: true
      })
    })
    polygonPrimitive = new Cesium.Primitive({
      geometryInstances: polygonGeomInstanceArr,
      vertexFormat: Cesium.PolylineColorAppearance.VERTEX_FORMAT,
      appearance: new Cesium.PolylineColorAppearance({
        aboveGround: true,
        translucent: true
      })
    })
    window.viewer.scene.primitives.add(polylinePrimitive)
    window.viewer.scene.primitives.add(polygonPrimitive)
    window.viewer.scene.primitives.lowerToBottom(polylinePrimitive)
    window.viewer.scene.primitives.lowerToBottom(polygonPrimitive)
  },
  /**
   *@Description: 清除冲突航线和网格
   *@Date: 2023-10-07 19:20:53
   *@Params1:
   *@Return1:
   */
  clearConfilctLineGrid() {
    // 清除冲突航线
    conflictEntity.forEach((ent, index) => {
      window.viewer.entities.remove(ent)
    })
    if (polylinePrimitive) {
      window.viewer.scene.primitives.remove(polylinePrimitive)
    }
    if (polygonPrimitive) {
      window.viewer.scene.primitives.remove(polygonPrimitive)
    }
  },
  /**@Description : 更新航线
   *@Date : 2024-01-22 15:05:03
   *@Param :
   *@Return :
   */
  async updateFlightLine(params) {
    let res = await API.AIRWAY.updateFlightLine(params)
    // console.log(res, '更新航线返回值')
    if (res.status == 1) {
      this.$el_message('更新成功', () => {
        let targetElement = $('#airLineListBtnID')
        // console.log(targetElement, 'targetElementtargetElement')
        targetElement.click()
        if (this.is_from_situation_edit) {
          this.change_flightTask_view()
        }
      })
    }
  },
  /**@Description :  ******************************************* 导入航线KML
   **@Date: 2024-07-15 09:29:26
  */
  async handleAirLineKMLImport() {
    const fileData = new FormData() // 创建一个FormData实例
    // console.log('this.airway.kmlFile', this.airway.kmlFile);
    fileData.append('file', this.airway.kmlFile.raw)  // map便利多个文件
    // console.log('fileData', fileData);
    // console.log('API.AIRWAY.importKml', API.AIRWAY.importKml);
    let res = await API.AIRWAY.uploadKml(fileData);
    // console.log('导入航线KML返回值', res);
    this.airway.kmlFile = null;
    this.airway_handleKmlParse2PointLIst(res);
  },
  /**@Description :  ******************************************* 
   **@Date: 2024-07-15 10:37:52
  */
  handleAirLineKmlUploadOnChange(file, fileList) {
    // console.log('file', file);
    // console.log('fileList', fileList);
    this.airway.kmlFile = file;
  },
  /**@Description :  ******************************************* 接收解析KML的返回坐标值，生成航点列表
   **@Date: 2024-07-15 11:17:46
  */
  handleKmlParse2PointLIst(kmlData) { 
    // debugger
    // console.log('航线设置数据', this.panelForm);
    let name = kmlData.name;
    // this.panelForm.name = name ? name : this.panelForm.name;
    this.panelForm.name = this.panelForm.name;
    let regionArray = kmlData.region.split(' ');
    this.pointItemList = [];
    // console.log('regionArray', regionArray);
    // 航点面板数据
    regionArray.forEach((item, index) => { 
      let lng = parseFloat(item.split(',')[0]);
      let lat = parseFloat(item.split(',')[1]);
      let alt = parseFloat(item.split(',')[2]);
      console.log('lng, lat, alt', lng, lat, alt);
      let temp = {};
      temp.index = index;
      temp.flyHeight = alt;
      temp.flySpeed = 5;
      temp.lng = lng;
      temp.lat = lat;
      temp.yaw = 0;
      temp.pitch = 0;
      temp.loadActionSelectedList = [];
      temp.guid = Cesium.createGuid();
      this.guid = temp.guid;
      this.pointItemList.push(temp);
      console.log('temp', temp);
      
      let c3 = Cesium.Cartesian3.fromDegrees(lng, lat, alt);
      this.pointList.push(c3);
      // 将航点绘制到地图上
      let en = this.addPoint(index + 1, c3);
      temp.pointEntity = en;
    });
    // debugger
    this.pointItemList.forEach((item, index) => { 
      debugger
      console.log('绘制航线', item);
      
      let lng = parseFloat(item.lng);
      let lat = parseFloat(item.lat);
      let alt = parseFloat(item.flyHeight);
      let c3 = Cesium.Cartesian3.fromDegrees(lng, lat, alt);
      // 将航线绘制到地图上
      if (index < this.pointItemList.length - 1) {
        let nextPoint = this.pointItemList[index + 1];
        let lng = parseFloat(nextPoint.lng);
        let lat = parseFloat(nextPoint.lat);
        let alt = parseFloat(nextPoint.flyHeight);
        let c3_2 = Cesium.Cartesian3.fromDegrees(lng, lat, alt);
        console.log('lng, lat, alt 航线绘制', lng, lat, alt);
        
        this.addManualPolyline([c3, c3_2], { guid: nextPoint.guid });
      }
    });
    // console.log('this.pointItemList', this.pointItemList);
    // 取消地图事件
    this.setDynamicDrawFlag(false);
    // 绘制完成后定位到航线处
    // console.log('导入并绘制完成');
    this.flyToAirLinePointDataSource();
  },
  /**@Description :  ******************************************* 航线设置，完成动作选择
   **@Date: 2024-07-16 14:00:28
  */
  handleFinishedActionChange(v) {
    console.log('v', v);
    if (v == 'AutoLand') {
      this.rules.finishedActionParam[0].required = true;
    } else {
      this.rules.finishedActionParam[0].required = false;
    }
  },
  /**@Description :  ******************************************* 航线设置，是否跟随选择
   **@Date: 2024-07-17 15:37:35
  */
  handleIsFollowAirLineChange(v) {
    console.log('v', v);
    
    Bus.$emit('isFollowAirLineChange', v);
  }
}
