<template>
  <div class="nset_control_box">
    <div class="nset_control_box_header lh34">
      <div class="title ml10">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="font">操作区域</div>
      </div>
      <div class="exit mr10 cp" @click="$emit('exit')">关闭</div>
    </div>
    <div class="nset_control_box_area p10">
      <div class="wrj" v-show="!controlTypeFlag">
        <!-- <div class="w48 h48 item mb3  cf  tc cp" @click="$emit('uav-location')">
                    <SymbolIcon icon="dingwei" />
                    <span class="dib f8">定位</span>
                </div> -->
        <div class="w48 h48 item mb3 cf tc cp" @click="$emit('uav-location')">
          <SymbolIcon icon="guiji" />
          <span class="dib f8">轨迹</span>
        </div>
        <div class="w48 h48 item mb3 cf tc cp" @click="wrjfn(0)">
          <SymbolIcon icon="jixufeihang1" />
          <span class="dib f8">继续飞行</span>
        </div>
        <div class="w48 h48 item mb3 cf tc cp" @click="wrjfn(1)">
          <SymbolIcon icon="zantingfeihang1" />
          <span class="dib f8">暂停飞行</span>
        </div>
        <div class="w48 h48 item mb3 cf tc cp" @click="handclick">
          <SymbolIcon icon="anquanjiangla1" />
          <span class="dib f8">安全降落</span>
        </div>
        <div class="w48 h48 item mb3 cf tc cp" v-if="!wsShow" @click="$emit('yxrz')">
          <SymbolIcon icon="yunhangrizhi2" />
          <span class="dib f8">运行日志</span>
        </div>
        <div class="w48 h48 item mb3 cf tc cp" @click="zdfx">
          <SymbolIcon icon="tiaozhuandaozuobiao" />
          <span class="dib f8">指点飞行</span>
        </div>
      </div>
    </div>
    <!-- 飞控 无人机 安全降落 -->
    <el-dialog :visible.sync="safetyNotice" width="30%" :append-to-body="true" style="margin-top: 20vh">
      <div class="endrenwu">
        <div class="tishiyu">安全降落</div>
        <div class="queding">
          无人机即将原地降落，请确认无人机下方是否安全！
        </div>
        <div class="btn_kuang">
          <div class="btn btn_lan" @click="safetyNotice = false">取消</div>
          <div style="width: 20px"></div>
          <div class="btn btn_lv" @click="remind">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
let Point = null;
import API from "@/api";
import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";
let handler = null;
// 引用bus
import Bus from "@/assets/ligature.js";
export default {
  components: { SymbolIcon },
  props: {
    wsShow: {
      type: Boolean,
      default: () => {
        false;
      },
    },
    controlTypeFlag: {
      type: Boolean,
      default: () => {
        false;
      },
    },
    jcData: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    username: {
      type: String,
      default: () => {
        "";
      },
    },
  },
  watch: {
    jcData: function (value) {
      this.change(value);
    },
    deep: true,
    immediate: true,
  },
  data () {
    return {
      ws: "",
      zdfcFlag: true,
      safetyNotice: false, //安全降落弹框
      unlockType: true,
      chargerPower: true,
      lifts: true,
    };
  },
  beforeDestroy () {
    if (Point) {
      window.viewer.entities.remove(Point);
    }
  },
  methods: {
    async handclick () {
      // 权限判断
      let flag = await this.checkUseOperateFn(this.device.deviceHardId);
      if (!flag) return;
      this.safetyNotice = true;
    },
    // 安全降落确认
    remind () {
      if (!this.wsShow) {
        this.$emit("fn", { type: 516 });
      } else {
        this.$emit("fun", {
          type: 200,
          systemCode: "mmc",
          state: 1,
          username: this.username,
          data: {
            cmdFunction: 2116,
          },
          deviceHardId: this.device.deviceHardId,
        });
      }
      this.safetyNotice = false;
      this.$message.success("操作成功");
    },
    change (item) {
      if (item.chargerPower == 2) {
        this.chargerPower = true;
      }
      if (item.chargerPower == 1) {
        this.chargerPower = false; remind
      }
      if (item.lifts == 1) {
        this.lifts = true;
      }
      if (item.lifts == 2) {
        this.lifts = false;
      }
    },
    async wrjfn (item) {
      let flag = await this.checkUseOperateFn(this.device.deviceHardId);
      if (!flag) return false;
      if (item == 0) {
        if (!this.wsShow) {
          this.$emit("fn", { type: 524 });
        } else {
          //航线模式
          this.$emit("fun", {
            type: 200,
            systemCode: "mmc",
            state: 1,
            username: this.username,
            data: {
              cmdFunction: 2118,
            },
            deviceHardId: this.device.deviceHardId,
          });
        }
      } else if (item == 1) {
        if (!this.wsShow) {
          this.$emit("fn", { type: 523 });
        } else {
          //暂停飞行
          this.$emit("fun", {
            type: 200,
            systemCode: "mmc",
            state: 1,
            username: this.username,
            data: {
              cmdFunction: 2117,
            },
            deviceHardId: this.device.deviceHardId,
          });
        }
      }
      this.$message.success("操作成功");
    },

    addModeelPoint (viewer, position, name) {
      return viewer.entities.add({
        position,
        point: {
          pixelSize: 12,
          color: Cesium.Color.fromCssColorString("#ee0000"),
          outlineColor: Cesium.Color.fromCssColorString("#fff"),
          outlineWidth: 1,
          show: true,
        },
        label: {
          text: name,
          scale: 0.5,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          outlineWidth: 0,
          pixelOffset: new Cesium.Cartesian2(26, -20),
        },
      });
    },
    async zdfx () {
      let flag = await this.checkUseOperateFn(this.device.deviceHardId);
      if (!flag) return false;
      if (!this.zdfcFlag) {
        return false;
      }
      let viewer = window.viewer;
      // 指点飞行 wgs84
      this.zdfcFlag = false;
      let position = null;
      this.$message("请点击地图获取目标位置");
      handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction((movement) => {
        let earthPosition = viewer.camera.pickEllipsoid(
          movement.position,
          viewer.scene.globe.ellipsoid
        );
        let cartographic = Cesium.Cartographic.fromCartesian(
          earthPosition,
          viewer.scene.globe.ellipsoid,
          new Cesium.Cartographic()
        );
        let lng = Cesium.Math.toDegrees(cartographic.longitude);
        let lat = Cesium.Math.toDegrees(cartographic.latitude);
        if (Point) {
          window.viewer.entities.remove(Point);
        }
        console.log(movement.position, " movement.position");
        // let position = window.viewer.scene.camera.pickEllipsoid(
        //   movement.position
        // );
        (position = Cesium.Cartesian3.fromDegrees(lng, lat, 20)),
          (Point = this.addModeelPoint(window.viewer, position, "目标点"));
        // 一键返航
        this.$confirm(
          "位置获取成功，请确认是否进行指点模式飞行?",
          "引导飞行确认",
          {
            cancelButtonText: "取消",
            confirmButtonText: "确定",
            customClass: "uav_controlPane",
            showClose: false,
          }
        )
          .then(() => {
            console.log({
              data: {
                latitude: lat,
                longitude: lng,
                speed: 6,
              },
              type: 518,
            }, '指点移动指令下发');
            console.log(this.wsShow, 'this.wsShow');
            this.zdfcFlag = true;
            if (!this.wsShow) {
              this.$emit(
                "fn",
                {
                  data: {
                    latitude: lat,
                    longitude: lng,
                    speed: 6,
                  },
                  type: 518,
                },
                "wrj"
              );
            } else {
              this.$emit("fun", {
                type: 200,
                systemCode: "mmc",
                state: 1,
                username: this.username,
                data: {
                  cmdFunction: 2219,
                  lat: lat,
                  lng: lng,
                },
                deviceHardId: this.device.deviceHardId,
              });
            }
            handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
          })
          .catch(() => {
            this.zdfcFlag = true;
            if (Point) {
              window.viewer.entities.remove(Point);
            }
            handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    changeType () {
      this.unlockType = true;
      this.ws = null;
    },
    // unlock() {
    //     this.unlockType = false
    //     this.ws = new WebSocket("ws://127.0.0.1:8802");
    //     this.ws.onmessage = (e) => {
    //         let reader = new FileReader();
    //         reader.onload = (event) => {
    //             let data = event.currentTarget.result;
    //             data = JSON.parse(data).data;
    //             this.$emit(
    //                 "fn",
    //                 {
    //                     type: 519,
    //                     data
    //                 },
    //                 "wrj"
    //             );
    //         }

    //         reader.readAsText(e.data);
    //     }
    //     this.$message.success("操作成功");
    // },
    async checkUseOperateFn (device) {
      // 查看是否有控制权限
      let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device });
      if (res.code == 201) {
        this.$message.warning(res.msg);
        return false;
      } else {
        return true;
      }
    },
    handClick (item) {
      this.$emit("fn", this.list[item].data, "yc");
      this.$message.success("操作成功");
    },
  },
};
</script>

<style lang="scss" scoped>
.nset_control_box {
  min-height: 111px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  position: absolute;
  right: 27px;
  top: -82px !important;
  /* top: calc(50% - 14px); */
  bottom: auto !important;
  width: 420px;

  .nset_control_box_header {
    display: flex;
    justify-content: space-between;
    height: 32px;
    background: linear-gradient(180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.45) 40%,
        #05091a 100%);
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .title {
      display: flex;
      align-items: center;

      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(135deg,
            #e3aa77 0%,
            #f5cda9 38%,
            #f9ecd3 58%,
            #fcdbb1 79%,
            #edb07a 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .exit {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .nset_control_box_area {
    .yc {
      display: flex;
      justify-content: space-between;

      .item {
        padding: 5px;
        background-image: linear-gradient(179deg,
            #1773b6 0%,
            #3484c3 36%,
            #2a7abd 56%,
            #084681 100%);
        box-shadow: 0 0 5px 0 #0c1c47;
        border-radius: 4.5px;
      }
    }

    .wrj {
      display: flex;
      justify-content: space-between;

      .item {
        padding: 5px;
        background-image: linear-gradient(179deg,
            #1773b6 0%,
            #3484c3 36%,
            #2a7abd 56%,
            #084681 100%);
        box-shadow: 0 0 5px 0 #0c1c47;
        border-radius: 4.5px;
      }
    }
  }
}

// 弹框样式
.endrenwu {
  width: 100%;
  height: 177px;
  border: 1px solid #70daf9;
  background: rgba(9, 32, 87, 0.7);
}

.queding {
  color: #92d9ff;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 30px 0 0 0;
}

.tishiyu {
  color: #92d9ff;
  font-size: 18px;
  margin: 30px 0 0 0;
  text-align: center;
  width: 100%;
}

.btn_kuang {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  width: 79px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  margin: 20px 0 0 0;
  cursor: pointer;
}

.btn_lan {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #606266;
}

.btn_lv {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #fff;
}

/deep/ .el-dialog__header {
  margin-left: 0;
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
  z-index: 2023 !important;
}

/deep/ .el-dialog {
  z-index: 2022 !important;
  background: transparent !important;

}
</style>
