<template>
  <div class="w142 h48 mountBox">
    <div v-if="mounts.length>0" class="list">
      <div class="mount-item pr mt6" v-for="(item, index) in mounts" :key="index">
        <div class="icon-box" @click="selected_mount = item">
          <img class="cp dib w30 h30" :src="item.icon" />
        </div>
      </div>
    </div>
    <template v-if="selected_mount">
      <component
        :device="device"
        :selected_mount="selected_mount"
        :moveType="moveType"
        :containerStyle="{  left: '-306px', top: '143px'}"
        :is="selected_mount.component"
        :offline='offline'
        @directive="handle_directive"
        @close="selected_mount = null"
        :PoliceKeyCode="nest_this.nest.data.deviceList[0].deviceHardId"
        :payload="
                selected_mount && uavMountsPayload
                  ? uavMountsPayload[selected_mount.name]
                  : []
              "
      />
    </template>
  </div>
</template>
<script>
export default {
  name: "Mount1027",
  data() {
    return {
      selected_mount: null,
      moveType: "yc",
    };
  },
  props: {
    iconShow: {
      type: Boolean,
      default: false,
    },
    uavMounts: {
      type: Array,
      default: () => [],
    },
    uavMountsPayload: {
      type: Object,
      default: () => ({}),
    },

    mount: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    offline: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selected_mount: {
      immediate: true,
      handler() {
        this.$store.commit("fckernel/SET_SELECT_MOUNT", this.selected_mount);
      },
    },
  },
  inject: ["nest_this"],
  methods: {
    handle_directive(buffer) {
      let { selected_mount } = this;
      if (selected_mount) {
        this.$emit(
          "fn",
          {
            type: 514,
            data: {
              mountId: selected_mount.mountId,
              payload: buffer,
            },
          },
          "wrj"
        );
      }
    },
  },
  computed: {
    mounts() {
      let { uavMounts, mount } = this;
      if (uavMounts && uavMounts.length) {
        let mounts = [];
        uavMounts.forEach((item) => {
          let m = mount.list.find((i) => i.name === item.gimbalName);
          if (m) {
            m.mountId = item.mountId;
            mounts.push(m);
          }
        });
        console.log(mounts);
        return mounts;
      }
      setTimeout(() => {
        this.tips = "暂无挂载";
      }, 5000);
      return [];
    },
  },
  created() {
    this.tips = "正在识别使用中的挂载....";
  },
  beforeDestroy() {
    this.$store.commit("device/SET_MOVE_DATA", {});
  },
};
</script>
<style scoped lang="scss">
.mountBox {
  background: rgba(9, 32, 87, 0.7);
  border: 1px solid #70daf9;
  position: absolute;
  top: 50px;
  right: 70px;
  z-index: 10;
  .list {
    display: flex;
    justify-content: space-around;
    .mount-item {
      width: 30px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #70daf9;
    }
  }
}
</style>
