import API from '@/api'
import Bus from '@/assets/ligature.js'
import dayjs from 'dayjs'
import _ from 'lodash'
import { createHTMLCanvasElement } from '@/lib/cesium/utils.js'
// 地图事件句柄
let cesiumHandlerEvent = null;

export default {
  /**@Description :  ******************************************* 点击每一个子任务卡片
   **@Date: 2024-05-22 16:38:56
  */
  async handleCardClick(item, index) {
    // debugger
    // console.log('子任务', item, index);
    // console.log('this.whxf.subTaskSelectedIndex', this.whxf.subTaskSelectedIndex);
    // 子任务待生效或者执行中，且飞机在线
    if ((item.taskStatus == 0 || item.taskStatus == 3) && (item.reportStatus == 1)) {
      this.whxf_unSelectAllUavEntity();
      // 无人机跟踪
      let en = window.viewer.entities.getById('whxf_model_img_' + item.deviceHardId);
      let enLabel = window.viewer.entities.getById('whxf_model_label_' + item.deviceHardId);
      if (en && enLabel) {
        // 还是点击当前已选中的卡片，那么就取消选中
        let flag = true;
        let name = this.whxf_getDeviceNameByDeviceHardId(item.deviceHardId)
        if (this.whxf.subTaskSelectedIndex == index) {
          // console.log('取消选中');
          flag = false;
          this.whxf.subTaskSelectedIndex = -1;
          let imgCanvas = createHTMLCanvasElement(name, 10, 'rgba(4, 19, 40, 0.50)', 'rgba(52, 70, 97, 0.50)', 'rgba(114, 179, 255, 0.49)');
          enLabel.billboard.image = imgCanvas;
          enLabel.billboard.pixelOffset = new Cesium.Cartesian2(0, -32);
          en.billboard.image = "image/uav_img_normal.png";
          // 选中状态不调整视角
          // window.viewer.trackedEntity = null;
        } else {
          // console.log('选中');
          this.whxf.subTaskSelectedIndex = index;
          let imgCanvas = createHTMLCanvasElement(name, 10, 'rgba(0, 100, 192, 1)', 'rgba(33, 165, 239, 1)', 'rgba(10, 180, 255, 1)');
          enLabel.billboard.image = imgCanvas;
          en.billboard.image = "image/uav_img_selected.png";
          enLabel.billboard.pixelOffset = new Cesium.Cartesian2(0, -46);
          // 选中状态不调整视角
          // await window.viewer.flyTo(en);
          // window.viewer.trackedEntity = en;
          // en.viewFrom = new Cesium.Cartesian3(0, -700, 850);
        }
        // 判断是否有锁，如果是取消，则把锁全部解开
        // let len = this.subTaskListCopy.length;
        // for (let i = 0; i < len; i++) {
        //   let task = this.subTaskListCopy[i];
        //   task.uavLock = false;
        //   this.$set(this.subTaskListCopy, i, task);
        // }
        // item.uavLock = flag;
        // uvaDeviceList 查找该卡片对应的device，下发指令的时候要使用！
        this.uvaDeviceList.forEach((device) => {
          if (device.deviceHardId == item.deviceHardId) {
            console.log('device', device);
            console.log('item', item);
            this.uav.control.device = device;
            device._video = true
            this.uav_show_video(device, '全国')
          }
        });
        // console.log('this.wsShow', this.wsShow);
        if (this.uav.control.device.stationType == 1) {
          console.log('数据类型：飞控链路mqtt')
          this.wsShow = false
        } else {
          console.log('数据类型：地面站数据')
          this.wsShow = true
        }
        console.log('this.wsShow', this.wsShow);
      }
    }
  },
  /**@Description :  ******************************************* 点击图标解锁entity追踪
   **@Date: 2024-05-22 17:22:58
  */
  handleUavUnLock(item, index) {
    // console.log('item', item);
    // 把锁给解开
    if ((item.taskStatus == 0 || item.taskStatus == 3) && (item.reportStatus == 1)) { 
      // if (this.whxf.subTaskSelectedIndex == index) { 
      //   this.whxf.subTaskSelectedIndex = -1;
      // } else {
        this.whxf.subTaskSelectedIndex = index;
      // }
      item.uavLock = !item.uavLock;

      // 判断是否有锁，如果是取消，则把锁全部解开
      let len = this.subTaskListCopy.length;
      for (let i = 0; i < len; i++) {
        if (i != index) {
          let task = this.subTaskListCopy[i];
          task.uavLock = false;
          this.$set(this.subTaskListCopy, i, task);
        }
      }
      // this.$set(this.subTaskListCopy[index], 'uavLock', false);
      if (item.uavLock) {
        let en = window.viewer.entities.getById('whxf_model_img_' + item.deviceHardId);
        window.viewer.trackedEntity = en;
      } else {
        window.viewer.trackedEntity = null;
      }
    }
  },
  /**@Description :  ******************************************* 根据devicehardId获取deviceName
   **@Date: 2024-06-03 16:11:47
  */
  getDeviceNameByDeviceHardId(deviceHardId) {
    // console.log('this.uav.deviceList', this.uav.deviceList);
    let deviceName = '';
    this.uav.deviceList.forEach(item => {
      if (item.deviceHardId == deviceHardId) {
        deviceName = item.deviceName;
      }
    });
    return deviceName;
  },
  /**@Description :  ******************************************* 如果是周期任务，且已完成，则不显示
   **@Date: 2024-06-03 16:46:06
   **@param: {} item 子任务
  */
  periodTaskFinishShowFlag(item) {
    console.log('this.taskDetailData', this.taskDetailData);
    console.log('this.item', item);
    if (this.taskDetailData.taskType == 'period' && item.taskStatus == 1) {
      return false;
    } else {
      return true;
    }
  },
  /**@Description :  ******************************************* 地图上无人机模型图片和标签全部取消选择
   **@Date: 2024-07-08 17:34:43
  */
  unSelectAllUavEntity() {
    let len = this.subTaskListCopy.length;
    for (let i = 0; i < len; i++) {
      let task = this.subTaskListCopy[i];
      let en = window.viewer.entities.getById('whxf_model_img_' + task.deviceHardId);
      let enLabel = window.viewer.entities.getById('whxf_model_label_' + task.deviceHardId);
      if (en && enLabel) { 
        let name = this.whxf_getDeviceNameByDeviceHardId(task.deviceHardId)
        let imgCanvas = createHTMLCanvasElement(name, 10, 'rgba(4, 19, 40, 0.50)', 'rgba(52, 70, 97, 0.50)', 'rgba(114, 179, 255, 0.49)');
        enLabel.billboard.image = imgCanvas;
        enLabel.billboard.pixelOffset = new Cesium.Cartesian2(0, -32);
        en.billboard.image = "image/uav_img_normal.png";
      }
    }
  },
  /**@Description :  ******************************************* 无人机强制接管
   **@Date: 2024-07-08 17:56:13
  */
  uavForceTakeOver(item, index) {

  },
  /**@Description :  ******************************************* 地图左键点击事件 可选择左侧列表选中，也可点击图标选中，选中后，其遥测信息显示
   **@Date: 2024-07-09 09:46:54
  */
  initCesiumEvent() { 
    let viewer = window.viewer;
    cesiumHandlerEvent = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
    // console.log('cesiumHandlerEvent', cesiumHandlerEvent);
    cesiumHandlerEvent.setInputAction(async (event) => {
        let pick = viewer.scene.pick(event.position); 
        if (Cesium.defined(pick)) {
          let en = pick.id;
          // console.log('en', en);
          if (en._id.includes('whxf_model_img')) {
            //选中了 
            // console.log('en._id', en._id);
            /**
             * 选中之后
             * 1. 对应卡片处于选中状态，面板显示对应设备信息
             * 2. 无人机图标及标签更新成选中
             * **/
            let deviceHardId = en._id.split('_')[3];
            this.subTaskListCopy.forEach((item, index) => {
              if (item.deviceHardId == deviceHardId) {
                // console.log('item', item);
                // console.log('index', index);
                let enImg = window.viewer.entities.getById('whxf_model_img_' + item.deviceHardId);
                let enLabel = window.viewer.entities.getById('whxf_model_label_' + item.deviceHardId);
                if (enImg && enLabel) {
                  let name = this.whxf_getDeviceNameByDeviceHardId(item.deviceHardId)
                  // 判断是否处于选中状态
                  if (enImg.billboard.image == "image/uav_img_selected.png") {
                    this.whxf.subTaskSelectedIndex = -1;
                    let imgCanvas = createHTMLCanvasElement(name, 10, 'rgba(4, 19, 40, 0.50)', 'rgba(52, 70, 97, 0.50)', 'rgba(114, 179, 255, 0.49)');
                    enLabel.billboard.image = imgCanvas;
                    enLabel.billboard.pixelOffset = new Cesium.Cartesian2(0, -32);
                    enImg.billboard.image = "image/uav_img_normal.png";
                  } else {
                    this.whxf.subTaskSelectedIndex = index;
                    let imgCanvas = createHTMLCanvasElement(name, 10, 'rgba(0, 100, 192, 1)', 'rgba(33, 165, 239, 1)', 'rgba(10, 180, 255, 1)');
                    enLabel.billboard.image = imgCanvas;
                    enLabel.billboard.pixelOffset = new Cesium.Cartesian2(0, -46);
                    enImg.billboard.image = "image/uav_img_selected.png";
                  }
                }
              }
            });
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  },
  /**@Description :  ******************************************* 移除地图鼠标事件
   **@Date: 2024-07-09 10:06:42
  */
  removeCesiumEvent() { 
    cesiumHandlerEvent && cesiumHandlerEvent.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK)//移除事件
  }
}