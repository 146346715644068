<!-- 飞控中心电池-->
<template>
  <div v-if="batteryList.length > 0" class="cpt-observe-mspace-dashboard-battery jcsb">
    <div class="battery-inner pr" :class="setClass(index, batteryList.length)" v-for="(item, index) in batteryList"
      :style="batteryList.length > 1 && index == 1 ? 'left:-128px' : ''" :key="index">
      <div class="dec mr9 f12">{{ index + 1 }}</div>
      <div class="size-wrap ml20">
        <div class="size-box">
          <div class="size-inner">
            <div class="dianchigai"></div>
            <div class="size-inner-box" :style="`height: ${item.size || 100}%; ${setColor(item.size || 100)}`"></div>
          </div>
        </div>
      </div>
      <span class="pa top32 f11 left43 cf">{{ item.size || 100 }}%</span>
      <div class="ml12">
        <div class="voltage-box">
          <div class="voltage-value f12 ml10 jcsb">
            {{ item.voltage || 0 }} <span class="cf ml2">v</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="cpt-observe-mspace-dashboard-battery jcsb">
      <div class="battery-inner pr mt9" v-if="batteryList.length == 0">
        <div class="dec mr9  top7 f12">1</div>
        <div class="size-wrap ml20">
          <div class="size-box">
            <div class="size-inner">
              <div class="dianchigai"></div>
              <div class="size-inner-box" :style="`height: 100%; ${setColor(100)}`"></div>
            </div>
          </div>
        </div>
        <span class="pa top27 f11 left43 cf">100 %</span>
        <div class="ml12">
          <div class="voltage-box">
            <div class="voltage-value f12 ml10 jcsb">
              45 <span class="cf ml2">v</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uavBattery: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      battery: {},
      state: {
        1: { label: "良好", color: "#76c230" },
        2: { label: "一般", color: "#FFBD36" },
        3: { label: "低电量", color: "#f73939" },
      },
      batteryList: [
        // {
        //   state: { label: "良好", color: "#76c230" },
        //   size: 80,
        //   voltage: 20,
        // },
      ],
    };
  },
  mounted () { },
  methods: {
    setColor (size) {
      if (size > 95) {
        return "background-color: #76c230";
      } else if (size > 30) {
        return " background: linear-gradient(to top,yellow,green);";
      } else {
        return "background-color: #f73939";
      }
    },
    setClass (index, num) {
      let str = "";
      if (num > 1 && index == 0) {
        str = str + " " + "top-10 ";
      } else if (num == 1 && index == 0) {
        str = str + " " + "top7";
      }
      if (num > 1 && index == 1) {
        str = str + " " + "top20 ";
      }
      return str;
    },
  },
  watch: {
    device: {
      handler (val) {
        this.batteryList = [];
      },
    },
    uavBattery: {
      // 低电量，良好，
      handler (value) {
        let uavInfo = value;
        let size = uavInfo?.chargeRemaining ? uavInfo.chargeRemaining : 0;
        // console.log('电池id：',value.id,'百分比:',size,"%，电压:",uavInfo.voltage.toFixed(1),'v')
        if (uavInfo?.voltage) {
          let state = this.state["3"];
          let id = null;
          if (uavInfo.id) {
            id = uavInfo.id;
          }
          if (this.device.cateName == "px4") {
            if (size > 40) {
              state = this.state["1"];
            } else if (size <= 40) {
              state = this.state["3"];
            }
          } else {
            if (size > 45) {
              state = this.state["1"];
            } else if (size <= 45) {
              state = this.state["3"];
            }
          }
          let voltage = uavInfo.voltage.toFixed(1);
          this.battery = {
            id,
            size,
            state,
            voltage, //: voltage <= 42 ? 42 : voltage,
          };
          if (value.id) {
            if (this.batteryList.length > 0) {
              let arr = [];
              this.batteryList.forEach((val) => {
                arr.push(val.id);
              });
              if (arr.indexOf(value.id) > -1) {
                this.batteryList[arr.indexOf(value.id)] = this.battery;
              } else {
                this.batteryList.push(this.battery);
              }
            } else {
              this.batteryList.push(this.battery);
            }
          } else if (value.id !== undefined) {
            this.batteryList = [];
            this.batteryList.push(this.battery);
          }
          this.$forceUpdate()
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-observe-mspace-dashboard-battery {
  width: 96px;
  height: 100%;
  box-sizing: border-box;

  .battery-inner {
    display: flex;
    align-items: center;
    position: relative;

    .dec {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      line-height: 18px;
      color: #fff;
      border: 1px solid #50abff;
      background: #080c41;
    }

    /* &::before {
      width: 1px;
      height: 90px;
      content: " ";
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      background-image: linear-gradient(
        206deg,
        rgba(36, 146, 252, 0) 5%,
        #2492fc 56%,
        rgba(36, 146, 252, 0) 100%
      );
      transform: translateY(-50%);
    } */
    .voltage-box {
      width: 100%;
      box-sizing: border-box;

      .voltage-value {
        font-size: 14px;
        color: #93d755;
        font-weight: 400;
        margin-top: 3px;
      }

      .voltage-unit {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #b3bbc5;
        letter-spacing: 0;
        font-weight: 400;
      }
    }

    .status-wrap {
      .status-box {
        width: 36px;
        height: 16px;
      }
    }

    .size-wrap {
      transform: rotate(90deg);

      .size-box {
        box-sizing: border-box;
        width: 25px;
        height: 60px;

        .size-inner {
          margin-top: 8px;
          width: 100%;
          height: calc(100%);
          border: 1px solid #50abff;
          box-sizing: border-box;
          // border-radius: 4px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: 2px;
          position: relative;

          .dianchigai {
            position: absolute;
            left: 2px;
            top: 2px;
            width: 89%;
            height: calc(100% - 3px);
            // border: 1px solid red;
            background-image: linear-gradient(to right,
                #fff -14%,
                transparent 41%);
            // border-radius: 3px;
          }

          &::before {
            content: " ";
            display: block;
            position: absolute;
            top: -5px;
            left: 50%;
            width: 10px;
            height: 4px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border: 1px solid #50abff;
            border-bottom: 0px;
            transform: translateX(-50%);
          }

          .size-inner-box {
            width: 100%;
            // border-radius: 4px;
          }
        }
      }
    }
  }
}

.batteryI {
  display: flex;
  align-items: center;
}
</style>