<!-- 飞控中心机巢 -->
<!-- 电池 -->
<template>
  <div class="cpt-observe-mspace-dashboard-battery jcsb">
    <div class="battery-inner pr">
      <div class="dec ml4 f12">{{ 1 }}</div>
      <div class="size-wrap ml20">
        <div class="size-box">
          <div class="size-inner">
            <div class="dianchigai"></div>
            <div
              class="size-inner-box"
              :style="`height: ${battery.size || 0}%;  ${setColor(
                battery.size
              )}`"
            ></div>
          </div>
        </div>
      </div>
      <span class="pa top32 f11 left50 cf" v-if="battery.size"
        >{{ battery.size }}%</span
      >
      <div class="ml12">
        <div class="voltage-box">
          <div class="voltage-value ml12 jcsb f12">
            {{ battery.voltage || 0 }} <span class="ml2 cf">v</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uavBattery: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      battery: {
        state: { label: "低电量", color: "#f73939" },
        size: 0,
      },
      state: {
        1: { label: "满电", color: "#76c230" },
        2: { label: "良好", color: "#FFBD36" },
        3: { label: "低电量", color: "#f73939" },
      },
    };
  },
  methods: {
    setColor(size) {
      if (size > 95) {
        return "background-color: #76c230";
      } else if (size > 30) {
        return " background: linear-gradient(to top,yellow,green);";
      } else {
        return "background-color: #f73939";
      }
    },
    setClass(index, num) {
      let str = "";
      if (num > 1 && index == 0) {
        str = str + " " + "top-10 ";
      } else if (num == 1 && index == 0) {
        str = str + " " + "top10";
      }
      if (num > 1 && index == 1) {
        str = str + " " + "top20 ";
      }
      return str;
    },
  },
  mounted() {
    3;
  },
  watch: {
    uavBattery: {
      // 低电量，良好，
      handler(value) {
        let uavInfo = value;
        if (uavInfo) {
          let size = uavInfo.chargeRemaining;
          let state = this.state["3"];
          if (size >= 26) {
            state = this.state["1"];
          } else if (size < 26 && size > 23) {
            state = this.state["2"];
          } else if (size <= 23) {
            state = this.state["3"];
          }
          let voltage = uavInfo.voltage.toFixed(1);
          this.battery = {
            size,
            state,
            voltage, //: voltage <= 42 ? 42 : voltage,
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-observe-mspace-dashboard-battery {
  width: 135px;
  height: 100%;
  box-sizing: border-box;

  .battery-inner {
    display: flex;
    align-items: center;
    position: relative;
    .dec {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      line-height: 18px;
      color: #fff;
      border: 1px solid #50abff;
      background: #080c41;
    }

    .voltage-box {
      width: 100%;
      box-sizing: border-box;
      /* padding-left: 30px; */
      text-align: center;

      .voltage-value {
        font-size: 14px;
        color: #93d755;
        font-weight: 400;
        margin-top: 3px;
      }

      .voltage-unit {
        font-family: MicrosoftYaHei;
        font-size: 10px;
        color: #b3bbc5;
        letter-spacing: 0;
        font-weight: 400;
      }
    }

    .status-wrap {
      /* padding-left: 40px; */
      .status-box {
        width: 44px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .size-wrap {
      // margin-top: 11px;
      transform: rotate(90deg);

      /* padding-left: 55px; */
      .size-box {
        box-sizing: border-box;
        width: 25px;
        height: 60px;

        .size-inner {
          margin-top: 8px;
          width: 100%;
          height: calc(100% - 8px);
          border: 1px solid #50abff;
          box-sizing: border-box;
          // border-radius: 4px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: 2px;
          position: relative;
          .dianchigai {
            position: absolute;
            left: 2px;
            top: 2px;
            width: 89%;
            height: calc(100% - 3px);
            // border: 1px solid red;
            background-image: linear-gradient(
              to right,
              #fff -14%,
              transparent 41%
            );
            // border-radius: 3px;
          }
          &::before {
            content: " ";
            display: block;
            position: absolute;
            top: -5px;
            left: 50%;
            width: 10px;
            height: 4px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border: 1px solid #50abff;
            border-bottom: 0px;
            transform: translateX(-50%);
          }

          .size-inner-box {
            width: 100%;
            // border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>
