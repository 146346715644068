<!-- 
  任务库--常态任务/临时紧急任务
 -->
<template>
  <div class="task-library-container">
    <div class="header">
      <div>
        <span v-show="taskLibraryShrinkFlag" class="open iconfont icon-baseqianjin" @click="openClickHandle"></span>
        <span class="name">任务库</span>
      </div>
      <img class="img" src="@/assets/img/i40.png" alt="" @click="close">
    </div>
    <div class="task-search">
      <el-input class="uav-search__input" clearable placeholder="请输入任务名称" v-model="taskName" @clear="onUavSearch">
      </el-input>
      <el-button class="uav-search__btn" @click="onUavSearch" icon="el-icon-search"></el-button>
    </div>
    <div class="content">
      <div class="title">
        <div class="name name1" :class="[taskTypeSelectedFlag == 0 ? 'name-selected' : '']" @click="taskTypeClick(0)">
          常态任务
        </div>
        <div class="name name2" :class="[taskTypeSelectedFlag == 1 ? 'name-selected' : '']" @click="taskTypeClick(1)">
          临时紧急任务
        </div>
      </div>
      <div class="body">
        <div class="item" v-for="(item, index) in lines.children || []" :key="index" @click="lineItemClick(item, index)"
          :class="[lineSelectedFlag == index ? 'item-selected' : '']">
          {{ item.taskTitle }}
        </div>
      </div>
    </div>
    <div class="foot" @click="createTask">
      <img class="img" src="@/assets/img/i39.png" alt="">
      <span class="name">创建基于航线的任务</span>
    </div>
  </div>
</template>

<script>
// 引用bus
import Bus from "@/assets/ligature.js";
import { COMMON } from "@/utils/common.js";
import _ from 'lodash';
export default {
  props: {
    taskLibraryShrinkFlag: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      taskName: '',
      taskTypeSelectedFlag: 0, //点击常态任务0， 和临时紧急任务1 切换
      airway_list: [], //常态任务和临时紧急任务
      lineSelectedFlag: -1, //常态任务和临时紧急任务下面点击航线样式改变
      lines: [],
    }
  },
  watch: {
    taskLibraryShrinkFlag (newVal, oldVal) {
      console.log(newVal, oldVal, 'xxxxxxxxxxxxxxxxxxxxxxx');
    },
  },
  mounted () {
    Bus.$on('airway_list_2_taskPanel', (v) => {
      console.log(v, '任务库面板原始数据');
      this.lineSelectedFlag = -1;
      this.airway_list = v;
      this.lines = _.cloneDeep(this.airway_list[2]);
    });
  },
  methods: {
    // 关掉任务库面板
    close () {
      this.taskTypeSelectedFlag = 0;
      this.taskName = '';
      Bus.$emit('taskLibraryShow', false);
    },
    // 搜索任务
    onUavSearch () {
      if (this.taskTypeSelectedFlag == 0) {
        this.lines = _.cloneDeep(this.airway_list[2]);
        this.lines.children = COMMON.search(this.airway_list[2].children, this.taskName, 'taskTitle');
      } else {
        this.lines = _.cloneDeep(this.airway_list[3]);
        this.lines.children = COMMON.search(this.airway_list[3].children, this.taskName, 'taskTitle');
      }
    },
    // 点击常态任务和临时紧急任务标签
    taskTypeClick (v) {
      this.taskTypeSelectedFlag = v;
      if (v == 0) {
        this.lines = _.cloneDeep(this.airway_list[2]);
      } else {
        this.lines = _.cloneDeep(this.airway_list[3]);
      }
      this.lineSelectedFlag = -1;
    },
    // 点击常态任务和临时紧急任务的航线
    lineItemClick (v, index) {
      this.lineSelectedFlag = index;
      // 将点击的航线数据发送到创建任务面板
      let data = {
        lines: this.lines,
        index: index,
      }
      // 将数据发送到创建任务面板组件taskList
      Bus.$emit('lineData2CreateTaskPanel', data);
      // 关闭当前面板
      this.close();
    },
    /**
     *@Description: 隐藏任务库面板，隐藏任务库组件，飞行任务显示 根据航线自动创建任务
     *@Date: 2023-10-18 09:48:33
     *@Params1: 
     *@Return1: 
    */
    createTask () {
      /**
       * 1 先判断创建常态任务还是临时紧急任务
       * 2 调用信息管理里面创建任务组件
       * **/
      // console.info('点击创建任务', this.taskTypeSelectedFlag);
      // this.$emit('createTaskBaseAirLineComp', this.taskTypeSelectedFlag);
      // 1. 点击创建基于航线的任务，该面板隐藏
      Bus.$emit('taskLibraryCompShowFlag', false);
    },
    /**
     *@Description: 任务库向右移
     *@Date: 2023-10-19 13:44:11
     *@Params1: 
     *@Return1: 
    */
    openClickHandle () {
      Bus.$emit('task-library-move-right', true);
    },
  },
}
</script>

<style lang="scss" scoped>
.task-library-container {
  width: 100%;
  height: 100% !important;
  background-color: #0A293A;
  z-index: 211 !important;

  .header {
    width: 410px;
    height: 54px;
    background: #224D68;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .open {
      color: #ffffff;
      margin-right: 10px;
      cursor: pointer;
    }

    .name {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }

    .img {
      width: 20px;
      height: 20px;
      // border: 2px solid #FFFFFF;
      cursor: pointer;
    }
  }

  .task-search {
    width: 410px;
    height: 64px;
    background: #133E55;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #133E55;

    .uav-search__input ::v-deep {
      height: 40px;
      border-radius: 4px;
      border: 0;

      .el-input__icon {
        line-height: 32px;
      }

      input {
        height: 40px;
        background-color: #0A293A;
        color: #43deff;
        border: 0px solid #315ec7;

        &::placeholder {
          font-size: 12px;
          color: #7bb5d5;
        }
      }
    }

    .uav-search__btn {
      border-radius: 4px;
      border: 1px solid;
      font-size: 14px;
      color: #fff;
      width: 42px;
      height: 40px;
      background: #4E87AC;
      border-radius: 4px;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }

  .content {
    width: 410px;
    height: calc(100% - 238px);

    .title {
      width: 410px;
      height: 54px;
      background: #224D68;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .name {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }

      .name1 {
        width: 101px;
      }

      .name2 {
        width: 130px;
      }

      .name-selected {
        color: #00F5FF;
        border-bottom: 2px solid #00F5FF;
      }
    }

    .body {
      width: 410px;
      height: calc(100% - 54px);
      overflow-y: auto;

      .item {
        width: 410px;
        height: 40px;
        background: #1D3652;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 0 0 0 10px;
        margin-bottom: 2px;
        cursor: pointer;
      }

      .item:hover {
        background: #0A293A;
        color: #00F5FF;
      }

      .item-selected {
        background: #0A293A;
        color: #00F5FF;
      }
    }
  }

  .foot {
    width: 378px;
    height: 56px;
    background: #133E55;
    // background-color: #00F5FF;
    background: #133E55;
    border-radius: 4px;
    margin-top: 20px;
    margin-left: 5px;
    margin-top: -24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .img {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }

    .name {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #00F5FF;
    }
  }
}
</style>