<!-- 飞控中心机巢列表 -->
<template>
  <div style="height: 100%">
    <div class="uav-list-header">
      <div>
        <img class="uav-list-header__icon" src="~@/assets/images/uav_list_header.png" />
        <span class="uav-list-header__text">机巢列表</span>
      </div>
      <div class="uav-list-header__count">
        共{{ count.sumCount }}架 /
        <span class="online">{{ count.onlineCount }}在线</span> /
        {{ count.offlineCount }}离线
      </div>
    </div>
    <div class="cpt-observe-nest-list">
      <div class="ctx-box pl0">
        <div class="list-box pr0">
          <template v-if="list && list.length">
            <Item v-for="item in list" :key="item.id" :data="item" />
          </template>
        </div>
      </div>
    </div>
  </div>
  <!-- </Dialog> -->
</template>

<script>
import Item from "./item";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    asyncList: {
      type: Function,
      default: () => () => { },
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: { Item },
  data () {
    return {
      name: null,
      curr_value: null,
    };
  },
  mounted () {
    console.log(this.list);
    this.asyncList();
  },
  watch: {
    value: {
      handler (value) {
        this.curr_value = value;
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    count () {
      if (this.list.length == 0) {
        return {
          onlineCount: 0,
          offlineCount: 0,
          sumCount: 0,
        };
      } else {
        return {
          onlineCount: this.list[0].onLineCount,
          offlineCount: this.list[0].offLineCount,
          sumCount: this.list[0].onLineCount + this.list[0].offLineCount,
        };
      }
    },
  },
  provide () {
    return {
      // console.log(list);
      // 飞控 机巢 列表选择
      itemClick: (select, item) => {
        this.list.forEach((element) => {
          element.nestList.forEach((res) => {
            if (res.deviceHardId == item.deviceHardId) {
              res.status = select;
            } else {
              res.status = false;
            }
          });
        });
        let value = select ? item : null;
        this.curr_value = value;
        this.$emit("change", this.curr_value, item);
        this.$emit("input", this.curr_value);
      },
      current: () => this.curr_value,
      itemLocation: (device) => {
        this.$emit("item-location", device);
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.healthy {
  box-sizing: border-box;
  padding: 0 5px;
  display: flex;
  align-items: center;
  backdrop-filter: blur(3px);
  font-size: 16px;
  font-weight: bold;
  height: 100%;

  .healthy--total {
    color: #cad8d9;
  }

  .healthy--un {
    color: #d54a15;
  }
}

.nestlist {
  background: rgba(4, 18, 50, 0.5);
}

.cpt-observe-nest-list {
  display: flex;
  flex-direction: column;
  height: 87vh;
  // background: rgba(12, 34, 73, 0.7);
  background-color: #0A293A;

  .search-box {
    flex-shrink: 0;
    display: flex;
    height: 36px;
    padding: 0 10px;
    background: #000000;
    border: 1px solid #3dcdff;
    border-radius: 2px;
    box-sizing: border-box;

    ::v-deep .el-input {
      height: 36px;

      input {
        height: 36px;
        background-color: transparent;
        border: none;
        padding: 0;

        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #08c2d1;
        font-weight: 400;
      }

      .el-input__suffix {
        display: flex;
        align-items: center;
      }
    }

    .search-icon-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      .iconfont {
        font-size: 24px;
        color: #08ffff;
      }

      &:hover {
        .iconfont {
          opacity: 0.8;
        }
      }
    }
  }

  .ctx-box {
    flex: 1;
    overflow: auto;
    margin-bottom: 30px;
    height: 100%;
    /* backdrop-filter: blur(3px); */
    position: relative;
    top: 0px;
  }
}

.jianj {
  display: flex;
  align-items: center;
  height: 100%;
}

.jl {
  margin: 0 0 0 5px;
  color: #70daf9 !important;
  font-weight: 700 !important;
}

.uav-list-header {
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 100%;
  height: 33px;
  // background: linear-gradient(
  //   180deg,
  //   #9198ff 0%,
  //   rgba(45, 81, 153, 0.45) 40%,
  //   #05091a 100%
  // );
  // box-shadow: inset 0px 0px 10px 2px #3f9dff;
  // border-radius: 10px 10px 0px 0px;
  // border: 1px solid #427dff;
  // width: 410px;
  height: 54px;
  background: #224D68;

  .uav-list-header__text {
    vertical-align: bottom;
    // font-size: 20px;
    // font-family: YouSheBiaoTiHei;
    // color: #14faff;
    // line-height: 26px;
    // text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    // background: linear-gradient(135deg,
    //     #f7b67d 38%,
    //     #f9eacb 58%,
    //     #f5d2a6 79%,
    //     #f59743 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // width: 80px;
    // height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    // line-height: 22px;
  }

  .uav-list-header__icon {
    width: 26px;
    margin-left: 9px;
    display: none;
  }

  .uav-list-header__count {
    color: #fff;
    font-weight: 500;
    margin-right: 16px;
    font-size: 14px;

    .online {
      font-size: 16px;
      font-weight: 900;
      color: #31db24;
    }

    display: none;
  }
}
</style>
