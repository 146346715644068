<template>
  <div class="cpt-player-webrtc">
    <video id="rtc_media_player" ref="webrtc" controls autoplay muted></video>
    <!-- <div class="cutImg" id="cutImage">
      <span class="closeImg">X</span>
    </div> -->
  </div>
</template>

<script>
let sdk = null;
// import Qs from "qs";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      curr_data: {
        vUrl: null
      },
      flight_info: null,
    };
  },
  mounted () {
    this.curr_data = {}
    let self = this;
    self?.$bus?.$on("takePhotos", (flight_info) => {
      self.flight_info = flight_info();
      self.screenShot(self.flight_info);
    });
  },

  methods: {
    init () {
      if (this.$refs["webrtc"]) {
        if (!this.data?.vUrl) {
          return;
        }
        // if (sdk) {
        //   sdk.close();
        // }
        // sdk = new SrsRtcPlayerAsync();
        // this.$refs["webrtc"].srcObject = sdk.stream;
        // sdk.play(curr_data.vUrl);

        if (sdk) {
          sdk.close();
        }
        sdk = new SrsRtcPlayerAsync();
        // $('#rtc_media_player').prop('srcObject', sdk.stream);
        // console.log("sdk...", sdk)
        this.$refs["webrtc"].srcObject = sdk.stream;
        // curr_data.vUrl = "rtmp://121.43.58.140/live/";
        // 'webrtc://srs.mmcuav.cn/live/xxxxxxxxxxxxxxx' // 测试地址，需使用推流工具推流
        // console.log("curr_data.vUrl:", curr_data.vUrl);
        let self = this
        sdk
          .play(this.data.vUrl)
          .then(function (session) {
            console.log(session);
            /* if (session) {
              self.$bus.$emit("handleVideoState")
            } */
          })
          .catch(function (reason) {
            console.log('err reason', reason)
            sdk.close();
            console.error(reason);
          });
      }
    },
    async screenShot (data) {
      // console.log("screenShot:", data);
      var player = document.getElementById("rtc_media_player"); //获取video的Dom节点
      player.setAttribute("crossOrigin", "anonymous"); //添加srossOrigin属性，解决跨域问题
      var canvas = document.createElement("canvas");
      // var img = document.createElement("img");
      canvas.width = player.clientWidth;
      canvas.height = player.clientHeight;
      canvas
        .getContext("2d")
        .drawImage(player, 0, 0, canvas.width, canvas.height); //截
      // var dataURL = canvas.toDataURL("image/png"); //将图片转成base64格式
      // img.src = dataURL;
      // img.width = player.clientWidth; //控制截出来的图片宽的大小
      // img.height = player.clientHeight; //控制截出来的图片高的大小
      // img.style.border = "1px solid #333333"; //控制截出来的图片边框的样式
      // document.getElementById("cutImage").appendChild(img); //显示在页面中
      // console.log("img:", dataURL);
      try {
        canvas.toBlob((blob) => {
          // 创建a元素来实现下载
          // const a = document.createElement("a");
          // a.style.display = "none";
          // document.body.appendChild(a);
          // a.href = window.URL.createObjectURL(blob);
          // // 下载的文件名
          // let fileName = "nt图片";
          // a.download =
          //   fileName || `screenshot-${canvas.width}x${canvas.height}.png`;
          // console.log("IMG-BLOD:", blob);
          const myFile = new File([blob], "image.png", {
            type: blob.type,
          });
          this.savaTaskImages(myFile, data.deviceId, data.id);
          // a.click();
          // // 触发下载后，移除元素
          // a.remove();
        });
      } catch (e) {
        console.log(e);
      }
      // this.downFile(dataURL, "图片.jpg"); //下载截图
    },
    async savaTaskImages (images, deviceHardId, task_id) {
      let forms = new FormData();

      forms.append("task_id", task_id);
      forms.append("deviceHardId", deviceHardId);
      forms.append("images", images);
    },
  },
  watch: {
    data: {
      handler (newVal, oldVal) {
        let self = this
        if (newVal?.vUrl != oldVal?.vUrl) {
          this.$nextTick(() => {
            console.log('self.init')
            self.init();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
video::-webkit-media-controls-enclosure {
  display: none;
}

.cpt-player-webrtc {
  height: 100%;
  width: 100%;
  background-color: #000;

  &:hover {
    video::-webkit-media-controls-enclosure {
      display: inherit !important;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
</style>