<template>
  <div class="lsdom">

    <div class="tc content mt10">
      {{ LsdomVlue.waring }}
      <div class="infoBtn cp" @click="infoFlag = true" v-show="!infoFlag && LsdomVlue && LsdomVlue.detailsList">查看详情</div>
    </div>
    <div></div>
    <div class="w487 h212 info" v-show="infoFlag">
      <div class="info_th">
        <div>航线名称</div>
        <div>单位名称</div>
        <div>飞手</div>
        <div>联系号码</div>
      </div>
      <div class="info_td" v-for="(item, index) in LsdomVlue.detailsList " :key="index">
        <el-tooltip :content="item.flightName" placement="top">
          <div>{{ item.flightName }}</div>
        </el-tooltip>
        <el-tooltip :content="item.anotherName" placement="top">
          <div>{{ item.anotherName }}</div>
        </el-tooltip>
        <div>{{ item.flyName }}</div>
        <el-tooltip :content="item.phones" placement="top">
          <div>{{ item.phones }}</div>
        </el-tooltip>
      </div>
    </div>
    <div class="title">
      <div>该航线存在{{ LsdomVlue.title || '预警' }}，是否确认起飞？</div>
      <div class="isFly">
        <div class="mr60 cp" @click="$emit('close')">取消</div>
        <div class="mr10 cp" @click="$emit('isFly')">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      infoFlag: false,
    };
  },
  props: {
    LsdomVlue: {
      type: Object,
      default () {
        return {};
      }
    },
  },
  mounted () {
    console.log(this.LsdomVlue, 3333)
  }
};
</script>

<style lang='scss' scoped>
.lsdom {
  width: 531px;
  min-height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background: rgba(50, 2, 2, 0.7);
  background: url('../../assets/images/warn/yj.png') no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
  border-radius: 10px;
  z-index: 121;

  .title {
    text-align: center;
    height: 100px;
    line-height: 40px;
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #ff734b;
    // letter-spacing: 0;
    font-weight: 700;

    // padding-left: 20px;
    // background: rgba(53, 3, 3, 0.25);
    // box-shadow: inset 0 0 20px 0 rgba(255, 101, 56, 0.65);
    // border-radius: 10px 10px 0 0;
    .isFly {
      display: flex;
      justify-content: center;

      div {
        padding: 0 10px;
        background: rgba(53, 3, 3, 0.25);
        box-shadow: inset 0 0 20px 0 rgba(255, 101, 56, 0.65);
        border-radius: 5px;
      }
    }
  }

  .exit {
    cursor: pointer;
  }

  .content {
    min-height: 120px;
    // background: rgba(50, 2, 2, 0.7);
    // box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
    border-radius: 10px;
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #ffcdcd;
    text-align: center;
    line-height: 28px;
    font-weight: 400;

    .infoBtn {
      width: 120px;
      margin: 30px auto;
      background: rgba(140, 25, 0, 0.2);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #ff4600;
      border-radius: 6px;
    }
  }

  .info {
    background: #2a1017;
    border: 1px solid rgba(255, 115, 75, 0.5);
    box-shadow: 0 2px 4px 0 rgba(143, 0, 0, 0.5);
    border-radius: 10px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #ed5944;
    margin: 20px auto;
    padding: 5px;
    letter-spacing: 0;
    overflow-y: auto;
    font-weight: 400;

    .info_th {
      font-family: MicrosoftYaHei-Bold;
      font-size: 16px;
      color: #ed5944;
      letter-spacing: 0;
      font-weight: 700;
      text-align: center;
      display: flex;
      margin-top: 20px;

      div {
        width: 25%;
      }
    }

    .info_td {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ed5944;
      letter-spacing: 0;
      font-weight: 400;
      display: flex;
      text-align: center;
      margin-top: 10px;

      div {
        width: 25%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}</style>