import mqtt from "mqtt";
import dayjs from "dayjs";
const { VUE_APP_FCMQTT_URL } = process.env;
import API from "@/api";

export default {
  uint8array2json (uint8array) {
    if (!uint8array || !uint8array.length) {
      return uint8array;
    }
    let {
      decoder
    } = this.mqtt;
    if (!decoder) {
      decoder = new TextDecoder("utf-8");
    }
    let content = decoder.decode(uint8array);
    return content ? JSON.parse(content) : content;
  },

  json2uint8array (data) {
    if (!data) {
      return data;
    }
    let {
      encoder
    } = this.mqtt;
    if (!encoder) {
      encoder = new TextEncoder();
    }
    let uint8array = encoder.encode(JSON.stringify(data));
    return uint8array;
  },
  circulate () {
    clearTimeout(this.uav.offlineTime)
    this.uav.offline = false
    this.uav.offlineTime = setTimeout(() => {
      this.uav.offline = true
      this.mqtt_inserWebLogs()
    }, 3000);
  },
  // 记录数据d断开日志
  async inserWebLogs () {
    let day = dayjs();
    let { user_info } = this;
    let { deviceName, deviceHardId } = this.uav.control.device
    const time = day.format("YYYY-MM-DD HH:mm:ss");
    let errorData =
      "登录用户：" +
      user_info.name +
      "，所属机构：" +
      deviceName +
      ",无人机id：" +
      deviceHardId +
      ",断开时间" +
      time;
    let errorType = '无人机应用'
    let res = await API.FCKERNEL.inserWebLogs({
      errorType,
      errorData
    })
  },
  init () {
    let { user_info, uav } = this;
    if (this.mqtt.client) {
      this.mqtt.client.end();
      this.mqtt.client = null;
    }
    // const crypto = window.crypto || window.webkitCrypto || window.mozCrypto || window.oCrypto || window.msCrypto;
    // let uuid = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    let url = VUE_APP_FCMQTT_URL;
    // user_info.id
    let protocol = url.replace(/(.+)\:\/\/.*/, "$1");
    this.mqtt.client = mqtt.connect(url, { protocol, clientId: user_info.id, });
    this.mqtt.client.on("connect", (e) => {
      console.log(e, "mqtt is connected: " + url);
    });
    this.mqtt.client.on("reconnect", (reconnect) => {
      console.log(reconnect, "mqtt reconnect " + url);
    });
    this.mqtt.client.on("`error`", (error) => {
      console.log(error, "mqtt is `error` " + url);
    });
    this.mqtt.client.on("message", (topic, payload, packet) => {
      let data = this.mqtt_uint8array2json(payload);
      // console.log(data, '开始接收飞控链路订阅的数据');
      let deviceHardId = packet.topic.split("/")[2];
      window.$log("observe/fckernel/uav/methods/mqtt.js message", data);
      window.$log("topic", topic);
      try {
        data.data.locationCoordinate3D.altitude += 10; //由于3D地图悬浮于2D地图表面，需要增加无人机模型的海拔高度，不然会被3D地图遮挡
      } catch (e) { }
      // console.log(uav.videos[0].deviceHardId,topic,'topic');
      // 判断当前无人机和传过去的无人机是否相等
      // let ycTopic = `APRON/RECEIVE/${uav.videos[0].deviceHardId}`;
      // console.log(this.uavId.deviceHardId,'deviceHardIddeviceHardId');
      //  console.log(deviceHardId,'nest.data.deviceHardId');
      // console.log(data.type);
      //
      if (this.uavId.deviceHardId == deviceHardId) {
        // 无人机实时状态信息
        if (data.type === 258) {
          this.mqtt_circulate()
          // console.log(data, '更新无人机状态信息，位置，面板');
          // 更新无人机位置信息
          this.uav_update_location({ deviceHardId }, data.data);
          // 更新控制面板数据
          this.uav_update_control({ deviceHardId }, data.data);
        }
        if (data.type === 268) {
          // window.$falg = localStorage.getItem('$falg') == 1 ? true : false;
          // if(window.$falg == true){
          //   console.log(data.data.accelerator, "油门")
          // }
        }
        if (data.type == 275) {
          this.healthData = data.data
        }
        if (data.type === 261) {
          // 等待航线上传成功在执行
          if (this.takeOff == true) {
            this.fly_take_off();
          }
        }
        if (data.type === 263) {
          console.log(data, '航线进度');
        }
        if (data.type === 268) {
          this.uav_update_channelData({
            deviceHardId
          }, data.data);
        }
        if (data.type === 257) {
          // 无人机电池数据
          // console.log('1 飞控链路无人机电池数据', data);
          this.uav_update_control_battery({ deviceHardId }, data.data);
        }
        if (data.type === 514) {
          // 挂载数据
          this.uav_update_mount({
            deviceHardId
          }, data.data);
        }
        if (data.type === 259) {
          this.uav_update_mount({
            deviceHardId
          }, data.data);
          // 挂载数据
        }
        // 无人机故障信息
        if (data.type === 269) {
          this.$message.info(data.data);
        }
        if (data.type == 270) {
          this.uav.wrjType = data.data;
          this.uav.msg_list.unshift({
            type: data.data.code,
            text: data.data.text,
            time: this.mqtt_getstartDate()
          });
          // console.log(this.uav.msg_list,"msg_list");
          if (data.data.code == 10043) {
            this.$message.success(data.data.text);
          } else if (data.data.code == 10053) {
            this.$message.success(data.data.text);
          }
        }
        // 无人机提示信息
        if (data.type === 260) {
          console.log(data, "data260");
          if (data.data.severity == 99) {
            this.warnShow = true
            this.warnMessage = data.data.msg
          }
          this.uav.msg_list.unshift({
            type: "警告",
            grade: data.data.severity,
            text: data.data.msg,
            time: this.mqtt_getstartDate()
          });
          this.uav_update_control_info({
            deviceHardId
          }, data.data);
        }
        if (data.type === 500 && data.msgnum === 4132) {
          // 设备上线
          this.uav_update_device_report_status(1, deviceHardId);
        }
        if (data.type === 500 && data.msgnum === 4121) {
          // 设备离线
          this.uav_update_device_report_status(0, deviceHardId);
        }
        if (data.type === 500 && data.msgnum === 4121) {
          // 设备离线
          this.uav_update_device_report_status(0, deviceHardId);
        }
        if (data.type === 264) {
          // 航线上传
          this.airway_upload_message(data.data, packet.topic);
        }
        if (data.type === 272) {
          //避障信息
          this.$store.commit('fckernel/SET_OBSTACLE', {
            ...data.data,
            deviceHardId
          });
        }
      }
    });
    /**
     * 订阅无人机上线下线消息
     */
    this.mqtt_subscribe(`on_and_off_broker`, (e) => {
      console.log("subscribe on_and_off_broker");
    });
  },
  getstartDate () {
    let date = new Date();
    let y = date.getFullYear();
    let m =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let mi =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    let startDateTime =
      y + "-" + m + "-" + d + " " + h + ":" + mi + ":" + s;
    return startDateTime
  },

  /**
   * 订阅
   * @param {*} topic
   * @param {*} callback
   */
  subscribe (topic, callback) {
    if (!topic) {
      return topic;
    }
    let {
      client
    } = this.mqtt;
    if (client) {
      console.log("订阅数据a", topic);
      client.subscribe(topic, callback);
    }
  },

  /**
   * 取消订阅
   * @param {*} topic
   * @returns
   */
  unsubscribe (topic) {
    if (!topic) {
      return topic;
    }
    let {
      client
    } = this.mqtt;
    if (client) {
      client.unsubscribe(topic);
    }
  },

  /**
   * 发布
   * QoS 0：消息最多传递一次，如果当时客户端不可用，则会丢失该消息。
   * QoS 1：消息传递至少 1 次。
   * QoS 2：消息仅传送一次。
   * @param {*} publication
   * @param {*} callback
   * @param {*} options
   * @param {*} type
   */
  publish (topic, message, callback, options = {
    qos: 0
  }) {
    let {
      client
    } = this.mqtt;
    // console.log(JSON.stringify(message), "发送数据---------------");
    // this.mqtt_json2uint8array(message)
    client && client.publish(
      topic,
      new TextEncoder().encode(JSON.stringify(message)),
      options,
      callback
    );
  },
};