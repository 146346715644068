<!-- 
  无人机每一行
 -->
<template>
  <div class="cpt-app-uav-list">
    <div class="uav-item-box">
      <div class="uav-item-inner" @click="itemClick()">

        <div class="title-box">
          <span class="el-icon-caret-right" :class="{ collapse: data.collapse }"></span>
          <img class="level-icon" src="~@/assets/images/uav_item1_1.png" v-if="level === 1" />
          <img class="level-icon level-icon2" :class="[!data.collapse ? 'level-icon-open' : '']"
            :src="imgSrc(data.collapse)" v-if="level === 2" />
          <!-- <img class="level-icon" src="~@/assets/images/uav_item2.svg" v-if="level === 2" /> -->
          <img class="level-icon level-icon3" :class="[!data.collapse ? 'level-icon-open' : '']"
            :src="imgSrc(data.collapse)" v-if="level === 3" />
          <!-- <img class="level-icon" src="~@/assets/images/uav_item3.svg" v-if="level === 3" /> -->
          <div class="org-name" :title="data.name">{{ data.orgName }}</div>
          <i class="refresh-icon el-icon-refresh-right" v-if="level === 1" @click.stop="$emit('refresh')" />
        </div>

        <div class="online-info">

          <el-row class="row">
            <el-col :span="6" class="col" style="color: #fff;">
              {{ data.online + data.offline }} 架
            </el-col>
            <el-col :span="6" class="col">
              <img src="@/assets/img/i28.png" class="aircraft-online" alt="">
            </el-col>
            <el-col :span="12" class="col" style="text-align: left;">
              <span class="ml10" :class="{ online: data.online }">{{ data.online }} 在线</span>/{{ data.offline }} 离线
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="uav-item-child-box" :class="{ collapse: data.collapse }">
        <Item v-for="child in data.child" :key="child.id" :data="child" :level="level + 1" />

        <div class="device-item-box" :class="{ online: device.reportStatus == 1 }" v-for="device in data.devices"
          :key="`device_${device.id}`">

          <div class="device-name jcsb">

            <img src="@/assets/img/i36.png" v-if="device.reportStatus == 1" alt="">
            <img src="@/assets/img/i35.png" v-else alt="">

            <span style="margin-right:10px; color: #fff; margin-top: 8px;" :title="device.deviceName">{{
              device.deviceName }}</span>

            <span style="color:#31DB24 ; margin-top: 8px;" class="dib"
              v-if="reportStatusFun(device, device.reportStatus)">(在线)</span>
            <span style="margin-top: 8px;" v-else class="dib">(离线)</span>

            <div class="symbol-icon-box">
              <div class="uav_version status-icon cp" :title="device.goodsName"
                v-if="['入云龙I', '入云龙II', '插翅虎M9'].includes(device.goodsName)">
                <img src="~@/assets/images/I.svg" v-if="device.goodsName == '入云龙I'" />
                <template v-else-if="device.goodsName == '入云龙II'">
                  <img src="~@/assets/images/I.svg" />
                  <img src="~@/assets/images/I.svg" />
                </template>
                <img v-else-if="device.goodsName == '插翅虎M9'" src="~@/assets/images/cq.svg">

              </div>
              <div :title="device.comment || '异常'" v-if="device.state == 2" style="color:red"
                class="status-icon iconfont icon-yichang1"></div>
              <div :title="device.comment || '维修'" v-if="device.state == 3" class="status-icon iconfont icon-weixiu">
              </div>
              <div :title="device.comment || '保养'" v-if="device.state == 4" class="status-icon iconfont icon-baoyang">
              </div>
            </div>

          </div>
          <div class="device-fns">
            <!-- 视频按钮 -->
            <div class="iconfont" :class="{ active: device._video }" title="视频"
              @click="fn(3, device, orgName = data.orgName)">
              <img v-if="!device._video" class="img-action1" src="@/assets/img/i31.png" alt="">
              <img v-else class="img-action1" src="@/assets/img/i31-1.png" alt="">
            </div>
            <!-- 控制面板按钮 -->
            <div v-if="device.reportBy != 1" class="iconfont" :class="{ active: device._control }" title="控制面板"
              @click="fn(4, device, orgName = data.orgName); $store.commit('fckernel/SET_OBSTACLE', null)">
              <img v-if="!device._control" class="img-action2" src="@/assets/img/i32.png" alt="">
              <img v-else class="img-action2" src="@/assets/img/i32-1.png" alt="">
            </div>
            <!-- 回到创建任务面板按钮 -->
            <div v-if="device.reportBy != 1" class="iconfont" :class="{ active: device._control }" title="查看任务"
              @click="fn(6, device, orgName = data.orgName)">
              <img v-if="!device._control" class="img-action2" src="@/assets/img/i143.png" alt="">
              <img v-else class="img-action2" src="@/assets/img/i143-1.png" alt="">
            </div>
            <!-- 接管无人机按钮 -->
            <div class="jieg" title="接管无人机  " v-if="device.reportBy != 1" @click="fn(5, device, orgName = data.orgName)">
              <img class="img-action3" src="@/assets/img/i34.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";

export default {
  name: "Item",
  data () {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    level: {
      type: Number,
      default: -1
    }
  },
  inject: ["fn"],
  components: { SymbolIcon },
  methods: {
    itemClick () {
      this.data.collapse = !this.data.collapse;
      console.log(this.data, 'dataaaaaaaaaaaaaa');
    },
    imgSrc (v) {
      if (v) {
        return require('@/assets/img/i29.png');
      } else {
        return require('@/assets/img/i30.png');
      }
    },
    reportStatusFun (data, v) {
      // console.log(data, v, 'vvvvvvvvvvvvvvvvv');
      return v == 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-app-uav-list {
  // padding: 0 4px;
  // background-color: #00ffff;

  .uav-item-box {
    font-family: Microsoft YaHei;
    font-size: 16px;
    color: #b3bbc5;
    font-weight: 400;
    // padding: 0 5px;
    // background-color: #31DB24;

    .uav-item-inner {
      // height: 30px;
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid yellow;
      background: #153A51;
      margin-bottom: 2px;
      padding: 0 5px;
      box-sizing: border-box;

      .title-box {
        display: flex;
        align-content: center;
        align-items: center;
        // background-color: #00ffff;

        .el-icon-caret-right {
          cursor: pointer;
          transition: 0.3s;
          transform-origin: center;
          transform: rotate(90deg);
          margin-right: 4px;

          &.collapse {
            transform: rotate(0deg);
          }
        }

        .org-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 13px;
          color: #fff;
        }
      }

      .online-info {
        font-size: 12px;
        white-space: nowrap;
        width: 50%;

        .online {
          color: #00F5FF;
        }

        .row {
          // background-color: #129c9c;
          width: 100%;
          float: right;

          .col {
            // width: 100%;
            // background-color: #b3bbc5;
            // border: 1px solid yellow;
            text-align: right;
          }
        }
      }
    }

    .uav-item-child-box {
      // padding-left: 20px;

      max-height: 20000px;
      transition: 0.3s;
      overflow: hidden;
      position: relative;

      &::before {
        content: " ";
        display: block;
        height: 100%;
        width: 0px;
        background-color: #129c9c;
        position: absolute;
        left: 7px;
        top: 0;

      }

      &.collapse {
        max-height: 0;
      }

      .device-item-box {
        // height: 30px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        transition: 0.2s;
        padding: 0 0 0 20px;

        .device-name {
          width: calc(100% - 150px);
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
        }

        &.online {
          .device-name {
            color: #00ffff;
          }
        }

        .device-fns {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 30%;
          padding: 0 10px;

          .jieg {
            width: 20px;
            cursor: pointer;
            height: 20px;
            // background: url("~@/assets/jieguan.svg") no-repeat;
            background-size: 100% 100%;
          }

          .iconfont {
            font-size: 24px;
            margin-right: 8px;
            cursor: pointer;

            &.active {
              color: #00ffff;
            }
          }

          .img-action1 {
            width: 17px;
            height: 17px;
          }

          .img-action2 {
            width: 17px;
            height: 17px;
          }

          .img-action3 {
            width: 17px;
            height: 17px;
          }
        }
      }
    }
  }

  .goodsName_two {
    background-image: url("~@/assets/images/goodsName_two.svg");
    background-size: 100% 100%;
  }

  .goodsName_one {
    background-image: url("~@/assets/images/goodsName_one.svg");
    background-size: 100% 100%;

  }
}

.green {
  color: #31DB24
}

.red {
  color: red
}

.item_items {
  align-items: center;
}

.level-icon {
  width: 16px;
  margin-right: 6px;
  width: 30px;
  height: 30px;
}

.level-icon2 {
  margin-left: 18px;
  height: 13px;
  width: 13px;
}

.level-icon3 {
  margin-left: 28px;
  height: 13px;
  width: 13px;
}

.level-icon-open {
  // margin-left: 28px;
}

.refresh-icon {
  width: 16px;
  margin-left: 34px;
}

.jcsb {
  justify-content: left;
}

.status-icon {
  margin-left: 12px;
  font-size: 14px;
  color: RGBA(251, 192, 1, 1);
}

.uav_version {
  width: 14px;
  height: 12px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.symbol-icon-box {
  display: flex;
  align-items: center;

  img {
    height: 8px;
  }
}

.aircraft-online {
  width: 13px;
  height: 12px;
}

.el-icon-caret-right::before {
  display: none;
}

.el-icon-refresh-right {
  margin-left: 12px;
}
</style>