<template>
  <div class="page-observe-fckernel">
    <!-- <Header v-model="page" v-if="page === 1" /> -->
    <!-- 无人机 -->
    <Uav class="uav" ref="uacRef" @Lsdom="LsdomFn" @changeF="changeF" v-if="page === 1" />
    <Nest ref="nestRef" @Lsdom="LsdomFn" @changeF="changeF" :weather="weather" v-if="page === 2" />
    <Lsdom :LsdomVlue="LsdomVlue" v-show="LsdomFlag" @close="LsdomFlag = false" @isFly="isFly"></Lsdom>
  </div>
</template>

<script>
import Header from "@/components/observe/fckernel/header";
import Uav from "./uav/index";
import Nest from "./nest/index";
import Mount from "@/components/PagerP1";
import Lsdom from "@/components/lsdomV2";
import { Utils } from "@/lib/cesium";

export default {
  components: { Header, Uav, Nest, Mount, Lsdom },
  data () {
    return {
      page: 1, // 默认是无人机
      weather: {},
      LsdomVlue: { title: "提示" },
      LsdomFlag: false
    };
  },
  created () {
    // this.init_ws();

  },
  methods: {
    LsdomFn (value) {
      this.LsdomVlue = value
      this.LsdomFlag = true
    },
    isFly () {
      if (this.page == 1) {
        this.$refs.uacRef.$refs.TaskListRef.getTrue()
      } else if (this.page == 2) {
        this.$refs.nestRef.$refs.TaskListRef.getTrue()
      }
      this.LsdomFlag = false
    },
    init_ws () {
      let ws_url_al = process.env.VUE_APP_WS_URL_ALARM;
      console.log(ws_url_al, "ws_url_al");
      let ws = new WebSocket(ws_url_al);
      let _this = this;
      let { appid, username, token } = JSON.parse(localStorage.getItem("user_info")).data
      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            type: 100,
            systemCode: "mmc",
            state: 1,
            username,
            token,
            appId: appid,
          })
        );

      };

      ws.onmessage = (e) => {
        try {
          let metadata = JSON.parse(e.data);
          // console.log(metadata, '无人机原始数据，可能引起地图压力过大22');
          if (metadata.msgnum === 4131) {
            let data = metadata.data.filter((item) => {
              return item.id == 8;
            });
            _this.weather = data[0];
          }
        } catch (err) {
          console.log('警报数据错误', err);
          console.log('数据为', e);
        }
      };

      ws.onerror = (error) => {
        //console.log("error", error);
      };

      ws.onclose = (data) => {
        // console.log("onclose", data);
      };
    },
    cesium_layer_change_mode (fn, modes) {

      // 定位到芜湖
      Utils.initLocation2WUHU();
      return;

      // fn(modes.GLOBE);
      window.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(120.218241, 33.165767, 21812.321476599747),
        orientation: {
          heading: 0.006918732929549698,
          pitch: -0.6928665494487145,
          roll: 7.638334409421077e-14
        },
        time: 5
      })
    },
    changeF (item) {
      if (this.page == 1) {
        this.page = 2;
      } else {
        this.page = 1;
      }
    },
  },
  mounted () {
    document.title = "飞控中心";
    let { page } = this.$route.query;
    console.log(page, 'page');
    if (page == 2) {
      this.changeF()
      // this.page = page;
    }
  },
};
</script>
<style lang="scss" scoped>
.page-observe-fckernel {
  // width: 100%;
  // height: 100%;
  // border: 10px solid greenyellow;
  // box-sizing: border-box;
}
</style>