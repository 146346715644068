<template>
  <div class="cpt-common-float-player moveVideo_yc pa" @click="fn">
    <div class="title-box drag-handle ofh">
      <div class="title">
        <span class="scrollTitleStyle">{{ data.list[0].name }}</span>
        <span class="scrollTitleStyle">{{ data.list[0].name }}</span>
      </div>
      <span class="device-name">{{ data.deviceName }}</span>
      <div class="el-icon-close" @click="handleClose"></div>
    </div>
    <div class="box">
      <div class="iconBG" v-if="flag">
        <div
          v-if="type == 4"
          class="cp infop cf right50"
          @click="infoshow = !infoshow"
          alt
        />
        <div
          :class="type == 4 ? 'right17' : 'right23'"
          class="cp reset cf"
          @click="reset"
        />
      </div>
      <div class="video-box" v-if="flag">
        <ViedojsPlayer v-if="type == 1" :data="data" />
        <SRSPlayer v-else-if="type == 2" :data="data" />
        <QingLiuPlayer v-else-if="type == 4" :data="data" />
        <LiveNVRPlayer ref="live-nvr" v-else-if="type == 3" :data="data" />
        <WebRTCPlayer v-else :data="data" />

        <!-- <QingLiuPlayer v-if="type == 4" :data="data" />
    <span v-if="type==4">
        {{data.vUrl}}
        </span>-->
      </div>
    </div>
    <div class="info" v-if="infoshow">
      <div class="title_box" v-interact>
        <div class="title pl20">
          <img src="~@/assets/images/mount/mount_head.png" />
          <div class="font">视频信息</div>
        </div>
        <div style="display: flex">
          <div class="icon-box">
            <!-- <span class="iconfont icon-qingchushuju"></span> -->
            <!-- <span class="icon-text pr20">清除数据</span> -->
          </div>
          <div class="cf ml20 mr10 c70d" @click="infoshow = false">关闭</div>
        </div>
      </div>
      <div class="content">
        <div class="de">
          <div class="title">bitrate:</div>
          <div class="detail">{{ raw_msg ? raw_msg / 1000 : "0" }}kbps</div>
        </div>
        <div class="de">
          <div class="title">venc_avg:</div>
          <div class="detail">
            {{ infoData.venc_avg ? infoData.venc_avg / 1000 : "0" }}ms
          </div>
        </div>
        <div class="de">
          <div class="title">venc_last:</div>
          <div class="detail">
            {{ infoData.venc_last ? infoData.venc_last / 1000 : "0" }}ms
          </div>
        </div>
        <div class="de">
          <div class="title">pushrtt_avg:</div>
          <div class="detail">
            {{ infoData.pushrtt_avg ? infoData.pushrtt_avg[0] / 1000 : "0" }}ms
          </div>
        </div>
        <div class="de">
          <div class="title">pushrtt_last:</div>
          <div class="detail">
            {{
              infoData.pushrtt_last ? infoData.pushrtt_last[0] / 1000 : "0"
            }}ms
          </div>
        </div>
        <div class="de">
          <div class="title">recvrtt_avg:</div>
          <div class="detail">
            {{ infoData.recvrtt_avg ? infoData.recvrtt_avg[0] / 1000 : "0" }}ms
          </div>
        </div>
        <div class="de">
          <div class="title">recvrtt_last:</div>
          <div class="detail">
            {{
              infoData.recvrtt_last ? infoData.recvrtt_last[0] / 1000 : "0"
            }}ms
          </div>
        </div>
        <div class="de">
          <div class="title">pushretrans_avg:</div>
          <div class="detail">
            {{
              infoData.pushretrans_avg
                ? (infoData.pushretrans_avg[0] * 0.1).toFixed(2)
                : "0"
            }}%
          </div>
        </div>
        <div class="de">
          <div class="title">pushretrans_last:</div>
          <div class="detail">
            {{
              infoData.pushretrans_last
                ? (infoData.pushretrans_last[0] * 0.1).toFixed(2)
                : "0"
            }}%
          </div>
        </div>
        <div class="de">
          <div class="title">recvretrans_avg:</div>
          <div class="detail">
            {{
              infoData.recvretrans_avg
                ? (infoData.recvretrans_avg[0] * 0.1).toFixed(2)
                : "0"
            }}%
          </div>
        </div>
        <div class="de">
          <div class="title">recvretrans_last:</div>
          <div class="detail">
            {{
              infoData.recvretrans_last
                ? (infoData.recvretrans_last[0] * 0.1).toFixed(2)
                : "0"
            }}%
          </div>
        </div>
        <div class="de">
          <div class="title">vdec:</div>
          <div class="detail">
            {{ infoData.vdec ? infoData.vdec / 1000 : "0" }}ms
          </div>
        </div>
        <div class="de">
          <div class="title">vbuffer:</div>
          <div class="detail">
            {{ infoData.vbuffer ? infoData.vbuffer / 1000 : "0" }}ms
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebRTCPlayer from "@/components/player/webrtc";
import ViedojsPlayer from "@/components/player/videojs";
import SRSPlayer from "@/components/player/srs";
import LiveNVRPlayer from "@/components/player/livenvr";
import QingLiuPlayer from "@/pages/observe/fckernel/uav/components/float-playerfk/qingliu/index.vue";
import VueDragResize from "vue-drag-resize";
import { to_moveMount } from "@/utils/to_moveMount.js";
import { dataURLToBlob, canvasToDataURL } from "@/utils/image-tool";
import API from "@/api";

// 引用bus
import Bus from "@/assets/ligature.js";
export default {
  props: {
    type: {
      type: Number | String,
      default: () => 1,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      flag: true,
      mountData: {},
      infoshow: false,
      infoData: {},
      raw_msg: 0,
    };
  },
  components: {
    WebRTCPlayer,
    ViedojsPlayer,
    SRSPlayer,
    LiveNVRPlayer,
    VueDragResize,
    QingLiuPlayer,
  },
  mounted() {
    this.initStyle();
  },
  watch: {
    "$store.state.device.move_data": function (newVal) {
      this.mountData = newVal;
    },
    type: function (newval) {
      if (newval == 4) {
        window.$Bus.$on("qoeinfo", (e) => {
          this.infoData = e.data;
        });
        window.$Bus.$on("raw_msg", (e) => {
          this.raw_msg = e.data;
        });
      }
    },
    infoData: function () {
      // this.qoeInform(this.raw_msg);
    },
    raw_msg: function () {
      // this.qoeInform(this.raw_msg);
    },
    deep: true,
  },
  methods: {
    async initStyle() {
      // let arr = document.querySelectorAll('.moveVideo_yc')
    },
    // 上报清流数据
    qoeInform() {
      try {
        let { infoData } = this;
        let {
          deviceHardId,
          udpStatus,
          cateId,
          cateName,
          deviceName,
          flightState,
          goodsName,
          reportStatus,
          socketStatus,
          state,
          stationType,
          store,
          firmId,
          firmName,
        } = this.device;
        let data = {
          uavId: deviceHardId,
          udpStatus,
          cateId,
          cateName,
          deviceName,
          flightState,
          goodsName,
          reportStatus,
          socketStatus,
          state,
          stationType,
          store,
          firmId,
          firmName,
          info: {
            bitrate: raw_msg ? raw_msg / 1000 + "kbps" : "0kbps",
            venc_avg: infoData ? infoData.venc_avg / 1000 + "ms" : "0ms",
            venc_last: infoData ? infoData.venc_last / 1000 + "ms" : "0ms",
            pushrtt_avg: infoData
              ? infoData.pushrtt_avg[0] / 1000 + "ms"
              : "0ms",
            pushrtt_last: infoData
              ? infoData.pushrtt_last[0] / 1000 + "ms"
              : "0ms",
            recvrtt_avg: infoData
              ? infoData.recvrtt_avg[0] / 1000 + "ms"
              : "0ms",
            recvrtt_last: infoData
              ? infoData.recvrtt_last[0] / 1000 + "ms"
              : "0ms",
            pushretrans_avg: infoData
              ? (infoData.pushretrans_avg[0] * 0.1).toFixed(2) + "%"
              : "0%",
            pushretrans_last: infoData
              ? (infoData.pushretrans_last[0] * 0.1).toFixed(2) + "%"
              : "0%",
            recvretrans_avg: infoData
              ? (infoData.recvretrans_avg[0] * 0.1).toFixed(2) + "%"
              : "0%",
            recvretrans_last: infoData
              ? (infoData.recvretrans_last[0] * 0.1).toFixed(2) + "%"
              : "0%",
            vdec: infoData ? infoData.vdec / 1000 + "ms" : "0ms",
            vbuffer: infoData ? infoData.vbuffer / 1000 + "ms" : "0ms",
          },
        };
        API.QINGLIU.qoeInform(data);
      } catch (error) {
        console.log(error, "机巢上报飞控数据");
      }
    },
    async fn(el) {
      console.log(231321);
      let { mountData, device } = this;
      // if mountStatus =1 代表启用 屏幕指点功能
      if (
        mountData.mountStatus == 1 &&
        device.deviceHardId == mountData.deviceHardId &&
        mountData.moveType == "yc" &&
        this.data.deviceName == "无人机"
      ) {
        let evt = window.event || el;
        let len = document.getElementsByClassName("player-wrapper").length;
        let div = document.getElementsByClassName("player-wrapper")[len - 2];
        console.log(evt.offsetX, div.offsetWidth, "divdivdiv");
        let data = {
          x: evt.offsetX * (1920 / div.offsetWidth) - 960,
          y: -(evt.offsetY * (1080 / div.offsetHeight)) + 540,
          w: 1920,
          h: 1080,
        };
        let buffer = await to_moveMount.gcs_click_coordinates(data);
        if (buffer) {
          this.$emit("directive", { data: buffer, ...mountData });
        }
      }
    },
    handleClose() {
      this.$emit("update:visible", !this.data.visible);
      this.$emit("close");
    },
    reset() {
      this.flag = false;
      setTimeout(() => {
        this.flag = true;
        console.log("视频已刷新zz");
      }, 1000);
    },
    screenShot() {
      let video = {};
      let scale = 1;
      video = this.$refs["live-nvr"].$el.querySelector("video"); //
      if (!video) {
        return null;
      }
      video.useCORS = true; // 解决跨域
      video.crossOrigin = "Anonymous"; // 这两个参数必填 解决跨域
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth * scale;
      canvas.height = video.videoHeight * scale;
      var img = document.createElement("img");
      img.src = canvas.toDataURL("image/png");
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height); // 将video中的数据绘制到canvas里
      // const image = canvas.toDataURL("image/png");
      const image = canvasToDataURL(canvas);
      let blob = dataURLToBlob(image);
      return blob;
    },
    // resize(newRect) {
    //   this.width = newRect.width;
    //   this.height = newRect.height;
    //   this.top = newRect.top;
    //   this.left = newRect.left;
    // },
  },
  created() {
    // 刷新视频
    // 关键字：视频刷新
    Bus.$on("flushed_result", () => {
      this.reset();
    });
  },
};
</script>

<style lang="scss" scoped>
// @media srceen and (max-width: 400px)  {
//   .iconBG {
//     position: absolute;
//     z-index: 10;
//     right: 23px;
//     display: flex;
//     bottom: 23px;
//     background: red !important;
//   }
// }

.cpt-common-float-player {
  min-height: 200px;
  width: 351px;
  box-sizing: border-box;
  position: absolute;
  z-index: 5;
  /* background-color: black; */
  /* background:url("~@/assets/images/observe/fckernel/nest/videoBg.png"); */
  background-size: contain;
  .scrollTitleStyle {
    display: inline-block;
    animation: 15s scrollTitle linear infinite;
    // min-width: 100% !important;
    position: relative;
    box-sizing: border-box;
    padding-right: 50px;
  }
  .scrollTitleTStyle {
    display: inline-block;
    top: 9px;
    white-space: nowrap;
    z-index: 12;
    animation: 15s scrollTitleStyleT linear infinite;
    min-width: 100%;
    box-sizing: border-box;
  }
  @keyframes scrollTitleStyleT {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes scrollTitle {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .iconBG {
    position: absolute;
    z-index: 10;
    right: 0px;
    display: flex;
    bottom: 12px;
    .reset {
      margin-left: 28px;
      width: 18px;
      height: 18px;
      z-index: 4;
      background: url("./img/reset.png");
      background-size: 100%;
    }
    .infop {
      width: 18px;
      height: 18px;
      z-index: 4;
      background: url("~@/assets/info.png");
      background-size: 100%;
    }
  }
  .box {
    min-height: 197px;
    min-width: 351px;
    background: url("~@/assets/images/observe/fckernel/nest/videoBg.png");
  }

  /* top: 200px;
  left: 500px; */
  &._0 {
    top: 250px;
    left: 500px;
  }

  &._1 {
    top: 200px;
    left: 872px;
  }

  &._2 {
    top: 452px;
    left: 500px;
  }

  &._3 {
    top: 452px;
    left: 872px;
  }

  // z-index: 9999;
  .title-box {
    background: rgba($color: #000000, $alpha: 0.6);
    /* height: 40px; */
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    top: 36px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.6);

    .title {
      font-family: Microsoft YaHei;
      font-size: 14px;
      color: #ffffff;
      font-weight: 400;
      // width: calc(100% - 60px);
      white-space: nowrap;
      line-height: 30px;
      overflow: hidden;
    }

    .el-icon-close {
      color: #8aabd4;
      font-size: 16px;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }

  .video-box {
    width: 351px;
    box-sizing: border-box;
  }

  .device-name {
    margin-left: 10px;
    margin-right: 15px;
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    flex-shrink: 0;
  }
}
.info {
  position: fixed;
  top: 34%;
  left: 32%;
  display: flex;
  background: rgba(9, 32, 87, 0.7);
  border-radius: 10px 10px 0 0;
  flex-direction: column;
  .title_box {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .title {
      display: flex;
      align-items: center;
      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .icon-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      .iconfont {
        font-size: 18px;
        color: #00ffff;
        margin-right: 4px;
      }

      .icon-text {
        font-family: MicrosoftYaHeiUI;
        font-size: 14px;
        color: #ccedff;
        font-weight: 400;
      }
    }
  }
  .content {
    padding: 16px;
    .de {
      margin-top: 8px;
      display: flex;
      color: #fff;
      font-size: 14px;
      .detail {
        margin-left: 8px;
      }
    }
  }
}
</style>
