<template>
  <div class="qingliu">
    <div class="cf" v-if="infoflag">bitrate:{{ raw_msg ? raw_msg/1000 : "0"}}kbps</div>
    <div ref="player_area"></div>
  </div>
</template>

<script>
import { dataURLToBlob, canvasToDataURL } from "@/utils/image-tool";

export default {
  name: "QingLiuPlayer",
  props: {
    data: Object,
    raw_msg: Number,
    infoflag: Boolean,
  },
  data() {
    return {
      name: 'QingLiuPlayer' + Date.now(),
    };
  },
  mounted() {
    console.log(this.raw_msg,"raw_msg");
    window.onresize = (e) =>{
      const clientheight =  document.documentElement.clientHeight || document.body.clientHeight
      let isFullScreen = screen.height == clientheight
      if(isFullScreen == true){
        return
      }else{
        this.$emit("close")
      }
    }
    window.qingliu = this.init;
    this.init();
  },
  beforeDestroy() {
    window.kbt_player_destroy(this.name);
  },
  methods: {
    /**
     * 初始化
     */
    init() {
      window.kbt_sdk_load().then( () => {
        let parentObj = this.$refs.player_area;
        window.kbt_player_create(
          this.name,
          this.data.vUrl,
          970,
          545,
          parentObj
        );
      });
    },
    screenShot(){
      var canvas = document.querySelector("#sdk_canvas_" + this.name);
      const image = canvasToDataURL(canvas);
      let blob = dataURLToBlob(image);
      return blob
    },
    fullScreen(){
      // var canvas = document.querySelector("#sdk_canvas_" + this.name);
      var canvas = document.querySelector(".qingliu");
      canvas.requestFullscreen();
    }
  },
};
</script>

<style scoped lang='scss'>
.qingliu {
  .player_area{
    width: 100%;
    height: 100%;
  }
  .cf{
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px;
    color: #fff;
    background: rgba($color: #000000, $alpha: 0.6);
  }
  // width: 100%;
  // height: 100%;
  /deep/canvas {
    width: 100% !important;
  }
}
</style>