<template>
  <div>
    <FloatPlayer @close="$emit('close')" type="3" :data="data" />
  </div>
</template>

<script>
  import FloatPlayer from "@/components/common/float-player";
  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    components: { FloatPlayer },
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .cpt-common-float-player {
      top: calc(50% - 111px);
    }
  }
</style>