<template>
  <div class="nest_header_box jcsb">
    <div class="left">
      <div class="title" @click="goHome">
        <img alt="" src="~@/assets/img/observe-shi-logo.png" />
        <span>飞行监控</span>
      </div>
      <div class="menu" v-if="false">
        <ul class="ul">
          <template v-for="item in menuData">
            <li class="tap jin" :key="item.id" v-if="item.id == 1">
              <span>{{ item.title + "" }}</span>
            </li>
            <li class="tap" :key="item.id" @click="changeItem" v-else-if="item.id == 2">
              <span>{{ item.title }}</span>
            </li>
            <li class="tap" :key="item.id" @click="changeBtn" v-else>
              <span>{{ item.title }}</span>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div class="right">
      <tianqi class="tianqi"></tianqi>
    </div>
  </div>
</template>

<script>
import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";
import tianqi from "@/pages/observe/home/components/header/tianqi";
import dayjs from "dayjs";
// 引用bus
import Bus from "@/assets/ligature.js";
import { mapGetters } from "vuex";
import API from "@/api";
export default {
  props: {
    uavBattery: {
      type: Object,
    },
    level: {
      type: Number,
    },
    nestData: {
      type: Object,
    },

    uavData: {
      type: Object,
      default: () => ({}),
    },
    isSelectFlag: {
      type: Boolean,
      default: () => true,
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    wsShow: {
      type: Boolean,
      default: () => {
        false;
      },
    },
  },
  data () {
    return {
      openshow: false, //强制打开降落伞二次确认框
      stta: null,
      menuData: [
        { id: 1, title: "无人机应用", url: "/fckernel?page=1" },
        { id: 2, title: "机巢应用", url: "/fckernel?page=2" },
        { id: 3, title: "视频墙", url: "/fckernelVideos" },
      ],
      modelData: null,
      mode: "离线状态",
      modelType: null,
      typeNet: 0,
      weatherIcon: null,
      now: null,
      curr_val: 0,
      dialog_type: "jls",
      rcChannelStateList: {
        0: "摇杆中位",
        "-98": "摇杆外场权限",
        "-99": "摇杆未连接",
        1: "俯仰偏前",
        "-1": "俯仰偏后",
        "-2": "横滚偏左",
        2: "横滚偏右",
        3: "油门偏高",
        "-3": "油门偏低",
        4: "航向偏右",
        "-4": "航向偏左",
      },
      parachuteList_: [
        {
          icon: "jianglasanweiqiyong",
          title: "未启用",
        },
        {
          icon: "tuichujingjie",
          title: "正常",
        },
        {},
        {
          icon: "jingjie",
          title: "降落伞使能",
        },
        {},
        {},
        {},
        {
          icon: "kaisan",
          title: "降落伞开伞",
        },
      ],
      parachuteStateList: [
        {
          icon: "tuichujingjie",
          title: "降落伞进入警戒",
        },
        {
          icon: "jingjie",
          title: "降落伞进入警戒",
        },
        {
          icon: "tuichujingjie",
          title: "降落伞退出警戒",
        },
        {
          icon: "ksan",
          title: "降落伞开伞",
        },
      ],

      parachuteList: [
        {
          icon: "jingjie",
          title: "正常",
        },
        {
          icon: "jianglasanweiqiyong",
          title: "未启用",
        },
        {
          icon: "weizaixian",
          title: "未在线",
        },
        {
          icon: "sanbaoweilianjie",
          title: "伞包未连接",
        },
        {
          icon: "jianglasanguzhang",
          title: "降落伞故障",
        },
      ],
      remote: {
        0: "yaokongqi-duankailianjie",
        1: "yaokongqiliang",
      },
      week_map: {
        0: "天",
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
      },
      fire: {
        0: "yanganan",
        1: "yanganhong",
        2: "yanganliang",
      },
      water: {
        0: "shuijinan",
        1: "shuijinhong",
        2: "sfhuijinliang",
      },
      power: {
        1: "dianliangbaifenzhiershi",
        2: "dianliangbaifenzhisishi",
        3: "dianliangbaifenzhiliushi",
        4: "dianliangbaifenzhibashi",
        5: "chongdianmoshi5",
      },
      weather: {},
      weather_data: {
        weather: "晴",
        icon: require(`@/assets/images/weather/icon/day/01.png`),
        temperature: 0,
        humidity: 0,
        windPower: "",
        windSpeed: "",
        minTemp: "",
        maxTemp: "",
        windDirection: "东北",
      },
      // <el-option label="1.4G" :value="2"> </el-option>
      //     <!-- 公网  -->
      //     <el-option label="4G/5G" :value="3"> </el-option>
      //     <el-option label="自动" :value="0"> </el-option>
      ModeStatusList: [
        { id: 2, data: "1.4G", label: "专网" },
        { id: 3, data: "4G/5G", label: "公网" },
        { id: 0, data: "自动", label: "自动" },
      ],
      zwArr: [
        {
          // icon: "xinhaoshaixuan",
          icon: "a-14-4beifen21",
          info: "基站专网信号数据断开",
        },
        {
          icon: "a-14-6",
          info: "覆盖非常好",
        },
        {
          icon: "a-14-5",
          info: "覆盖好,室外能够发起各种业务,可获得高等数据业务,室内能够发起各种业务，可获得中等数据业务",
        },
        {
          icon: "a-14-4",
          info: "覆盖较好,室外能够发起各种业务,可获得中等数据业务,室内能够发起各种业务，可获得低数据业务",
        },
        {
          icon: "a-14-3",
          info: "覆盖一般，室外能够发起各种业务，可获得中等数据业务,室内呼叫成功率低",
        },
        {
          icon: "a-14-2",
          info: "覆盖率差，户外语音业务能够起呼，但成功率较低",
        },
        {
          icon: "a-14-1",
          info: "覆盖较差，业务基本无法起呼",
        },
      ],
      gwArr: [
        {
          // icon: "xinhaoshaixuan",
          icon: "a-bianzu79beifen",
          info: "基站公网信号数据断开",
        },
        {
          icon: "a-5g-6",
          info: "覆盖非常好",
        },
        {
          icon: "a-5g-5",
          info: "覆盖好,室外能够发起各种业务,可获得高等数据业务,室内能够发起各种业务，可获得中等数据业务",
        },
        {
          icon: "a-5g-4",
          info: "覆盖较好,室外能够发起各种业务,可获得中等数据业务,室内能够发起各种业务，可获得低数据业务",
        },
        {
          icon: "a-5g-3",
          info: "覆盖一般，室外能够发起各种业务，可获得中等数据业务,室内呼叫成功率低",
        },
        {
          icon: "a-5g-2",
          info: "覆盖率差，户外语音业务能够起呼，但成功率较低",
        },
        {
          icon: "a-5g-1",
          info: "覆盖较差，业务基本无法起呼",
        },
      ],
      modeList: [
        { id: 1, data: "MANUAL", label: "手动模式", disabled: true },
        { id: 2, data: "ALTITUDE", label: "定高模式", disabled: false },
        { id: 3, data: "POSITION", label: "定点模式", disabled: false },
        { id: 4, data: "AUTO_MISSION", label: "航线模式", disabled: false },
        { id: 5, data: "ACRO", label: "特技模式", disabled: true },
        { id: 6, data: "OFF_BOARD", label: "版外模式", disabled: true },
        { id: 7, data: "STABILIZED", label: "自稳模式", disabled: true },
        { id: 8, data: "HOLD", label: "保持模式", disabled: false },
        { id: 9, data: "LAND", label: "降落模式", disabled: true },
        { id: 10, data: "TAKE_OFF", label: "起飞模式", disabled: true },
        { id: 11, data: "AUTO_RTL", label: "返航模式", disabled: false },
        {
          id: 12,
          data: "AUTO_FOLLOW_TARGET",
          label: "自动跟随模式",
          disabled: true,
        },
      ],
      modeListws: [
        { id: 1, data: "11", label: "安全降落", disabled: false },
        { id: 2, data: "12", label: "返航模式", disabled: false },
        { id: 3, data: "13", label: "航线模式", disabled: false },
        { id: 4, data: "17", label: "gps模式", disabled: false },
      ],
      flightModews: "",
      signal: {
        1: "GPS1",
        2: "GPS2",
        3: "GPS3",
        4: "GPS4",
        5: "GPS5",
      },
      typeNeticon: "xinhaoshaixuan",
      isSelect: true,
    };
  },
  watch: {
    value: {
      handler (value) {
        this.curr_val = value;
      },
      deep: true,
      immediate: true,
    },
    uavData: function (val, old) {
      if (val && this.wsShow) {
        this.flightModews = val.flightMode;
      }
    },

    isSelectFlag: function (newValue) {
      this.isSelect = newValue;
    },
  },
  computed: {
    ...mapGetters(["user_info"]),
    obstacle () {
      return this.$store.state.fckernel.obstacle;
    },
    rcChannelState () {
      let { uavData } = this;
      return uavData.rcChannelState;
    },
    rcState () {
      let { uavData } = this;
      // 监听内外场的值
      this.$store.commit(
        "shoudong/tai",
        uavData && uavData.rcState ? uavData.rcState : 0
      );
      return uavData && uavData.rcState ? uavData.rcState : 0;
    },
    parachute () {
      let { uavData } = this;
      return uavData ? uavData.parachute : 3;
    },
    _parachute () {
      let { uavData } = this;
      return uavData ? uavData._parachute : 0;
    },
    parachuteState () {
      let { uavData } = this;
      return uavData ? uavData.parachuteState : 2;
    },

    gwSqe45 () {
      let { uavData } = this;
      if (uavData && uavData.signal && uavData.signal.sqe45) {
        if (uavData.signal.sqe45 === -1) {
          return 0;
        }
        return uavData.signal.sqe45;
      }
      return 6;
    },
    zwSqe14 () {
      let { uavData } = this;
      if (uavData && uavData.signal && uavData.signal.sqe14) {
        if (uavData.signal.sqe14 === -1) {
          return 0;
        }
        return uavData.signal.sqe14;
      }
      return 6;
    },
    FlightMode () {
      let { uavData, modeList } = this;
      let flightMode = modeList.find((e) => e.data == uavData?.flightMode);
      // 用vuex将当前的模式存起来 飞控 无人机列表
      this.$store.commit("shoudong/compose_mode", flightMode?.label);
      return flightMode?.label || "离线状态";
    },
    ModeStatus () {
      let { uavData } = this;
      let name = null;
      if (uavData && uavData.link) {
        if (uavData?.link[0].priority == 2) {
          if (uavData?.link[0].using) {
            name = "专网";
          } else {
            name = "公网";
          }
        } else if (uavData?.link[0].priority == 3) {
          if (uavData?.link[0].using) {
            name = "公网";
          } else {
            name = "专网";
          }
        }
      }
      return name || "离线状态";
    },
    priority () {
      let { uavData } = this;
      let name = "离线";
      if (uavData && uavData.priority == 0) {
        name = "自动";
      } else if (uavData && uavData.priority == 1) {
        name = "图传";
      } else if (uavData && uavData.priority == 2) {
        name = "专网";
      } else if (uavData && uavData.priority == 3) {
        name = "公网";
      }
      return name;
    },
    fire_state () {
      let { nestData } = this;
      return nestData?.fireWarn ? nestData.fireWarn : 0;
    },

    FIX () {
      let { uavData } = this;
      return (uavData && uavData.isRtkEnable == true) || 0;
    },
    water_state () {
      let { nestData } = this;
      return nestData?.waterWarn ? nestData.waterWarn : 0;
    },
    is_charger () {
      let { nestData } = this;
      return nestData && nestData.chargerPower == 1;
    },
    power_state () {
      let { uavBattery } = this;
      if (uavBattery && uavBattery.chargeRemaining) {
        return parseInt(uavBattery && uavBattery.chargeRemaining / 20 + 1);
      } else {
        return 1;
      }
    },
    remote_state () {
      let { uavData } = this;

      return uavData ? (uavData.isRcConnecting ? 1 : 0) : 0;
    },
    voltage () {
      let { uavBattery } = this;
      return uavBattery ? uavBattery.voltage || 0 : 0;
    },
    power_num () {
      let { uavBattery } = this;
      return uavBattery ? uavBattery.chargeRemaining : 0;
    },
    date () {
      let { now } = this;
      return now ? `${now.format("YYYY/MM/DD")}` : "0000/00/00";
    },
    week () {
      let { now, week_map } = this;
      return now ? `周${week_map[now.day()]}` : "周N/A";
    },
    time () {
      let { now } = this;
      return now ? `${now.format("HH:mm")}` : "00:00";
    },
    satelliteCount () {
      let { uavData } = this;
      return uavData?.gps?.satelliteCount || 0;
    },
    signal_state () {
      let { uavData } = this;
      if (uavData && uavData.satelliteCount) {
        let count = parseInt(uavData.satelliteCount / 6);
        if (count >= 5) {
          count = 5;
        } else {
          count += 1;
        }
        return uavData.satelliteCount ? count : 1;
      }
      return 5;
    },
  },
  components: { SymbolIcon, tianqi },
  methods: {
    // 跳转到首页
    goHome () {
      // this.$router.push('/observe/home');
      // this.$router.push('/home');

      let { href } = this.$router.resolve({ path: "/home" });
      window.open(href, "_self");
      setTimeout(() => {
        this.$router.go(0);
      }, 100);
    },
    //强制打开降落伞
    async open () {
      let flag = await this.checkUseOperateFn(this.device.deviceHardId);
      if (!flag) return false;
      this.$emit("fn", { type: 533 });
      this.openshow = false;
    },
    async getWeather () {
      let organId = { organId: this.user_info["departmentId"] };
      let res = await API.WEATHER.getNewWeather(organId);
      if (res.length > 0) {
        this.weather_data = res[0];
      }
      // console.log(this.weatherList,'this.weatherListthis.weatherList');
      const date = new Date();
      let time = date.getHours();
      this.weatherIcon = require(`@/assets/images/weather/icon/iconfont/W${time >= 18 ? this.weather_data.nightIcon : this.weather_data.dayIcon
        }.png`);
    },
    async checkUseOperateFn (device) {
      // 查看是否有控制权限
      let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device });
      console.log(res);
      if (res.code == 201) {
        this.$message.warning(res.msg);
        return false;
      } else {
        return true;
      }
    },
    toVideos () {
      window.open(`#/videos`, "_blank");
    },
    // flag判断是当前模块触发的还是其他页面是触发的 1代表其它触发
    async handle_change_uav_mode (data, flag) {
      console.log(this.rcChannelState, "this.rcChannelState");
      // 查看是否有权限接管无人机
      let res = await this.checkUseOperateFn(this.device.deviceHardId);
      // 判断当前状态 true为一接管
      if (res == false) {
        return;
      }
      let mode = this.modeList.find((item) => item.data === data);
      this.modelData = data;
      if (
        mode.label == "定高模式" ||
        mode.label == "定点模式" ||
        mode.label == "保持模式"
      ) {
        if (
          (this.rcChannelState !== 0 && flag !== 1) ||
          (this.rcChannelState !== "-99" && flag !== 1)
        ) {
          this.modelType = mode.label;
          this.dialog_type = "model";
          this.openshow = true;
        } else {
          this.model_qr(data);
        }
      } else {
        this.model_qr(data);
      }
    },
    model_qr (data) {
      let mode = this.modeList.find((item) => item.data === data);
      if (mode) {
        this.$emit("fn", { type: 513, data: mode.data });
        this.$message.success("操作成功");
      }
      if (mode.data == "AUTO_MISSION") {
        this.$emit("auto");
      }
      this.openshow = false;
    },
    handle_change_uavws_mode (item) {
      this.$emit("control_ws", item);
    },
    //视频墙
    changeBtn () {
      // this.$emit('changeF')
      // 飞控选择其他模块时，关闭航线
      // 关键字：关闭预设航线
      Bus.$emit("uav_Route");
      // 删除飞控 无人机 模型和航线
      // 搜索关键字：删除飞控模型
      Bus.$emit("remove_model");
      this.$router.push("/fckernelVideos");
    },
    handle_uav_mode (id) {
      let { uavData } = this;
      if (id == 0) {
        this.$emit("fn", { type: 770, data: id });
        return this.$message.success("改为自动切换模式");
      } else if (id == 2) {
        // 专网
        if (uavData?.link.length > 0) {
          let item = uavData?.link.filter((item) => item.priority == 2);
          if (item.length > 0) {
            this.$emit("fn", { type: 770, data: id });
          } else {
            return this.$message.warning("暂无专网环境");
          }
        } else {
          return this.$message.warning("暂未链接无人机");
        }
      } else if (id == 3) {
        // 公网
        if (uavData?.link.length > 0) {
          let item = uavData?.link.filter((item) => item.priority == 3);
          if (item.length > 0) {
            this.$emit("fn", { type: 770, data: id });
          } else {
            return this.$message.warning("暂无公网环境");
          }
        } else {
          return this.$message.warning("暂未链接无人机");
        }
      }
    },
    //机巢应用
    changeItem () {
      this.$emit("changeF");
      // 飞控选择其他模块时，关闭航线
      // 关键字：关闭预设航线
      Bus.$emit("uav_Route");
      // 删除飞控 无人机 模型和航线
      // 搜索关键字：删除飞控模型
      Bus.$emit("remove_model");
      // this.isSelect = !this.isSelect;
      // this.$forceUpdate();
      // this.$emit("nest_chenge", this.isSelect);
    },
    onchange (val) { },
  },
  created () {
    this.now = dayjs();
    // this.getNestWeatherFn()
    // 飞控 无人机 自动=>航线模式
    // 飞控 无人机 手动=>定点模式
    // 关键字：无人机模式修正
    Bus.$on("uavState", (data) => {
      this.handle_change_uav_mode(data, 1);
    });
    // 无人机列表返航模式
    // 关键字：无人机返航
    Bus.$on("uavFan", (data) => {
      this.handle_change_uav_mode(data, 1);
    });
  },
  inject: ["g_weather"],
};
</script>

<style lang="scss" scoped>
.Outside {
  background: url("~@/assets/images/Outside.svg");
  background-repeat: no-repeat;
}

.within {
  background: url("~@/assets/images/within.svg");
  background-repeat: no-repeat;
}

.nest_header_box {
  z-index: 1;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 64px;
  width: 100%;
  // height: 68px;
  background: #0E3042;
  // background: center url("~@/assets/img/head_bg.png");
  background-size: 100%;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-left: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      margin-top: -10px;

      img {
        width: 63px;
        height: 58px;
        margin-right: 10px;
      }

      span {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
    }

    .menu {
      margin-left: 150px;

      ul {
        list-style: none;
        display: flex;
        height: 100%;
        vertical-align: top;

        .tap {
          width: 185px;
          height: 64px;
          position: relative;
          text-align: center;
          // margin: -8px 0 0 0;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: row;
  }

  .tap {
    background-image: url("~@/assets/img/head-menu-bg-nomal.png");
    background-size: 100% 100%;
    cursor: pointer;
  }

  .tap:hover {
    background-image: url("~@/assets/img/head-menu-bg-select.png");
    background-size: 100% 100%;
  }

  .jin {
    background-image: url("~@/assets/img/head-menu-bg-select.png");
    background-size: 100% 100%;
  }

  .gohome {
    left: 50%;
    transform: translateX(-50%);
  }

  .ZW {
    width: 25px;
    height: 25px;
    background: url("~@/assets/images/observe/fckernel/nest/ZW.svg") no-repeat;
    background-size: 100% 100%;
  }

  .GW {
    width: 25px;
    height: 25px;
    background: center url("~@/assets/images/observe/fckernel/nest/GW.svg") no-repeat;
    background-size: 100% 100%;
  }

  .nest_header_box_right {
    height: 62px;
    min-width: 590px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .changeBtn {
      position: absolute;
      top: 5px;
      right: calc(50% - 350px);
      width: 150px;
      height: 50px;
      background: center url("~@/assets/images/observe/fckernel/nest/right.png");
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #70f6f9;
    }

    .input_group {
      .symbol-icon-box {
        display: flex;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      display: flex;
      align-items: center;

      /deep/.el-select {
        width: 80px;
        color: #fff;
        border: none;
        background: transparent;

        .el-input--suffix {
          display: flex;
        }

        .el-input__inner {
          position: relative;
          right: -8px;
          width: 50px;
          padding: 0;
          border: none;
          color: #fff;
          background: transparent;
        }

        .el-input__suffix {
          position: relative;
        }
      }
    }

    .jgjl {
      background: url("~@/assets/images/observe/fckernel/nest/jgjl.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .changeBtn:hover {
      background: center url("~@/assets/images/observe/fckernel/nest/rightD.png");
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isSelect {
      background: center url("~@/assets/images/observe/fckernel/nest/btnActiveRight.png");
      background-size: contain;
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }
  }

  .nest_header_box_left {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 60px;
    padding-left: 117px;

    .changeBtn {
      position: absolute;
      top: 5px;
      left: calc(50% - 350px);
      width: 150px;
      height: 50px;
      background: center url("~@/assets/images/observe/fckernel/nest/leftD.png");
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-size: 14px;
      color: #70f6f9;
      letter-spacing: 0;
    }

    .changeBtn:hover {
      background: center url("~@/assets/images/observe/fckernel/nest/left.png");
      // background-size: contain;
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isSelect {
      background: center url("~@/assets/images/observe/fckernel/nest/btnActive.png");
      background-size: contain;
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isFlight {
      font-family: YouSheBiaoTiHei;
      font-size: 28px;
      color: #6aff64;
      letter-spacing: 0;
      font-weight: 400;
    }

    .noFlight {
      font-family: YouSheBiaoTiHei;
      font-size: 28px;
      color: #ffc15b;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  .nav-list {
    width: 590px;
    height: 77px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;

    .nav-item {
      width: 223px;
      height: 77px;
      box-sizing: border-box;
      background: center url("~@/assets/images/observe/fckernel/nav-item.png") no-repeat;
      background-size: cover;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;

      &:hover,
      &.selected {
        background: center url("~@/assets/images/observe/fckernel/nav-item-selected.png") no-repeat;

        .text-box {
          color: #3df1ff;
        }
      }

      .text-box {
        line-height: 77px;
        font-family: Microsoft YaHei Bold;
        font-size: 20px;
        color: #aab8d1;
        font-weight: 700;
        transition: 0.3s;
      }

      &._2 {
        transform: scaleX(-1);

        .text-box {
          transform: scaleX(-1);
        }
      }
    }
  }

  .date-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 20px;
    top: 0;
    height: 60px;
    box-sizing: border-box;

    .yyyy {
      font-family: Microsoft YaHei;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      margin-bottom: 3px;
    }

    .week-box {
      display: flex;
      justify-content: space-between;

      .week {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }

      .hhmm {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.mode_nameBox {
  box-sizing: border-box;
  width: 116px;
  height: 28px;
  background: url("~@/assets/images/observe/fckernel/nest/select.png") no-repeat;
  background-size: contain;
  margin-right: 5px;
  padding-left: 15px;
  display: flex;
  align-items: center;

  .mode_name {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    color: #3df1ff;
    margin: 0 6px 0 0;
  }
}

.el-dropdown-menu {
  background: rgba(9, 32, 87, 0.5) !important;

  .el-dropdown-menu__item {}
}

.noStyle {
  opacity: 0.3;
}

.el-dropdown {
  color: #3df1ff;
  font-weight: 400;
  font-size: 14px;
  margin-top: 2px;

  .el-icon-caret-bottom {
    color: #fff !important;
    line-height: 24px;
  }
}

.nones {
  opacity: 0;
}

.right {
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
}

.line {
  width: 1px;
  height: 32px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 47%,
      rgba(255, 255, 255, 0) 100%);
}

// 弹框样式
.endrenwu {
  width: 100%;
  height: 177px;
  border: 1px solid #70daf9 !important;
  background: rgba(9, 32, 87, 0.7) !important;
}

.queding {
  color: #92d9ff;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 30px 0 0 0;
}

.tishiyu {
  color: #92d9ff;
  font-size: 18px;
  margin: 30px 0 0 0;
  text-align: center;
  width: 100%;
}

.btn_kuang {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  width: 79px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  margin: 20px 0 0 0;
  cursor: pointer;
}

.btn_lan {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #606266;
}

.btn_lv {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #fff;
}

/deep/ .el-dialog__header {
  margin-left: 0;
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
  z-index: 2023 !important;
}

/deep/ .el-dialog {
  z-index: 2022 !important;
  background: transparent !important;
}
</style>
