import API from "@/api";

let location_icon = null;
let locations = [];
export default {
  init_params() {
    this.nest.data = null;
    this.nest.weather = null;
    this.nest.video = null;
    this.nest.msg_list = [];
    location_icon = null;
  },
  async list() {
    try {
      let data = await API.NEST.getNestList();

      function each(tree, data) {
        return tree.map((item) => ({
          ...item,
          offLineCount: data.offLineCount,
          onLineCount: data.onLineCount,
          collapse: false,
          child_collapse: false,
          child: item.child && item.child.length && each(item.child),
        }));
      }
      if (data.tree && data.tree.length) {
        data = each(data.tree, data);
      }
      this.nest.list = data || [];
    } catch (error) {}
  },

  change(data, item) {
    this.uav_video_init(item);
    if (data) {
      this.nest.msg_list = [];
      this.nest.data = item;
      this.nest_location(data);
      // 清除一键任务航线
      this.changeBtn(true);
      this.flag = false;
      this.VideoTomapFlag = true;
      this.loggerFlag = true;
      this.powerFlag = true;
      this.isAirwayEdit = false;
      // 打开 任务面板
      this.nest.controlMenuFlag = true;
      sessionStorage.removeItem("waypointList");
    } else {
      this.changeBtn(false);
      this.nest.controlMenuFlag = false;
      this.loggerFlag = false;
      this.CreateTaskFlag = false;
      this.VideoTomapFlag = false;
      this.flag = true;
      this.nest.data = null;
    }
  },
  location(device) {
    if (device) {
      this.uav.mounts = [];
    }
    if (device.latitude && device.longitude) {
      let viewer = window.viewer;
      if (locations) {
        viewer.entities.remove(locations);
      }
      let f = viewer.entities.add({
        position: Cesium.Cartesian3.fromDegrees(
          Number(device.longitude),
          Number(device.latitude)
        ),
        billboard: {
          image: require("@/assets/images/icons/ycaddress.png"),
          width: 30,
          height: 40,
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
        },
      });
      locations = f;
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          Number(device.longitude),
          Number(device.latitude),
          30000
        ),
        maximumHeight: 10,
      });
    } else {
      // let viewer = window.viewer
      // locations.forEach((item, index) => viewer.entities.remove(item))
      this.$el_message("该机巢没有经纬度", () => {}, "warning");
    }
  },
};
