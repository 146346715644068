export default {

  columns: [
    {
      title: "航线ID",
      prop: "id"
    },
    {
      title: "航线名称",
      prop: "flightName"
    },
    {
      title: "航点数量(个)",
      prop: "pointCount"
    },
    {
      title: "航线长度(m)",
      prop: "distance"
    },
    {
      title: "预计时长(s)",
      prop: "time"
    },
    {
      title: "创建时间",
      prop: "addTime"
    },
    {
      title: "空域状态",
      prop: "statuscss"
    },
    {
      title: "航线标签",
      prop: "labelName"
    },
    {
      title: "操作",
      slot: "operate"
    },
  ],
  pagination: {
    flightName: "",
    type: null,
    isSafe: null,
    sourceType: null,
    labelName: "",
    pageNo: 1,
    pageSize: 10,
    totalCount: 0,
  },
  list: [],
  add: {
    visible: false
  },
  kmlFileList: [],
  kmlFile:null, //只支持单个文件上传
}