<template>
  <div class="content">
    <div class="content_head" v-interact>
      <div class="left">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="font">人脸识别</div>
      </div>
      <div class="right" @click="() => $emit('closeface')">关闭</div>
    </div>
    <div class="content_box">
      <div class="face-item" v-for="item in list" :key="item.name">
        <div
          class="avatar xiangsi"
          :class="item.similarity >= 80 ? 'cheng' : ''"
        >
          <div class="avatar-box left">
            <img :src="baseUrl + item.recordImageUrl" />
            <!-- <img :src="item.snapImageUrl" /> -->
          </div>
          <div class="compare-box" @click="infoFn(item)">
            <div class="value">{{ Number(item.similarity).toFixed(2) }}%</div>
            <div class="label cp">相似度</div>
          </div>
          <div class="avatar-box right">
            <img :src="baseUrl + item.snapImageUrl" />
          </div>
        </div>
        <div class="detail" v-if="item.show">
          <div class="image">
            <div class="left">
              <img :src="baseUrl + item.recordImageUrl" alt="" />
              <div class="tip">比对照片</div>
            </div>
            <div class="right">
              <img :src="baseUrl + item.snapImageUrl" alt="" />
              <div class="tip">抓拍照片</div>
            </div>
          </div>
          <div class="ds">
            <div class="row">
              <div class="title">姓名：</div>
              <div class="props">{{ item.userName || '暂无' }}</div>
            </div>
            <div class="row">
              <div class="title">身份证号：</div>
              <div class="props">{{ item.idCard || '暂无' }}</div>
            </div>
            <div class="row">
              <div class="title">出生年份：</div>
              <div class="props">{{ item.birthday || '暂无' }}</div>
            </div>
            <div class="row">
              <div class="title">发现时间：</div>
              <div class="props">{{ item.occurTime || '暂无' }}</div>
            </div>
            <div class="row">
              <div class="title">发现地点：</div>
              <div class="props">{{ item.addr || item.address  || '暂无' }}</div>
            </div>
            <div class="row">
              <div class="title">AI识别：</div>
              <div class="props border">{{ item.labels && item.labels.length > 0 ? item.labels[0] : '暂无' }}</div>
            </div>
          </div>
        </div>
        <div class="down"  @click="item.show = !item.show">
          <img v-if="!item.show" src="~@/assets/newImage/down.png" alt="" />
          <img v-else src="~@/assets/newImage/up.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import data from "./data";
export default {
  props:['uavId'],
  data() {
    return {
      ...data,
      baseUrl: process.env.VUE_APP_IMG_URL
    };
  },
  methods:{
    async get_face_list () {
      if(!this.uavId){
        this.$message.error('请选择无人机')
        return
      }
      let data = {
        uavId: this.uavId,
      };
      let res = await API.AIUSE.getFaceuavvideoMsg(data);
      for(let i = 0; i < res.length; i++){
        let item = res[i];
        item.show = true
        if(Number(item.longi) && Number(item.lati)){
          let address = await API.MAP.AiRegeo({
            location: `${item.longi},${item.lati}`
          })
          item.addr = address.province.value + address.city.value + address.dist.value + address.road.roadname + address.poi;
        }
      }
      this.list = res || [];
    },
  },
  mounted(){
    this.get_face_list()
  }
};
</script>

<style lang="scss" scoped>
.content {
  z-index: 101;
  position: fixed;
  top: calc(50% - 120px);
  left: calc(50% + 80px);
  width: 468px;
  max-height: 635px;
  margin-bottom: 190px;
  background: rgba(0, 39, 121, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
    inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
    inset 0px 0px 8px 0px #019aff;
  backdrop-filter: blur(2px);
    border-radius: 10px 10px 0px 0px;
  .content_head {
    display: flex;
    justify-content: space-between;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    align-items: center;
    padding: 0 16px;
    .left {
      display: flex;
      align-items: center;
      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .right {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  .content_box {
    width: 436px;
    max-height: 600px;
    padding: 0 16px;
    overflow: auto;
    .face-item {
      position: relative;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      .avatar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 130px;
        position: relative;
        margin-bottom: 15px;
        .avatar-box {
          width: 130px;
          height: 130px;
          // box-sizing: border-box;
          // display: flex;
          // align-items: center;
          img {
            width: 88px;
            height: 88px;
            margin-top: 21px;
          }
          &.left {
            margin-left: 7px;
          }
          &.right {
            display: flex;
            justify-content: flex-end;
            margin-right: 6px;
          }
        }
        .compare-box {
          width: 74px;
          height: 74px;
          border-radius: 74px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          cursor: pointer;
          .value {
            font-size: 24px;
            font-family: UniDreamLED;
            color: #ffffff;
            line-height: 28px;
          }
          .label {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #c7ebff;
            line-height: 21px;
            letter-spacing: 1px;
          }
        }
      }
      .detail {
        width: 404px;
        height: 379px;
        padding: 16px;
        .image {
          height: 150px;
          display: flex;
          justify-content: space-between;
          .left,
          .right {
            position: relative;
            width: 198px;
            height: 150px;
            img {
              width: 198px;
              height: 150px;
            }
            .tip {
              position: absolute;
              bottom: 0;
              width: 198px;
              height: 27px;
              background: #0c1120;
              opacity: 0.8;
              font-size: 14px;
              font-family: MicrosoftYaHeiUI;
              color: #aee9ff;
              line-height: 27px;
              text-align: center;
            }
          }
        }
        .ds {
          .row {
            margin-top: 16px;
            display: flex;
            .title {
              white-space: nowrap;
              width: 70px;
              font-size: 14px;
              font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
              font-weight: bold;
              color: #ffffff;
              line-height: 18px;
            }
            .props {
              // position: absolute;
              margin-left: 30px;
              font-size: 16px;
              font-family: MicrosoftYaHeiUI;
              color: #aee9ff;
              line-height: 20px;
            }
            .border {
              width: 70px;
              height: 23px;
              border-radius: 8px;
              border: 1px solid #ff4a4a;
              font-size: 14px;
              font-family: MicrosoftYaHeiUI;
              color: #ff4a4a;
              line-height: 23px;
              text-align: center;
            }
          }
        }
      }
      .down {
        width: 436px;
        height: 20px;
        box-shadow: inset 0px 0px 12px 0px #019aff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        .icon {
        }
      }
    }
  }
}
.xiangsi {
  background-image: url("~@/assets/newImage/lan.png");
  background-size: 100% 100%;
}
.cheng {
  background-image: url("~@/assets/newImage/cheng.png");
  background-size: 100% 100%;
}
</style>