<!-- 
  芜湖消防---无人机飞行监控
 -->
<template>
  <div class="page-observe-fckernel-uav">
    <!-- 顶部标题栏 -->
    <div class="header">
      <span>{{ taskDetailData.taskName }}</span>
      <span class="header-hx-btn" :class="[!hxAllShowFlag ? 'header-hx-btn-unselect' : '']" @click="handleHXAllShow">{{ !hxAllShowFlag ? '一键开启航线' : '一键关闭航线'
        }}</span>
    </div>
    <!-- 卡片区域 -->
    <div class="card-list">
      <div class="card" v-for="(item, index) in subTaskListCopy" :key="index" @click="whxf_handleCardClick(item, index)"
        :class="whxf.subTaskSelectedIndex == index ? 'card-selected' : ''">
        <div class="title" :style="{ color: statusColor[getTaskStatus(item)] }">
          <div :style="{ backgroundColor: statusColor[getTaskStatus(item)] }" style="width: 4px; height: 16px;"></div>
          <span>{{ getTaskStatus(item) }}</span>
        </div>
        <div class="card-t">
          <!-- 无人机的信息 -->
          <div class="card-uav card-uav-info">
            <div class="left">
              <img v-if="item.reportStatus == 0" src="@/assets/img/i174.png" alt="" style="width: 27px; height: 27px;">
              <img v-else src="@/assets/img/i165.png" alt="" style="width: 27px; height: 27px;">
              <div class="n">
                <span class="n-name">
                  <span>{{ whxf_getDeviceNameByDeviceHardId(item.deviceHardId) }}</span>
                  <span> ({{ item.goodsName }}) </span>
                </span>
                <span v-if="item.taskStatus == 0 || item.taskStatus == 3" class="n-status"
                  :class="[(item.reportStatus == 0) ? 'status-offline' : 'status-online']">
                  {{ (item.reportStatus == 0) ? '(离线)' : '(在线)' }}
                </span>
              </div>
            </div>
            <div class="right">
              <img src="@/assets/img/i169.png" alt="" style="width: 27px; height: 27px; cursor: pointer" @click.stop="whxf_uavForceTakeOver">
              <img v-if="!item.uavLock" src="@/assets/img/i172.png" alt="" style="width: 22px; height: 22px; cursor: pointer"
                @click.stop="whxf_handleUavUnLock(item, index)">
              <img v-else src="@/assets/img/i173.png" alt="" style="width: 22px; height: 22px; cursor: pointer" @click.stop="whxf_handleUavUnLock(item, index)">
            </div>
          </div>
          <div class="card-uav card-uav-header">
            <div class="left">
              <span>子任务编号：</span>
              <span>{{ item.taskTitle }} </span>
              <!-- <el-tooltip class="item" effect="dark" :content="item.taskTitle" placement="top">
                <span>{{ item.taskTitle }} </span>
              </el-tooltip> -->
            </div>
            <div class="right">
              <!-- <el-button v-if="item.taskStatus == 0" class="btn btn-wj" @click.stop="handleSubTaskExecute(item)">立即执行</el-button> -->
              <span v-if="item.taskStatus == 3" class="btn btn-wj" @click.stop="(e) => handleSubTaskFinish(item, e)">完结</span>
              <span class="btn btn-hx whxf-xtst-btn-hx" @click.stop="(e) => handleHXShow(item, e)">航线</span>
              <el-button v-if="false" class="btn btn-bh" @click.stop="handleHJShow(item)">航迹</el-button>
              <!-- <div class="f" style="padding: 3px 5px;"
                :style="{ color: statusColor[getTaskStatus(item)], border: '1px solid ' + statusColor[getTaskStatus(item)] }">
                {{ getTaskStatus(item) }}
              </div> -->
            </div>
          </div>
          <div class="card-uav card-uav-date">
            <img src="@/assets/img/i168.png" alt="" style="width: 24px; height: 27px;">
            <span class="n">{{ formatCoord(item.startCoord) }}</span>
            <img src="@/assets/img/i167.png" alt="" style="width: 34px; height: 10px; margin: 0 10px">
            <img src="@/assets/img/i166.png" alt="" style="width: 24px; height: 27px;">
            <span class="n">{{ formatCoord(item.endCoord) }}</span>
          </div>
          <div class="card-uav card-uav-plan">
            <!-- startedAt -->
            <span>计划任务时间：{{ item.startedAt }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="foot">
      <el-button class="p" @click="backToTaskList">返回任务订单列表</el-button>
    </div>
    <!-- 底部无人机信息 -->
    <div class="uav-info-panel" v-show="whxf.subTaskSelectedIndex != -1">
      <Control :wsShow="wsShow" :uavData="uav.control.data" :device="uav.control.device" @fn="(data, type) => uav_mqtt_fn(data, type)"
        @fun="(data) => uav_send_ws(data)">
      </Control>
    </div>
    <!-- 视频窗口 -->
    <div class="videoBox uav_videoBox" ref="uav_videoBox">
      <FloatPlayer @directiveFn="uav_move_directive" @imgUrl="getimgUrl" :device="uav.control.device" v-for="(video, index) in uav.videos"
        :uav-data="uav.control.data" @videoItemFn="videoItemFn(index)" @close="uav_hide_video" @fn="uav_mqtt_fn" :key="video.id" :data="video"
        :class="`_${index}`" :videoItem="videoItem" :index="index" :showCenter="showCenter"></FloatPlayer>
    </div>
    <!-- 网格码开关 -->
    <!-- <el-button class="grid-btn" size="mini" @click="removeGrids">网格码</el-button> -->
    <div class="grid-btn">
      <el-switch size="mini" @change="handleGridSwitch" v-model="gridsOpenFlag" active-color="#04D0DD" inactive-color="#c0c0c0">
      </el-switch>
    </div>
    <!-- 地名搜索组件 -->
    <div class='place-search'>
      <MapPlaceNameSearch></MapPlaceNameSearch>
    </div>
  </div>
</template>

<script>
import * as turf from '@turf/turf'
import QS from '@/components/PagerP1_New';

import { mapGetters } from 'vuex';
// 无人机列表组件
import UavList from './components/list';
// 视频播放器组件
import FloatPlayer from './components/float-playerfk';
import AirwayEdit from './components/airway-edit';

import data from './data';
import methods from './methods';
import Header from './components/header/index';
// 下面的油门图标
import Control from './components/control/index.vue';
// 创建任务面板
import TaskList from './components/taskList';
// 下面一键任务的小图标
import ControlMenu from './components/control/controlMenu';
import Notice from './components/control/components/notice';
import Logger from './components/control/logger/index';
import TakeOver from './components/control/components/takeOver';
import VideoTomap from './components/control/videoTomap';
import MapPlaceNameSearch from '@/components/map-placename-search/index'
// 侧边栏
import SideBar from '../../home/components/sideBar';
// 任务库面板
import TaskLibrary from './components/taskList/components/taskLibrary.vue';
// 航线库面板
import AirLineLibrary from './components/taskList/components/airLineLibrary.vue';
// 创建常态任务、临时紧急任务组件
import CreateTask from '@/components/command/flight-task/new-components/flyTask/createTask.vue';
// 航线规划面板
import Manual from '@/components/command/flight-task/new-components/airLine/manual/index.vue';
// 巫山机场网格码数据
import gridListDataWUSHAN_Origin from '@/data/grid/airSpace_wushan_origin.json';
let currentGridsOriginData = gridListDataWUSHAN_Origin;
// 引用bus
import Bus from '@/assets/ligature.js';
import API from '@/api';
import _ from 'lodash';
import dayjs from 'dayjs';
import cesiumSpaceAPI from '@/utils/cesiumSpace'
let airLineEntityCollection = null;
let uvaDeviceList = [];
// 网格码格子数组
let threeDimensionalGridprimitivesList = [];
// 网格码格子id和面坐标
let gridFacesList = [];
// 警情点entity列表
let jqdEntityList = [];
// 航线entity集合
let airLinePointDataSource = null;
export default {
  components: {
    QS,
    UavList,
    FloatPlayer,
    Header,
    Control,
    TaskList,
    ControlMenu,
    AirwayEdit,
    Notice,
    TakeOver,
    Logger,
    VideoTomap,
    SideBar,
    TaskLibrary,
    AirLineLibrary,
    CreateTask,
    Manual, MapPlaceNameSearch,
  },
  data() {
    return {
      ...data,
      healthData: {},
      message: {},
      showCenter: false,
      h: 0,
      level: null, //M300信号等级
      warnShow: false, //异常消息弹窗
      warnMessage: '', //异常消息
      imgshow: false, //拍照图片展示
      imgshowurl: null,
      videoFlag: true,
      // 头部状态是否为展示状态
      flag: true,
      controlMenuFlag: true, //创建任务面板显隐控制
      loggerFlag: false,
      // 运行监测
      powerFlag: true,
      // 创建航线窗口
      isAirwayEdit: false,
      // 创建任务
      CreateTaskFlag: false,
      // 左下角切换视频
      VideoTomapFlag: false,
      // 下方展示数据面板
      ControlFlag: false,
      iconShow: false,
      taskType: '',
      videoItem: 0,
      collapseFlag: false,
      linevisible: false,
      takeOff: false,
      uavId: null, //无人机id
      progressT: false, //进度条弹框
      jindu: 0, //进度条进度
      uavSearchContent: '', //无人机搜索内容
      wsShow: false, //判断是走飞控中心链路还是地面站链路,
      vuaListHeader: false, //无人机列表头部收缩按钮面板
      taskLibraryPanelShow: false, //任务库面板显示与否
      airLineLibraryPanelShow: false, //航线库面板显示与否
      br3ClickedFlag: -1, // controlMenu 组件右侧顶部工具条点击标志
      createTaskCompTitleFlag: 0, // 创建常态任务、临时紧急任务标志 0 常态任务，1 临时紧急任务
      createTaskCompPanelShow: false, //创建常态任务、临时紧急任务组件显示隐藏标志
      manualPanelShow: false, //手动规划航线面板显示与否
      newLineDialogVisible: false, //前创建航线弹框选择选项
      createAirlineDialogForm: {
        name: '', // 航线名称
        actionWay: '', //执行方式 无人机 uav，机巢 nest
        nest: '', //机巢，执行方式选择机巢时候有效
        scene: '', //场景
        createLineManualOrAuto: 1, // 创建方式，手动创建 0， 自动创建 1
        // altitudeMode: 'relativeToGround', //高度模式，默认相对高度
        altitudeMode: '', //高度模式，默认相对高度
        // finishedAction: 'GoHome', //返航模式 默认分回到Home点
        finishedAction: '', //返航模式 默认分回到Home点
        isFollowAirLine: true, //跟随航线模式，布尔类型，默认为true；当为true时，生效航线高度、速度设置，同时禁止编辑航点速度；当为false时，生效高度、航点速度。
        baseHeight: 100, //全局高度
        baseSpeed: 5 //全局速度
      }, //弹框表单
      createAirlineDialogFormCopy: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入航线名称',
            trigger: 'blur'
          }
        ]
      }, //弹窗表单校验
      scene_list: [
        { name: '巡查', id: 1 },
        { name: '预警', id: 2 },
        { name: '侦察', id: 3 },
        { name: '服务', id: 4 },
        { name: '救援', id: 5 },
        { name: '处置', id: 6 },
        { name: '打击', id: 7 },
        { name: '宣传', id: 8 },
        { name: '物流', id: 9 }
      ],
      nestShowFlag: false,
      taskLibraryShrinkFlag: false, //任务库收缩标志
      airLineLibraryShrinkFlag: false, //航线库收缩标志
      manualPlanShrinkFlag: false, //手动规划航线收缩标志
      orgName: '',
      altitudeMode_list: [
        { name: '相对高度', id: 1, value: 'relativeToGround' },
        { name: '绝对高度', id: 2, value: 'absolute' }
      ], //高度模式
      finishedAction_list: [
        { name: '返回到Home点', id: 1, value: 'GoHome' },
        { name: '悬停，无动作', id: 2, value: 'Hover' },
        { name: '原地降落', id: 3, value: 'AutoLand' }
      ], //返航模式
      statusColor: {
        待生效: "#FEDF73",
        执行中: "#11FDC4",
        已取消: "#FFFFFF",
        已完成: "#6BD4FF",
      },
      statusColor: {
        待生效: "#FEDF73",
        执行中: "#11FDC4",
        已取消: "#FFFFFF",
        已完成: "#6BD4FF",
      },
      // 无人机状态列表
      uavInfoList: [],
      currentOnlineDeviceHardId: '',
      subTaskListCopy: [],
      uvaDeviceList: [],
      gridsOpenFlag: false,
      hxAllShowFlag: true,
    };
  },
  inject: ['g_cesium_layer'],
  provide() {
    return {
      uav_This: this,
      findList: (search) => {
        if (search) {
          this.uav_list(search);
        } else {
          this.uav_list(search);
          // this.uav_mountList(data.name);
        }
      },
      uav_mqtt_fn: this.uav_mqtt_fn,
      uav: this.uav
    };
  },
  props: {
    taskDetailData: {
      type: Object,
      default: () => { }
    },
    whxfXTSTAirLineSqrt: {
      type: Object,
      default: () => { }
    },
    whxfSubTaskAirLineList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    healthData: function (newval, old) {
      // console.log(newval, old, 'newvalnewvalnewvalnewvalnewval');
      if (JSON.stringify(old) == '{}') {
        for (let i in newval) {
          if (newval[i].warningLevel != 'NORMAL') {
            if (this.taskId) {
              this.message.taskId = this.taskId;
            }
            this.message = {
              deviceId: this.uav.control.device.id,
              exceptionTitle: newval[i].title,
              exceptionTypeSign: i
            };
            // console.log(this.message, 'message222');
            this.addMessage();
          }
        }
      }
      for (let i in newval) {
        if (newval[i].warningLevel != 'NORMAL') {
          if (old[i] && newval[i].code != old[i].code) {
            if (this.taskId) {
              this.message.taskId = this.taskId;
            }
            this.message = {
              deviceId: this.uav.control.device.id,
              exceptionTitle: newval[i].title,
              exceptionTypeSign: i
            };
            // console.log(this.message, 'message1111');
            this.addMessage();
          }
        }
      }
    },
    data: function (vla, old) { },
    'uav.control.device': function (val) {
      this.uav.msg_list = [];
    },
    taskDetailData: {
      handler: function (oldVal, newVal) {
        if (!newVal) { return }
        console.log('主任务详情飞行监控', newVal);
        this.subTaskListCopy = _.cloneDeep(newVal.subtaskList);
        let a = _.cloneDeep(this.subTaskListCopy);
        for (let i = 0; i < this.uav.deviceList.length; i++) {
          let device = this.uav.deviceList[i];
          for (let j = 0; j < a.length; j++) {
            if (a[j].deviceHardId == device.deviceHardId) {
              a[j].reportStatus = device.reportStatus;
              a[j].goodsName = device.goodsName;
              a[j].latestData = device.latestData;
              a[j].uavLock = false;
              this.uvaDeviceList.push(device);
            }
          }
        }
        this.subTaskListCopy = a;
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters(['user_info']),
    taskId() {
      return this.$store.state.fckernel.taskId;
    },
    uav_mounts() {
      let { mounts } = this.uav.control;
      return mounts
        .map((item) => {
          let find_item = this.mount.list.find((m) => m.name === item.gimbalName);
          return find_item || undefined;
        })
        .filter((item) => item);
    }
  },
  created() {
    Bus.$on('remove_model', () => {
      // 删除飞控 无人机 模型和航线
      // 搜索关键字：删除飞控模型
      console.log('删除飞控 无人机 模型和航线...fackenral');
      this.uav_remove_model(this.uav.control.device);
    });
    // 等待航线上传成功在执行
    // 判断是否以一键任务
    Bus.$on('off_take', () => {
      this.takeOff = true;
    });
  },
  async mounted() {
    this.$nextTick(async () => {
      console.log('主任务详情', this.taskDetailData);
      console.log('子任务航线数据', this.whxfSubTaskAirLineList);
      console.log('子任务航线四至范围', this.whxfXTSTAirLineSqrt);
      let a = _.cloneDeep(this.taskDetailData.subtaskList);
      // 初始化页面变量
      this.uav_initVariable();
      // 绘制航线
      this.getSubTaskAirLine();
      // 获取无人机列表
      await this.uav_list();
      this.uvaDeviceList = [];
      for (let i = 0; i < this.uav.deviceList.length; i++) {
        let device = this.uav.deviceList[i];
        let subTask = _.find(this.taskDetailData.subtaskList, { 'deviceHardId': device.deviceHardId });
        if (subTask) {
          this.$set(subTask, 'reportStatus', device.reportStatus);
          this.$set(subTask, 'goodsName', device.goodsName);
          this.$set(subTask, 'latestData', device.latestData);
        }
        subTask = _.merge(device, subTask);
      }
      this.subTaskListCopy.forEach((v) => {
        // v.uavLock = true;
        this.$set(v, 'uavLock', false);
      });
      console.log('subTaskListCopy', this.subTaskListCopy);
      console.log('this.taskDetailData', this.taskDetailData);
      // 警情任务添加警情点
      this.addPointFromOriginJQTask();
      console.log('航线的四至范围', this.whxfXTSTAirLineSqrt);
      this.cameraFlyByBounds();
      this.whxf_initCesiumEvent();
    });
    this.mqtt_init();
    // 初始化数据接收 ws
    this.uav_init_ws();
    // 初始化数据状态 ws
    this.uav_init_ws_status();
    // 获取航线规划标签
    this.getlable();
    Bus.$on('createTaskBack2List', () => {
      // 如果任务库或者航线库面板还没关闭，则不能点击
      if (this.taskLibraryPanelShow || this.airLineLibraryPanelShow) {
        return;
      }
      // 显示无人机列表面板，隐藏创建任务面板
      this.vuaListHeader = true;
      this.collapseFlagfn();
      this.controlMenuFlag = false;
    });
    // 创建任务面板上点击任务库显示任务库面板
    Bus.$on('taskLibraryShow', (v) => {
      // console.log(v, 'vbvb');
      this.taskLibraryPanelShow = v;
      this.airLineLibraryPanelShow = false;
    });
    // 创建任务面板上点击航线库显示航线库面板，隐藏任务库面板
    Bus.$on('airLineLibraryShow', (v) => {
      this.taskLibraryPanelShow = false;
      this.airLineLibraryPanelShow = v;
    });
    // 任务库面板的显示与隐藏
    Bus.$on('taskLibraryCompShowFlag', (v) => {
      this.taskLibraryPanelShow = v;
    });
    // 手动规划航线组件面板显示控制
    Bus.$on('manualPanelShow', (v) => {
      // 重置航线设置表单
      this.$nextTick(() => {
        this.$refs['creatAirlineForm'].resetFields();
        this.createAirlineDialogForm.name = dayjs(new Date()).format('YYYYMMDDHHmmss');
      });
      // this.manualPanelShow = v;
      // 显示航线设置对话框
      this.newLineDialogVisible = v;
    });
    // 一级面板的显示与隐藏，二级面板要联动
    Bus.$on('secondLevelShow', (v) => {
      /**
       * 二级面板有：
       * 1 任务库面板
       * 2 航线库面板
       * 3 手动规划航线面板
       * **/
      // 收缩任务库
      if (this.taskLibraryPanelShow) {
        this.taskLibraryShrinkFlag = !v;
      }
      // 收缩航线库
      if (this.airLineLibraryPanelShow) {
        this.airLineLibraryShrinkFlag = !v;
      }
      // 手动规划航线面板
      if (this.manualPanelShow) {
        this.manualPlanShrinkFlag = !v;
      }
    });
    // 二级面板任务库向右移动，一级面板，也要展开
    Bus.$on('task-library-move-right', (v) => {
      // 任务库向右移动
      this.taskLibraryShrinkFlag = false;
      // 展开一级面板
      Bus.$emit('create-task-panel-show', true);
    });
    // 二级面板航线库向右移动，以及面板，也要展开
    Bus.$on('airline-library-move-right', (v) => {
      // 任务库向右移动
      this.airLineLibraryShrinkFlag = false;
      // 展开一级面板
      Bus.$emit('create-task-panel-show', true);
    });
    // 二级面板手动规划航线向右移动，一级面板，也要展开
    Bus.$on('airline-library-move-right', (v) => {
      // 任务库向右移动
      this.manualPlanShrinkFlag = false;
      // 展开一级面板
      Bus.$emit('create-task-panel-show', true);
    });
    Bus.$on('clearRealTimeAirLineFromMap', (v) => {
      this.uav.online[v.deviceHardId].positions = [];
      let a = window.viewer.entities.getById(`observe_polylineUav_${v.deviceHardId}`);
      a.polyline.positions = [];
    });
  },
  beforeDestroy() {
    this.uav.control.device = null;
    this.uav.control.device = null;
    this.uav.control.data = null;
    this.uav.control.battery = null;
    this.uav.control.mount = null;
    this.uav.control.mounts = [];
    this.uav.control.mounts = [];
    this.$store.commit('mapmanage/SET_MAP_IS_STATUSl', {
      flag: false,
      type: 'wrj'
    });
    this.uav.videos = [];
    if (this.mqtt.client) {
      this.mqtt.client.end();
      this.mqtt.client = null;
    }
  },
  methods: {
    ...methods,
    async getDeviceInfoByTaskId() {
      let taskId = this.$route.query.taskId
      let res = await API.FCKERNEL.getDeviceInfoByTaskId({
        taskId
      });
      this.uav.control.device = res
      // this.uav.control.device.deviceHardId = "MMC301R22208068"
      // this.uav.control.device.reportStatus = 1
      this.uav_fn_click(4, res, '全国')
      Bus.$emit('uavOnLineFlagFn', res.reportStatus)
    },
    async addMessage() {
      let res = await API.FCKERNEL.exceptionAdd(this.message);
    },
    showLine(val) {
      this.showCenter = val;
    },
    auto() {
      //定点-->航线
      //摇杆手动-->自动
      this.$refs.ControlMenu.changeAuto();
    },
    getimgUrl(val) {
      this.imgshow = true;
      this.imgshowurl = val;
      console.log('imgshowurl', imgshowurl);

    },
    async qzjg() {
      let res = await API.DEVICE.forceTakeOver({
        deviceHardId: this.uav.NoticeData.deviceHardId
      });
      if (res.code == 200) {
        this.$message.success(res.msg);
        this.uav.NoticeFlag = false;
      }
    },
    fly_take_off() {
      // 等待航线上传成功在执行
      this.$refs.TaskListRef.upload_complete();
      // Bus.$emit("take_off");
    },
    // 无人机列表面板显示或者关闭
    collapseFlagfn() {
      this.collapseFlag = !this.collapseFlag;
      // if (this.collapseFlag == true) {
      // 修改飞控 无人机 左边'任务库'的位置
      // Bus.$emit("ydh", false);
      // } else {
      // Bus.$emit("ydh", true);
      // }
    },
    Lsdom(item) {
      this.$emit('Lsdom', item);
    },
    clearIdFn() {
      if (this.$refs.TaskListRef) {
        this.$refs.TaskListRef.close();
        try {
          let deviceHardId = this.uav.control.device.deviceHardId;
          this.uav_hide_airway(this.uav.control.device);
          this.uav.online[deviceHardId].positions = [this.uav.online[deviceHardId].position];
        } catch (error) { }
      }
    },
    videoItemFn(index) {
      this.videoItem = index;
      this.$store.commit('mapmanage/SET_MAP_IS_VIDEOITEM', index);
    },
    LoggerFn() {
      this.loggerFlag = !this.loggerFlag;
      // this.collapseFlag = false;
    },
    videoChange() {
      this.videoFlag = !this.videoFlag;
    },
    showTakeOver() {
      this.uav.TakeOverFlag = !this.uav.TakeOverFlag;
      if (this.uav.TakeOverFlag) {
        this.collapseFlag = true;
      }
    },
    // 关闭航线创建列表
    AirwayQuit() {
      this.isAirwayEdit = false;
      // 运行监测关闭
      this.powerFlag = true;
      this.Videoflag = true;
      this.ControlFlag = true;
      this.collapseFlag = false;
    },
    taskTypeFn(item) {
      this.taskType = item;
    },
    // 创建航线
    CraeteRoute() {
      this.isAirwayEdit = true;
      // // 运行监测关闭
      this.powerFlag = false;
      this.ControlFlag = false;
      this.collapseFlag = true;
    },
    createTaskClick() {
      this.CreateTaskFlag = !this.CreateTaskFlag;
      this.controlMenuFlag = false;
    },
    async checkUseOperateFn(device) {
      // 查看是否有控制权限
      let res = await API.FCKERNEL.checkUseOperate({
        deviceHardId: device
      });
      if (res.code == 201) {
        this.$message.warning(res.msg);
        return false;
      } else {
        return true;
      }
    },
    /**
     * 无人机搜索
     */
    onUavSearch() {
      console.log(this.uavSearchContent, '无人机列表查询');
      this.uav_list({
        search: this.uavSearchContent
      });
    },
    //点击任务按钮
    onChangeTask(open) {
      if (open === undefined) {
        this.controlMenuFlag = !this.controlMenuFlag;
      } else {
        this.controlMenuFlag = open;
      }
    },
    /** 挂载、健康管理、图库工具栏点击事件
     *@Description:
     *@Date: 2023-10-17 11:31:07
     *@Params1:
     *@Return1:
     */
    gzPanelClose(v) {
      // console.log(v, 'vvvvvvvvvvvvv');
      this.br3ClickedFlag = v;
    },
    /**
     *@Description: 调用创建常态任务、临时紧急任务面板
     *@Date: 2023-10-18 09:57:11
     *@Params1:
     *@Return1:
     */
    createTaskComp(v) {
      // console.log('父组件', v);
      this.createTaskCompTitleFlag = parseInt(v);
      // 关闭任务库
      this.taskLibraryPanelShow = false;
      // 打开创建任务组件
      this.createTaskCompPanelShow = true;
    },
    /**
     *@Description: 关闭创建常态、临时紧急任务面板，显示任务库
     *@Date: 2023-10-18 10:32:30
     *@Params1:
     *@Return1:
     */
    createTaskCompMainShow() {
      // 打开任务库
      this.taskLibraryPanelShow = true;
      // 关闭创建任务组件
      this.createTaskCompPanelShow = false;
      // 通知任务库面板刷新数据
      Bus.$emit('refresh');
    },
    /**
     *@Description: 航线库面板显示与否
     *@Date: 2023-10-18 15:05:58
     *@Params1:
     *@Return1:
     */
    airLineLibraryPanelHandleShow(v) {
      this.airLineLibraryPanelShow = v;
    },
    /**
     *@Description: 创建航线的时候，选择机巢或者无人机
     *@Date: 2023-10-19 10:33:16
     *@Params1:
     *@Return1:
     */
    handle_actionWayChange(v) {
      console.log(v, '选择执行的方式');
      // 如果是无人机，隐藏选择机巢，如果是机巢，则显示选择机巢
      if (v == 'uav') {
        this.nestShowFlag = false;
      } else {
        this.nestShowFlag = true;
      }
    },
    /**
     *@Description: 显示创建航线面板，航线设置对话框提交数据
     *@Date: 2023-10-19 10:34:38
     *@Params1:
     *@Return1:
     */
    createLineDialogSubmit() {
      this.$refs['creatAirlineForm'].validate((valid) => {
        if (valid) {
          // alert('submit!');
          // 创建航线弹窗隐藏
          this.newLineDialogVisible = false;
          // console.log(this.createAirlineDialogForm, '创建航线面板数据');
          this.createAirlineDialogFormCopy = _.cloneDeep(this.createAirlineDialogForm);
          // 显示创建航线面板
          this.manualPanelShow = true;
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消航线设置
    createLineDialogCancel() {
      // alert("x");
      // this.$refs['creatAirlineForm'].resetFields();
      this.newLineDialogVisible = false;
    },
    /**
     *@Description:获取航线标签，航线规划的时候使用
     *@Date: 2023-10-19 10:46:30
     *@Params1:
     *@Return1:
     */
    // 获取航线标签
    async getlable() {
      this.lablistL = await API.AIRWAY.labelList();
    },
    /**
     *@Description: 关闭航线规划面板
     *@Date: 2023-10-19 10:51:45
     *@Params1:
     *@Return1:
     */
    closeCreatePanel() {
      // 隐藏创建航线面板
      this.manualPanelShow = false;
    },
    /**
     *@Description: 飞行监控里面航线规划选择弹出框
     *@Date: 2023-10-19 10:55:19
     *@Params1:
     *@Return1:
     */
    editCreateAriLinePanelClick(v) {
      // console.log(v, 'f-u');
      this.$nextTick(() => {
        this.createAirlineDialogForm = _.cloneDeep(v.panelForm);
      });

      this.newLineDialogVisible = true;
    },
    /** 当关闭创建任务面板时，打开无人机列表面板
     *@Description:
     *@Author: name
     *@Date: 2023-11-22 10:57:28
     *@Params1:
     *@Return1:
     */
    taskListOpenUvaListPanel(v) {
      // 先检查任务库或者航线库是否关闭，如果没有关闭，则不能关闭创建任务面板
      if (this.taskLibraryPanelShow || this.airLineLibraryPanelShow) {
        this.$message({
          type: 'warning',
          message: '请先关闭任务库或者航线库面板'
        });
        return;
      }
      // console.log(v, '当关闭创建任务面板时，打开无人机列表面板');
      //关闭创建任务面板
      this.controlMenuFlag = !v;
      // 打开无人机列表面板
      this.collapseFlag = !v;
    },
    /** 点击无人机列表数据
     *@Description:
     *@Author: name
     *@Date: 2023-12-18 15:36:13
     *@Params1:
     *@Return1:
     */
    uav_fn_click(cate, device, orgName) {
      console.log(' ********************************** 点击无人机列表数据 ********************************** ');
      console.log(cate, 'cate');
      console.log(device, 'device');
      console.log(orgName, 'orgName');
      this.orgName = orgName;
      this.uav_fn(cate, device, orgName);
    },
    /** 在创建任务列表点击接管无人机按钮
     *@Description:
     *@Author: name
     *@Date: 2024-01-10 11:25:26
     *@Params1:
     *@Return1:
     */
    takeoverFromTaskList(v) {
      this.uav_fn(v.cate, v.device, v.orgName);
    },
    // 设置全局高度
    flyHeightClickHandle(v, index) {
      // console.log('v', v);
      let c = parseFloat(this.createAirlineDialogForm.baseHeight) + parseFloat(v);
      if (c < 1 || c > 500) {
        this.$message({
          type: 'warning',
          message: '飞行高度不在[1-500]范围内，无法设置'
        });
      } else {
        this.createAirlineDialogForm.baseHeight = c;
      }
    },
    //全局高度输入框
    flyHeightInputHandle(v) {
      let c = parseFloat(v);
      if (c < 2 || c > 1500) {
        this.$message({
          type: 'warning',
          message: '飞行高度不在[1-500]范围内，无法设置'
        });
        if (v > 500) {
          this.createAirlineDialogForm.baseHeight = 500;
        }
        if (v < 1) {
          this.createAirlineDialogForm.baseHeight = 1;
        }
      } else {
        this.createAirlineDialogForm.baseHeight = v;
      }
      // console.log(v, 'vvvv');
    },
    //设置全局速度
    flySpeedClickHandle(v) {
      let a = parseInt(this.createAirlineDialogForm.baseSpeed) + parseInt(v);
      if (a < 1 || a > 20) {
        this.$message({
          type: 'warning',
          message: '飞行速度不在[1-20]范围内，无法设置'
        });
      } else {
        this.createAirlineDialogForm.baseSpeed = a;
      }
    },
    //设置全局速度输入框
    flySpeedInputHandle(v) {
      let a = parseInt(v);
      if (a < 1 || a > 20) {
        this.$message({
          type: 'warning',
          message: '飞行速度不在[1-20]范围内，无法设置'
        });
        if (a < 1) {
          this.createAirlineDialogForm.baseSpeed = 1;
        }
        if (a > 20) {
          this.createAirlineDialogForm.baseSpeed = 20;
        }
      } else {
        this.createAirlineDialogForm.baseSpeed = a;
      }
    },
    /**@Description :  ******************************************* 获取子任务的任务状态
     **@Date: 2024-05-14 14:54:18
    */
    getTaskStatus(row) {
      let val = row;
      let text = "";
      if (val.taskStatus == 0) {
        text = "待生效";
      } else if (val.taskStatus == 3) {
        text = "执行中";
      } else if (val.taskStatus == 99) {
        text = "已取消";
      } else if (val.taskStatus == 1) {
        text = "已完成";
      }
      // if (val.applyStatus == 1 && val.papplyStatus == 1) {
      //   text = "待生效";
      //   if (val.executeStatus == 0) {
      //     text = "待执行";
      //   } else if (val.executeStatus == 1) {
      //     text = "已完成";
      //   } else {
      //     text = "执行中";
      //   }
      // } else if (val.applyStatus == 2 || val.papplyStatus == 2) {
      //   text = "已取消";
      // } else {
      //   text = "待生效";
      // }
      return text;
    },
    /**@Description :  ******************************************* 格式化坐标，保留四位小数点 (119.092321, 20.232122)
     **@Date: 2024-05-15 11:35:55
    */
    formatCoord(coord) {
      let c = '';
      try {
        let a = coord.replace('(', '').replace(')', '');
        // console.log('a', a);
        let b = a.split(',');
        c = '(' + parseFloat(b[0]).toFixed(4) + ',' + parseFloat(b[1]).toFixed(4) + ')';
        return c;
      } catch (e) {
        return '';
      }
    },
    /**@Description :  ******************************************* 返回任务订单列表
     **@Date: 2024-05-17 16:24:03
    */
    backToTaskList() {
      this.whxf.subTaskSelectedIndex = -1;
      this.removeEntity();
      this.uav_initVariable();
      this.uav_closeWS();
      this.removeGrids();
      this.whxf_removeCesiumEvent();
      Bus.$emit('backToTaskList');
    },
    /**@Description :  ******************************************* 子任务完结
     **@Date: 2024-05-17 16:32:44
    */
    async handleSubTaskFinish(item, e) {
      if (e.target.style.backgroundColor != 'rgba(255, 119, 119, 0.88)') {
        e.target.style.backgroundColor = 'rgba(255, 119, 119, 0.88)';
        e.target.style.color = 'rgb(255, 255, 255)';
      } else {
        e.target.style.backgroundColor = 'transparent';
        e.target.style.color = 'rgba(0, 245, 255)';
      }
      // 判断用户权限
      // let isPilot = this.user_info?.isPilot;
      // if (isPilot == 0) {
      //   this.$message.warning('无操作权限');
      //   return;
      // }
      console.log('item', item);

      let params = { "taskId": item.id, "status": 1 };
      let res = await API.WHXF.subTaskStatusUpdate(params);

      console.log('子任务完结', res);
      if (res) {
        item.taskStatus = 1;
      }
      // return;
      this.$message({
        type: 'success',
        message: '子任务完结成功'
      });
      // ！！！还需要更新任务状态，根据主任务id，查询主任务详情，然后更新到这里
      // let mainTaskId = this.taskDetailData.mainTaskId;
      // console.log('this.taskDetailData', this.taskDetailData);

      // Bus.$emit('coordinationViewerGetTaskDetail', { 'mainTaskId': mainTaskId, 'subTask': item });
      // ************************* 结束指令发送功能 **********************************
      // 发送结束任务参数
      let wsShow = true;
      if (item.device.stationType == 1) {
        console.log('数据类型：飞控链路mqtt')
        wsShow = false
      } else {
        console.log('数据类型：地面站数据')
        wsShow = true
      }
      // 获取 flyTypeId
      // 航线id
      let flyTypeId = null;
      let resTaskAndUserRelation = await API.FCKERNEL.getTaskAndUserRelation({ sourceType: 0, deviceHardId: item.device.deviceHardId, });
      if (resTaskAndUserRelation.code == 200 && resTaskAndUserRelation.list) {
        flyTypeId = resTaskAndUserRelation.list.taskId;
      }
      flyTypeId = item.id;
      if (!wsShow) { // 飞控链路
        let flag = await this.checkUseOperateFn(item.device.deviceHardId);
        if (!flag) return false;
        // 结束任务
        await API.DEVICE.task_update({ id: flyTypeId, status: 4, deviceHardId: item.device.deviceHardId });
        // this.$emit('fn', { type: 525, data: null })
        this.uav_mqtt_fn({ type: 525, data: null });
      } else {
        // 地面站数据
        // API.DEVICE.task_update({ id: flyTypeId, status: 1, deviceHardId: item.device.deviceHardId });
        let username = this.user_info.username;
        let data = {
          type: 200,
          systemCode: 'mmc',
          state: 1,
          username: username,
          data: {
            // cmdFunction: 2280,
            cmdFunction: 2180, //指令修改为2180
            taskId: flyTypeId + ''
          },
          deviceHardId: item.device.deviceHardId
        }
        this.uav_send_ws(data);
      }
    },
    /**@Description :  ******************************************* 获取子任务的航线信息，并添加到地图上
     **@Date: 2024-05-20 14:54:21
    */
    async getSubTaskAirLine() {
      console.log('airLineList', this.whxfSubTaskAirLineList);
      // airLinePointDataSource = new Cesium.CustomDataSource('airLinePointDataSource');
      // await window.viewer.dataSources.add(airLinePointDataSource);
      for (let i = 0; i < this.whxfSubTaskAirLineList.length; i++) {
        let item = this.whxfSubTaskAirLineList[i];
        let polylineEn = new Cesium.Entity({
          id: 'hx_' + item.flagId,
          polyline: {
            positions: item.cList,
            width: 6,
            // material: Cesium.Color.fromCssColorString('rgba(189, 194, 114, 0.6)'),
            // material: Cesium.Color.fromCssColorString('rgba(0, 209, 255, 0.56)'),
            // material: Cesium.Color.fromRandom({ alpha: 0.6 }),
            material: Cesium.Color.fromRandom({
              minimumRed: 1.0,
              minimumGreen: 0.5,
              minimumBlue: 0,
              alpha: 0.6
            }),
            show: true,
            // zIndex: 999,
          },
        });
        // console.log('polylineEn', polylineEn);
        // airLinePointDataSource.entities.add(polylineEn);
        window.viewer.entities.add(polylineEn);
      }
    },
    /**@Description :  ******************************************* 航线显示与隐藏
     **@Date: 2024-05-20 17:31:06
    */
    handleHXShow(v, e) {
      let id = 'hx_' + v.taskTitle + '_' + v.deviceHardId;
      let en = window.viewer.entities.getById(id);
      // let en = airLinePointDataSource.entities.getById(id);
      // console.log('en', en);
      // console.log('en.show', en.show);
      // console.log('id', id);
      // return;
      if (en) {
        en.show = !en._show;
      } else {
        return;
      }
      if (en.show) {
        window.viewer.flyTo(en);
        e.target.style.backgroundColor = 'rgb(8, 170, 185)';
        e.target.style.color = 'rgb(255, 255, 255)';
      } else {
        e.target.style.backgroundColor = 'transparent';
        e.target.style.color = 'rgba(0, 245, 255)';
        this.hxAllShowFlag = false;
      }
      // 如果所有的头显示了，则一键按钮高亮
      let flag = 1;
      this.subTaskListCopy.forEach(v => {
        let id = 'hx_' + v.taskTitle + '_' + v.deviceHardId;
        let en = window.viewer.entities.getById(id);
        // let en = airLinePointDataSource.entities.getById(id);
        if (!en.show) {
          flag = 2;
        }
      });
      if (flag == 1) {
        this.hxAllShowFlag = true;
      }
    },
    /**@Description :  ******************************************* 一键开启或者关闭航线
     **@Date: 2024-06-29 20:46:58
    */
    handleHXAllShow() {
      this.hxAllShowFlag = !this.hxAllShowFlag;
      this.subTaskListCopy.forEach(v => {
        let id = 'hx_' + v.taskTitle + '_' + v.deviceHardId;
        let en = window.viewer.entities.getById(id);
        console.log('v', v);
        console.log('en', en);
        en.show = this.hxAllShowFlag;
      });
      let domList = document.querySelectorAll('.whxf-xtst-btn-hx');
      let len = domList.length;
      if (this.hxAllShowFlag) {
        // 将所有的卡片上航线按钮给高亮起来
        for (let i = 0; i < len; i++) {
          domList[i].style.backgroundColor = 'rgb(8, 170, 185)';
          domList[i].style.color = 'rgb(255, 255, 255)';
        }
      } else {
        for (let i = 0; i < len; i++) {
          domList[i].style.backgroundColor = 'transparent';
          domList[i].style.color = 'rgb(0, 245, 255)';
        }
      }
    },
    /**@Description :  ******************************************* 航迹显示与隐藏
     **@Date: 2024-05-20 17:42:08
    */
    handleHJShow(v) {
      let id = 'hj_' + v.taskTitle + '_' + v.deviceHardId;
      let en = window.viewer.entities.getById(id);
      if (en) {
        en.show = !en._show;
      }
      if (en.show) {
        window.viewer.flyTo(en);
      }
    },
    /**@Description :  ******************************************* 获取goodsName
     **@Date: 2024-05-21 16:08:11
    */
    getGoodsName(item) {
      console.log('item', item);
      let res = item?.device?.goodsName || '';
      return res;
    },
    /**@Description :  ******************************************* 删除子任务的航线和飞机模型、警情点
     **@Date: 2024-05-22 14:44:34
    */
    removeEntity() {
      // airLinePointDataSource.entities.removeAll();
      for (let i = 0; i < this.whxfSubTaskAirLineList.length; i++) {
        let item = this.whxfSubTaskAirLineList[i];
        let id_hx = 'hx_' + item.flagId;
        let id_hj = 'hj_' + item.flagId;
        let en1 = window.viewer.entities.getById(id_hx);
        let en2 = window.viewer.entities.getById(id_hj);
        console.log('id_hx', id_hx);
        console.log('en1', en1);
        console.log('id_hj', id_hj);
        console.log('en2', en2);
        en1 && window.viewer.entities.remove(en1);
        en2 && window.viewer.entities.remove(en2);
      }
      for (let i = 0; i < this.uvaDeviceList.length; i++) {
        let device = this.uvaDeviceList[i];
        let id_mo_20 = 'whxf_model_q20_' + device.deviceHardId;
        let id_mo_550 = 'whxf_model_td550_' + device.deviceHardId;
        let id_mo_550_up = 'whxf_model_td550_up_' + device.deviceHardId;
        let id_mo_550_down = 'whxf_model_td550_down_' + device.deviceHardId;
        let id_mo_img = 'whxf_model_img_' + device.deviceHardId;
        let id_mo_label = 'whxf_model_label_' + device.deviceHardId;
        let id_moLine = 'whxf_line_' + device.deviceHardId;
        let en1_20 = window.viewer.entities.getById(id_mo_20);
        let en1_550 = window.viewer.entities.getById(id_mo_550);
        let en1_550_up = window.viewer.entities.getById(id_mo_550_up);
        let en1_550_down = window.viewer.entities.getById(id_mo_550_down);
        let en1_mo_img = window.viewer.entities.getById(id_mo_img);
        let en1_mo_label = window.viewer.entities.getById(id_mo_label);
        let en1Line = window.viewer.entities.getById(id_moLine);
        en1_20 && window.viewer.entities.remove(en1_20);
        en1_550 && window.viewer.entities.remove(en1_550);
        en1_550_up && window.viewer.entities.remove(en1_550_up);
        en1_550_down && window.viewer.entities.remove(en1_550_down);
        en1_mo_img && window.viewer.entities.remove(en1_mo_img);
        en1_mo_label && window.viewer.entities.remove(en1_mo_label);
        en1Line && window.viewer.entities.remove(en1Line);
      }
      // 指点飞行绘制的图
      this.subTaskListCopy.forEach((v) => {
        let id = 'whxf_zdfx_point_' + v.deviceHardId;
        let cen3 = window.viewer.entities.getById(id);
        cen3 && window.viewer.entities.remove(cen3);
      })
      // 警情点
      jqdEntityList.forEach((v) => {
        window.viewer.entities.remove(v);
      });
    },
    /**@Description :  ******************************************* 向地图上添加警情任务的坐标点
     **@Date: 2024-05-19 10:37:25
    */
    addPointFromOriginJQTask() {
      jqdEntityList = [];
      console.log('this.taskDetailData', this.taskDetailData);
      if (!this.taskDetailData?.jingqingPositions) { return; }
      let jingqingPositions = JSON.parse(this.taskDetailData.jingqingPositions);
      console.log('jqTaskNoticeContentJSON', jingqingPositions);
      let noticeContent = jingqingPositions.noticeContent;
      noticeContent = JSON.parse(noticeContent);
      console.log("noticeContent", noticeContent);
      let alert_positions = noticeContent.alert_positions;
      alert_positions.forEach((v, index) => {
        let pos = v.value.split(",");
        let lon = parseFloat(pos[0]);
        let lat = parseFloat(pos[1]);
        let c1 = Cesium.Cartesian3.fromDegrees(lon, lat);
        let en1 = new Cesium.Entity({
          position: c1,
          label: {
            text: v.label,
            font: "8px Source Han Sans CN bold", //字体样式
            fillColor: Cesium.Color.WHITE, //字体颜色
            backgroundColor: Cesium.Color.fromCssColorString("rgba(210, 138, 120, 1.0)"), //背景颜色
            showBackground: true, //是否显示背景颜色
            style: Cesium.LabelStyle.FILL, //label样式
            backgroundPadding: new Cesium.Cartesian2(5, 3),
            pixelOffset: new Cesium.Cartesian2(0, -42),
            scaleByDistance: new Cesium.NearFarScalar(90000, 1.5, 660000, 0.4),
            pixelOffsetScaleByDistance: new Cesium.NearFarScalar(90000, 1.5, 660000, 0.4),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          },
          billboard: {
            image: `image/s2.png`,
            pixelOffset: new Cesium.Cartesian2(0, -20),
            width: 32,
            height: 41,
            scale: 0.5,
            scaleByDistance: new Cesium.NearFarScalar(90000, 1.5, 660000, 0.4),
            pixelOffsetScaleByDistance: new Cesium.NearFarScalar(90000, 1.5, 660000, 0.4),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          },
        });
        window.viewer.entities.add(en1);
        jqdEntityList.push(en1);
      });
    },
    /**@Description :  ******************************************* 立即执行
     **@Date: 2024-06-03 10:43:32
    */
    async handleSubTaskExecute(item) {

      let dataSubTask = { "taskId": item.id, "status": 3 }
      let resSubTask = await API.WHXF.subTaskStatusUpdate(dataSubTask);


      console.log(item, '子任务相关，立即执行');
      item.taskStatus = 3;

      let checkBeforeTakeOff = await API.DEVICE.checkBeforeTakeOff({ taskId: item.id })
      // 给飞机发送一键任务指令
      let flag = await this.checkUseOperateFn(item.device.deviceHardId)
      if (!flag) return

      let wsShow = true;
      if (item.device.stationType == 1) {
        console.log('数据类型：飞控链路mqtt')
        wsShow = false
      } else {
        console.log('数据类型：地面站数据')
        wsShow = true
      }
      // 获取 flyTypeId
      // 航线id
      let flyTypeId = null;
      let resTaskAndUserRelation = await API.FCKERNEL.getTaskAndUserRelation({ sourceType: 0, deviceHardId: item.device.deviceHardId, });
      if (resTaskAndUserRelation.code == 200 && resTaskAndUserRelation.list) {
        flyTypeId = resTaskAndUserRelation.list.taskId;
      }
      flyTypeId = item.id;
      if (!wsShow) {
        // 飞控链路
        let getFlightSortic = await API.DEVICE.getFlightSortic({ taskId: flyTypeId, deviceHardId: item.device.uavDeviceId })
        let res = await API.AIRWAY.GetAirwayInfo(flightLineId)
        let flightLinefkData = res.data
        let data = {
          taskId: flyTypeId,
          flightSortiesID: getFlightSortic,
          ...flightLinefkData
        }
        data.autoFlightSpeed = 5
        console.log(data, '飞控链路上传航线数据');
        this.uav_mqtt_fn({ data: data, type: 521 })
        this.uav_mqtt_fn({ data: { flyTypeId: item.id, flightSortiesID: getFlightSortic }, type: 769 })
        await API.DEVICE.task_update({ id: item.id, status: 3, deviceHardId: item.device.uavDeviceId })
      } else {
        // 地面站数据
        let username = this.user_info.username;
        this.uav_send_ws({
          type: 200,
          systemCode: 'mmc',
          state: 1,
          username: username,
          data: {
            cmdFunction: 2113,
            // cmdValue: waypointList.flightId, //航线id
            // cmdValue: "waypointList.flightId", //航线id
            cmdValue: item.flightLineId, //航线id
            taskId: flyTypeId + ''
          },
          deviceHardId: item.device.deviceHardId
        });
        // let task = await API.DEVICE.task_update({
        //   id: item.id,
        //   status: 3,
        //   deviceHardId: item.device.deviceHardId
        // })
        // 一键起飞任务监听
        let res = await API.TASK.flytaskLisener({
          taskId: item.id,
          deviceHardId: item.device.deviceHardId
        })
      }
    },
    /**@Description :  ******************************************* cesium 根据四至范围定位
     **@Date: 2024-06-03 14:23:31
    */
    cameraFlyByBounds() {
      // window.viewer.flyTo(airLinePointDataSource, {
      //     offset: new Cesium.HeadingPitchRange(Cesium.Math.toRadians(0.0), Cesium.Math.toRadians(-35.0), 0),
      // });
      // return;  
      let west = this.whxfXTSTAirLineSqrt?.minLon;
      let south = this.whxfXTSTAirLineSqrt?.minLat;
      let east = this.whxfXTSTAirLineSqrt?.maxLon;
      let north = this.whxfXTSTAirLineSqrt?.maxLat;
      // 协同视图，飞行监控里面开启地形检测
      window.viewer.scene.globe.depthTestAgainstTerrain = true;
      if (west && south && east && north) {
        let rectangle = Cesium.Rectangle.fromDegrees(west - 0.002, south - 0.002, east + 0.002, north + 0.002);
        window.viewer.camera.flyTo({
          destination: rectangle,
          orientation: {
            heading: Cesium.Math.toRadians(0),//1
            pitch: Cesium.Math.toRadians(-90),
            roll: 0
          },
          complete: () => { }
        });
      }
    },
    /******************************************************** 添加网格码开始 *****************************************************/
    /**@Description :  ******************************************* 添加网格码开始
     * distance 网格码距离, exHeight每一层的拉伸高度，tier 网格码层级
     **@Date: 2024-06-27 09:29:25
    */
    initGridsByOriginDataAndDistance(distance, exHeight, tier, defaultGridColor, gridOutLineColor) {
      let gridList = [];
      console.log('开始加载网格数据');
      let d1 = new Date();
      // 遍历原始网格数据，重新生成等边网格码
      for (let i = 0; i < currentGridsOriginData.length; i++) {
        let maxLon = _.maxBy(currentGridsOriginData[i].data, 'r').r;
        let minLon = _.minBy(currentGridsOriginData[i].data, 'l').l;
        let maxLat = _.maxBy(currentGridsOriginData[i].data, 't').t;
        let minLat = _.minBy(currentGridsOriginData[i].data, 'b').b;
        let originGridBounds = { maxLon, minLon, maxLat, minLat };
        //将相交的原始网格码数据，重新生成等边网格码
        let newGrids = this.reGenerateGridsByDistance(currentGridsOriginData[i].data, distance, originGridBounds);
        let temp = {};
        temp.id = currentGridsOriginData[i].id;
        temp.data = newGrids.data;
        temp.bounds = newGrids.bounds;
        gridList.push(temp);
      }
      let d2 = new Date();
      console.log('生成网格数据耗时: ', d2.getTime() - d1.getTime(), '毫秒');
      console.log('开始绘制网格码到地图上');
      this.addThreeDimensionalGrid(gridList, distance, exHeight, tier, defaultGridColor, gridOutLineColor);
      let d3 = new Date();
      console.log('绘制网格码结束，耗时: ', d3.getTime() - d2.getTime());
    },
    /**@Description :  ******************************************* 根据网格码最大范围生成小的网格码坐标
     **@Date: 2024-06-27 09:45:31
    */
    reGenerateGridsByDistance(oriGrids, distance, originGridBounds) {
      // 获取原始网格边界 getOriGridsBounds
      let oriGridsRealBounds = this.getOriGridsBounds(oriGrids);
      let oriGridsRealBoundsPolygon = turf.polygon([oriGridsRealBounds]);
      let res = cesiumSpaceAPI.generateSquareGrids(distance, originGridBounds, oriGridsRealBoundsPolygon);
      return res;
    },
    /**@Description :  ******************************************* 获取边界
     **@Date: 2024-06-27 09:47:55
    */
    getOriGridsBounds(grids) {
      let lngMaxItem = _.maxBy(grids, 'r');
      let lngMinItem = _.minBy(grids, 'l');
      let latMaxItem = _.maxBy(grids, 't');
      let latMinItem = _.minBy(grids, 'b');
      let ycList = [];
      let zcList = [];
      let ubList = [];
      let dbList = [];
      let ycObjList = [];
      let zcObjList = [];
      let ubObjList = [];
      let dbObjList = [];
      for (let i = 0; i < grids.length; i++) {
        let pp1 = [grids[i].l, grids[i].b];
        let pp2 = [grids[i].l, grids[i].t];
        let pp3 = [grids[i].r, grids[i].t];
        let pp4 = [grids[i].r, grids[i].b];
        let fp = [pp1, pp2, pp3, pp4];
        for (let ii = 0; ii < 4; ii++) {
          let clng = fp[ii][0];
          let clat = fp[ii][1];
          //右侧线
          if (clng == lngMaxItem.r) {
            ycList.push([clng, clat]);
            ycObjList.push({ lng: clng, lat: clat });
          }
          //左侧线
          if (clng == lngMinItem.l) {
            zcList.push([clng, clat]);
            zcObjList.push({ lng: clng, lat: clat });
          }
          //顶上线
          if (clat == latMaxItem.t) {
            ubList.push([clng, clat]);
            ubObjList.push({ lng: clng, lat: clat });
          }
          //底下线
          if (clat == latMinItem.b) {
            dbList.push([clng, clat]);
            dbObjList.push({ lng: clng, lat: clat });
          }
        }
      }
      let a = _.orderBy(ycObjList, 'lat', 'asc');
      let b = _.orderBy(ubObjList, 'lng', 'desc');
      let c = _.orderBy(zcObjList, 'lat', 'desc');
      let d = _.orderBy(dbObjList, 'lng', 'asc');
      ycList = [];
      zcList = [];
      ubList = [];
      dbList = [];
      _(a).forEach((item) => {
        ycList.push([item.lng, item.lat]);
      });
      _(b).forEach((item) => {
        ubList.push([item.lng, item.lat]);
      });
      _(c).forEach((item) => {
        zcList.push([item.lng, item.lat]);
      });
      _(d).forEach((item) => {
        dbList.push([item.lng, item.lat]);
      });
      let aP = [...ycList, ...ubList, ...zcList, ...dbList];
      let line = turf.lineString(aP);
      let res = turf.cleanCoords(line).geometry.coordinates;
      res.push(res[0]);
      return res;
    },
    /**@Description :  ******************************************* 绘制网格码到地图上
     **@Date: 2024-06-27 09:56:37
    */
    addThreeDimensionalGrid(gridList, distance, exHeight, tier, defaultGridColor, gridOutLineColor) {
      let threeDimensionalGridprimitives = null;
      // 遍历每一个区域网格数据
      for (let i = 0; i < gridList.length; i++) {
        // 为每一个区域创建一个PrimitiveCollection集合，存储拉伸的网格和边框
        threeDimensionalGridprimitives = new Cesium.PrimitiveCollection();
        // 单个区域网格数据
        let grids = gridList[i];
        let id = grids.id;
        let polygonOutlineInstanceArray = [];
        let polygonInstanceArray = [];
        let polygonInstanceHArray = [];
        let polygonInstanceOutLineHArray = [];
        // 对每一层进行遍历
        for (let m = 0; m < tier; m++) {
          let h = m * exHeight;
          let eh = (m + 1) * exHeight;
          // 对每一个网格进行处理，绘制贴地网格和边框
          for (let j = 0; j < grids.data.length; j++) {
            let item = grids.data[j];
            // 计算每个网格面
            let polygonPos = [item.r, item.b, h, item.r, item.t, h, item.l, item.t, h, item.l, item.b, h];
            // ************************************ 每一个小方网格开始 ****************************************
            // 四个角点坐标
            let p1 = new Cesium.Cartesian3.fromDegrees(item.r, item.b, eh);
            let p2 = new Cesium.Cartesian3.fromDegrees(item.r, item.t, eh);
            let p3 = new Cesium.Cartesian3.fromDegrees(item.l, item.t, eh);
            let p4 = new Cesium.Cartesian3.fromDegrees(item.l, item.b, eh);
            // 面往上拉伸成立方体
            let insId = id + '_' + m + '_' + j + '_' + 'grid_box';
            let face = this.getGridFaces(item, h, eh);
            gridFacesList.push({ id: insId, face: face });
            let insIdGround = id + '_' + m + '_' + j + '_' + 'grid_box_ground';
            let polygonPosH = [p1, p2, p3, p4];
            let polygonGeometryH = Cesium.PolygonGeometry.createGeometry(new Cesium.PolygonGeometry({
              polygonHierarchy: new Cesium.PolygonHierarchy(polygonPosH),
              vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
              extrudedHeight: eh, //拉伸
              height: h, //高度，必须设置，要不要然会从贴地拉伸
              perPositionHeight: false,
            }));
            let polygonInstanceH = new Cesium.GeometryInstance({
              geometry: polygonGeometryH,
              attributes: {
                color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString(defaultGridColor))
              },
              id: insId,
            });
            // console.log('polygonInstanceH', polygonInstanceH);

            // 立方体的边框
            let polygonOutlinePosH = [
              item.r, item.b, eh,
              item.r, item.t, eh,
              item.l, item.t, eh,
              item.l, item.b, eh,
              item.r, item.b, eh,
              item.r, item.b, h,
              item.r, item.t, h,
              item.l, item.t, h,
              item.l, item.b, h,
              item.r, item.b, h,
              item.l, item.b, h,
              item.l, item.b, eh,
              item.l, item.t, eh,
              item.l, item.t, h,
              item.r, item.t, h,
              item.r, item.t, eh,
            ];
            let polygonOutlineInstanceH = new Cesium.GeometryInstance({
              geometry: Cesium.CoplanarPolygonOutlineGeometry.fromPositions({
                positions: Cesium.Cartesian3.fromDegreesArrayHeights(polygonOutlinePosH),
              }),
              attributes: {
                color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString(gridOutLineColor)),
              },
            });
            // 贴地的一层
            if (m == 0) {
              let polygonInstance = cesiumSpaceAPI.generatePolygonInstance(polygonPos, defaultGridColor, insIdGround);
              let polygonOutlineInstance = cesiumSpaceAPI.generatePolygonOutlineInstance(polygonPos, gridOutLineColor);
              polygonInstanceArray.push(polygonInstance);
              polygonOutlineInstanceArray.push(polygonOutlineInstance);
            }
            // 向上拉伸的层
            polygonInstanceHArray.push(polygonInstanceH);
            polygonInstanceOutLineHArray.push(polygonOutlineInstanceH);
          }
        }
        // 创建一个区域的primitive，包括网格和边框，并添加到primitive集合中
        let polygonPrimitive = new Cesium.GroundPrimitive({
          geometryInstances: polygonInstanceArray,
          releaseGeometryInstances: false,
          asynchronous: false,
        });
        let polygonOutlinePrimitive = new Cesium.GroundPolylinePrimitive({
          geometryInstances: polygonOutlineInstanceArray,
          appearance: new Cesium.PolylineColorAppearance(),
          asynchronous: false,
        });
        let primitivePolygonHPrimitive = new Cesium.Primitive({
          geometryInstances: polygonInstanceHArray,
          appearance: new Cesium.PerInstanceColorAppearance({
            closed: true,
          }),
          releaseGeometryInstances: false,
          asynchronous: false,
        });
        let primitivePolygonOutLineHPrimitive = new Cesium.Primitive({
          geometryInstances: polygonInstanceOutLineHArray,
          appearance: new Cesium.PerInstanceColorAppearance({
            flat: true,
            renderState: {
              lineWidth: Math.min(1.0, window.viewer.scene.maximumAliasedLineWidth),
            },
          }),
          releaseGeometryInstances: false,
        });
        threeDimensionalGridprimitives.add(polygonPrimitive);
        threeDimensionalGridprimitives.add(polygonOutlinePrimitive);
        threeDimensionalGridprimitives.add(primitivePolygonHPrimitive);
        threeDimensionalGridprimitives.add(primitivePolygonOutLineHPrimitive);
        window.viewer.scene.primitives.add(threeDimensionalGridprimitives);
        // 将单个区域网格信息添加到全局变量中
        threeDimensionalGridprimitivesList.push({
          'id': id,
          'primitiveCollection': threeDimensionalGridprimitives,
          'polygonPrimitive': polygonPrimitive,
          'polygonHPrimitive': primitivePolygonHPrimitive,
          'bounds': grids.bounds,
        });
      }
    },
    /**@Description :  ******************************************* 清除网格码
     **@Date: 2024-06-27 10:37:40
    */
    removeGrids() {
      for (let t = 0; t < threeDimensionalGridprimitivesList.length; t++) {
        threeDimensionalGridprimitivesList[t].primitiveCollection.removeAll();
      }
      threeDimensionalGridprimitivesList = [];
    },
    /**@Description :  ******************************************* 网格码开启或者关闭
     **@Date: 2024-06-27 11:01:25
    */
    handleGridSwitch(v) {
      if (v) {
        if (threeDimensionalGridprimitivesList.length == 0) {
          // 重新生成
          let defaultGridColor = 'rgba(0, 255, 194, 0.03)';//默认网格码颜色
          let gridOutLineColor = 'rgba(135, 135, 135, 0.2)';//网格码边框颜色
          let intersectColor = 'rgba(255,170,37, 0.35)';//和航线相交的网格码颜色
          gridFacesList = [];
          this.initGridsByOriginDataAndDistance(300, 300, 8, defaultGridColor, gridOutLineColor);
          this.addAirLineGrid(intersectColor, 300, 8);
        } else {
          // 显示隐藏-控制显示
          for (let t = 0; t < threeDimensionalGridprimitivesList.length; t++) {
            let length = threeDimensionalGridprimitivesList[t].primitiveCollection.length;
            for (let i = 0; i < length; ++i) {
              const p = threeDimensionalGridprimitivesList[t].primitiveCollection.get(i);
              p.show = true;
            }
          }
        }
      } else {
        for (let t = 0; t < threeDimensionalGridprimitivesList.length; t++) {
          let length = threeDimensionalGridprimitivesList[t].primitiveCollection.length;
          for (let i = 0; i < length; ++i) {
            const p = threeDimensionalGridprimitivesList[t].primitiveCollection.get(i);
            p.show = false;
          }
        }
      }
    },
    /**@Description :  ******************************************* 航线所在网格码变色
     **@Date: 2024-06-29 17:57:19
    */
    addAirLineGrid(intersectColor, exHeight, tier) {
      let lineDatasWUHU = [
        {
          index: 0,
          flightName: '高圆村-合肥工业大学宣城小区',
          deviceName: '联飞芜湖Q20#001',
          startTime: '2024.03.24 12:00:00',
          endTime: '2024.03.24 12:30:00',
          crossGridColor: 'rgba(223, 70, 66, 0.5)',
          bufferColor: 'rgba(255,170,37, 0.1)',
          data: [
            { "lon": 109.7186490, "lat": 31.0292229, "alt": 597.0 },
            { "lon": 109.7074453, "lat": 31.0625827, "alt": 1816.0 }
          ]
        }
      ];
      for (let ii = 0; ii < lineDatasWUHU.length; ii++) {
        // 添加航线
        let lineSegment = [];
        lineSegment = this.addAirLineEntity(lineDatasWUHU[ii], 'rgba(255, 255, 255, 0.5)', 2);
        // 计算航线网格
        for (let i = 0; i < threeDimensionalGridprimitivesList.length; i++) {
          // 该区域的网格primitive
          let gridsPrimitives = threeDimensionalGridprimitivesList[i].polygonPrimitive;
          let gridsHPrimitives = threeDimensionalGridprimitivesList[i].polygonHPrimitive;
          let id = threeDimensionalGridprimitivesList[i].id;
          let instanceArray = gridsHPrimitives.geometryInstances;
          let instanceHArray = gridsHPrimitives.geometryInstances;
          // 对primitive进行遍历
          for (let j = 0; j < instanceArray.length; j++) {
            let instance = instanceArray[j];
            let insId = instance.id;
            let face = [];
            gridFacesList.forEach(v => {
              if (v.id == insId) {
                face = v.face;
              }
            });
            for (let m = 0; m < tier; m++) {
              // 判断每一个立方体跟航线是否相交
              let isIns = cesiumSpaceAPI.judgeSegmentCubeIntersect(face, lineSegment);
              console.log('isIns', isIns);
              if (isIns) {
                instance.attributes.color.value = Cesium.ColorGeometryInstanceAttribute.toValue(Cesium.Color.fromCssColorString(intersectColor));
              }
            }
          }
        }
      }
    },
    /**@Description :  ******************************************* 生成航线线段
     **@Date: 2024-06-29 18:13:45
    */
    addAirLineEntity(lineDataAll, airLineColor, airLineWidth) {
      let lineData = lineDataAll.data;
      let lineSegment = [];
      for (let i = 0; i < lineData.length - 1; i++) {
        let p1 = lineData[i];
        let p2 = lineData[i + 1];
        p1.height = p1.alt;
        p2.height = p2.alt;
        lineSegment.push([p1, p2]);
      }
      return lineSegment;
    },
    /**@Description :  ******************************************* 获取网格的6个面
     **@Date: 2024-06-29 19:41:49
    */
    getGridFaces(item, h, eh) {
      //底
      let face11 = [
        { lon: item.r, lat: item.b, height: h },
        { lon: item.r, lat: item.t, height: h },
        { lon: item.l, lat: item.t, height: h },
        { lon: item.l, lat: item.b, height: h },
      ];
      //前
      let face12 = [
        { lon: item.r, lat: item.b, height: h },
        { lon: item.r, lat: item.b, height: eh },
        { lon: item.l, lat: item.b, height: eh },
        { lon: item.l, lat: item.b, height: h },
      ];
      //顶
      let face13 = [
        { lon: item.r, lat: item.b, height: eh },
        { lon: item.r, lat: item.t, height: eh },
        { lon: item.l, lat: item.t, height: eh },
        { lon: item.l, lat: item.b, height: eh },
      ];
      //后
      let face14 = [
        { lon: item.r, lat: item.t, height: h },
        { lon: item.r, lat: item.t, height: eh },
        { lon: item.l, lat: item.t, height: eh },
        { lon: item.l, lat: item.t, height: h },
      ];
      //右
      let face15 = [
        { lon: item.r, lat: item.b, height: h },
        { lon: item.r, lat: item.b, height: eh },
        { lon: item.r, lat: item.t, height: eh },
        { lon: item.r, lat: item.t, height: h },
      ];
      //左
      let face16 = [
        { lon: item.l, lat: item.b, height: h },
        { lon: item.l, lat: item.b, height: eh },
        { lon: item.l, lat: item.t, height: eh },
        { lon: item.l, lat: item.t, height: h },
      ];
      let faceList = [face11, face12, face13, face14, face15, face16];
      return faceList;
    }
    /******************************************************** 添加网格码结束 *****************************************************/
  }
};
</script>

<style lang="scss" scoped>
.collapse {
  transform: translateX(-100%);
  transition: 0.3s;
}

.page-observe-fckernel-uav {
  // border: 1px solid #ff0000;
  width: 100%;
  height: 100%;
  color: #fff;
  box-sizing: border-box;
  background-color: #0A293A;
  padding: 0 5px;

  .header {
    width: 100%;
    height: 52px;
    background: #224D68;
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 10px;

    span {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 19px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .header-hx-btn {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      background-color: rgb(8, 170, 185);
      color: rgb(255, 255, 255);
      border-radius: 2px 2px 2px 2px;
      border: 1px solid rgba(255, 255, 255, 0.16);
      cursor: pointer;
      box-sizing: border-box;
      padding: 2px 3px;
    }

    .header-hx-btn-unselect {
      background-color: transparent;
      color: #00F5FF;
    }
  }

  .card-list {
    height: calc(100% - 115px);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #0A293A;

    .card {
      height: auto;
      box-sizing: border-box;
      padding: 10px;
      margin-top: 3px;

      .title {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // display: none;
        box-sizing: border-box;
        padding: 0 10px;
        // border: 1px solid red;
        font-weight: 600;
        font-size: 16px;

        span {
          margin-left: 6px;
        }
      }

      .card-t {
        width: 100%;
        height: 166px;
        background-color: #133144;
        // border: 1px solid red;
        width: 100%;
        margin: 5px 0;
        border-radius: 4px;

        .card-uav {
          height: 25%;
          width: 100%;
          // border:1px solid #ff0000;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background: #163B53;
          box-sizing: border-box;
          padding: 0 10px;
        }

        .card-uav-header {
          height: 25%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            // border:1px solid #ff0000;
            overflow: hidden;
            display: block;
            white-space: nowrap;
            /* 防止文本换行 */
            text-overflow: ellipsis;
            /* 超出部分显示省略号 */
            box-sizing: border-box;
            padding-right: 10px;
          }

          .right {
            margin-right: 0px;
            // background-color: #007AFF;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
              width: 40px;
              max-width: 60px;
              height: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              text-align: left;
              font-style: normal;
              text-transform: none;
              border-radius: 2px 2px 2px 2px;
              border: 1px solid rgba(255, 255, 255, 0.16);
              cursor: pointer;
              margin-right: 4px;
            }

            .btn-wj {
              background: rgba(255, 119, 119, 0.2);
              color: #FF7777;
            }

            .btn-hx {
              // background: rgba(0, 245, 255, 0.2);
              // background-color: transparent;
              // color: #00F5FF;
              // background-color: 'rgb(8, 170, 185)';
              background-color: rgb(8, 170, 185);
              color: rgb(255, 255, 255);
            }

            .btn-bh {
              background: rgba(165, 251, 255, 0.2);
              color: #A5FBFF;
            }
          }

          box-sizing: border-box;
          padding: 0 10px;
        }

        .card-uav-info {
          .left {
            width: 75%;
            height: 100%;
            // background-color: #007AFF;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

          }

          .right {
            width: 40%;
            height: 100%;
            // background-color: #231fff;
            display: flex;
            align-items: center;
            justify-content: flex-end;

          }

          .n {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-left: 10px;
            display: flex;
            flex-direction: column;

            .status-offline {
              color: #c0c0c0;
            }

            .status-online {
              color: #3CE171;
            }
          }

          box-sizing: border-box;
          padding: 0 10px;
        }

        .card-uav-date {
          background-color: #234D69;
          display: flex;
          align-items: center;
          justify-content: center;

          .n {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-left: 10px;
          }
        }

        .card-uav-plan {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 14px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
    }

    .card-selected {
      box-sizing: border-box;
      border: 1px solid #807c7c;
      border-radius: 4px;
    }
  }

  .foot {
    height: 60px;
    width: 100%;

    .p {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 334px;
      height: 40px;
      background: #007AFF;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
      margin: 0 auto;
      border: 0;
      font-size: 18px;
    }
  }

  .uav-info-panel {
    position: fixed;
    bottom: 20px;
    left: 28%;
    //border: 1px solid red;
    min-height: 100px;
    min-width: 800px;
    width: 60%;
  }

  .videoBox {
    /* width: 382px; */
    // border: 1px solid #D8D8D8;
    position: fixed;
    top: 2%;
    right: 20px;
    // top: -95px;
    // padding-bottom: 10px;
    // left: 496px;
    width: 587px;
    height: 347px;
    height: auto;
  }

}

.grid-btn {
  position: fixed;
  top: 18px;
  right: 340px;
  border: 0;
  z-index: 1000;
  // background-color: rgba(18, 89, 111, 1.0);
  color: rgba(4, 208, 221, 1.0);
}

.place-search {
  position: fixed;
  top: 10px;
  right: 20px;
  border: 0;
  z-index: 1000;
}
</style>
<style lang="scss">
@import '@/styles/wuhuCommon.scss';
</style>