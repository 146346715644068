<template>
  <div class="wih100 ht100">
    <div class="nest_search pl16 mt16">
      <div class="flexs waikuang">
        <el-input
          size="mini"
          v-model="search.key"
          class="input-name"
          placeholder="请输入任务名称"
          clearable
        />
      </div>
      <div class="flexs waikuang shijanSelect">
        <el-date-picker
          size="mini"
          prefix-icon="ccc"
          v-model="pickTime"
          type="datetimerange"
          range-separator="至"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          popper-class="flight-log"
        ></el-date-picker>
      </div>
      <el-button size="mini" class="search__btn" @click="onSearch">搜索</el-button>
    </div>
    <div class="lists" v-if="messageList.length>0" >
      <div class="info_single" v-for="item in messageList" :key="item.id">
        <div class="name">{{item.exceptionTitle}}</div>
        <div class="task">{{item.taskTile}}</div>
        <div class="time">{{item.addTime}}</div>
      </div>
    </div>
    <div v-else class="cf tc" style="margin:100px auto"> 
      暂无数据！
    </div>
    <div class="page">
      <el-pagination
        small
        :page-sizes="[10, 20, 30, 40]"
        :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize"
        layout="prev, pager, next"
        :total="pagination.totalCount"
      ></el-pagination>
      <!-- <div class="zhuan">
        <span>前往</span>
        <input type="text" v-model="qianVal" />
        <span>页</span>
      </div>-->
    </div>
  </div>
</template>

<script>
import API from "@/api";

export default {
  data() {
    return {
      search: {
        key: null,
        type: "",
        pageNo: 1,
        pageSize: 10,
      },
      pickTime: null,
      qianVal: null,
      messageList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 100,
      },
    };
  },
  props: {
    type: {
      type: String,
    },
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.search.type = this.type;
    this.onSearch();
  },
  methods: {
    async onSearch() {
      this.search.deviceId = this.device.id;
      if (this.pickTime) {
        this.search.startTime = this.pickTime[0];
        this.search.endTime = this.pickTime[1];
      } else {
        this.search.startTime = null;
        this.search.endTime = null;
      }
      let res = await API.FCKERNEL.findAll(this.search);
      this.pagination.totalCount = res.total;
      this.messageList = res.data;
    },
  },
};
</script>

<style scoped lang='scss'>
.nest_search {
  display: flex;
  .waikuang {
    width: 170px;
    height: 32px;
    background: #02173d;
    border-radius: 2px;
    border: 1px solid #315ec7;
    align-items: center;
    margin: 0 14px 0 0;
  }
  .search__btn {
    padding: 7px 25px 6px 26px;
    // background: rgba(38, 71, 238, 0.71);
    background: url("~@/assets/images/observe/twobg.png");
    border-radius: 4px;
    border: 1px solid;
    font-size: 14px;
    color: #fff;
  }
  .flexs {
    display: flex;
  }
  .shijanSelect {
    width: 300px;
  }
  // 所有输入框样式
  /deep/ .waikuang .input-name .el-input__inner {
    background: rgba(13, 50, 92, 0) !important;
    border: 0px solid #43deff !important;
    align-items: center !important;
    padding-left: 5px !important;
  }
  /deep/.el-date-editor .el-range-input {
    background: rgba(2, 31, 51, 0.1);
    // width: 390px;
    color: #fff;
  }

  //时间选择器样式
  /deep/ .waikuang .el-range-input {
    height: 32px !important;
  }

  /deep/ .waikuang .el-date-editor {
    padding: 0;
    border: 0px solid;
    height: 32px;
    background: rgba(13, 50, 92, 0) !important;
  }
}
.lists {
  height: 290px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  .info_single {
    padding: 12px 16px;
    margin-top: 16px;
    width: 612px;
    height: 58px;
    background: url("~@/assets/images/observe/fckernel/health/listbg.png")
      no-repeat;
    background-size: 100% 100%;
    .name {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffbd36;
      margin-bottom: 18px;
    }
    .task {
      float: left;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ebf9ff;
    }
    .time {
      float: right;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: rgba(235, 249, 255, 0.8);
    }
  }
}
.page {
  padding-right: 16px;
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  /deep/.el-pager .number:hover {
    border-radius: 2px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    background: rgba(38, 71, 238, 0.71) !important;
    width: 14px;
    height: 22px;
    line-height: 22px;
  }

  /deep/ .el-pagination--small .el-pager .number {
    background: transparent;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #889fb2;
  }
  /deep/.el-pagination button {
    background: transparent;
    color: #889fb2;
    opacity: 0.3;
  }
  /deep/.el-pagination .el-pager .more {
    background: transparent;
    color: #889fb2;
  }
  /deep/.active {
    color: #ffffff;
    background: rgba(38, 71, 238, 0.71) !important;
  }
  .zhuan {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #b6d4ff;
    input {
      width: 60px;
      height: 24px;
      text-align: center;
      border-radius: 2px;
      border: 1px solid #b6d4ff;
      outline: 0px solid;
    }
  }
}
</style>