<template>
  <div class="page-observe-nest_v2">
    <!-- 头部 -->
    <Header :device="nest.data" :uav-data="uav.data" @nest_chenge="nestchange" @changeF="$emit('changeF')"
      :isSelectFlag="flag" :uav-battery="uav.battery" @fn="(data, type) => mqtt_fn(data, type)" :nest-data="nest.jcData">
    </Header>

    <div class="nsetLeftBox" :class="{ w410: flag, collapse: collapseFlag }">
      <img @click="collapseFlagfn" v-if="collapseFlag" style="transform: rotate(180deg)" src="@/assets/img/i26.png"
        class="icon-collapse nsetLeftBox_btn icon-img icon-img-1" :class="nest.Videoflag && !flag ? 'nestbtn' : ''" />

      <img @click="collapseFlagfn" v-else src="~@/assets/img/i26.png"
        class="icon-collapse nsetLeftBox_btn  icon-img icon-img-2" :class="nest.Videoflag && !flag ? 'nestbtn' : ''" />
      <!-- 机巢列表 -->
      <NestList v-show="flag" @close="closeFn" v-model="nest.selected" :async-list="nest_list" :list="nest.list"
        @change="nest_change" @item-location="nest_location" />

      <!-- 展示视频 -->
      <div class="left-video" v-if="nest.Videoflag && !flag">
        <div class="left-video-header">
          <div class="changelist" @click="flag = true">机巢列表</div>
          <div class="nest-name">
            <span class="nest-name__text">{{ nest.data.name }}</span>
            <span class="nest-name__text">{{ nest.data.name }}</span>
          </div>
        </div>
        <div class="left-video-nest">
          <PlayerInner class="fl" v-show="!mapisStatus.flag || mapisStatus.num != 1" @close="uav_video_close('in')"
            v-if="uav.video.in" :data="uav.video.in" />
          <div v-else />
          <PlayerUav class="fr" v-show="!mapisStatus.flag || mapisStatus.num != 2" v-if="uav.video.uav"
            @close="uav_video_close('uav')" :data="uav.video.uav" />
          <div v-else />
        </div>
        <PlayerOuter class="left-video-uav" @imgUrl="getimgUrl" :device="nest.data" @directive="mqtt_move_directive"
          v-if="uav.video.out" v-show="!mapisStatus.flag || mapisStatus.num != 0" @close="uav_video_close('out')"
          :data="uav.video.out" />
      </div>
      <div class="xing" v-if="yun == true">
        <!-- 运行监测 -->
        <Power v-if="powerFlag && !flag" :wrjType="uav.wrjType" :jc-data="nest.jcData" :qxz-data="nest.qxzData"
          :nest-data="nest.jcData" />
      </div>
    </div>
    <!-- 全屏视频 start   -->
    <PlayerOuter class="full-screen-video _in_video" :device="nest.data" @directive="mqtt_move_directive"
      v-if="uav.video.out" v-show="nest.Videoflag &&
        mapisStatus.type === 'yc' &&
        mapisStatus.flag &&
        mapisStatus.num == 0
        " @close="uav_video_close('out')" :data="uav.video.out" />
    <PlayerInner class="full-screen-video _in_video" :device="nest.data" @directive="mqtt_move_directive"
      v-if="uav.video.in" v-show="nest.Videoflag &&
        mapisStatus.type === 'yc' &&
        mapisStatus.flag &&
        mapisStatus.num == 1
        " @close="uav_video_close('in')" :data="uav.video.in" />
    <PlayerUav class="full-screen-video _in_video" :device="nest.data" @directive="mqtt_move_directive"
      v-if="uav.video.uav" v-show="nest.Videoflag &&
        mapisStatus.type === 'yc' &&
        mapisStatus.flag &&
        mapisStatus.num == 2
        " @close="uav_video_close('uav')" :data="uav.video.uav" />
    <!-- 全屏视频 end -->

    <div class="xing" v-if="yun == true">
      <!-- 运行监测 -->
      <Power v-if="powerFlag && !flag" :wrjType="uav.wrjType" :jc-data="nest.jcData" :qxz-data="nest.qxzData"
        :nest-data="nest.jcData" />
    </div>
    <Tools ref="ToolsRef" v-show="false" :video="nest.video" @video-play="uav_video_play" />
    <!-- 日志信息 -->
    <Logger :wrjType="uav.wrjType" :flag="flag" :jc-data="nest.jcData" @exit="loggerFlag = false" v-if="loggerFlag"
      :list="nest.msg_list" @clear-msg="nest.msg_list = []" @step="stepShow = true"></Logger>
    <Step v-if="stepShow" :data="nest.data" @close="stepShow = false" />
    <!-- 机巢气温，湿度，烟感信息展示 -->
    <Temp :device="{ deviceName: nest.data.name }" :uav-data="uav.data" v-if="!nest.flag" :weather-data="nest.weather" />

    <!--底部数据状态栏 -->
    <Control :data="uav.channelData" v-if="nest.data && ControlFlag" :uav-battery="uav.battery" :offline="uav.offline"
      :uav-data="uav.data" @fn="(data, type) => mqtt_fn(data, type)"></Control>
    <VideoTomap v-if="!flag" @showVideo="changeBtn(true)"></VideoTomap>
    <!--创建航线-->
    <AirwayEdit :data="nest.data" :flag="true" @add="airway_add" v-if="isAirwayEdit" :isShow="false" @quit="AirwayQuit">
    </AirwayEdit>
    <!-- 任务列表-->
    <TaskList @Lsdom="Lsdom" @taskType="taskTypeFn" @airway-display="uav_airway" @iconShow="iconShow = true"
      :data="nest.data" ref="TaskListRef" @fn="(data, type) => mqtt_fn(data, type)" @airway-view="airway_view"
      @craeteRoute="CraeteRoute" v-if="nest.controlMenuFlag" @createTaskClick="createTaskClick"
      @closeflyLog="loggerFlag = false"></TaskList>
    <!-- 右边菜单栏 -->
    <ControlMenu @Lsdom="Lsdom" :device="nest.data" @clearId="uav_clearIdFn" @uav-location="uav_location"
      @imgUrl="getimgUrl" :uavData="uav.data" @airway-display="uav_airway" :taskType="taskType" :wrjType="uav.wrjType"
      :jcData="nest.jcData" :mount="mount" :offline="uav.offline" :uav-mounts="uav.mounts"
      :uav-mounts-payload="uav.mounts_payload" @yxrz="loggerFlag = !loggerFlag" :iconShow="iconShow"
      @fn="(data, type) => mqtt_fn(data, type)" v-if="nest.data" @changeTask="onChangeTask"></ControlMenu>
    <!-- 创建定时任务 -->
    <CreateTask @fn="(data, type) => mqtt_fn(data, type)" :CreateTaskType="CreateTaskType" v-if="CreateTaskFlag" @exit="
      CreateTaskFlag = false;
    nest.controlMenuFlag = true;
    "></CreateTask>
    <el-dialog title :visible.sync="endRenwu" width="30%" style="margin-top: 20vh">
      <div class="endrenwu">
        <div class="tishiyu">温馨提示</div>
        <div class="queding">检测到无人机即将到达站点， 是否需要结束任务？</div>
        <div class="btn_kuang">
          <div class="btn btn_lan" @click="endRenwu = false">取消</div>
          <div style="width: 20px"></div>
          <div class="btn btn_lv" @click="queding">确定</div>
        </div>
      </div>
    </el-dialog>
    <div class="imgshowurl" v-if="imgshow">
      <div @click="imgshow = false" class="close">关闭</div>
      <img :src="imgshowurl" />
    </div>
    <!-- 侧边栏 -->
    <SideBar :selectedFlag="1" class="side-bar"></SideBar>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import AirwayEdit from "./components/airway-edit";
import Header from "./components/header/index";
import TaskList from "./components/taskList/index";
import NestList from "./components/list/index";
import Power from "./components/power/index";
import Tools from "./components/tools";
import Temp from "./components/temp";
import Control from "./components/control/index";
import API from "@/api";
import ControlMenu from "./components/control/controlMenu/index";
import CreateTask from "./components/control/createTask";
import VideoTomap from "./components/control/videoTomap";
import Logger from "./components/control/logger/index";
import Step from "./components/control/step";
import PlayerInner from "./components/uav/video/inner";
import PlayerOuter from "./components/uav/video/outer";
import PlayerUav from "./components/uav/video/uav";
// 侧边栏
import SideBar from '../../home/components/sideBar';

import { mapGetters } from "vuex";
import data from "./data";
import bus from "@/utils/bus";
import methods from "./methods";
// 引用bus
import Bus from "@/assets/ligature.js";
export default {
  components: {
    Header,
    NestList,
    Power,
    Tools,
    Temp,
    PlayerInner,
    PlayerOuter,
    PlayerUav,
    Control,
    Logger,
    Step,
    AirwayEdit,
    TaskList,
    ControlMenu,
    CreateTask,
    VideoTomap,
    SideBar,
  },
  data () {
    return {
      ...data,
      imgshow: false, //拍照图片展示
      imgshowurl: null,
      endRenwu: false,
      flag: true, //true为展示机巢列表， false为展示机巢详情
      // 日志
      loggerFlag: false,
      //流程
      stepShow: false,
      // 运行监测
      powerFlag: true,
      // 创建航线窗口
      isAirwayEdit: false,
      // 创建任务
      CreateTaskFlag: false,
      // 左下角切换视频
      VideoTomapFlag: false,
      // 下方展示数据面板
      ControlFlag: true,
      iconShow: false,
      // 是否展示一键任务按钮 当 值为itemB 时不展示
      taskType: "",
      Model: "",
      CreateTaskType: "",
      collapseFlag: false,
      UAVvideo: false, //飞控 机巢 无人机视频刷新
      count: 0, // 飞控 机巢 刷新计数
      videoCount: 0, //飞控 机巢 刷新计数
      oneClicktask: false, //一键任务
      ShutdownCount: 0, //关机计时
      yun: false, // 运行状态开关
    };
  },
  provide () {
    return {
      uav_This: this,
      findList: (search) => {
        if (search) {
          this.uav_list(search);
        } else {
          this.uav_list(search);
        }
      },
      mqtt_fn: this.mqtt_fn,
      uav: this.uav,
    };
  },
  watch: {
    "uav.data": {
      handler (uavData, oldVal) {
        let { modeList } = this.uav;
        // 飛行模式
        let flightMode = modeList.find((e) => e.data == uavData?.flightMode);
        // 离机巢距离
        let home = uavData?.distanceToHome?.toFixed(2);
        // 飞行高度
        try {
          let relativeAlt = uavData?.gps?.relativeAlt.toFixed(2);
          let waypointList = JSON.parse(sessionStorage.getItem("waypointList"));
          // 当飞行模式为 返航模式时，并且 飞行高度 低于一米、记录home点 距离低于一米 且有执行的任务 ，就提示是否需要结束任务
          if (
            flightMode?.label == "返航模式" &&
            relativeAlt < 0.2 &&
            home < 1 &&
            waypointList
          ) {
            this.endRenwu = true;
          }
        } catch (error) { }
      },
      deep: true,
    },

    "nest.data": {
      handler (newVal, oldVal) {
        this.uav_closeIconShow();
        if (newVal) {
          this.$store.commit("device/SET_VIDEO", true);
          this.mqtt_init();
        } else {
          //关闭旧飞机的mqtt连接
          this.mqtt_close();
          this.$store.commit("device/SET_VIDEO", false);
        }
      },
      deep: true,
    },
    "uav.channelData": {
      handler (value) { },
      deep: true,
    },
    collapseFlag (newVal) {
      // 搜索关键字：飞控机巢左侧任务框位置判定(侧边栏关闭状态)
      Bus.$emit("close_ce", newVal);
      this.$store.commit("mapmanage/SET_YC_MAP_COLLAPSE", newVal);
    },
    flag (val) {
      if (val) {
        this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
          flag: false,
          type: "yc",
        });

        Bus.$emit("videos_flag", false);
      }
      // 搜索关键字：飞控机巢左侧任务框位置判定
      Bus.$emit("ren_task", val);
    },
    UAVvideo: function (vla, old) {
      // 监听mqtt的值，有258则表示开机
      if (vla == true) {
        console.log("已接收258");
        // 刷新视频
        // 关键字：视频刷新
        Bus.$emit("flushed_result");
      } else {
        Bus.$emit("flushed_result");
      }
    },
  },
  props: {
    weather: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["user_info", "situation_detail", "mapisStatus"]),
  },
  mounted () {

  },
  methods: {
    ...methods,
    getimgUrl (val) {
      this.imgshow = true;
      this.imgshowurl = val;
    },

    // 结束任务确认框，确定结束任务
    async queding () {
      let waypointList = JSON.parse(sessionStorage.getItem("waypointList"));
      if (waypointList) {
        // 结束任务
        API.DEVICE.task_update({
          id: waypointList.flyTypeId,
          status: 4,
          deviceHardId: waypointList.uavDeviceId,
        });
        // this.$emit("fn", { type: 525 }, "wrj");
        // 飞控结束任务
        this.mqtt_fn(
          {
            type: 769,
          },
          "wrj"
        );
        // 修改机巢绑定任务
        API.FCKERNEL.updateTaskAndUserRelation({
          taskId: waypointList.flyTypeId,
        });
        this.uav_clearIdFn();
        sessionStorage.removeItem("waypointList");

        // this.$message.success("操作成功");
      } else {
        this.$message.warning("暂无飞行任务");
      }
      this.endRenwu = false;
    },
    //点击任务按钮
    onChangeTask (open) {
      if (open === undefined) {
        this.nest.controlMenuFlag = !this.nest.controlMenuFlag;
      } else {
        this.nest.controlMenuFlag = open;
      }
    },
    ...mapMutations("device", ["TOOLBAR_IS_SHOW", "IS_SHOW_FACTOR_ICONS"]),
    taskrenwu () {
      // 一键任务显示模型和轨迹
      // 关键词：一键任务
      // 调用显示无人机轨迹和定位的方法
      this.uav_one_click_task();
    },
    collapseFlagfn () {
      // 列表页面 关闭时 this.collapseFlag=false 并且 flag 是ture 代表视频页面没出来
      if (this.nest.Videoflag && this.flag) {
        this.flag = false;
        this.collapseFlag = false;
        // flag flase 代表是视频详情页面
      } else if (this.nest.Videoflag && !this.flag) {
        this.collapseFlag = !this.collapseFlag;
      } else {
        this.collapseFlag = !this.collapseFlag;
      }

      this.$store.commit("fckernel/SET_NEST_COLLAPSE", this.collapseFlag);
    },
    closeFn () {
      this.flag = false;
    },
    taskTypeFn (item) {
      this.taskType = item;
    },
    Lsdom (item) {
      this.$emit("Lsdom", item);
    },
    // 关闭航线创建列表
    AirwayQuit () {
      this.isAirwayEdit = false;
      // 运行监测关闭
      this.powerFlag = true;
      this.nest.Videoflag = true;
      this.ControlFlag = true;
      this.loggerFlag = true;
      this.collapseFlag = false;
    },
    createTaskClick (item) {
      this.CreateTaskType = item;
      this.CreateTaskFlag = !this.CreateTaskFlag;
      if (!this.CreateTaskFlag) {
        this.loggerFlag = true;
      } else {
        this.loggerFlag = false;
      }
      this.nest.controlMenuFlag = false;
    },
    // 是否展示机巢
    nestchange (flag) {
      this.flag = flag;
      if (this.flag) {
        this.loggerFlag = false;
      }
      this.nest.controlMenuFlag = false;
    },
    // 创建航线
    CraeteRoute () {
      this.isAirwayEdit = true;
      // 运行监测关闭
      this.powerFlag = false;
      this.nest.Videoflag = false;
      this.ControlFlag = false;
      this.loggerFlag = false;
      this.collapseFlag = true;
    },
    // 开关视频
    changeBtn (flag) {
      if (flag) {
        this.nest.Videoflag = true;
        this.uav_video_play("in");
        this.uav_video_play("uav");
        this.uav_video_play("out");
      } else {
        this.nest.Videoflag = false;
        this.uav_video_close("in");
        this.uav_video_close("uav");
        this.uav_video_close("out");
      }
    },
    auxiliary (data, type) {
      this.mqtt_fn(data, type);
    },
  },
  provide () {
    return {
      nest_this: this,
      async_nest_list: this.nest_list,
      nest_list: () => this.nest.list,
      nest_selected: () => this.nest.selected,
      async_airway_drd: this.airway_drd,
    };
  },
  inject: ["g_cesium_layer"],
  async created () {
    // 运行状态检测
    Bus.$on("yunxing", (status) => {
      if (status == true) {
        this.yun = true;
      } else {
        this.yun = false;
      }
    });
    await this.airway_init_data_source();
    // 清除轨迹模型
    // 关键词：飞控机巢任务结束
    Bus.$on("closeIconShow", () => {
      this.uav_closeIconShow();
    });
    // 一键任务显示模型和轨迹
    // 关键词：一键任务
    // 被调用的方法：oneClicktask变为true
    Bus.$on("onetask", () => {
      this.oneClicktask = true;
    });
    // 运行状态检测
    Bus.$on("yunxing", (status) => {
      if (status == true) {
        this.yun = true;
      } else {
        this.yun = false;
      }
    });
  },
  beforeDestroy () {
    this.airway_destroy_data_source();
    if (this.mqtt.client) {
      this.mqtt.client.end();
      this.mqtt.client = null;
    }
    this.ws_destroy();
    this.nest.data = null;
    sessionStorage.removeItem("waypointList");
    this.loggerFlag = false;
    this.powerFlag = true;
    this.isAirwayEdit = false;
    this.CreateTaskFlag = false;
    this.VideoTomapFlag = false;
    this.ControlFlag = true;
    this.iconShow = false;
    this.nest.Videoflag = false;
    this.nest.controlMenuFlag = false;
    this.nest.flag = true;
    this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
      flag: false,
      type: "yc",
    });
  },
};
</script>
<style scoped lang="scss">
.collapse {
  transform: translateX(-100%);
  transition: 0.3s;
}

.nsetLeftBox {
  // z-index: 1;
  // display: none;
  position: absolute;
  left: 80px;
  top: 64px;
  height: 87vh;
  width: 382px;
  transition: 0.3s;
  border-radius: 10px 10px 0px 0px;

  // background: rgba(9, 32, 87, 0.7);
  // border: 1px solid #70daf9;
  .nsetLeftBox_btn {
    position: absolute;
    cursor: pointer;
    // right: -22px;
    right: 10px;
    // top: 42%;
    top: 15px;
    // margin-top: -90px;
    width: 22px;
    height: 72px;
    z-index: 99;
  }

  .nestbtn {
    // top: 530px;
    top: 15px;
    right: -22px;
  }

  .icon-img {
    width: 21px;
    height: 24px;
  }

  .icon-img-1 {
    right: -20px;
    top: 0;
    background: #224D68;
  }

  .left-video {
    width: 382px;
    height: 386px;
    position: absolute;
    left: 0px;
    top: 308px;
    padding: 48px 14px 16px;
    box-sizing: border-box;
    background: rgba(12, 34, 73, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(26, 92, 246, 0.5);
    z-index: 10;

    .left-video-header {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 32px;
      background-image: linear-gradient(180deg,
          #9198ff 0%,
          rgba(45, 81, 153, 0.22) 40%,
          #05091a 100%);
      border-radius: 10px 10px 0 0;
      // border: 1px solid #70daf9;
      box-shadow: inset 0 0 10px 2px #3f9dff;
      box-sizing: inset 0px 0px 10px 2px #3f9dff;
      background: url("~@/assets/img/line.png") no-repeat;
      background-position: 60%;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #70daf9;
      display: flex;
      // justify-content: center;
      align-items: center;
    }

    .left-video-nest::v-deep {
      // display: flex;
      box-sizing: border-box;

      &>div {
        position: relative;
        flex: 1;
      }

      & * {
        min-width: initial;
        min-height: initial;
      }

      .cpt-common-float-player {
        position: static;
        width: 170px;
        height: 100px;
        bottom: 0;

        .title-box {
          top: 0;

          .cfc {
            font-size: 12px !important;
          }
        }

        .box {
          width: auto;
          top: -35px;
          position: relative;

          .video-box {
            width: auto;
          }
        }

        .vjs-control {
          width: 34px;
        }

        .reset {
          bottom: 21px;
          right: 17px;
        }
      }
    }

    .left-video-uav::v-deep {
      margin-top: 17px;

      .cpt-common-float-player {
        .title-box {
          top: 0;

          .cfc {
            font-size: 12px !important;
          }
        }

        .box {
          width: auto;
          top: -35px;
          position: relative;

          .video-box {
            width: auto;
          }
        }

        .reset {
          bottom: 48px;
          right: 1px;
        }

        .infop {
          bottom: 48px;
        }
      }
    }
  }

  .videoBox {
    width: 350px;
    height: 65vh;
    position: absolute;
    left: 0px;
    top: 190px;
  }
}

.acitve {
  color: #70daf9;
}

.changeBtn {
  position: absolute;
  right: calc(50% - 350px);
  width: 140px;
  height: 40px;
  z-index: 10;
  background: center url("~@/assets/images/observe/fckernel/nest/btnDefault.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: -8px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #70f6f9;
}

.changeBtn:hover {
  background: center url("~@/assets/images/observe/fckernel/nest/btnActiveRight.png");
  background-size: contain;
  background-position-y: -8px;
  background-repeat: no-repeat;
  font-size: 14px;
  color: #ffe9ae;
  letter-spacing: 0;
  font-weight: 700;
}

.full-screen-video {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 50px;

  ::v-deep .cpt-common-float-player {
    top: 24px !important;
  }
}

.xing {
  position: fixed;
  right: 460px;
  top: 12%;
}

.changelist {
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 16px;
  width: 86px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  background: url("~@/assets/img/nestlist.png");
  color: #fff;
}

.nest-name {
  margin-left: 30px;
  margin-right: 20px;
  width: 250px;
  overflow: hidden;
  white-space: nowrap;

  .nest-name__text {
    display: inline-block;
    white-space: nowrap;
    animation: 15s scrollTitle linear infinite;
    min-width: 100%;
    padding-right: 50px;
    box-sizing: border-box;
    font-family: YouSheBiaoTiHei;
    background-image: -webkit-linear-gradient(right,
        #e3aa77,
        #f5cda9,
        #f9ecd3,
        #fcdbb1,
        #edb07a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
    font-weight: 100;
  }
}

@keyframes scrollTitle {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.endrenwu {
  width: 100%;
  height: 177px;
  border: 1px solid #70daf9;
  background: rgba(9, 32, 87, 0.7);
}

.queding {
  color: #92d9ff;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 30px 0 0 0;
}

.tishiyu {
  color: #92d9ff;
  font-size: 18px;
  margin: 30px 0 0 0;
  text-align: center;
  width: 100%;
}

.btn_kuang {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  width: 79px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  margin: 20px 0 0 0;
  cursor: pointer;
}

.btn_lan {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #606266;
}

.btn_lv {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #fff;
}

/deep/ .el-dialog__header {
  margin-left: 0;
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
  z-index: 2023 !important;
}

/deep/ .el-dialog {
  z-index: 2022 !important;
  background: transparent !important;
}

.imgshowurl {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba($color: #000000, $alpha: 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;

  img {
    width: 1000px;
  }

  .close {
    color: #fff;
    margin-left: 1000px;
    margin-bottom: 16px;
    // position: absolute;
    // right: 10px;
    // top: 10px;
  }
}

._in_video {
  /deep/ .cpt-common-float-player {
    width: 100% !important;
    position: fixed !important;
    top: 50px !important;
    right: 0 !important;
    z-index: 0 !important;
  }

  /deep/.video-box {
    width: 100% !important;
    height: 100% !important;
  }

  /deep/.title-box {
    width: 63%;
    padding-right: 20px;
    margin-left: 480px;
  }

  /deep/ .iconBG {
    right: 50px;
    height: 35px !important;
    padding-top: 5px;
    border-radius: 10px;
    width: 80px;
    display: flex;
    justify-content: space-evenly;
    background-image: url("./img/bg.png") !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .reset {
      width: 18px;
      height: 18px;
      margin-top: 7px;
      background: url("./img/img3.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-left: 0px;
    }

    .infop {
      width: 20px;
      height: 20px;
      z-index: 4;
      margin-left: 0px;
      margin-top: 7px;
      background: url("./img/img2.png") !important;
      background-size: cover !important;
      background-repeat: no-repeat;
    }

    .infop:hover {
      transform: scale(1.5);
    }

    .reset:hover {
      transform: scale(1.5);
    }
  }
}

.side-bar {
  position: absolute;
  top: 64px;
  left: 0;
  z-index: 100;
}
</style>
