import API from "@/api";
import { Utils } from "@/lib/cesium";
let airline_entitys = [];
let air_line_preview = [];
let b = ""; // 接收速度
export default {
  async createFn() {
    if (this.taskType == "itemB") {
      this.$emit("createTaskClick", this.taskType);
    } else {
      let res = await API.AIRWAY.getNestApprovedTask({
        nestId: this.nest_this.nest.data.id,
      });
      this.zqTaskList = res;
      this.dialogVisible = true;
    }
    let viewer = window.viewer;
    if (airline_entitys?.length) {
      airline_entitys.forEach((item) => {
        viewer.entities.remove(item);
      });
    }
    let res = {};
    air_line_preview.forEach((item) => {
      viewer.entities.remove(item);
    });
  },
  close() {
    this.$nextTick(async () => {
      let viewer = window.viewer;
      if (airline_entitys?.length) {
        airline_entitys.forEach((item) => {
          viewer.entities.remove(item);
        });
      }
      let res = {};
      air_line_preview.forEach((item) => {
        viewer.entities.remove(item);
      });

      await this.init();
      await this.initTask();
      this.flightId = "";
      this.flyTypeId = "";
      this.flight = [
        {
          flightName: "",
        },
      ];
      this.taskCate = 1
    });
  },
  // 获取任务列表
  async init() {
    let res = await API.AIRWAY.getNestApprovedTask({
      nestId: this.nest_this.nest.data.id,
    });
    this.TaskList = [
      {
        taskTitle: "选择航线自动生成任务",
        id: "z1322",
      },
      ...res,
    ];
    // 周期任务
    let FlightLineList = await API.AIRWAY.Drd({
      sourceType: 2,
      nestId: this.nest_this.nest.data.id,
    });
    this.flightList = FlightLineList;
  },

  // 预览航线
  showRoute(item) {
    console.log("item", item);
    this.handleAirLinePreview(item, true);
  },
  changeBtn(item) {
    this.taskType = item;
    this.$emit("taskType", item);
    if (this.taskType == "itemA") {
      this.init();
      if (this.rwtime) {
        clearInterval(this.rwtime);
        this.rwtime = null;
      }
    }
    if (this.taskType == "itemB" || this.taskType == "itemC") {
      this.getTimingTaskListByNestId();
      if (this.rwtime) {
        clearInterval(this.rwtime);
        this.rwtime = null;
      }
      this.rwtime = setInterval(() => {
        this.getTimingTaskListByNestId();
      }, 15000);
    }
  },
  // 待执行 和执行中任务
  async getTimingTaskListByNestId() {
    let taskType = null;
    if (this.taskType == "itemC") {
      //周期
      taskType = 1;
    } else if (this.taskType == "itemB") {
      //零时
      taskType = 0;
    }
    let res = await API.NEST.getTimingTaskListByNestId({
      nestId: this.nest_this.nest.data.deviceHardId,
      taskType,
    });
    this.rwList = res;
  },
  task_update(status) {
    API.DEVICE.task_update({
      id: this.flyTypeId,
      status,
      deviceHardId: this.nest_this.nest.data.deviceList[0].deviceHardId,
    });
  },
  startFlight(data) {
    // 开始任务
    this.$emit(
      "fn",
      {
        data,
        type: 769,
      },
      "wrj"
    );
  },
  // async start(item) {
  //     this.$emit("fn", this.data[item].data, "wrj");
  //     // 更改任务状态 status 任务执行状态 默认-1待派发 0表示待执行；3执行中;1执行完成
  //     this.task_update(3)
  //     // 生成架次號
  //     let getFlightSortic = await API.DEVICE.getFlightSortic({
  //         taskId: this.flyTypeId,
  //         deviceHardId: this.nest_this.nest.data.deviceList[0].deviceHardId,
  //     });
  //     this.startFlight({
  //         taskId: this.flyTypeId,
  //         flightSortiesID: getFlightSortic,
  //     })
  //     this.$message.success("操作成功");

  // },
  end(item) {
    // 结束任务
    this.task_update(4);
    this.$emit("fn", this.data[item].data, "wrj");
    // 飞控结束任务
    this.startFlight(null);
    this.flyTypeId = null;
    this.init();
    (this.flight = [
      {
        flightName: "",
      },
    ]),
      sessionStorage.removeItem("waypointList");
    this.$message.success("操作成功");
  },
  unlock() {
    if (this.unlockValue) {
      this.ws = new WebSocket("ws://127.0.0.1:8802");
      this.ws.onmessage = (e) => {
        let reader = new FileReader();
        console.log(e.data, "e.data");
        reader.onload = (event) => {
          let data = event.currentTarget.result;
          data = JSON.parse(data).data;
          this.$emit(
            "fn",
            {
              type: 519,
              data,
            },
            "wrj"
          );
        };

        reader.readAsText(e.data);
      };

      // 更改为定点模式
      // this.$emit('fn', {
      //     type: 513,
      //     data: "POSITION",
      // }, "wrj")
      this.$message.success("操作成功");
    }
  },
  plan() {
    if (this.airwayUploadData.totalWaypointCount) {
      return parseInt(
        (this.airwayUploadData.uploadedWaypointIndex /
          this.airwayUploadData.totalWaypointCount) *
          100
      );
    } else {
      return "";
    }
  },
  handle_airway_change() {
    if (!this.flyTypeId) return this.$message.warning("请选择任务");
    this.$emit("airway-view", this.flight[0]);
  },
  upload__airway() {
    if (!this.flyTypeId) return this.$message.warning("请选择任务");
    this.$emit("fn", { type: 521, data: this.flightLineData.data }, "wrj");
    this.$message.success("操作成功");
  },

  // 航线预览
  async handleAirLinePreview(item, state) {
    // console.log(item, state, "d--------------");
    // 获取航线速度
    let a = JSON.parse(item.flightCourseJson);
    b = a.line.baseSpeed;

    try {
      let viewer = window.viewer;
      if (airline_entitys?.length) {
        airline_entitys.forEach((item) => {
          viewer.entities.remove(item);
        });
      }
      let res = {};
      air_line_preview.forEach((item) => {
        viewer.entities.remove(item);
      });
      if (state) {
        res.flightCourseJson = item.flightCourseJson;
      } else {
        if (item.flightLineId) {
          res = await API.TASK.JingQingTrack({
            taskId: item.flightLineId,
          });
        }
      }
      //     // 有航点高度和距离的代码
      if (res?.flightCourseJson) {
        let positions = [];
        let center = [];
        let polyline = JSON.parse(res.flightCourseJson);
        let label_arr = [];
        let _this = this;

        polyline.points.forEach((item, index) => {
          positions.push(item.lon, item.lat, item.alt);
          label_arr.push(
            Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
          );
          let point_entity = viewer.entities.add({
            position: Cesium.Cartesian3.fromDegrees(
              item.lon,
              item.lat,
              item.alt
            ),
            name: "show_airline_point",
            point: {
              pixelSize: 20,
              color: Cesium.Color.red,
              color: Cesium.Color.fromCssColorString("red"),
            },
            label: {
              text: String(item.alt) + "m",
              scale: 0.5,
              font: "bold 28px Microsoft YaHei",
              // fillColor: Cesium.Color.BLUE,
              fillColor: Cesium.Color.fromCssColorString("#FF7F09"),
              horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.CENTER,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              showBackground: false,
              outlineWidth: 0,
              pixelOffset: new Cesium.Cartesian2(25, -10),
            },
          });
          let point_entity_num = viewer.entities.add({
            name: "airline_point",
            // id: uuid,
            position: Cesium.Cartesian3.fromDegrees(
              item.lon,
              item.lat,
              item.alt
            ),
            point: {
              pixelSize: 10,
              color: Cesium.Color.red,
              color: Cesium.Color.fromCssColorString("red"),
              // fillColor: Cesium.Color.red,
              // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND, // supermap版本会导致拖动显示错误
            },
            label: {
              text: new Cesium.CallbackProperty(() => {
                return String(index + 1);
              }, false),
              // text:index + 1,
              font: "bold 14px Microsoft YaHei",
              // fillColor: Cesium.Color.BLUE,
              fillColor: Cesium.Color.fromCssColorString("white"),
              horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.CENTER,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              showBackground: false,
              outlineWidth: 0,
              pixelOffset: new Cesium.Cartesian2(0, 0),
            },
          });
          airline_entitys.push(point_entity);
          airline_entitys.push(point_entity_num);

          if (label_arr.length > 1) {
            let before = label_arr[label_arr.length - 2];
            let after = label_arr[label_arr.length - 1];

            _this.create_label(before, after);
          }
        });
        positions = Cesium.Cartesian3.fromDegreesArrayHeights(positions);
        let redLine = viewer.entities.add({
          name: "Red line on terrain",
          polyline: {
            positions,
            width: 4,
            material: Cesium.Color.fromCssColorString("#00A9A9"),
          },
        });

        viewer.flyTo(redLine);
        airline_entitys.push(redLine);
      } else {
        this.$el_message("暂无航线", () => {}, "error");
      }
    } catch (error) {
      this.$el_message("暂无航线", () => {}, "error");
    }
  },

  // 显示距离
  create_label(before, after) {
    let viewer = window.viewer;
    let before_wgs84 = Utils.transformCartesian2WGS84(before);
    let after_wgs84 = Utils.transformCartesian2WGS84(after);

    let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
    let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
    let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

    let position = Utils.transformWGS842Cartesian({
      lng: center_lng,
      lat: cetner_lat,
      alt: alt + 10,
    });

    let text = `${Cesium.Cartesian3.distance(before, after).toFixed(
      2
    )} m   |   ${b}m/s`;

    let label_entity = viewer.entities.add({
      id: `label_${before}`,
      position: position,
      label: {
        text: text,
        scale: 0.5,
        font: "bold 30px Microsoft YaHei",
        fillColor: Cesium.Color.fromCssColorString("#fff"),
        horizontalOrigin: Cesium.VerticalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        showBackground: true,
        backgroundPadding: new Cesium.Cartesian2(10, 10),
      },
    });
    airline_entitys.push(label_entity);
  },
  /**
   * 清除航线
   */
  clearAirLine() {
    // console.log('wo被调用了');
    airline_entitys.forEach((item) => {
      window.viewer.entities.remove(item);
    });
    air_line_preview.forEach((item) => {
      window.viewer.entities.remove(item);
    });
  },
};
