<template>
  <div class="content">
    <div class="content_head" v-interact>
      <div class="left">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="font">车辆识别</div>
      </div>
      <div class="right" @click="() => $emit('closecar')">关闭</div>
    </div>
    <div class="content_box">
      <div class="items" v-show="!show">
        <div
          class="car_item"
          v-for="item in list"
          :key="item"
          @click="handleDetail(item)"
        >
          <div class="image">
            <img :src="baseUrl + item.imageUrl" alt="" />
            <div class="tip">{{ item.plateNum || "暂无" }}</div>
          </div>
          <div class="info">
            <div class="row">
              <div class="title">地点：</div>
              <div class="props">{{ item.findAddress || "暂无" }}</div>
            </div>
            <div class="row">
              <div class="title">时间：</div>
              <div class="props">{{ item.findTime || "暂无" }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="detail" v-show="show">
        <div class="image">
          <canvas id="canvas" ref="canvas"></canvas>
          <!-- <img :src="detail.imageUrl" alt="" /> -->
          <div class="tip">抓拍照片</div>
        </div>
        <div class="info">
          <div class="row" v-if="detail.plateNum">
            <div style="display: flex" v-if="detail.plateNum">
              <div class="title">车牌号：</div>
              <div class="props">{{ detail.plateNum || "暂无" }}</div>
            </div>
          </div>
          <div class="row" v-if="detail.carBrand">
            <div class="col" v-if="detail.carBrand">
              <div class="title">车辆品牌：</div>
              <div class="props">{{ detail.carBrand || "暂无" }}</div>
            </div>
          </div>
          <div class="row" v-if="detail.plateName">
            <div style="display: flex" v-if="detail.plateName">
              <div class="title">车主：</div>
              <div class="props">{{ detail.plateName || "暂无" }}</div>
            </div>
          </div>
          <div class="row" v-if="detail.telPhone">
            <div class="col" v-if="detail.telPhone">
              <div class="title">联系号码：</div>
              <div class="props">{{ detail.telPhone || "暂无" }}</div>
            </div>
          </div>
          <div class="row" v-if="detail.userCode">
            <div style="display: flex" v-if="detail.userCode">
              <div class="title">身份证/单位编号：</div>
              <div class="props">{{ detail.userCode || "暂无" }}</div>
            </div>
          </div>
          <div class="row">
            <div style="display: flex">
              <div class="title">是否有违法未处理：</div>
              <div class="props">
                {{
                  detail.illegalRecord
                    ? "有违法未处理"
                    : "无违法未处理" || "暂无"
                }}
              </div>
            </div>
            <div class="col" v-if="detail.carType">
              <div class="title">车辆种类：</div>
              <div class="props">{{ detail.carType || "暂无" }}</div>
            </div>
          </div>
          <div class="row" v-if="detail.carCode">
            <div style="display: flex">
              <div class="title">车辆识别代号：</div>
              <div class="props">{{ detail.carCode || "暂无" }}</div>
            </div>
          </div>
          <div class="row" v-if="detail.findAddress">
            <div style="display: flex">
              <div class="title">预警地点：</div>
              <div class="props">{{ detail.findAddress || "暂无" }}</div>
            </div>
          </div>
          <div class="row" v-if="detail.findTime">
            <div style="display: flex">
              <div class="title">识别时间：</div>
              <div class="props">{{ detail.findTime || "暂无" }}</div>
            </div>
          </div>
          <div class="row">
            <div style="display: flex">
              <div class="title">车辆轨迹：</div>
              <div class="props">{{ detail.locus && detail.locus.length || "0" }}条</div>
              <div class="cf" @click="add_zysb(detail.locus)">预览</div>
            </div>
          </div>
        </div>
        <div class="btn" @click="show = !show">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import data from "./data";
import { Viewer, Utils } from "@/lib/cesium";
export default {
  props: ["uavId","list"],
  inject: ["g_cesium_layer", "g_collection"],
  compute: {
    cesium_layer() {
      return this.g_cesium_layer();
    },
  },
  data() {
    return {
      // ...data,
      baseUrl: process.env.VUE_APP_IMG_URL,
      show: false,
      detail: {},
      image: null,
      canvas: null,
      ctx: null,
      gjshow: true,
    };
  },
  methods: {
    getAddress() {
      this.list.forEach(async (val) => {
        if (val.lon) {
          let address = await API.MAP.AiRegeo({
            location: `${val.lon},${val.lat}`,
          });
          val.findAddress =
            address.province.value +
            address.city.value +
            address.dist.value +
            address.road.roadname +
            address.poi;
        }
      });
      // this.handleDetail(this.list[0])
    },
    handleDetail(item) {
      this.show = true;
      this.detail = item;
      this.getcanvas(item);
    },
    getcanvas(val) {
      // let scale = 1;
      setTimeout(() => {
        this.canvas = this.$refs.canvas;
        this.canvas.width = 436;
        this.canvas.height = 255;
        console.log(this.canvas);
        this.image = new Image();
        this.image.src = this.baseUrl + val.imageUrl;
        this.image.onload = () => {
          this.drawImage(val.yiSaPlateDetection);
        };
      }, 1500);
    },
    drawImage(plate_detection) {
      this.ctx = this.canvas.getContext("2d");
      this.ctx.drawImage(
        this.image,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      plate_detection.x =
        (this.canvas.width * plate_detection.x) / this.image.width;
      plate_detection.y =
        (this.canvas.height * plate_detection.y) / this.image.height;
      plate_detection.h =
        (this.canvas.height * plate_detection.h) / this.image.height;
      plate_detection.w =
        (this.canvas.width * plate_detection.w) / this.image.width;
      this.ctx.lineWidth = 2;
      this.ctx.strokeStyle = "yellow";
      this.ctx.strokeRect(
        plate_detection.x,
        plate_detection.y,
        plate_detection.w,
        plate_detection.h
      );
    },
    async get_car_list() {
      if (!this.uavId) {
        this.$message.error("请选择无人机");
        return;
      }
      let data = {
        uavId: this.uavId,
      };
      let res = await API.HOME.getflightvideoMsg(data);
      // console.log("get_face_list", res);
      this.list = res || [];
      // console.log("车牌this.list", this.list);
    },

    add_zysb(res) {
      if(!res) return this.$message.error("暂无轨迹！")
      let cesium_layer = this.g_cesium_layer();
      cesium_layer.fly_to_globe();
      let cate_name = "zysb";
      let collection = this.init_collection(cate_name);
        let list = res;
        list.forEach((item) => {
          item.longitude *= 1;
          item.latitude *= 1;
          if (item.latitude && item.longitude) {
            collection.add({
              position: Utils.transformWGS842Cartesian({
                lng: item.longitude,
                lat: item.latitude,
                alt: 0,
              }),
              image: require(`@/assets/images/factor/gj.png`),
              scale: 1,
              width: 54,
              height: 89,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              id: JSON.stringify({
                ...item,
                entityType: "zdys",
              }),
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                0,
                2.2e5
              ),
              scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
            });
          }
        });
        this.gjshow = !this.gjshow
        if(this.gjshow){
          this.hide_collection(cate_name)
        }else{
          this.show_collection(cate_name);
        }
      },
    show_collection(cate) {
      let primitive = this.g_collection[cate];
      if (Cesium.defined(primitive)) {
        let viewer = window.viewer;
        let scene = viewer.scene;
        if (!scene.primitives.contains(primitive)) {
          scene.primitives.add(primitive);
        }
        primitive.show = true;
      }
    },
    hide_collection(cate) {
        let primitive = this.g_collection[cate]
        if (Cesium.defined(primitive)) {
            // let viewer = this.cesium_layer.viewer()
            let viewer = window.viewer
            let scene = viewer.scene
            if (scene.primitives.contains(primitive)) {
                // primitive.show = false
                scene.primitives.remove(primitive)
            }
        }
    },
    init_collection(cate) {
      console.log(cate, "......cate");
      let viewer = window.viewer;
      let scene = viewer.scene;
      if (Cesium.defined(this.g_collection[cate])) {
        scene.primitives.remove(this.g_collection[cate]);
      }
      this.g_collection[cate] = new Cesium.BillboardCollection({
            scene,
            show: true,
          });
    return this.g_collection[cate]
    },
  },
  mounted() {
    // this.get_car_list();
    this.getAddress();
    // this.handleDetail(this.list[0]);
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: fixed;
  z-index: 101;
  top: calc(50% - 120px);
  left: calc(50% + 80px);
  margin-right: 16px;
  width: 468px;
  max-height: 635px;
  margin-bottom: 190px;
  background: rgba(0, 39, 121, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
    inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
    inset 0px 0px 8px 0px #019aff;
  backdrop-filter: blur(2px);
  border-radius: 10px 10px 0px 0px;
  .content_head {
    display: flex;
    justify-content: space-between;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    align-items: center;
    padding: 0 16px;
    .left {
      display: flex;
      align-items: center;
      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .right {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  .content_box {
    width: 436px;
    max-height: 600px;
    padding: 0 16px;
    overflow: auto;
    position: relative;
    .items {
      width: 100%;
      height: 100%;
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      justify-content: space-between;
      .car_item {
        margin: 11px auto;
        width: 204px;
        height: 177px;
        border: 1px solid #43deff;
        padding: 4px 4px 0 4px;

        .image {
          position: relative;
          width: 204px;
          height: 118px;
          img {
            width: 204px;
            height: 118px;
          }
          .tip {
            position: absolute;
            bottom: 0;
            width: 204px;
            height: 16px;
            background: #000000;
            opacity: 0.8;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: #b7bfc8;
            text-align: center;
            line-height: 16px;
          }
        }
        .info {
          width: 204px;
          height: 53px;
          // border: 1px solid #43deff;
          background: url("~@/assets/newImage/info.png");
          .row {
            margin-left: 9px;
            padding-top: 7px;
            display: flex;
            .title {
              font-size: 14px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #b7cae2;
            }
            .props {
              width: 150px;
              height: 19px;
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #b7cae2;
              word-wrap: break-word;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          &:hover {
            background: url("~@/assets/newImage/infos.png");
          }
        }
      }
    }
    .detail {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      .image {
        position: relative;
        width: 436px;
        height: 255px;
        img {
          width: 436px;
          height: 255px;
        }
        .tip {
          position: absolute;
          bottom: 0;
          width: 436px;
          height: 31px;
          background: #000000;
          opacity: 0.8;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #b7bfc8;
          text-align: center;
          line-height: 31px;
        }
      }
      .info {
        // display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;
        .row {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          .col {
            display: flex;
          }
          .title {
            // flex: 1;
            flex-shrink: 0;
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 21px;
          }
          .props {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #c5ebff;
            line-height: 21px;
          }
        }
      }
      .btn {
        margin: 20px 0;
        // position: absolute;
        // bottom: 0;
        align-self: center;
        width: 79px;
        height: 32px;
        background: rgba(23, 70, 216, 0.2);
        box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
          inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
          inset 0px 0px 4px 0px #00a7ff;
        border-radius: 6px;
        // border: 1px solid;
        border-image: linear-gradient(
            180deg,
            rgba(138, 218, 255, 1),
            rgba(82, 179, 255, 0)
          )
          1 1;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #00ffff;
        text-align: center;
        line-height: 32px;
      }
    }
  }
}
</style>
