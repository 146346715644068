<template>
  <div class="search-info">
      <el-autocomplete popper-class="create-airline-keyword-search-panel" 
      :popper-append-to-body="false" class="autocomplete-dd" style="width:300px"
        v-model="destination.name" :fetch-suggestions="handle_query_address_async" placeholder="请输入地名名称搜索例如：芜湖高铁站" 
        :trigger-on-focus="false"
        @select="handle_select_address" clearable @clear="destroy_location_icon">
        <template slot-scope="{ item }">
          <div class="w-[300px]!">
            <span style="font-size: 14px; color: #333">{{ item.name }}</span>
            <span style="font-size: 12px; color: #999; margin-left: 12px">{{ item.address }}</span>
          </div>
        </template>
      </el-autocomplete>
    </div>
</template>
<script>
import API from '@/api';
import Utils from '@/lib/cesium/utils.js';
let location_icon = null;
export default {  
  data() {
    return {
      destination: {
        //地名搜索
        name: '',
        lng: 0,
        lat: 0
      }
    }
  },
  inject: ['g_cesium_layer'],
  computed: {
    cesium_layer() {
      return this.g_cesium_layer();
    }
  },
  methods:{
    // 地名搜索1
    handle_query_address_async(address, cb) {
      // console.log(address, cb, '输入搜索搜索');
      // API.MAP.KeyWordsTDT({ keywords: address }).then((res) => {
      // console.log('天地图地名返回值', res);
      //     cb(res.data.pois);
      // });
      API.MAP.KeyWordsGaoDe({ keywords: address }).then((res) => {
        console.log('高德地图地名返回值', res);
        cb(res.data.pois);
      });

    },
    // 地名搜索2
    handle_select_address(item) {
      console.log(item, '搜索输入');
      let viewer = window.viewer;
      viewer.trackedEntity && (viewer.trackedEntity = undefined);
      viewer.scene.globe.show = true;
      viewer.scene.skyAtmosphere.show = true;
      viewer.scene.globe.showGroundAtmosphere = true;
      // 模式关闭
      window.plate_data_source && (window.plate_data_source.show = false);
      if (item) {
        // let location = item.lonlat.split(',');
        let location = item.location.split(',');
        this.destination.name = item.name;
        this.destination.lon = Number(location[0]);
        this.destination.lat = Number(location[1]);
        let cesium_layer = this.g_cesium_layer();
        let viewer = cesium_layer.viewer();
        let potiion = Utils.transformWGS842Cartesian({
          lng: this.destination.lon,
          lat: this.destination.lat,
          alt: 50000
        });
        viewer.camera.flyTo({
          destination: potiion
        });
        this.init_location_icon(potiion, item);
      }
    },
    /**
     *@Description: 兴趣点添加到地图上
     *@Date: 2023-11-02 14:46:48
     *@Params1:
     *@Return1:
     */
    init_location_icon(position, item) {
      // console.log(item, 'item兴趣点');
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      if (location_icon) {
        // location_icon.position = position;
        viewer.entities.remove(location_icon);
      }
      location_icon = viewer.entities.add({
        position,
        billboard: {
          width: 36,
          height: 36,
          image: require('@/assets/images/icons/icon_location.png'),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.Bottom,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        },
        label: {
          // text: `${item.name}\r\n${item.address}`,
          text: `${item.name}`,
          orizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          showBackground: false,
          font: '13px monospace bolder',
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(0, -18),
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          fillColor: Cesium.Color.fromCssColorString('#FF0000') // 字体颜色
          // translucencyByDistance: new Cesium.NearFarScalar(0, 0, 1000000, 1),
        }
      });
    },
    destroy_location_icon() {
      if (!location_icon) {
        return location_icon;
      }
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      viewer.entities.remove(location_icon);
      location_icon = null;
    }
  },
  destroyed() {
    this.destroy_location_icon();
  },
}

</script>
<style lang="scss" scoped>
  .search-info {
    // position: fixed;
    // top: 80px;
    // right: 40px;
    // border: 1px solid yellow;
    z-index: 2;
    width: 300px;

    .autocomplete-dd {
      width: 100%;
      border: 0;
    }

    /deep/ .el-input__inner {
      color: #ffffff;
    }
  }
</style>
<style lang="scss">
.confirm-cuetomer-class {
  width: 376px;
  border: 0 !important;
  height: 195px;
  background: #0e3042 !important;

  .el-message-box__header {
    width: 375px;
    height: 40px;
    background: #133e55;
    padding: 0;

    .el-message-box__title {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff !important;
    }

    .el-message-box__headerbtn {
      display: none;
    }
  }

  .el-message-box__content {
    padding: 25px;

    .confirm-warning-icon-class {
      background-image: url('~@/assets/img/i94.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 20px;
      height: 20px;
    }

    .p {
      width: 280px;
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
    }
  }

  .el-message-box__btns {
    .el-button--default {
      width: 114px;
      height: 40px;
      background: #133e55;
      border-radius: 4px;
      color: #fff;
      /* width: 28px; */
      /* height: 20px; */
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      border: 0;
    }

    .el-button--primary {
      background: #007aff;
    }
  }

  .el-message-box__btns button:nth-child(2) {
    box-shadow: none;
  }
}

.create-airline-keyword-search-panel {
  // left: 0 !important;
  width: 300px !important;
  right: 100px;

  .el-scrollbar {
    width: 300px;

    .el-autocomplete-suggestion li:hover {
      background-color: #34466e !important;
    }

    li {
      box-sizing: border-box;
      padding: 0 10px;

      span {
        color: #ffffff !important;
      }
    }
  }
}

.el-autocomplete {
  border: 1px solid #04d5da;
  right: 0;
  background: rgba(22, 29, 45, 0.58);
  width: 300px;
}
</style>
