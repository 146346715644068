<template>
  <div>
    <FloatPlayer ref="player" @directive="(data) => { $emit('directive', data) }" :device="device" @close="$emit('close')"
      :type="dataList.type || 3" :data="dataList" />
    <el-tooltip class="cp pa right20 cf ai" content="AI识别" placement="bottom">
      <div @click="aiVisible = !aiVisible">
        <img src="@/assets/newImage/car.png" />
      </div>
    </el-tooltip>
    <div class="faceAndcar cp pa right70 cf" v-if="aiVisible">
      <el-tooltip content="人脸识别" placement="bottom">
        <div class="plan" @click="handle('a')">
          <img src="~@/assets/images/observe/faceAI2.png" />
        </div>
      </el-tooltip>
      <el-tooltip content="车辆识别" placement="bottom">
        <div class="plan" @click="handle('b')">
          <img src="~@/assets/images/observe/carAI2.png" />
        </div>
      </el-tooltip>
    </div>
    <el-tooltip content="拍照" placement="bottom">
      <div class="cp pa right20 cf plate" @click="photo">
        <img src="@/assets/newImage/ai.png" />
      </div>
    </el-tooltip>
    <FaceAI v-if="faceAiShow" :uavId="device.deviceList[0].deviceHardId" @closeface="faceAiShow = false" />
    <CarAI v-if="carAiShow" :uavId="device.deviceList[0].deviceHardId" :list="carList" @closecar="carAiShow = false" />
  </div>
</template>
<script>
import API from "@/api";

import FaceAI from "@/components/observe/uav/faceai";
import CarAI from "@/components/observe/uav/carai";
import FloatPlayer from "@/components/common/float-player";
import Bus from "@/assets/ligature.js";
import { dataURLToBlob, canvasToDataURL } from "@/utils/image-tool";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { FaceAI, CarAI },
  computed: {
    taskId () {
      return this.$store.state.fckernel.taskId;
    },
  },
  data () {
    return {
      dataList: {},
      type: 4,
      carList: [], //车牌识别结果
      aiVisible: false, //打开ai
      faceAiShow: false,
      carAiShow: false,
    };
  },
  watch: {

    "$store.state.fckernel.yc_video_status": {
      handler (newVal, oldVal) {
        this.getVideoFn(newVal);
      },
      deep: true,
    },
    "data": {
      handler (newVal, oldVal) {
        // console.log('===========================================');
        this.getVideoFn(this.$store.state.fckernel.yc_video_status);
      },
      deep: true,
      immediate: true,
    },
  },
  components: { FloatPlayer },
  mounted () {
    //视屏截图
    Bus.$on("take_photo", ({ callback }) => {
      console.log("take_photo on");
      let video = {};
      let scale = 1;
      video = document.getElementsByTagName("video")[0]; //
      video.useCORS = true; // 解决跨域
      video.crossOrigin = "Anonymous"; // 这两个参数必填 解决跨域
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth * scale;
      canvas.height = video.videoHeight * scale;
      var img = document.createElement("img");
      img.src = canvas.toDataURL("image/png");
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height); // 将video中的数据绘制到canvas里
      // const image = canvas.toDataURL("image/png");
      const image = canvasToDataURL(canvas);
      let blob = dataURLToBlob(image);
      callback && callback(blob);
    });
  },
  methods: {
    async getVideoFn () {
      this.dataList = JSON.parse(JSON.stringify(this.data));
      let lable = this.$store.state.fckernel.yc_video_status;
      console.log(lable, "lable");
      let deviceHardId = this.data.list[0].deviceHardId;
      console.log(deviceHardId, "deviceHardId");
      if (deviceHardId) {
        let data = await API.FCKERNEL.GetDeviceStreamAddressByDeviceId({
          deviceHardId: deviceHardId,
        });
        if (data.list && data.list) {
          if (lable == "公网") {
            let qingLiuUrl = data.list.public.qingLiuUrl;
            let webUrl = data.list.public.webUrl;
            if (data.list.public && qingLiuUrl) {
              this.dataList.vUrl = qingLiuUrl;
              this.dataList.type = 4;
            } else {
              this.dataList.vUrl = webUrl;
              this.dataList.type = 3;
            }
          } else if (lable == "专网") {
            let qingLiuUrl = data.list.private.qingLiuUrl;
            let webUrl = data.list.private.webUrl;
            if (data.list.public && qingLiuUrl) {
              this.dataList.vUrl = qingLiuUrl;
              this.dataList.type = 4;
            } else {
              this.dataList.vUrl = webUrl;
              this.dataList.type = 3;
            }
          }
        }
        this.$forceUpdate();
      }
    },
    async photo () {
      // if (!this.taskId) {
      //   this.$message.error("暂无任务！");
      //   return;
      // }
      let blob = this.screenShot();
      let fd = new FormData();
      fd.append("uploadFile", blob, `拍照.png`);
      if (this.taskId) {
        fd.append("taskId", this.taskId);
      }
      fd.append("deviceHardId", this.device.deviceList[0].deviceHardId);
      let res = await API.FCKERNEL.Upload(fd);
      console.log(res);
      this.$message.success("拍照成功！");
      this.imgshowurl = process.env.VUE_APP_IMG_URL + "/uploads" + res.fileKey;
      this.$emit("imgUrl", this.imgshowurl);
    },
    handle (command) {
      this.getcanvas(command);
    },
    screenShot () {
      console.log(this.$refs.player.$refs["live-nvr"]);
      return this.$refs.player.screenShot();
    },
    // 人脸车牌识别
    async getcanvas (command) {
      let blob = this.screenShot();
      let fd = new FormData();
      fd.append("uploadFiles", blob, `下载.jpeg`);
      let res = await API.MAPMANAGE.fileUpload(fd);
      if (command == "a") {
        this.aiuse(res[0].storage);
      } else {
        this.plate(res[0].storage);
      }
    },
    async aiuse (url) {
      let data = {
        imageUrl: url,
        deviceHardId: this.device.deviceList[0].deviceHardId,
      };
      this.$el_message("正在识别中...", () => { }, "info", 0);
      let res = await API.AIUSE.aiphoto(data);
      if (res.code == 200) {
        this.$el_message("识别成功！", () => { }, "success");
        this.faceAiShow = true;
      } else {
        this.$el_message(res.message, () => { }, "error");
      }
    },
    async plate (url) {
      let data = {
        imgUrl: url,
        deviceHardId: this.device.deviceList[0].deviceHardId,
      };
      this.$el_message("正在识别中...", () => { }, "info", 0);
      let res = await API.AIUSE.palteId(data);
      if (res.code == 200) {
        this.$el_message("识别完成", () => { }, "success");
        this.carList = res.data;
        this.carAiShow = true;
      } else {
        this.$el_message(res.message, () => { }, "error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .cpt-common-float-player {
    bottom: -10px;
  }
}

.ai {
  z-index: 10;
  bottom: 30%;
}

.plate {
  bottom: 20%;
  z-index: 10;
}

.faceAndcar {
  z-index: 10;
  width: 55px;
  bottom: 32%;
  display: flex;
  justify-content: space-between;

  .plan {
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 0 5px;
    background: rgba(9, 32, 87, 0.7);
  }
}

.box {
  .ai {
    z-index: 10;
    bottom: 60%;
  }

  .plate {
    bottom: 50%;
    z-index: 10;
  }

  .faceAndcar {
    bottom: 60.5%;
  }
}
</style>