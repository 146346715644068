import mount from "@/components/observe/mount"
import mqtt from "./mqtt"
import uav from "./uav"
import whxf from "./whxf"

export default {
    mount,
    mqtt,
    uav,
    whxf
}