<template>
  <div
    class="cpt-nest-logger"
    :class="{ chang: dakai == true, yidong: flag == true }"
  >
    <div class="title-box" v-interact>
      <div class="title pl20">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="font">运行监控日志</div>
      </div>
      <div style="display: flex">
        <div class="icon-box" @click="$emit('clear-msg')">
          <span class="iconfont icon-qingchushuju"></span>
          <!-- <span class="icon-text pr20">清除数据</span> -->
        </div>
        <!-- <div class="icon-box" @click="$emit('step')">
        <span class="icon-text">运行流程</span>
      </div> -->
        <div class="cf ml20 mr10 c70d" @click="$emit('exit')">关闭</div>
      </div>
    </div>
    <div class="mb24 mt14 pl20 pr20 jcsb liucBox">
      <div :class="folderFn(folder)">
        <span class="w10 mr4 h10 radius"></span>固定无人机
      </div>
      <div :class="coverFn(cover)">
        <span class="w22 line"></span>
        <span class="w10 mr4 h10 radius"></span>打开舱盖
      </div>
      <div :class="liftsFn(lifts)">
        <span class="w22 line"></span>
        <span class="w10 mr4 h10 radius"></span>升起平台
      </div>
      <div :class="folderFn3(folder)">
        <span class="w22 line"></span>
        <span class="w10 mr4 h10 radius"></span>释放无人机
      </div>
      <div :class="rktFn(wrjType)">
        <span class="w22 line"></span>
        <span class="w10 mr4 h10 radius"></span>无人机RTK定位
      </div>
    </div>
    <div class="ctx-box">
      <div class="head mt7">
        <div class="text">消息等级</div>
        <div class="text con">消息内容</div>
        <div class="time">时间</div>
      </div>
      <div class="list-box mt7">
        <div class="item-box" v-for="(item, index) in list" :key="index">
          <div class="text-box">
            <div
              class="type-box"
              :class="{ emerg: item.type === 1, ordinary: item.type === 8 }"
            >
              <span class="type">AUTO</span>
            </div>
            <div class="text">{{ item.text }}</div>
            <div class="time">{{ item.time }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="jiantou" @click="jiantou" :class="{ zhaun: dakai == true }">
      <img :src="jian" style="width: 100%; height: 100%" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jian: require("@/assets/zong_lan_ye_img/jiantou.png"),
      dakai: false, //变长变短
    };
  },
  props: {
    wrjType: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
    jcData: {
      type: Object,
      default: () => ({}),
    },
    flag: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    list: function (vla, old) {
      vla.forEach((item) => {
        if (item.text == "[回收流程][11-7]关闭舱盖...") {
          console.log("..............");
        }
      });
      // console.log(vla,'运行日志');
    },
    flag: function (val) {
      // console.log(val,"flagval");
    },
  },
  computed: {
    rtkPower() {
      let { jcData } = this;
      if (jcData.rtkPower) {
        return jcData.rtkPower;
      }
      return 0;
    },
    folder() {
      let { jcData } = this;
      if (jcData.folder) {
        return jcData.folder;
      }
      return 0;
    },
    lifts() {
      let { jcData } = this;
      if (jcData.lifts) {
        return jcData.lifts;
      }
      return 0;
    },
    cover() {
      let { jcData } = this;
      if (jcData.cover) {
        return jcData.cover;
      }
      return 0;
    },
  },
  methods: {
    jiantou() {
      if (this.dakai == false) {
        this.dakai = true;
      } else {
        this.dakai = false;
      }
    },
    handle_clear() {
      this.list = [];
    },
    folderFn(folder) {
      // 已降落 夹紧中
      if (this.lifts == 1 && folder == 3) {
        return "current";
      } else if (this.lifts == 1 && folder == 2) {
        return "default";
      } else {
        return "end";
      }
    },
    // 是否出仓
    coverFn(cover) {
      switch (cover) {
        case 0:
          return "default";
          break;
        case 4:
          return "current";
          break;
        case 2:
          return "end";
          break;
        default:
          return "default";

          break;
      }
    },
    // 升降
    liftsFn(lifts) {
      switch (lifts) {
        case 0:
          return "default";
          break;
        case 4:
          return "current";
          break;
        case 2:
          return "end";
          break;
        default:
          return "default";
          break;
      }
    },
    // 释放无人机
    folderFn3(folder) {
      // 已升起 放松中
      if (this.lifts == 2 && folder == 4) {
        return "current";
      } else if (this.lifts == 2 && folder == 2) {
        return "end";
      } else {
        return "default";
      }
    },
    rktFn(data) {
      if (data.code == 1000 && this.folder == 2 && this.lifts == 2) {
        return "end";
      } else if (this.folder == 2 && this.lifts == 2) {
        if (data.code != 1000) {
          return "current";
        } else {
          return "end";
        }
      } else {
        return "default";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.yidong {
  left: 52% !important;
}
.cpt-nest-logger {
  position: fixed;
  width: 670px;
  height: 143px;
  top: 21%;
  left: 32%;
  display: flex;
  // overflow-y: auto;
  background: rgba(9, 32, 87, 0.7);
  // border: 1px solid #70daf9;
  border-radius: 10px 10px 0 0;
  flex-direction: column;

  .liucBox {
    .default {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #afdcff;
      letter-spacing: 1px;
      font-weight: 400;
      .radius {
        display: inline-block;
        border-radius: 50%;
        border: 1px solid #afdcff;
        margin-left: 5px;
      }
      .line {
        height: 0.5px;
        display: inline-block;
        margin-bottom: 4px;
        border-top: 0.2px dashed #afdcff;
      }
    }

    .current {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #43deff;
      letter-spacing: 1px;
      font-weight: 400;
      .line {
        height: 0.5px;
        display: inline-block;
        margin-bottom: 4px;
        border-top: 1px solid #43deff;
        background: #43deff;
      }
      .radius {
        display: inline-block;
        border-radius: 50%;
        margin-left: 5px;
        background: #43deff;
        border: 1px solid #43deff;
      }
    }
    .end {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: rgba(67, 222, 255, 0.6);
      letter-spacing: 1px;
      font-weight: 400;
      .radius {
        display: inline-block;
        border-radius: 50%;
        margin-left: 5px;
        background: rgba(67, 222, 255, 0.7);
      }
      .line {
        height: 0.5px;
        display: inline-block;
        margin-bottom: 4px;
        border: 1px solid #afdcff;
        background: #afdcff;
      }
    }
  }

  .title-box {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .title {
      display: flex;
      align-items: center;
      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .icon-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      .iconfont {
        font-size: 18px;
        color: #00ffff;
        margin-right: 4px;
      }

      .icon-text {
        font-family: MicrosoftYaHeiUI;
        font-size: 14px;
        color: #ccedff;
        font-weight: 400;
      }
    }
  }

  .ctx-box {
    height: calc(100% - 120px);
    // flex: 1;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: SourceHanSansCN-Bold;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 1px;
      line-height: 22px;
      font-weight: 700;
      .text,
      .time {
        width: 20%;
        text-align: center;
      }
      .time {
        width: 35%;
      }
      .con {
        width: 50%;
      }
    }
    .list-box {
      overflow: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      .item-box {
        display: flex;
        align-items: center;
        color: #fff;
        padding: 11px 0;

        .text-box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .type-box {
            width: 20%;
            height: 20px;
            text-align: center;
            font-family: SourceHanSansCN-Medium;
            font-size: 14px;
            color: #ebf9ff;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;

            &.ordinary {
              background-color: #298ad3;
            }

            &.emerg {
              background-color: #ff3c3c;
            }

            .type {
              width: 100px;
              text-align: center;
              font-size: 10px;
              white-space: nowrap;
            }
          }

          .text {
            width: 50%;
            text-align: center;
            font-family: SourceHanSansCN-Medium;
            font-size: 14px;
            color: #ebf9ff;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
          }
          .time {
            width: 35%;
            text-align: center;
            font-family: SourceHanSansCN-Medium;
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
          }
        }
      }
      .item-box:nth-of-type(2n - 1) {
        background: rgba(73, 135, 210, 0.2);
      }
    }
  }

  .xb {
    transform: rotate(-180deg);
    background: rgba(1, 10, 46, 0.63);
    border-radius: 10px 10px 0 0;
  }
}
.jiantou {
  width: 22px;
  height: 22px;
  transform: rotate(90deg);
  cursor: pointer;
  margin: 0 auto;
}
.chang {
  height: 326px !important;
  top: 233px;
}
.zhaun {
  transform: rotate(270deg) !important;
}
.c70d {
  color: #70daf9;
}
</style>
