<template>
  <div class="livenvr">
    <LivePlayer
      aspect="fulllscreen"
      :class="{
        small: !videoFlag,
        'livenvr-player': videoFlag,
        vUrl: isStatus && !videoFlag,
        vUrlDeffault: !isStatus && !videoFlag,
      }"
      ref="livePlayer"
      :videoUrl="data.vUrl"
    />

    <LivePlayer
      v-if="fpvUrl.vUrl"
      :class="{
        vUrl: isStatus,
        vUrlDeffault: !isStatus,
        small: videoFlag,
        'livenvr-player': !videoFlag,
      }"
      ref="fpvPlayer"
      :controls="false"
      :videoUrl="fpvUrl.vUrl"
    />
  </div>
</template>

<script>
import LivePlayer from "@liveqing/liveplayer";
import { dataURLToBlob, canvasToDataURL } from "@/utils/image-tool";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    fpvUrl: {
      type: Object,
      default: () => ({}),
    },
    isStatus: {
      type: Boolean,
      default: () => false,
    },
    videoFlag: {
      type: Boolean,
      default: () => true,
    },
  },
  components: { LivePlayer },
  data() {
    return {
    };
  },
  watch: {
    fpvUrl: {
      handler(value, oldVal) {
        if(value.vUrl == oldVal?.vUrl){
          return;
        }
        if (value && value.vUrl) {
          this.$nextTick(() => {
            this.$refs["fpvPlayer"].play();
          });
        }
      },
      deep: true,
      immediate: true,
    },
    data: {
      handler(value, oldVal) {
        if(value.vUrl == oldVal?.vUrl){
          return;
        }
        // console.log('livenvr', value)
        if (value && value.vUrl) {
          this.$nextTick(() => {
            this.$refs["livePlayer"].play();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted(){
    window.player = this;
  },
  methods: {
    screenShot(){
      let video = {};
      let scale = 1;
      video = this.$refs.livePlayer.$el.querySelector('video');
      if(!video){
        return null;
      }
      video.useCORS = true; // 解决跨域
      video.crossOrigin = "Anonymous"; // 这两个参数必填 解决跨域
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth * scale;
      canvas.height = video.videoHeight * scale;
      var img = document.createElement("img");
      img.src = canvas.toDataURL("image/png");
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height); // 将video中的数据绘制到canvas里
      // const image = canvas.toDataURL("image/png");
      const image = canvasToDataURL(canvas);
      let blob = dataURLToBlob(image);
      return blob;
    }
  }
};
</script>

<style lang="scss" scoped>
.livenvr::v-deep{
  .livenvr-player {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: 1;
  }
  .small {
    width: 300px;
    height: 170px;
    position: absolute;
    right: 0;
  }
  .vjs-volume-panel,
  .vjs-playback-rate,
  .live-player-stretch-btn,
  .vjs-time-divider,
  .vjs-duration,
  .vjs-live-control {
    display: none !important;
  }
  @media all and (min-height: 1000px) {
    .vUrl {
      bottom: 7vh !important;
      right: 0px !important;
    }
  }
  .vUrl {
    bottom: 23vh;
    right: 10px;
  }

  .vUrlDeffault {
    bottom: 0;
}

  /* 隐藏画中画按钮 */
  .video-js .vjs-picture-in-picture-control .vjs-icon-placeholder {
      display: none !important;
  }

  /* 隐藏全屏按钮 */
  .video-js .vjs-fullscreen-control {
      display: none !important;
  }

  /* 隐藏截图按钮 */
  .video-js .vjs-control-bar .vjs-icon-spinner {
      display: none !important;
  }
  .vjs-control-bar{
      display: none !important;
  }
}

</style>